// export const FormatDate = (inputDate, type = 0, time = 0) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');

//     if (type === 1) { 
//         return `${year}-${month}-${day}`;
//     } else if (time === 1) { 
//         console.log("dasjdsyafghjklm;,");
//         const [datePart, timePart] = inputDate.split(' ')
//         const [hours, minutes] = timePart.split(':');
//         return `${year}-${month}-${day}T${hours}:${minutes}`;
//     }else {
//         return `${day}-${month}-${year}`; 
//     }
// };

// test code
export const newFormatdate = (timestamp , type = 0, time = 0, dateFormat=0) =>{
    if(type == 1)
        {
            const year = timestamp.substring(0, 4);
    const month = timestamp.substring(5, 7);
    const day = timestamp.substring(8, 10);
    const hours24 = parseInt(timestamp.substring(11, 13), 10); // Extract hours in 24-hour format
    const minutes = timestamp.substring(14, 16);

    // Determine AM/PM and convert hours to 12-hour format
    const ampm = hours24 >= 12 ? 'PM' : 'AM';
    let hours12 = hours24 % 12;
    hours12 = hours12 === 0 ? 12 : hours12; // Convert 0 to 12 for 12-hour format

    // Construct the formatted string
    const formatted = `${year}-${month}-${day} AT ${hours12}:${minutes} ${ampm}`;

    return formatted;
        }
       else if(time == 1)
        {
            const hours24 = parseInt(timestamp.substring(11, 13), 10); // Extract hours in 24-hour format
    const minutes = timestamp.substring(14, 16);

    // Determine AM/PM and convert hours to 12-hour format
    const ampm = hours24 >= 12 ? 'PM' : 'AM';
    let hours12 = hours24 % 12;
    hours12 = hours12 === 0 ? 12 : hours12; 
    return `${hours12}:${minutes} ${ampm}`

        }
    const year = timestamp.substring(0, 4);
    const month = timestamp.substring(5, 7);
    const day = timestamp.substring(8, 10);
    const hours = timestamp.substring(11, 13);
    const minutes = timestamp.substring(14, 16);

    // Construct the formatted string
    const formatted = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formatted;
};
export const FormatDate = (inputDate, type = 0, time = 0, dateFormat=0) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let  hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    if (type === 1) {
        return `${year}-${month}-${day}`;
    } else if (time === 1) {
        let  hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        hours = String(hours).padStart(2, '0');
        return `${year}-${month}-${day} AT ${hours}:${minutes}  ${ampm}`;
    } else if ( dateFormat ===  1){

        return `${day}-${month}-${year}`;
    }
     else {
        // return `${day}-${month}-${year}`;
        return `${year}-${month}-${day}T${hours}:${minutes}`;

    }
};
 
export const getTime = (inputDate, hour = 0, min = 0, today=0) => {
    var date = new Date(inputDate);
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    var  hours = String(date.getHours()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    var minutes = String(date.getMinutes()).padStart(2, '0');
    if (hour) {
        return  `${hours} : ${minutes} ${ampm}`;
    } else if (min) {
        return minutes;
    }  else if(today) {
        return day;
    } 
    else {
        // return `${year}-${month}-${day} AT ${hours}:${minutes}`;
        return `${year}-${month}-${day}`;

    }
}
export const Editable = (inputDate) => {
    const matchDate = new Date(inputDate);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - matchDate.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    if (Math.abs(hoursDifference) < 24) {
        return true;
    } else {
        return false;
    }

}

export const  format_2k_run_Time = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const seconds = Math.floor(remainingSeconds);
    const milliseconds = Math.floor((remainingSeconds - seconds) * 100);
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
  }

// export const  formatTimestamp = (timestamp)  => {
// const date = new Date(timestamp);

// // Format the date part (e.g., 24-Jan-2024)
// const day = date.getDate();
// const month = date.toLocaleString('default', { month: 'short' });
// const year = date.getFullYear();

// // Format the time part (e.g., 12:20 pm)
// let hours = date.getHours();
// const minutes = date.getMinutes();
// const ampm = hours >= 12 ? 'pm' : 'am';
// hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

// // Pad minutes with leading zero if needed
// const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

// return `${day}-${month}-${year} at ${hours}:${formattedMinutes} ${ampm}`;
// }

export const  formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);

  // Get date components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Months are zero-indexed, so add 1
  const day = date.getUTCDate();
  
  // Get time components
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  
  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format the date and time as a string
  const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${hours}:${String(minutes).padStart(2, '0')} ${ampm}`;

    return formattedDate;
  }