import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import PencilIcon from '../icons/PencilIcon';
import TNC from '../icons/TermNConditions';
import LogOutIcon from '../icons/LogoutIcon';
import FNQIcon from '../icons/faqIcon';
import CustomerCareIcon from '../icons/CustomerCareIcon';
import avatar from '../../assets/download (1).jpg'
import { Link } from 'react-router-dom';

const ScorerAppHeaderDropdown = () => {
  return (
    <CDropdown variant="nav-item" className='header-nav-dropdown'>
      <CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}>
        <CAvatar src={avatar} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="py-0 " placement="bottom-end">
        <Link to='/profile' className='text-decoration-none'>
          <CDropdownItem href="#" className='text-white py-2'>
            <PencilIcon />
              <span className='ps-2 '> Edit Profile </span>
          </CDropdownItem>
        </Link>

        <CDropdownItem href="#" className='text-white py-2'>
          <TNC />
          <span className='ps-2'> Terms & Conditions</span>
        </CDropdownItem>

        <CDropdownItem href="#" className='text-white py-2'>
          <CustomerCareIcon />
          <span className='ps-2'> Help & Support</span>
        </CDropdownItem>

        <CDropdownItem href="#" className='text-white py-2'>
          <FNQIcon />
          <span className='ps-2'> FAQ</span>
        </CDropdownItem>

        <CDropdownItem href="/logout" className='text-white py-2'>
            <LogOutIcon />
            <span className='ps-2'> Logout</span>
        </CDropdownItem>

      </CDropdownMenu>
    </CDropdown>
  )
}

export default ScorerAppHeaderDropdown
