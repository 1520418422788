import React, { useState, useEffect } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb'
import { FormatDate, newFormatdate } from '../../../../Common/CommonFunction'
import Loader from '../../Common/Loader'
import p1 from '../../../../assets/images/avatars/2.jpg'
import p2 from '../../../../assets/images/avatars/3.jpg'
import p3 from '../../../../assets/images/avatars/6.jpg'
import p4 from '../../../../assets/images/avatars/9.jpg'
import p5 from '../../../../assets/images/avatars/4.jpg'
import p6 from '../../../../assets/images/avatars/5.jpg'
import p7 from '../../../../assets/images/avatars/1.jpg'
import p8 from '../../../../assets/images/avatars/7.jpg'
import p9 from '../../../../assets/images/avatars/8.jpg'
import trophy from '../../../../assets/tropy.png'
import {
  GetTournamentList,
  stagestatus,
  winnerdetails,
  RoundMatchesAPI,
  AssignScorer,
  GetLogs,
  GetParticipantList
} from '../../../../API/admin'
import { enqueueSnackbar, useSnackbar } from 'notistack'
// import { FormatDate} from '../Common/CommonFunction'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

import { cilSave, cilX, cilGraph } from '@coreui/icons'

import { Card, CardBody } from 'react-bootstrap'

const Knockout = () => {
  // Manage AdminBreadcrumb\
    const url = window.location.href;
    console.log(url , "url");
    const queryString = url.split('?')[1];

// Split the query string by '=' and take the second part after '='
        const sportValue = queryString.split('=')[1];
        console.log(sportValue , "sportvalue");
  const maxmmatch = 64
  const initialEditModes = Array(maxmmatch).fill(false)
  const [visible, setVisible] = useState(false)
  const [editModes, setEditModes] = useState(initialEditModes)
  const adminBreadcrumbLinks = [
    { label: 'Dashboard', to: '/admin/dashboard', active: false },
    { label: 'Knockout', to: '/knockout', active: true }
  ]
  let arr = []
  let scoresss = []
  let date = []
  for (let i = 0; i < 65; i++) {
    arr.push({ p1: null, p2: null })
    scoresss.push('')
    date.push(null)
  }
  let [matchscorer, setmatchscorer] = useState(scoresss)
  let [players, setplayers] = useState(arr)
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE
  const [selectedGender, setSelectedGender] = useState('male')
  const [selectedRound, setSelectedRound] = useState(64)
  const [selectedRoundPlayer, setselectedRoundPlayer] = useState(128)
  // const [editMode, setEditMode] = useState(false);
  const [editMode, setEditMode] = useState(-1)
  // const [dateTime, setDateTime] = useState(FormatDate('10-05-2024 19:00', 0, 1));

  const [newDateTime, setNewDateTime] = useState(date)
  const [selectedTournament, setSelectedTournament] = useState('')
  const [tournamentList, setTournamentList] = useState([])
  const urlParams = new URLSearchParams(window.location.search)
  const [selectedgame, setseletedgame] = useState('badminton')
  const [round, setround] = useState([])
  let [loaderStatus, setloaderStatus] = useState(true)
  const [selectedroundname, setselectedroundname] = useState('round_of_128')
  let [winnerlist, setwinnerlist] = useState([])
  let [RoundMatches, setRoundmatches] = useState([])
  const [selectedPlayer1, setSelectedPlayer1] = useState(null)
  const [selectedPlayer2, setSelectedPlayer2] = useState(null)
  const [state, setstate] = useState(0)
  let [scorer, setscorer] = useState([])
  let [updtatedwinnerlist, setupdtatedwinnerlist] = useState(0)
  let [update, setupdate] = useState(0)
  const [playersList, setPlayersList] = useState([])
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [Logs, setLogs] = useState([])  
   useEffect(()=>{
    setseletedgame(sportValue);
    
   } , [])
  //    loaderStatus = true;
  useEffect(() => {
    let tournamentListApi = async () => {
      let res = await GetTournamentList()
      // let tornament_id = urlParams.get('tournament');
      //  (res);
      if (res.status) {
        if (res.data.length > 0) {
          setSelectedTournament(res.data[0]._id.toString())
        }
      }

      setTournamentList(res.data)
    }
    let GetParticipantListApi = async () => {
        let res = await GetParticipantList()
        let userData = []
        if (res.status) {

          res.data.forEach((item, index) => {
            userData[item._id.toString()] = item
          })
         console.log(userData); 
          setPlayersList(userData)
        }
      }
    tournamentListApi()
    GetParticipantListApi()
    //  (tournamentList);
    setloaderStatus(false)
  }, [])
  // console.log(RoundMatches);
  // console.log(players);
  function findscorer (scorer_id) {
    const sel_scorer = scorer.find(scorer => scorer.id === scorer_id)
    console.log(sel_scorer)
    if (sel_scorer != null) return sel_scorer.fname + ' ' + sel_scorer.lname
    else return ''
  }
  function formattime (isoDateString) {
    const date = new Date(isoDateString)

    // Extract date and time components
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are 0-based
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)

    // Create formatted date and time string
    const formattedDateTime = `${year}-${month}-${day},${hours}:${minutes}`
    return formattedDateTime
  }
  useEffect(() => {
    if (editMode == -1) {
      // console.log("call editmode true" );

      let win_player = []
      let winnerlistapi = async () => {
        setloaderStatus(true)
        if (
          selectedroundname != '' &&
          selectedgame != '' &&
          selectedGender != '' &&
          selectedTournament != ''
        ) {
          let formdata = {
            tournament_id: selectedTournament,
            game_type: selectedgame,
            gender: selectedGender,
            round: selectedroundname
          }
          let res = await winnerdetails(formdata)
          if (res.status) {
            let players = []
            if (selectedroundname == 'round_of_128') {
              let winners = res.data.data.winner
              let scorer = res.data.data.scorer
              //  (winners);

              //  (winners.length)
              for (let i = 0; i < winners.length; i++) {
                //  (winners[i]);
                for (let j = 0; j < winners[i].winner.length; j++) {
                  players.push({
                    name: `${winners[i].winner[j].fname} ${winners[i].winner[j].lname} (${winners[i].group_name})`,
                    id: winners[i].winner[j]._id,
                    image: winners[i].winner[j].profile_image,
                    flag: false
                  })
                }
              }
              // console.log("playerssssssssss");
              win_player = players
              console.log(players)
              // console.log(res);
              setscorer(scorer)
              setwinnerlist(players)
              setloaderStatus(false)
              setupdtatedwinnerlist(0)
              setstate(!state)
            } else {
              // console.log("yes");
              // console.log(res);
              let winners = res.data.data.winner
              let scorer = res.data.data.scorer
              //    console.log(winners);
              for (let i = 0; i < winners.length; i++) {
                // console.log(winners[i]);
                players.push({
                  name: `${winners[i][0].fname} ${winners[i][0].lname}`,
                  id: winners[i][0]._id,
                  image: winners[i][0].profile_image,
                  flag: false
                })
              }
              // console.log(players);
              setscorer(scorer)
              setwinnerlist(players)
              setloaderStatus(false)
              setupdtatedwinnerlist(0)
              setstate(!state)
            }
          } else {
            setloaderStatus(false)
          }
        }
      }
      console.log("selected tournament" , selectedTournament);
      // console.log(scorer);
      let roundmatch = []
      let callAPI = async () => {
        console.log("yeeeeeeeeeeeeeyeeeeeyeeeeeeeeeeeyeeeeeeeee");
        setloaderStatus(true)
        if (
          selectedroundname != '' &&
          selectedgame != '' &&
          selectedGender != '' &&
          selectedTournament != ''
        ) {
          let formdata = {
            tournament_id: selectedTournament,
            game_type: selectedgame,
            gender: selectedGender,
            round: selectedroundname
          }
          let res = await RoundMatchesAPI(formdata)
          console.log("resssssss status" , res);
          if (res.status) {
            // console.log("completed roundwinnerapi")
            roundmatch = res.data.data
            // console.log(roundmatch);
            setRoundmatches(res.data.data)
            setstate(!state)
            setloaderStatus(false)
          } else {
            setloaderStatus(false)

            enqueueSnackbar('Matches not found', { variant: 'error' })
          }
        }
      }

      console.log('called roundmatches api', selectedroundname)
      async function fetchData () {
        try {
          await callAPI() // Wait for callAPI() to complete
          winnerlistapi() // Then call winnerlistapi()
        } catch (error) {
          console.error('Error fetching data:', error)
          // Handle errors if necessary
        }
      }

      // Call fetchData to start the process

      //  console.log("yeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      fetchData()
    }
  }, [
    selectedTournament,
    selectedgame,
    selectedGender,
    selectedroundname,
    update
  ])
  // console.log(winnerlist);
  // console.log(scorer);
  console.log(RoundMatches)
  if (
    RoundMatches.length > 0 &&
    winnerlist.length > 0 &&
    updtatedwinnerlist == 0
  ) {
    let newwinnerlist = winnerlist
    console.log(RoundMatches)
    for (let i = 0; i < RoundMatches.length; i++) {
      if (RoundMatches[i].scorer_id != null) {
        matchscorer[i] = RoundMatches[i].scorer_id
      }
      if (RoundMatches[i].match_date != null) {
        console.log('yaeeeee')
        newDateTime[i] = newFormatdate(RoundMatches[i].match_date)
      }
      if (RoundMatches[i].player_1_id != null) {
        // console.log("yes");
        let player_details = {
          id: RoundMatches[i].player_1_id._id,
          name:
            RoundMatches[i].player_1_id.fname +
            ' ' +
            RoundMatches[i].player_1_id.lname,
          image: RoundMatches[i].player_1_id.profile_image,
          flag: true
        }
        players[i].p1 = player_details
      }
      if (RoundMatches[i].player_2_id != null) {
        let player_details = {
          id: RoundMatches[i].player_2_id._id,
          name:
            RoundMatches[i].player_2_id.fname +
            ' ' +
            RoundMatches[i].player_2_id.lname,
          image: RoundMatches[i].player_2_id.profile_image,
          flag: true
        }
        players[i].p2 = player_details
      }
      // console.log(RoundMatches[i].status);
      if (RoundMatches[i].status == 'completed') {
        console.log('yohoooo')
        console.log(newwinnerlist)
        newwinnerlist = newwinnerlist.filter(
          (item, index) =>
            item.id !== RoundMatches[i].player_1_id._id &&
            item.id !== RoundMatches[i].player_2_id._id
        )
        console.log(newwinnerlist)
      } else {
        newwinnerlist = newwinnerlist.map(item => {
          if (
            (RoundMatches[i].player_1_id != null &&
              item.id === RoundMatches[i].player_1_id._id) ||
            (RoundMatches[i].player_2_id != null &&
              item.id === RoundMatches[i].player_2_id._id)
          ) {
            return { ...item, flag: true } // Spread syntax used here
          }
          return item // Return unchanged item for other players
        })
      }
    }
    let newplayers = players
    winnerlist = newwinnerlist
    // console.log(newplayers);
    setplayers(newplayers)
    setmatchscorer(matchscorer)
    setwinnerlist(newwinnerlist)
    setNewDateTime(newDateTime)
    setupdtatedwinnerlist(1)

    // console.log(winnerlist);
    // console.log(players);
    setstate(!state)
  }
  useEffect(() => {
    setloaderStatus(true)
    //  ("yaaaaammmm");
    let Stagestatus = async () => {
      setloaderStatus(true)
      let formdata = {
        game: selectedgame,
        tournament_id: selectedTournament,
        gender: selectedGender
      }
      let res = await stagestatus(formdata)
      //  (res);
      if (res.status) {
        //    (res);
        let data = []
        let data2 = []
        if (res.data.data.round_of_128 == 1) {
          //  ("yes");
          data.push({
            value: 64,
            name: 'Round of 128',
            db_name: 'round_of_128'
          })
          // data2.push({value:64 , name: "round_of_28"});
        }
        if (res.data.data.round_of_64 == 1) {
          data.push({ value: 32, name: 'Round of 64', db_name: 'round_of_64' })
          // data2.push({value:32 ,});
        }
        if (res.data.data.round_of_32 == 1) {
          data.push({ value: 16, name: 'Round of 32', db_name: 'round_of_32' })
          // data2.push({value:16 , name:"round_of_32"});
        }
        if (res.data.data.round_of_16 == 1) {
          data.push({ value: 8, name: 'Round of 16', db_name: 'round_of_16' })
          // data2.push({value:8 , name:"round_of_16"});
        }
        if (res.data.data.quarter_final == 1) {
          data.push({
            value: 4,
            name: 'Quarter-finals',
            db_name: 'quarter_final'
          })
          // data2.push({value:4 , name:"quarter_final"});
        }
        if (res.data.data.semi_final == 1) {
          data.push({ value: 2, name: 'Semi-finals', db_name: 'semi_final' })
          // data2.push({value:2 , name:"semi_final"});
        }
        if (res.data.data.final == 1) {
          data.push({ value: 1, name: 'Final', db_name: 'final' })
          // data2.push({value:1 , name:"final"});
        }
        setround(data)
        if (data.length > 0) setselectedroundname(data[0].db_name)
        setloaderStatus(false)
      } else {
        setloaderStatus(false)
      }
    }
    Stagestatus()
    setSelectedRound(64)
  }, [selectedTournament, selectedgame, selectedGender])
  // console.log(scorer);
  const handleRoundChange = event => {
    //  (event.target.value);
    console.log('yesssssssss')
    setSelectedRound(parseInt(event.target.value))
    for (let i = 0; i < round.length; i++) {
      if (round[i].value == event.target.value) {
        setselectedroundname(round[i].db_name)
        break
      }
    }
    setselectedRoundPlayer(parseInt(event.target.value) * 2)
    setwinnerlist([])
    setmatchscorer(scoresss)
    setscorer([])
    setplayers(arr)
    setNewDateTime(date)
    setupdtatedwinnerlist(0)
    setEditMode(-1)
    // setupdate(!update);
  }

  const handleSelectChange = event => {
    setSelectedGender(event.target.value)
    setwinnerlist([])
    setscorer(scoresss)
    setplayers(arr)
    setNewDateTime(date)
    setupdtatedwinnerlist(0)
    setEditMode(-1)
    // setupdate(!update);
  }

  const handleEdit = index => {
    if (editMode === -1) {
      setEditMode(index)
    }
    // setNewDateTime[index] = (dateTime); // Preserve the original value for editing
  }

  const handleSave = index => {
    //   console.log("index is" + index);
    //   if(players[index].p1 == null)
    //     enqueueSnackbar('Player-1 not seleted', { variant: 'error' });
    // else if(players[index].p2 == null)
    //     {
    //         enqueueSnackbar('Player-2 not seleted', { variant: 'error' });
    //     }
    // else if(newDateTime[index] == null)
    // {
    //     enqueueSnackbar('please select date and time of match', { variant: 'error' });
    // }
    // else if(matchscorer[index] == '')
    // {
    //     enqueueSnackbar('please select scorer of match', { variant: 'error' });
    // }
    // else {
    let callAPI = async () => {
      let formdata = {
        matchScorer: matchscorer[index],
        matchTime: newDateTime[index],
        player_1_id: players[index].p1 == null ? '' : players[index].p1.id,
        player_2_id: players[index].p2 == null ? '' : players[index].p2.id,
        matchID: RoundMatches[index].id
      }
      let res = await AssignScorer(formdata)
      // console.log(res);
      if (res.data.status) {
        enqueueSnackbar('succesfully updated match', { variant: 'success' })
      } else {
        enqueueSnackbar('Something error happened', { variant: 'error' })
      }
    }
    callAPI()
    setEditMode(-1)
  }

  function showsave (index) {
    if (matchscorer[index] === '') return true
    else if (players[index].p1 != null && players[index].p2 != null) return true
    return false
  }

  const handleChange = event => {
    setNewDateTime(event.target.value)
  }

  const getRandomImages = round => {
    let imageArray
    if (selectedGender === 'male') {
      imageArray = [p1, p2, p3, p4]
    } else {
      imageArray = [p5, p6, p7, p8, p9]
    }

    const selectedImages = []

    for (let i = 0; i < round + 1; i++) {
      const randomIndex = Math.floor(Math.random() * imageArray.length)
      selectedImages.push(imageArray[randomIndex])
    }

    return selectedImages
  }

  const randomImages = getRandomImages(selectedRound * 2)
  const handleSelectedTournament = event => {
    // setnewlink(event.target.value , selectedGroup , selectedSport , selectedGender);
    setSelectedTournament(event.target.value)
    setmatchscorer(scoresss)
    setwinnerlist([])
    setscorer([])
    setplayers(arr)
    setNewDateTime(date)
    setupdtatedwinnerlist(0)
    setEditMode(-1)
    // setupdate(!update);
  }
  const handleSelectedgame = event => {
    setseletedgame(event.target.value)
    setwinnerlist([])
    setmatchscorer(scoresss)
    setscorer([])
    setplayers(arr)
    setNewDateTime(date)
    setupdtatedwinnerlist(0)
    setEditMode(-1)
    // setupdate(!update);
  }
  // console.log(players);
  // console.log(winnerlist);
  const handlePlayerChange = event => {
    const selectedValue = JSON.parse(event.target.value)
    let index = selectedValue[1]
    console.log(index)

    // console.log("yeeeeeee");
    // console.log(event.target.name);
    if (event.target.name == 'player1') {
      // console.log(event.target.value[0]);
      const selectedValue = JSON.parse(event.target.value)
      // console.log(selectedValue);
      let index = selectedValue[1]
      // console.log(index);
      // console.log(players[1]);
      console.log(index)
      // console.log( players[index].p1.id);
      // console.log(selectedValue);
      // console.log(winnerlist)
      console.log('yes')
      if (selectedValue[0] == '') {
        // console.log(players[index].p1);
        if (players[index].p1 != null) {
          for (let j = 0; j < winnerlist.length; j++) {
            if (winnerlist[j].id == players[index].p1.id) {
              winnerlist[j].flag = false
              break
            }
          }
          players[index].p1 = null
          setwinnerlist(winnerlist)
          setplayers(players)
          setstate(!state)
          return
        } else return
      } else if (
        players[index].p1 != null &&
        selectedValue[0] == players[index].p1.id
      ) {
        return
      } else {
        const player1 = winnerlist.find(
          player => player.id === selectedValue[0]
        )
        player1.flag = true
        for (let i = 0; i < winnerlist.length; i++) {
          if (winnerlist[i].id === selectedValue[0]) {
            winnerlist[i].flag = true
            if (players[index].p1 != null) {
              for (let j = 0; j < winnerlist.length; j++) {
                if (winnerlist[j].id == players[index].p1.id) {
                  winnerlist[j].flag = false
                  break
                }
              }
            }
            // console.log(players[index]);
            players[index].p1 = winnerlist[i]
            break
          }
        }
        setwinnerlist(winnerlist)
        setplayers(players)
        setstate(!state)
      }
    } else {
      const selectedValue = JSON.parse(event.target.value)
      let index = selectedValue[1]
      if (selectedValue[0] == '') {
        if (players[index].p2 != null) {
          for (let j = 0; j < winnerlist.length; j++) {
            if (winnerlist[j].id == players[index].p2.id) {
              winnerlist[j].flag = false
              break
            }
          }
          players[index].p2 = null
          setwinnerlist(winnerlist)
          setplayers(players)
          setstate(!state)
        }
        return
      } else if (
        players[index].p2 != null &&
        selectedValue[0] == players[index].p2.id
      ) {
        return
      } else {
        const player2 = winnerlist.find(
          player => player.id === selectedValue[0]
        )
        player2.flag = true
        for (let i = 0; i < winnerlist.length; i++) {
          if (winnerlist[i].id === selectedValue[0]) {
            winnerlist[i].flag = true
            if (players[index].p2 != null) {
              for (let j = 0; j < winnerlist.length; j++) {
                if (winnerlist[j].id == players[index].p2.id) {
                  winnerlist[j].flag = false
                  break
                }
              }
            }
            // console.log(players[index]);
            players[index].p2 = winnerlist[i]
            break
          }
        }
        setwinnerlist(winnerlist)
        setplayers(players)
        setstate(!state)
      }
    }
  }
  //    console.log(players);
  //    console.log()
  function handlecancel () {
    // setRoundmatches([]);
    setwinnerlist([])
    setmatchscorer(scoresss)
    setscorer([])
    setplayers(arr)
    setNewDateTime(date)
    setupdtatedwinnerlist(0)
    setmatchscorer(scoresss)
    setEditMode(-1)
    setupdate(!update)
  }
  // const handlescorer = (event)
  // console.log(matchscorer);
  // console.log(newDateTime);
  // loaderStatus = false
  console.log(matchscorer)
  console.log(players)
  const handleLogs = async (event, matchData) => {
    console.log("matchData",matchData)
    let res = await GetLogs(matchData.id)
    console.log(res)
    if (res.status) {
      enqueueSnackbar('Logs fetched', { variant: 'success' })
      setLogs(res.data)
      setVisible(true)
      setSelectedMatch(matchData);
    } else {
      enqueueSnackbar('No logs for this match', { variant: 'error' })
    }
  }
  return (
    <>
      {loaderStatus ? <Loader /> : null}
      <AdminBreadcrumb links={adminBreadcrumbLinks} />
      <CRow>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedTournament}
            onChange={handleSelectedTournament}
          >
            {tournamentList.length
              ? tournamentList.map((tournament, index) => (
                  <option value={tournament._id.toString()}>
                    {tournament.name}
                  </option>
                ))
              : ''}
          </select>
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedgame}
            onChange={handleSelectedgame}
          >
            <option value='badminton' selected>
              Badminton
            </option>
            <option value='table_tenis' selected>
              Table Tenis
            </option>
          </select>
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedGender}
            onChange={handleSelectChange}
          >
            <option value='male' selected>
              Male
            </option>
            <option value='female'>Female</option>
          </select>
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedRound}
            onChange={handleRoundChange}
          >
            {round.length
              ? round.map((rounds, index) => (
                  <option value={rounds.value}>{rounds.name}</option>
                ))
              : ''}
          </select>
        </CCol>
        <br />
        <br />
        <br />
      </CRow>

      {RoundMatches.length > 1 ? (
        <>
          {
            <CRow>
              {RoundMatches.map((match, index) => (
                <CCol sm='6' md='6' lg='6' className='mb-3'>
                  <Card>
                    <CardBody>
                      <CRow>
                        <div>match {index + 1}
                        {
                            match.status == 'completed' ? (
                                <>
                                <span>
                                    <CButton
                                    size="sm"
                                    onClick={(event) => handleLogs(event, match)}
                                    id={match._id}
                                    color='warning'
                                    className='text-white float-end'
                                >
                                    <CIcon icon={cilGraph}></CIcon>
                                    </CButton>
                                </span>
                                </>
                            ) : null
                        }
                        </div>
                        <CModal
                size='xl'
                alignment='center'
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby='LiveDemoExampleLabel'
                scrollable={true}
              >
                <CModalHeader onClose={() => setVisible(false)}>
                  <CModalTitle id='LiveDemoExampleLabel'>
                    Match Logs
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CTable>
                    <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell  style= {{ textAlign:'end'}} colSpan={4}>
                          {
                          playersList.length>0 &&
                          selectedMatch &&
                          playersList[selectedMatch.player_1_id] &&
                          playersList[selectedMatch.player_1_id].fname !== undefined
                            ? playersList[selectedMatch.player_1_id].fname +
                              ' ' +
                              playersList[selectedMatch.player_1_id].lname
                            : null
                          }

                          </CTableHeaderCell>
                          <CTableHeaderCell className='text-align-end'style={{textAlign:'end'}} colSpan={3}>
                          {
                            playersList.length > 0&&
                            selectedMatch &&
                            playersList[selectedMatch.player_2_id] &&
                            playersList[selectedMatch.player_2_id].fname !== undefined
                              ? playersList[selectedMatch.player_2_id].fname +
                                ' ' +
                                playersList[selectedMatch.player_2_id].lname
                              : null
                          }

                          </CTableHeaderCell>
                        </CTableRow>
                        <CTableRow>
                        <CTableHeaderCell scope='col'>#</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PA</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PF</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PD</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Score1</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Score2</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PA</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PF</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PD</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Created By</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Time</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Type</CTableHeaderCell>

                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {Logs.length > 0
                        ? Logs.map((match, index) => (
                            <>
                            <CTableRow className='text-align-center align-items-center'>
                                <CTableDataCell>#</CTableDataCell>
                                <CTableDataCell>{match.player_1_PA}</CTableDataCell> 
                                <CTableDataCell>{match.player_1_PF}</CTableDataCell>
                                <CTableDataCell>{match.player_1_PD}</CTableDataCell>
                                <CTableDataCell style={{borderRight:'4px solid black'}}>{match.player_1_score}</CTableDataCell>
                                <CTableDataCell style={{textAlign:'center'}}>{match.player_2_score}</CTableDataCell>
                                <CTableDataCell>{match.player_2_PA}</CTableDataCell>
                                <CTableDataCell>{match.player_2_PF}</CTableDataCell>
                                <CTableDataCell style= {{borderRight:'4px solid black'}}>{match.player_2_PD}</CTableDataCell>
                                <CTableDataCell>{match.created_by.fname + ' ' + match.created_by.lname}</CTableDataCell>
                                <CTableDataCell>{FormatDate(match.createdAt,0, 1, 0)}</CTableDataCell>
                                <CTableDataCell>
                                  {
                                      match.status == 'live' ?  (
                                          <>
                                              <span
                                                  className='badge bg-info text-white'
                                                >
                                                  Live
                                              </span>
                                          </>
                                      ) : (
                                          match.status == 'update' ? (
                                          <span
                                          className='badge bg-danger text-white'
                                        >
                                          Updated
                                      </span>

                                          ) : ( 
                                              <span
                                              className='badge bg-success text-white'
                                              >
                                              Submited
                                          </span>
                                          )
                                      )
                                    
                                  }
                                </CTableDataCell>
                            </CTableRow>
                            </>
                          ))
                        : null}
                    </CTableBody>
                  </CTable>
                </CModalBody>
              </CModal>
                        <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${
                                players[index].p1 != null
                                  ? IMAGE_URL +
                                    'user/' +
                                    players[index].p1.image
                                  : selectedGender === 'male'
                                  ? IMAGE_URL + 'profile.jpg'
                                  : IMAGE_URL + 'female.jfif'
                              })`,
                              width: 'auto'
                            }}
                          ></div>
                          {editMode == index ? (
                            <select
                              className='form-select'
                              style={{ marginTop: '20px' }}
                              onClick={handlePlayerChange}
                              name='player1'
                              defaultValue={
                                players[index].p1
                                  ? JSON.stringify([
                                      players[index].p1.id,
                                      index
                                    ])
                                  : JSON.stringify(['', index])
                              }
                            >
                              <option value={JSON.stringify(['', index])}>
                                Select a player
                              </option>
                              {winnerlist.length > 0 ? (
                                winnerlist.map((player, indexx) => (
                                  <>
                                    {!player.flag ? (
                                      <option
                                        key={player.id}
                                        value={JSON.stringify([
                                          player.id,
                                          index
                                        ])}
                                      >
                                        {player.name}
                                      </option>
                                    ) : (
                                      <option
                                        key={player.id}
                                        value={JSON.stringify([
                                          player.id,
                                          index
                                        ])}
                                        disabled
                                      >
                                        {player.name}
                                      </option>
                                    )}
                                  </>
                                ))
                              ) : (
                                <span>No players found</span>
                              )}
                            </select>
                          ) : (
                            <div>
                              {players[index].p1 != null ? (
                                <div>
                                  <p style={{ textAlign: 'center' }}>
                                    {' '}
                                    {players[index].p1.name}
                                  </p>

                                  <p style={{ textAlign: 'center' }}>
                                    {match.status == 'completed' &&
                                    match.player_won.toString() ==
                                      players[index].p1.id.toString() ? (
                                      <img
                                        src={trophy}
                                        style={{ width: '40px' }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                              ) : (
                                //  <p style={{ textAlign: 'center' }}> <img src={tropy} style={{width:'20px'}} /></p>
                                <div>
                                  <p style={{ textAlign: 'center' }}> TBA</p>
                                  <p style={{ textAlign: 'center' }}> </p>
                                </div>
                              )}
                            </div>

                            //
                          )}
                        </CCol>

                        <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                          {match.status === 'completed' ? (
                            <>
                            

                              <p
                                className='badge bg-success text-white'
                                style={{
                                  marginTop: '30px',
                                  color: 'limegreen'
                                }}
                              >
                                Completed
                                {/* <br /> */}
                                {/* scorer */}
                              </p>
                              {/* <br /> */}
                              <p style={{ fontSize: '14px' }}>
                                <div>
                                  {matchscorer[index] !== ''
                                    ? findscorer(matchscorer[index])
                                    : 'Scorer'}
                                </div>
                              </p>
                              <br />
                              <p
                                className='badge bg-dark text-white'
                                style={{ marginTop: '0px', color: 'limegreen' }}
                              >
                                {match.player_1_score +
                                  ' ' +
                                  '- ' +
                                  ' ' +
                                  match.player_2_score}
                              </p>
                            </>
                          ) : (
                            <>
                              {editMode == index ? (
                                <div>
                                  <p
                                    style={{
                                      marginTop: '65px',
                                      color: 'limegreen'
                                    }}
                                  >
                                    VS
                                  </p>
                                  <p style={{ fontSize: '14px' }}>
                                    <input
                                      className='form-control'
                                      type='datetime-local'
                                      defaultValue={
                                        newDateTime[editMode] != null
                                          ? newDateTime[editMode]
                                          : null
                                      }
                                      onChange={e => {
                                        newDateTime[editMode] = e.target.value
                                        setNewDateTime(newDateTime)
                                        setstate(!state)
                                      }}
                                    />
                                  </p>
                                  <select
                                    className='form-select'
                                    onChange={event => {
                                      // event.preverntdefault
                                      // console.log("yeeee");
                                      console.log(event.target.value)
                                      let newmatchscorer = matchscorer
                                      newmatchscorer[editMode] =
                                        event.target.value
                                      setmatchscorer(newmatchscorer)
                                      setstate(!state)
                                    }}
                                    defaultValue={
                                      matchscorer[index] !== ''
                                        ? matchscorer[index]
                                        : ''
                                    }
                                  >
                                    <option value=''>select a scorer</option>
                                    {scorer.length > 0 ? (
                                      scorer.map((scorer, indexx) => (
                                        <>
                                          {
                                            <option
                                              key={scorer}
                                              value={scorer.id}
                                            >
                                              {scorer.fname +
                                                ' ' +
                                                scorer.lname}
                                            </option>
                                          }
                                        </>
                                      ))
                                    ) : (
                                      <span>No players found</span>
                                    )}
                                  </select>
                                  <br />
                                  {showsave(editMode) == true ? (
                                    <p style={{ fontSize: '14px' }}>
                                      <CButton
                                        onClick={() => {
                                          handleSave(editMode)
                                        }}
                                        title='Save'
                                        className='text-white'
                                        color='primary'
                                      >
                                        <CIcon icon={cilSave} />
                                      </CButton>
                                      &nbsp;&nbsp;
                                      <CButton
                                        title='Cancel'
                                        className='text-white'
                                        color='danger'
                                        type='button'
                                        onClick={handlecancel}
                                      >
                                        <CIcon icon={cilX} />
                                      </CButton>
                                    </p>
                                  ) : (
                                    <p style={{ fontSize: '14px' }}>
                                      &nbsp;&nbsp;
                                      <CButton
                                        title='Cancel'
                                        className='text-white'
                                        color='danger'
                                        type='button'
                                        onClick={handlecancel}
                                      >
                                        <CIcon icon={cilX} />
                                      </CButton>
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p
                                    style={{
                                      marginTop: '30px',
                                      color: 'limegreen'
                                    }}
                                  >
                                    VS
                                  </p>
                                  {/* <p style={{ fontSize: '14px' }}>{dateTime}</p> */}
                                  <p style={{ fontSize: '14px' }}>
                                    <div>
                                      {newDateTime[index] != null &&
                                      newDateTime[index] != ''
                                        ? formattime(newDateTime[index])
                                        : ''}
                                    </div>
                                  </p>
                                  <p style={{ fontSize: '14px' }}>
                                    <div>
                                      {matchscorer[index] !== ''
                                        ? findscorer(matchscorer[index])
                                        : 'Scorer'}
                                    </div>
                                  </p>

                                  {editMode == -1 ? (
                                    <p style={{ fontSize: '14px' }}>
                                      <CButton
                                        title='Edit'
                                        onClick={() => {
                                          handleEdit(index)
                                        }}
                                        className='bi bi-pencil text-white'
                                        color='warning'
                                      ></CButton>
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </>
                          )}
                        </CCol>

                        <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                          {/* <img className="img-fluid" src={
                                                                players[index].p2!= null ? IMAGE_URL + "user/" + players[index].p2.image : IMAGE_URL + 'profile.jpg'
                                                            } alt={"P1"} /> */}
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${
                                players[index].p2 != null
                                  ? IMAGE_URL +
                                    'user/' +
                                    players[index].p2.image
                                  : selectedGender === 'male'
                                  ? IMAGE_URL + 'profile.jpg'
                                  : IMAGE_URL + 'female.jfif'
                              })`,
                              width: 'auto'
                            }}
                          ></div>

                          {editMode == index ? (
                            <select
                              className='form-select'
                              style={{ marginTop: '20px' }}
                              onChange={handlePlayerChange}
                              name='player2'
                              defaultValue={
                                players[index].p2
                                  ? JSON.stringify([
                                      players[index].p2.id,
                                      index
                                    ])
                                  : JSON.stringify(['', index])
                              }
                            >
                              <option value={JSON.stringify(['', index])}>
                                Select a player
                              </option>
                              {winnerlist.length > 0 ? (
                                winnerlist.map((player, indexx) => (
                                  <>
                                    {!player.flag ? (
                                      <option
                                        key={player.id}
                                        value={JSON.stringify([
                                          player.id,
                                          index
                                        ])}
                                      >
                                        {player.name}
                                      </option>
                                    ) : (
                                      <option
                                        key={player.id}
                                        value={JSON.stringify([
                                          player.id,
                                          index
                                        ])}
                                        disabled
                                      >
                                        {player.name}
                                      </option>
                                    )}
                                  </>
                                ))
                              ) : (
                                <span>No players found</span>
                              )}
                            </select>
                          ) : (
                            <div>
                              {players[index].p2 != null ? (
                                <div>
                                  <p style={{ textAlign: 'center' }}>
                                    {' '}
                                    {players[index].p2.name}
                                  </p>

                                  <p style={{ textAlign: 'center' }}>
                                    {match.status == 'completed' &&
                                    match.player_won.toString() ==
                                      players[index].p2.id.toString() ? (
                                      <img
                                        src={trophy}
                                        style={{ width: '40px' }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </p>
                                </div>
                              ) : (
                                //  <p style={{ textAlign: 'center' }}> <img src={tropy} style={{width:'20px'}} /></p>
                                <div>
                                  <p style={{ textAlign: 'center' }}> TBA</p>
                                  <p style={{ textAlign: 'center' }}> </p>
                                </div>
                              )}
                            </div>
                          )}
                        </CCol>
                      </CRow>
                    </CardBody>
                  </Card>
                </CCol>
              ))}
            </CRow>
          }
        </>
      ) : RoundMatches.length == 1 ? (
        <>
          <CRow>
            {RoundMatches.map((match, index) => (
              <CCol sm='12' md='12' lg='12' className='mb-3'>
                <Card>
                  <CardBody>
                    <CRow>
                    <div>final
                        {
                            match.status == 'completed' ? (
                                <>
                                <span>
                                    <CButton
                                    size="sm"
                                    onClick={(event) => handleLogs(event, match)}
                                    id={match._id}
                                    color='warning'
                                    className='text-white float-end'
                                >
                                    <CIcon icon={cilGraph}></CIcon>
                                    </CButton>
                                </span>
                                </>
                            ) : null
                        }
                        
                        </div>
                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        <div
                          className='bg-image-team mb-3'
                          style={{
                            backgroundImage: `url(${
                              players[index].p1 != null
                                ? IMAGE_URL + 'user/' + players[index].p1.image
                                : selectedGender === 'male'
                                ? IMAGE_URL + 'profile.jpg'
                                : IMAGE_URL + 'female.jfif'
                            })`,
                            height: '10em',
                            width: '10em'
                          }}
                        ></div>
                        {editMode == index ? (
                          <select
                            className='form-select'
                            style={{ marginTop: '20px' }}
                            onClick={handlePlayerChange}
                            name='player1'
                            defaultValue={
                              players[index].p1
                                ? JSON.stringify([players[index].p1.id, index])
                                : JSON.stringify(['', index])
                            }
                          >
                            <option value={JSON.stringify(['', index])}>
                              Select a player
                            </option>
                            {winnerlist.length > 0 ? (
                              winnerlist.map((player, indexx) => (
                                <>
                                  {!player.flag ? (
                                    <option
                                      key={player.id}
                                      value={JSON.stringify([player.id, index])}
                                    >
                                      {player.name}
                                    </option>
                                  ) : (
                                    <option
                                      key={player.id}
                                      value={JSON.stringify([player.id, index])}
                                      disabled
                                    >
                                      {player.name}
                                    </option>
                                  )}
                                </>
                              ))
                            ) : (
                              <span>No players found</span>
                            )}
                          </select>
                        ) : (
                          <div>
                            {players[index].p1 != null ? (
                              <div>
                                <p style={{ textAlign: 'center' }}>
                                  {' '}
                                  {players[index].p1.name}
                                </p>

                                <p style={{ textAlign: 'center' }}>
                                  {match.status == 'completed' &&
                                  match.player_won.toString() ==
                                    players[index].p1.id.toString() ? (
                                    <img
                                      src={trophy}
                                      style={{ width: '40px' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </p>
                              </div>
                            ) : (
                              //  <p style={{ textAlign: 'center' }}> <img src={tropy} style={{width:'20px'}} /></p>
                              <div>
                                <p style={{ textAlign: 'center' }}> TBA</p>
                                <p style={{ textAlign: 'center' }}> </p>
                              </div>
                            )}
                          </div>

                          //
                        )}
                      </CCol>

                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                        {match.status === 'completed' ? (
                          <>
                            <p
                              className='badge bg-success text-white'
                              style={{ marginTop: '30px', color: 'limegreen' }}
                            >
                              Completed
                            </p>
                            <br />
                            <p
                              className='badge bg-dark text-white'
                              style={{ marginTop: '30px', color: 'limegreen' }}
                            >
                              {match.player_1_score +
                                ' ' +
                                '- ' +
                                ' ' +
                                match.player_2_score}
                            </p>
                          </>
                        ) : (
                          <>
                            {editMode == index ? (
                              <div>
                                <p
                                  style={{
                                    marginTop: '65px',
                                    color: 'limegreen'
                                  }}
                                >
                                  VS
                                </p>
                                <p style={{ fontSize: '14px' }}>
                                  <input
                                    className='form-control'
                                    type='datetime-local'
                                    defaultValue={
                                      newDateTime[editMode] != null
                                        ? newDateTime[editMode]
                                        : null
                                    }
                                    onChange={e => {
                                      newDateTime[editMode] = e.target.value
                                      setNewDateTime(newDateTime)
                                      setstate(!state)
                                    }}
                                  />
                                </p>
                                <select
                                  className='form-select'
                                  onChange={event => {
                                    // event.preverntdefault
                                    // console.log("yeeee");
                                    console.log(event.target.value)
                                    let newmatchscorer = matchscorer
                                    newmatchscorer[editMode] =
                                      event.target.value
                                    setmatchscorer(newmatchscorer)
                                    setstate(!state)
                                  }}
                                  defaultValue={
                                    matchscorer[index] !== ''
                                      ? matchscorer[index]
                                      : ''
                                  }
                                >
                                  <option value=''>select a scorer</option>
                                  {scorer.length > 0 ? (
                                    scorer.map((scorer, indexx) => (
                                      <>
                                        {
                                          <option
                                            key={scorer}
                                            value={scorer.id}
                                          >
                                            {scorer.fname + ' ' + scorer.lname}
                                          </option>
                                        }
                                      </>
                                    ))
                                  ) : (
                                    <span>No players found</span>
                                  )}
                                </select>
                                <br />
                                {showsave(editMode) == true ? (
                                  <p style={{ fontSize: '14px' }}>
                                    <CButton
                                      onClick={() => {
                                        handleSave(editMode)
                                      }}
                                      title='Save'
                                      className='text-white'
                                      color='primary'
                                    >
                                      <CIcon icon={cilSave} />
                                    </CButton>
                                    &nbsp;&nbsp;
                                    <CButton
                                      title='Cancel'
                                      className='text-white'
                                      color='danger'
                                      type='button'
                                      onClick={handlecancel}
                                    >
                                      <CIcon icon={cilX} />
                                    </CButton>
                                  </p>
                                ) : (
                                  <p style={{ fontSize: '14px' }}>
                                    &nbsp;&nbsp;
                                    <CButton
                                      title='Cancel'
                                      className='text-white'
                                      color='danger'
                                      type='button'
                                      onClick={handlecancel}
                                    >
                                      <CIcon icon={cilX} />
                                    </CButton>
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div>
                                <p
                                  style={{
                                    marginTop: '30px',
                                    color: 'limegreen'
                                  }}
                                >
                                  VS
                                </p>
                                <p style={{ fontSize: '14px' }}>
                                  <div>
                                    {newDateTime[index] != null &&
                                    newDateTime[index] != ''
                                      ? formattime(newDateTime[index])
                                      : ''}
                                  </div>
                                </p>
                                <p style={{ fontSize: '14px' }}>
                                  <div>
                                    {matchscorer[index] !== ''
                                      ? findscorer(matchscorer[index])
                                      : 'Scorer'}
                                  </div>
                                </p>
                                {editMode == -1 ? (
                                  <p style={{ fontSize: '14px' }}>
                                    <CButton
                                      title='Edit'
                                      onClick={() => {
                                        handleEdit(index)
                                      }}
                                      className='bi bi-pencil text-white'
                                      color='warning'
                                    ></CButton>
                                  </p>
                                ) : null}
                              </div>
                            )}
                          </>
                        )}
                      </CCol>

                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        {/* <img className="img-fluid" src={
                                                                players[index].p2!= null ? IMAGE_URL + "user/" + players[index].p2.image : IMAGE_URL + 'profile.jpg'
                                                            } alt={"P1"} /> */}
                        <div
                          className='bg-image-team mb-3'
                          style={{
                            backgroundImage: `url(${
                              players[index].p2 != null
                                ? IMAGE_URL + 'user/' + players[index].p2.image
                                : selectedGender === 'male'
                                ? IMAGE_URL + 'profile.jpg'
                                : IMAGE_URL + 'female.jfif'
                            })`,
                            height: '10em',
                            width: '10em'
                          }}
                        ></div>

                        {editMode == index ? (
                          <select
                            className='form-select'
                            style={{ marginTop: '20px' }}
                            onChange={handlePlayerChange}
                            name='player2'
                            defaultValue={
                              players[index].p2
                                ? JSON.stringify([players[index].p2.id, index])
                                : JSON.stringify(['', index])
                            }
                          >
                            <option value={JSON.stringify(['', index])}>
                              Select a player
                            </option>
                            {winnerlist.length > 0 ? (
                              winnerlist.map((player, indexx) => (
                                <>
                                  {!player.flag ? (
                                    <option
                                      key={player.id}
                                      value={JSON.stringify([player.id, index])}
                                    >
                                      {player.name}
                                    </option>
                                  ) : (
                                    <option
                                      key={player.id}
                                      value={JSON.stringify([player.id, index])}
                                      disabled
                                    >
                                      {player.name}
                                    </option>
                                  )}
                                </>
                              ))
                            ) : (
                              <span>No players found</span>
                            )}
                          </select>
                        ) : (
                          <div>
                            {players[index].p2 != null ? (
                              <div>
                                <p style={{ textAlign: 'center' }}>
                                  {' '}
                                  {players[index].p2.name}
                                </p>

                                <p style={{ textAlign: 'center' }}>
                                  {match.status == 'completed' &&
                                  match.player_won.toString() ==
                                    players[index].p2.id.toString() ? (
                                    <img
                                      src={trophy}
                                      style={{ width: '40px' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </p>
                              </div>
                            ) : (
                              //  <p style={{ textAlign: 'center' }}> <img src={tropy} style={{width:'20px'}} /></p>
                              <div>
                                <p style={{ textAlign: 'center' }}> TBA</p>
                                <p style={{ textAlign: 'center' }}> </p>
                              </div>
                            )}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                  </CardBody>
                </Card>
              </CCol>
            ))}
          </CRow>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
export default Knockout
