import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#fff"
        d="M16.347 15.604H.654a.602.602 0 00-.594.594c0 .322.272.594.594.594h15.693a.602.602 0 00.594-.594.602.602 0 00-.594-.594zM.084 10.406L.06 13.203c0 .148.05.322.173.42a.702.702 0 00.42.174l2.798-.025a.702.702 0 00.42-.173l9.604-9.604a.613.613 0 000-.842L10.703.381a.613.613 0 00-.841 0L7.93 2.31.258 9.986a.58.58 0 00-.174.42zm12.129-6.832l-1.09 1.09-1.93-1.932 1.09-1.089 1.93 1.931zm-10.94 7.08l7.079-7.08 1.93 1.93-7.079 7.08h-1.93v-1.93z"
      ></path>
    </svg>
  );
}

export default Icon;