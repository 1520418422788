import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#ffffff"
        d="M23.977 11.058a12.008 12.008 0 00-9.537 4.727 2.827 2.827 0 01-.204-1.277c.072-.664.266-1.31.572-1.904a8.56 8.56 0 00.67-2.004 5.194 5.194 0 00-1.02-4.198 4.293 4.293 0 00-3.402-1.5 4.29 4.29 0 00-3.403 1.5A5.193 5.193 0 006.635 10.6c.14.693.364 1.367.67 2.005.306.594.5 1.239.572 1.903a3.426 3.426 0 01-1.223 2.758c-1.616 1.872-3.829 4.436-1.9 13.762.123.596.232 1.152.322 1.63a2.997 2.997 0 002.946 2.435h6.068a2.984 2.984 0 002.893-2.295 11.837 11.837 0 006.994 2.3 12.02 12.02 0 100-24.04zM9.183 7.69a2.324 2.324 0 011.873-.789 2.325 2.325 0 011.874.79 3.182 3.182 0 01.577 2.57 6.805 6.805 0 01-.533 1.544 7.268 7.268 0 00-.734 2.592c-.03.504.016 1.008.134 1.498H9.74a5.1 5.1 0 00.134-1.498 7.27 7.27 0 00-.734-2.592 6.79 6.79 0 01-.534-1.546 3.178 3.178 0 01.577-2.569zm4.907 25.402H8.023a.997.997 0 01-.983-.805c-.092-.487-.202-1.055-.327-1.665-1.73-8.361.035-10.406 1.454-12.05.19-.22.377-.445.56-.677h4.658c.182.232.37.458.56.677 1.42 1.644 3.185 3.689 1.454 12.052-.125.608-.235 1.176-.327 1.662a.997.997 0 01-.982.806zm9.887.004a9.832 9.832 0 01-6.54-2.49c1.69-8.56-.197-11.253-1.756-13.08a10.005 10.005 0 118.296 15.57zm6.026-14.755a1 1 0 11-2 0 1 1 0 012 0zm-2 3.312a1 1 0 11-2 0 1 1 0 012 0zm4 0a1 1 0 11-2 0 1 1 0 012 0z"
      ></path>
    </svg>
  );
}

export default Icon;