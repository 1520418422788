import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import {CreateUser as CreateUserAPI, EditUser} from  '../../../../API/admin'
import { useNavigate} from 'react-router-dom';
import {UserExist} from '../../../../API/auth';
import { useSnackbar } from 'notistack';
import t from '../../../../components/translation';
import {
  CCard,
  CCardBody,
} from '@coreui/react';

const CreateUser = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Users', to: '/users', active: false },
        { label: 'Edit', to: '', active: true }
    ];

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
    const [userexist, setUserExist] = useState(false);
  	const [DisableRegister, setDisableRegister] = useState(false);
  	const [errors, setErrors] = useState({});
  	const [CriteriaShow, setCriteriaShow] = useState(false);
    const [FormData ,setFormData] = useState({
		fname:'',
		lname:'',
		username :'',
		password :'',
		email:'',
		corporate_email:'',
        gender: '',
		role:'',
        status:false,
        id:''
	})

    useEffect(()=> {
        const urlParams = new URLSearchParams(window.location.search);
        var urlid = {
            data : urlParams.get('id')
        }
        let callAPI = async() => {
            let res = await EditUser(urlid);
            if (res.status) {
                setFormData(res.data);
            }
        }
        if(urlid) {
            callAPI();
        }   
    }, [])

    const HandelChange = (event) => {
    	const { name, value } = event.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
    }
  
    const HandelSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validateFormData();
        if (!Object.keys(validationErrors).length > 0 && !DisableRegister) {
            setDisableRegister(true);
            console.log(FormData)
            let res = await CreateUserAPI(FormData)
            if(res.data.status) {
                if (res.data.totalRecords) {
                    enqueueSnackbar('User updated successfully', { variant: 'success' });
                    navigate('/users');
                } else {
                    localStorage.setItem('userID', res.data.data[0].id)
                    enqueueSnackbar(t.register_success, { variant: 'success' });
                    navigate('/users');
                }
            }
            else {
                // swal("Oops!", res.data.message, "error");
                enqueueSnackbar(res.data.message, { variant: 'error' });
                // swal("Oops!", res.data.message, "error");
            }
            setDisableRegister(false);
        }
    }

    
    const User_exist = async (event) => {
		if (event.target.name === 'email') {
			if (/\S+@\S+\.\S+/.test(FormData.email)) {
				let res = await UserExist(FormData.email,event.target.name)
				if (res.status !== undefined && res.status ) {
					setUserExist(true);
					setErrors({ ...errors, 'email': t.email_already_exist });
					return;
				}
			}
		}

		if (event.target.name === 'corporateEmail') {
			if (/\S+@\S+\.\S+/.test(FormData.corporateEmail)) {
				let res = await UserExist(FormData.corporateEmail,event.target.name)
				if (res.status !== undefined && res.status ) {
					setUserExist(true);
					setErrors({ ...errors, 'corporate_email': t.corporate_email_already_exist });
					return;
				}
			}
		}
		
		setUserExist(false);
    }
    const validateFormData = () => {
        console.log("Validate function is called");
        let temp_errors = {};
    
        if (!FormData.fname.trim()) {
            temp_errors.fname = t.f_name_required;
        }
    
        if (!FormData.lname.trim()) {
          temp_errors.lname = t.l_name_required;
        }
        if (!/\S+@\S+\.\S+/.test(FormData.email)) {
            temp_errors.email = t.invalid_email;
        }
        if (temp_errors.email == undefined && userexist) {
             temp_errors.email = t.email_already_exist;
        }
        if (!FormData.corporate_email === '') {
            if (!/\S+@\S+\.\S+/.test(FormData.corporate_email)) {
                temp_errors.corporate_email = t.invalid_corporate_email;
            }
        }
        
        if(temp_errors.email == undefined && temp_errors.corporate_email == undefined && FormData.email == FormData.corporate_email) {
            temp_errors.corporate_email = t.email_and_corporate_email_cant_be_same;
        }
        if (FormData.id == 0) {
            if (!FormData.password.trim()) {
                temp_errors.password = t.password_required;
            } else if (!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]).{8,}$/.test(FormData.password)) {
                temp_errors.password = t.password_criteria;
                setCriteriaShow(true);
            }
        }
        setErrors(temp_errors);
        return temp_errors ;
    };
    

    return (
        <>
            <h4 className="card-title mb-3 align-items-center">
                Create a user
            </h4>
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            
            <CCard className="mb-4">
            <CCardBody>
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <form >
                            <div className='row mb-2'>
                                <div className="col-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                    First name
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name='fname'
                                    pattern='^[^\s]+$'
                                    onChange={HandelChange}
                                    value={FormData.fname}
                                    placeholder='Enter Firstname'
                                    required
                                    />
                                {errors.fname && <span className='text-danger'>{errors.fname}</span>}

                                    <input type="hidden" value={FormData.id} name='id' />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                    Last Name
                                    </label>
                                    <input
                                    type="text"
                                    className="form-control"
                                    name='lname'
                                    pattern='^[^\s]+$'
                                    placeholder='Enter Lastname'
                                    onChange={HandelChange}
                                    value={FormData.lname}
                                    required
                                    />
                                {errors.fname && <span className='text-danger'>{errors.fname}</span>}
                                
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">
                                    Username
                                    </label>
                                    <input
                                    type="text"
                                    name="username"
                                    pattern='^[^\s]+$'
                                    className="form-control"
                                    placeholder='Enter Username'
                                    onChange={HandelChange}
                                    value={FormData.username}
                                    required
                                    /> 
                                {errors.username && <span className='text-danger'>{errors.username}</span>}
                                
                            </div>
                            <div className="mb-3">
                                <label className="form-label">
                                Coporate Email
                                </label>
                                <input
                                type="email"
                                className="form-control"
                                name='corporate_email'
                                onBlur={User_exist}
                                placeholder='Enter Coporate Email'
                                onChange={HandelChange}
                                value={FormData.corporate_email}
                                />
                                {errors.corporate_email && <span className='text-danger'>{errors.corporate_email}</span>}
                                
                            </div>
                            <div className="mb-3">
                                <label  className="form-label">
                                Personal Email
                                </label>
                                <input
                                type="email"
                                className="form-control"
                                name='email'
                                onBlur={User_exist}
                                placeholder='Enter Personal Email'
                                onChange={HandelChange}
                                value={FormData.email}
                                required
                                />
                                {errors.email && <span className='text-danger'>{errors.email}</span>}
                                <div id="emailHelp" className="form-text">
                                We'll never share your email with anyone else.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">
                                Password
                                </label>
                                <input
                                type="password"
                                name='password'
                                className="form-control"
                                placeholder='Enter Password'
                                onChange={HandelChange}
                                value={FormData.password}
                                />
                                {errors.password && <span className='text-danger'>{errors.password}</span>}

                            </div>
                            <div className="mb-3">
                                <label className='mb-2 form-label' htmlFor="gender">Gender</label>
                                <select
                                    name='gender'
                                    className='mb-4 form-control form-select'
                                    onChange={HandelChange}
                                    value={FormData.gender}
                                    required
                                >
                                    <option  value=""  selected>
                                    Select a Gender
                                    </option>
                                    <option  value='male'>Male</option>
                                    <option  value='female'>Female</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Select a role   
                                </label>
                                <select 
                                className="form-control"
                                onChange={HandelChange}
                                value={FormData.role}
                                name='role'
                                required
                                >
                                    <option selected disabled  value=''>---select a value ---</option>
                                    <option value="participant">Participant</option>
                                    <option value="scorer">Scorer</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                            <div>
                                <input type="checkbox" name="status" value='true' onChange={HandelChange}/>
                                <label className='ms-3 mb-5  form-label' htmlFor="verify">Verified user?</label>
                            </div>
                            <button type="submit" className="btn btn-secondary ms-3 float-end" onClick={()=> window.history.back()}>
                                Cancel
                            </button>
                            <button type="button" onClick={HandelSubmit} className="btn btn-primary float-end" disabled={DisableRegister || userexist} >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </CCardBody>
            </CCard>
        </>
    )
}
export default CreateUser;