import Database  from './model'
const {Get,Post ,Delete, uploadPost} = Database();

export const LoginAdmin = async(FormData) => {
    let response = await Post({ url: 'admin/login', bodyData: FormData })
    return response;
}
export const deletepdfs = async(search)=>{
    console.log("url" , `admin/records/delete/${search}`);
    let response = await Delete({url : `admin/records/delete/` , id :search });
    return response;
}
export const uploadPdf = async(FormData)=>{
    let response = await uploadPost({url : 'admin/upload_pdf' , bodyData:FormData});
    return response;
}
export const getpdfs = async()=>{
   let response = await Get({ url: 'admin/getpdfdetails' })
   return response
}
export const GetTournaments = async(search) => {
    let response
    if (search === undefined) {
        response = await Get({ url: 'admin/tournament/get' })
    } else {
        response = await Get({ url: `admin/tournament/get?search=${search.data}`})
    }
    return response;
}
export const showedit = async(FormData)=>{
    let response = await Post({ url: 'admin/match/showedit',  bodyData: FormData
    })
    return response
}
export const GetGroups = async(formData) => {
    let response = await Post({ url: 'admin/group/get', bodyData: formData})
    return response; 
}

export const GetGroupList = async(formData) => {
    let response = await Post({ url: 'admin/group/list', bodyData: formData})
    return response; 
}

export const GetTournamentList = async() => {
    let response = await Get({ url: 'admin/tournament/list' })
    return response;
}

export const CreateTournament = async(formData) => {
    let response = await Post({ url: 'admin/tournament/create', bodyData: formData})
    return response; 
}

export const DeleteTournament = async(tournamentID) => {
    let response = await Get({ url: `admin/tournament/delete?id=${tournamentID.id}` })
    return response;
}

export const EditTournament = async(urlID) => {
    let response = await Get({ url: `admin/tournament/detail?id=${urlID.data}`})
    return response;
}

export const AvailableSlots = async() => {
    let response = await Post({ url: `player/available/slots`, bodyData: {}})
    return response;
}
export const TournamentDetail = async(userID) => {
    let response = await Get({ url: `admin/tournament/detail?id=${userID.id}` })
    return response;
}


export const CreateCorporate = async(formData) => {
    let response = await Post({ url: 'admin/corporate/create', bodyData: formData})
    return response; 
}

export const GetCorporate = async(search) => {
    let response
    if (search === undefined) {
        response = await Get({ url: 'admin/corporate/get' })
    } else {
        response = await Get({ url: `admin/corporate/get?search=${search.data}`})
    }
    return response;
}

export const DeleteCorporate = async(corporateID) => {
    let response = await Get({ url: `admin/corporate/delete?id=${corporateID.id}` })
    return response;
}

export const EditCorporate = async(urlID) => {
    let response = await Get({ url: `admin/corporate/detail?id=${urlID.data}`})
    return response;
}

export const GetUsers = async(search = undefined, type) => { 
    let response;
    if (search === undefined || search === null) {
        response = await Get({ url: `admin/user/get?role=${type}` })
    } else {
        response = await Get({ url: `admin/user/get?role=${type}&&search=${search.data}`})
    }

    return response;
}

export const CreateUser = async(formData) => {
    let response = await Post({ url: 'admin/user/create', bodyData: formData})
    return response;
}

export const EditUser = async(urlID) => {
    let response = await Get({ url: `admin/user/detail?id=${urlID.data}`})
    return response;
}

export const DeleteUser = async(userID) => {
    let response = await Get({ url: `admin/user/delete?id=${userID.id}` })
    return response;
}

export const GetParticipantList = async(userID) => {
    let response = await Get({ url: `admin/user/participant/list` })
    return response;
}

export const UserDetail = async(userID) => {
    let response = await Get({ url: `admin/user/detail?id=${userID.id}` })
    return response;
}

export const Profile = async() => {
    let response = await Get({url: 'auth/profile'})
    return response;
}

export const GetScorers = async(search) => {
    let response = await Get({ url: 'admin/scorers/get' })
    return response;
}

export const AssignScorer = async(FormData) => {
    let response = await Post({ url: 'admin/match/assign', bodyData: FormData}) 
    return response;
}

export const GetTodayMatches = async() => {
    let  response = await Get({ url: 'admin/get_today_matches'})
    return response
}

export const SwapUser = async (FormData) => {
    let  response = await Post({ url: 'admin/match/swapuser', bodyData: FormData})
    return response
}

export const GetLogs = async (MatchID) => {
    let  response = await Get({ url: `admin/logs?match_id=${MatchID}` })

    return response
}

export const GetMatch = async (MatchID) => {
    let  response = await Get({ url: `admin/match-details?match_id=${MatchID}` })

    return response 
}

export const GetStandings = async (GroupID) => {
    let  response = await Get({ url: `admin/standings/get?group_id=${GroupID}` })

    return response
}
export const stagestatus = async(formData) => {
    let response = await Post({ url: 'admin/match/stagestatus', bodyData: formData });
    return response;
}
export const winnerdetails = async(FormData) => {
    let response = await Post({ url: 'admin/round_winner',  bodyData: FormData
    })
    return response
}

export const RoundMatchesAPI = async(FormData) => {
    let response = await Post({ url: 'admin/get_knockout_matches',  bodyData: FormData
    })
    return response
}
export const IsMatchDone = async(FormData) => {
    let response = await Post({ url: 'admin/match/ismatchdone',  bodyData: FormData
    })
    return response
}

export const SetMatchResult = async(FormData) => {
    let response = await Post({url:'scorer/set_match_result', bodyData:FormData});
    return response;
}

export const KnockoutStarted = async(FormData) => {
    let response = await Post({url:'admin/match/is_knockout', bodyData:FormData});
    return response;
}

export const submitScoreCard = async(FormData) => {
    let response = await Post({url:'admin/match/score', bodyData:FormData});
    return response;
}

export const GetBowlingMatch = async(FormData) => {
    let response = await Post({url:'admin/match/bowling_get', bodyData:FormData});
    return response;
}

export const Get2kMatch = async(FormData) => {
    let response = await Post({url:'admin/match/get_2k_match', bodyData:FormData});
    return response;
}

export const RoundConfig = async(FormData) => {
    let response = await Post({url:'admin/match/roundconfig', bodyData:FormData});
    return response;
}

export const SportsLogs = async(FormData) => {
    let response = await Post({url:'admin/match/submit_logs', bodyData:FormData});
    return response;
}

export const GetSportLogs = async(FormData) => {
    let response = await Post({url:'admin/match/showlogs', bodyData:FormData});
    return response;
}

export const userpaymentdetails = async(FormData)=>{
    let response = await Post({ url: 'admin/paymentdetails', bodyData:FormData})
    return response; 
}

export const UserPayment = async(FormData)=>{
    let response = await Post({ url: 'admin/user_payment', bodyData:FormData})
    return response; 
}

export const getEnrolledUserData = async(FormData) => {
    let response = await Post({ url: 'admin/get_enrolled', bodyData:FormData})
    return response;
}

export const playerPaymentDetails = async(FormData) => {
    let response = await Post({ url: 'admin/payment_detail', bodyData:FormData})
    return response;
}

export const getGamePricing = async() => {
    let response = await Get({ url: 'admin/game_pricing'})
    return response;
}
export const updatePricing = async(FormData) => {
    let response = await Post({ url: 'admin/update_pricing', bodyData: FormData})
    return response;
}

export const getSetting = async(FormData) => {
    let response = await Get({ url: 'admin/get_setting'})
    return response;
}
export const updatSetting = async(FormData) => {
    let response = await Post({ url: 'admin/update_setting', bodyData: FormData})
    return response;
}