import React, { useEffect, useState } from "react";
import FormContainer from './FormContainer';
import ImageUpload from "./ImageComponent"
import profile from '../../assets/images/user.png'
import batminton from '../../assets/images/games/bt.svg'
import tabletenis from '../../assets/images/games/tt.svg'
import bowling1 from '../../assets/images/games/Bowling11.svg'
import walk from '../../assets/images/games/walk1.svg'


import {json, useFetcher, useNavigate} from"react-router-dom";        
import { useSnackbar } from 'notistack';
import Loader from '../../views/admin/Common/Loader';
import {getPlayerSports, CurrentTournament, updateSport, updateUser, enrolledUser, avalibleSlotsAPI, uploadImage, enrolledWaiting,WaitlistPlayersAPI} from '../../API/player';
import {IsMatchDone, getSetting}  from '../../API/admin'
import Swal from "sweetalert2";
const SportSelection = () => {
    // let showsport =JSON.parse( localStorage.getItem('showsport'));
    let navigate = useNavigate();
    const [loaderStatus, setloaderStatus] = useState(false);
    const [badmintionSlot, setBadmintionSlot] = useState(null);
    const [tableTennisSlot, setTableTenniSlot] = useState(null);
    const [bowlingSlot, setBowlingSlot] = useState(null);
    const [twokRunSlot, settwokRuniSlot] = useState(null);
    const [waitListActive, setWaitListActive] = useState(
        {
        ['badminton'] : {waitList : false,  sport: null,round : null},
        ['table_tenis'] : {waitList : false,  sport: null,round : null},
        ['bowling'] : {waitList : false,  sport: null,round : null},
        ['twok_run'] :{waitList : false,  sport: null,round : null}
        }
    );
    const [logout, setLogout] = useState(false)
    const [logoutCount, setLogoutCount] = useState(0)
  
	const { enqueueSnackbar } = useSnackbar();

    const [FormData ,setFormData] = useState({
        sports:[],
        image: null
	})
    const [tournament, setTournament] = useState('');
    const [disable, setDisable] = useState(false);
    const [badmintion, setBadmintion] = useState(false);
    const [tennis, setTennis] = useState(false);


    const [selectBatminton, SetSelectBatminton] = useState(null);
    const [selectTableTenis, SetSelectTableTenis] = useState(null);
    const [selectBowling, SetSelectBowling] = useState(null);
    const [select2k_run, SetSelect2k_run] = useState(null);
            // const [temp, settemp] = useState(false);
    const [temp, setTemp] = useState(false);



    // const [selectbowling, SetSelectbowling] = useState(null);
    // const [select2krun, SetSelect2krun] = useState(null);
    const [sportSelection, setSportSelection] = useState({batminton:false,tableTenis:false, bowling: false, twokRun: false});
    var showsport=null;

    useEffect(() => {
        let callAPI = async () => {
            let res =  await getSetting()

            if(res.status) {
                if(res.data.value == "false") {
                    Swal.fire({
                        title: "Tournament registration are closed",
                        text: "You are late , tournament registration are closed, try again next time",
                        icon: "error",
                        allowOutsideClick : false,
                        allowEscapeKey : false

                      }).then(async (result) => {
                        if (result.isConfirmed) {
                           navigate('/logout')
                        }
                    });
                }
                
            } else { 
                setIsOn(null);
                enqueueSnackbar('unable to fetch setting', { variant: 'error' });
    
            } 
        }   
        if (tournament != '') {
            callAPI()
        }
    }, [tournament])
    
    useEffect(()=>{
        console.log("Firt hook")
        console.log(showsport)  

       

    if(showsport!=null) setSportSelection(showsport);
        if(sportSelection.batminton == true) 
            {
                SetSelectBatminton({
                    title:'Selected',
                    value:'cancel',
                    style:{
                        backgroundColor:'rgba(23, 92, 30, 1)',
                        color:'white'
                    },
                });
            }
        if(sportSelection.tableTenis == true)
            {
                SetSelectTableTenis({
                    title:'Selected',
                    value:'cancel',
                    style:{
                        backgroundColor:'rgba(23, 92, 30, 1)',
                        color:'white'
                    },
                })
            }
            if(sportSelection.bowling == true)
                {
                    SetSelectBowling({
                        title:'Selected',
                        value:'cancel',
                        style:{
                            backgroundColor:'rgba(23, 92, 30, 1)',
                            color:'white'
                        },
                    })
                }
                if(sportSelection.twokRun == true)
                    {
                            console.log("in here");
                        SetSelect2k_run({
                            title:'Selected',
                            value:'cancel',
                            style:{
                                backgroundColor:'rgba(23, 92, 30, 1)',
                                color:'white'
                            },
                        })
                    }    
    } , [showsport])
   
    
    useEffect(()=> {
     console.log("here back")

      showsport = JSON.parse(localStorage.getItem('showsport'));
     console.log(showsport)
     if (showsport != null) {
        
        if(showsport.batminton == true) 
            {
                SetSelectBatminton({
                    title:'Selected',
                    value:'cancel',
                    style:{
                        backgroundColor:'rgba(23, 92, 30, 1)',
                        color:'white'
                    },
                });
            }
        if(showsport.tableTenis == true)
            {
                SetSelectTableTenis({
                    title:'Selected',
                    value:'cancel',
                    style:{
                        backgroundColor:'rgba(23, 92, 30, 1)',
                        color:'white'
                    },
                })
            }
            if(showsport.bowling == true)
                {
                    SetSelectBowling({
                        title:'Selected',
                        value:'cancel',
                        style:{
                            backgroundColor:'rgba(23, 92, 30, 1)',
                            color:'white'
                        },
                    })
                }
                if(showsport.twokRun == true)
                    {
                            console.log("in here");
                        SetSelect2k_run({
                            title:'Selected',
                            value:'cancel',
                            style:{
                                backgroundColor:'rgba(23, 92, 30, 1)',
                                color:'white'
                            },
                        })
                    }    
     }

        let callAPI = async () => {
            let res =  await CurrentTournament()
            if(res.data.status) {
                setTournament(res.data.data.id);
                localStorage.setItem('tournamentID', res.data.data.id);
                
            } 
        }
         
        let getSports = async () => {
            let res =  await enrolledUser();
            if (res.status) {
                navigate('/dashboard');
                enqueueSnackbar('you have already enrolled in tournament', { variant: 'error' });
            }
        }

        let avalibleSlots = async () => {
            setloaderStatus(true)
            let res =  await avalibleSlotsAPI();
            console.log("responseee", res.data)
            if (res.status) {
                setloaderStatus(false)
                if (res.data.TotalResult.badminton > 0) {
                    console.log("in 0 igger")
                    SetSelectBatminton(
                        {
                            title:'Avalible',value:res.data.TotalResult.badminton,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setBadmintionSlot(res.data.TotalResult.badminton)
                    setLogoutCount(logoutCount+1)
                }  
                 if (res.data.TotalResult.badminton == 0){
                    console.log("in 0 smaller badmintion")

                    SetSelectBatminton(
                        {
                            title:'Unavalible',value:res.data.TotalResult.badminton,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setBadmintionSlot(res.data.TotalResult.badminton)
                    setWaitListActive((prevState) => ({
                        ...prevState,
                        badminton: {
                          waitList: true,
                          sport: 'badminton',
                          round: 'group_stage',
                        },
                      }));
                } 
                if (res.data.TotalResult.table_tenis > 0) {
                    SetSelectTableTenis(
                        {
                            title:'Avalible',value:res.data.TotalResult.table_tenis,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setTableTenniSlot(res.data.TotalResult.table_tenis)
                    setLogoutCount(logoutCount+1)

                }  
                if (res.data.TotalResult.table_tenis == 0) {
                    SetSelectTableTenis(
                        {
                            title:'Unavalible',value:res.data.TotalResult.table_tenis,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setTableTenniSlot (res.data.TotalResult.table_tenis)
                    setWaitListActive((prevState) => ({
                        ...prevState,
                        table_tenis: {
                            waitList: true,
                            sport: 'table_tenis',
                            round: 'group_stage'
                        },
                      }));
                    
                }

                //bowling
                if (res.data.TotalResult.bowling > 0) {
                    SetSelectBowling(
                        {
                            title:'Avalible',value:res.data.TotalResult.bowling,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setBowlingSlot(res.data.TotalResult.bowling)
                    setLogoutCount(logoutCount+1)

                } 

                 if (res.data.TotalResult.bowling == 0) {
                    console.log("Bowling is 0");
                    
                    SetSelectBowling(
                        {
                            title:'Unavalible',value:res.data.TotalResult.bowling,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    setBowlingSlot(res.data.TotalResult.bowling)
                    setWaitListActive((prevState) => ({
                        ...prevState,
                        bowling: {
                            waitList: true,
                            sport: 'bowling',
                            round: 'round_1'
                        },
                      }));
                   
                }

                //2k_run
                if (res.data.TotalResult.twok_run > 0) {
                    SetSelect2k_run(
                        {
                            title:'Avalible',value:res.data.TotalResult.twok_run,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    settwokRuniSlot(res.data.TotalResult.twok_run)
                    setLogoutCount(logoutCount+1)

                } 
                 if (res.data.TotalResult.twok_run == 0) {
                    SetSelect2k_run(
                        {
                            title:'Unavalible',value:res.data.TotalResult.twok_run,style:{color: 'rgba(23, 92, 30, 1)',
                            fontFamily: 'Roboto',
                            border:'1px solid rgba(23, 92, 30, 1)'
                        }}
                    )
                    settwokRuniSlot(res.data.TotalResult.twok_run)
                    setWaitListActive((prevState) => ({
                        ...prevState,
                        twok_run: {
                            waitList: true,
                        sport: '2k_run',
                        round: 'round_1'
                        },
                      }));
                }
                
            }
        }
        callAPI()
        getSports()
        avalibleSlots()
    },[])
    

    let waitlistRegisterAPI = async (game) => {
        setloaderStatus(true);
        console.log("tournament" , tournament);
        
        let FormData = {
            "game_type" : game,
            "type":"waiting",
            "tournament_id" : tournament
         }
        let res = await enrolledWaiting (FormData);
        if (res.data.status) {
        setloaderStatus(false);
        enqueueSnackbar('Wating List joined Successfully', { variant: 'success' });
        return true
        } else {
            setloaderStatus(false);
            enqueueSnackbar('unable to join Wating List ', { variant: 'error' });
              console.log("Check again");
            return false
        }
    }

    // useEffect(() => {
    
    //     // Define an async function to handle API calls
    //     const callAPI = async () => {
    //         // Convert the Object.entries to an array of promises
    //         const promises = Object.entries(waitListActive).map(async ([key, sport]) => {
    //             if (sport.waitList) {
    //                 // Prepare the request data
    //                 const formData = {
    //                     tournament_id: tournament,
    //                     game_type: sport.sport,
    //                     waitList: sport.waitList,
    //                     round: sport.round
    //                 };
                    
    //                 // Make the API call
    //                 setloaderStatus(true)
    //                 const res = await IsMatchDone(formData);
    //                 console.log(res);
                    
    //                 if (res.data.status) {
    //                 setloaderStatus(false)

    //                     // Update the corresponding state based on the sport
    //                     if (sport.sport === 'badminton') {
                            
    //                         if (res.data.data) {  
    //                             SetSelectBatminton({
    //                             title:'Join Waitlist',
    //                             value:0,
    //                             style:{
    //                                 backgroundColor:'#fab92c',
    //                                 color:'white'
    //                             },
    //                             });;
    //                         }
    //                         console.log("bad +++++", res.data.data);
    //                     } else if (sport.sport === 'table_tenis') {
    //                         if (res.data.data) {
    //                             SetSelectTableTenis({
    //                                 title:'Join Waitlist',
    //                                 value:0,
    //                                 style:{
    //                                     backgroundColor:'#fab92c',
    //                                     color:'white'
    //                                 },
    //                             })
    //                         } 
    //                         console.log("table +++++", res.data.data);
    //                     } else if (sport.sport === 'bowling') {
    //                         if (res.data.data) {
    //                             SetSelectBowling(
    //                                 {
    //                                     title:'Join Waitlist',
    //                                     value:0,
    //                                     style:{
    //                                         backgroundColor:'#fab92c',
    //                                         color:'white'
    //                                     },
    //                                 }
    //                             )
    //                         }
    //                         console.log("bow +++++", res.data.data);
    //                     } else if (sport.sport === '2k_run') {
    //                         if (res.data.data) {
    //                             SetSelect2k_run(
    //                                 {
    //                                     title:'Join Waitlist',
    //                                     value:0,
    //                                     style:{
    //                                         backgroundColor:'#fab92c',
    //                                         color:'white'
    //                                     },
    //                                 }
    //                             )
    //                         }
    //                         console.log("2k +++++", res.data.data);
    //                     }

    //                 } else {
    //                 setloaderStatus(false)

    //                 }
    //             } 
    //         });
    
    //         // Wait for all promises to complete
    //         let completed = await Promise.all(promises);
    //         settemp(true)
    //         let CallAPIWaitList = async () => {
    //             console.log("APi callrd");
                
    //             let formData = {
    //                 "tournament_id": tournament
    //             }
    //             setloaderStatus(true)
    //             let res = await WaitlistPlayersAPI(formData)
               
    //             if (res.data.status) {
    //                 setloaderStatus(false)
                    
    //                 // if (logoutCount == 0) { 
    //                 //     Swal.fire({
    //                 //         title: "You have joined waitlist Already ",
    //                 //         text: "You are already enrolled in waitlist , sit back  we will inform  you via email once slots are available",
    //                 //         icon: "success", 
    //                 //         confirmButtonColor: "#3085d6",
    //                 //         confirmButtonText: "Logout",
    //                 //         allowOutsideClick: false,
    //                 //         allowEscapeKey: false
                
    //                 //       }).then((result) => {
    //                 //         if (result.isConfirmed) {
    //                 //             window.location.href = '/logout'
            
    //                 //         }
    //                 //     });
    //                 // } else {
    //                 //     // res.data.data.map((sport, index) => {
    //                 //     //     if (sport == 'badminton') {
    //                 //     //         SetSelectBatminton(
    //                 //     //             {
    //                 //     //                 title:'Joined waitlist',
    //                 //     //                 value:0,
    //                 //     //                 style:{
    //                 //     //                     backgroundColor:'#5856d6',
    //                 //     //                     color:'white'
    //                 //     //                 },
    //                 //     //             }
    //                 //     //         )
    //                 //     //     } else if (sport == 'table_tenis') {
    //                 //     //         SetSelectTableTenis(
    //                 //     //             {
    //                 //     //                 title:'Joined waitlist',
    //                 //     //                 value:0,
    //                 //     //                 style:{
    //                 //     //                     backgroundColor:'#5856d6',
    //                 //     //                     color:'white'
    //                 //     //                 },
    //                 //     //             }
    //                 //     //         )
    //                 //     //     }
        
    //                 //     //     else if (sport == 'bowling') {
    //                 //     //         SetSelectBowling(
    //                 //     //             {
    //                 //     //                 title:'Joined waitlist',
    //                 //     //                 value:0,
    //                 //     //                 style:{
    //                 //     //                     backgroundColor:'#5856d6',
    //                 //     //                     color:'white'
    //                 //     //                 },
    //                 //     //             }
    //                 //     //         )
    //                 //     //     } else if (sport =='2k_run') {
    //                 //     //         SetSelect2k_run(
    //                 //     //             {
    //                 //     //                 title:'Joined waitlist',
    //                 //     //                 value:0,
    //                 //     //                 style:{
    //                 //     //                     backgroundColor:'#5856d6',
    //                 //     //                     color:'white'
    //                 //     //                 },
    //                 //     //             }
    //                 //     //         )
    //                 //     //     }
    //                 //     // })
    //                 // }
    //                 res.data.data.map((Entry, index) => {
    //                     if (Entry.game_type == 'badminton') {
    //                         SetSelectBatminton(
    //                             {
    //                                 title:'Joined Waitlist',
    //                                 value:0,
    //                                 style:{
    //                                     backgroundColor:'#5856d6',
    //                                     color:'white'
    //                                 },
    //                             }
    //                         )
    //                     } else if (Entry.game_type == 'table_tenis') {
    //                         console.log("im herererere");
                            
    //                         SetSelectTableTenis(
    //                             {
    //                                 title:'Joined Waitlist',
    //                                 value:0,
    //                                 style:{
    //                                     backgroundColor:'#5856d6',
    //                                     color:'white'
    //                                 },
    //                             }
    //                         )
    //                     }
        
    //                     else if (Entry.game_type == 'bowling') {
    //                         SetSelectBowling(
    //                             {
    //                                 title:'Joined Waitlist',
    //                                 value:0,
    //                                 style:{
    //                                     backgroundColor:'#5856d6',
    //                                     color:'white'
    //                                 },
    //                             }
    //                         )
    //                     } else if (Entry.game_type =='2k_run') {
    //                         SetSelect2k_run(
    //                             {
    //                                 title:'Joined Waitlist',
    //                                 value:0,
    //                                 style:{
    //                                     backgroundColor:'#5856d6',
    //                                     color:'white'
    //                                 },
    //                             }
    //                         )
    //                     }
    //                 })
        
    //             } else {
    //                 setloaderStatus(false)
        
    //             }
    //         }
    //         if (temp == true) {
    //             console.log(temp);
                
    //             CallAPIWaitList()
    //         }
          
    //     };
    
    //     // Call the async function
    //     callAPI();
    // }, [waitListActive,temp]);
    
  
    useEffect(() => {
        // Define an async function to handle API calls
        const callAPI = async () => {
          try {
            // Start loader
            setloaderStatus(true);
    
            // Convert the Object.entries to an array of promises
            const promises = Object.entries(waitListActive).map(async ([key, sport]) => {
              if (sport.waitList) {
                // Prepare the request data
                const formData = {
                  tournament_id: tournament,
                  game_type: sport.sport,
                  waitList: sport.waitList,
                  round: sport.round,
                };
    
                // Make the API call
                const res = await IsMatchDone(formData);
                console.log(res);
    
                if (res.data.status) {
                  // Update the corresponding state based on the sport
                  if (sport.sport === 'badminton' && res.data.data) {
                    SetSelectBatminton({
                      title: 'Join Waitlist',
                      value: 0,
                      style: {
                        backgroundColor: '#fab92c',
                        color: 'white',
                      },
                    });
                  } else if (sport.sport === 'table_tenis' && res.data.data) {
                    SetSelectTableTenis({
                      title: 'Join Waitlist',
                      value: 0,
                      style: {
                        backgroundColor: '#fab92c',
                        color: 'white',
                      },
                    });
                  } else if (sport.sport === 'bowling' && res.data.data) {
                    SetSelectBowling({
                      title: 'Join Waitlist',
                      value: 0,
                      style: {
                        backgroundColor: '#fab92c',
                        color: 'white',
                      },
                    });
                  } else if (sport.sport === '2k_run' && res.data.data) {
                    SetSelect2k_run({
                      title: 'Join Waitlist',
                      value: 0,
                      style: {
                        backgroundColor: '#fab92c',
                        color: 'white',
                      },
                    });
                  }
                }
              }
            });
    
            // Wait for all promises to complete
            await Promise.all(promises);
    
            // Call the second API after the first set is done
            await CallAPIWaitList();
          } catch (error) {
            console.error('Error in API call:', error);
          } finally {
            // Stop loader
            setloaderStatus(false);
          }
        };
    
        const CallAPIWaitList = async () => {
          try {
            let formData = {
              tournament_id: tournament,
            };
    
            const res = await WaitlistPlayersAPI(formData);
    
            if (res.data.status) {
              res.data.data.forEach((Entry) => {
                if (Entry.game_type === 'badminton') {
                  SetSelectBatminton({
                    title: 'Joined Waitlist',
                    value: 0,
                    style: {
                      backgroundColor: '#5856d6',
                      color: 'white',
                    },
                  });
                } else if (Entry.game_type === 'table_tenis') {
                  SetSelectTableTenis({
                    title: 'Joined Waitlist',
                    value: 0,
                    style: {
                      backgroundColor: '#5856d6',
                      color: 'white',
                    },
                  });
                } else if (Entry.game_type === 'bowling') {
                  SetSelectBowling({
                    title: 'Joined Waitlist',
                    value: 0,
                    style: {
                      backgroundColor: '#5856d6',
                      color: 'white',
                    },
                  });
                } else if (Entry.game_type === '2k_run') {
                  SetSelect2k_run({
                    title: 'Joined Waitlist',
                    value: 0,
                    style: {
                      backgroundColor: '#5856d6',
                      color: 'white',
                    },
                  });
                }
              });
            }
          } catch (error) {
            console.error('Error in WaitlistPlayersAPI:', error);
          }
        };
    
        // Call the async function
        callAPI();
      }, [waitListActive]);

   useEffect(() => {
        if (logoutCount != 0) {
            if(logoutCount == 4) {
                setLogout(false)
            }  
            if (logoutCount > 0 && logoutCount< 4) {
                setLogout(true)
            } 

        } 
   }, [logoutCount])


    const HandelChange = (event) => {
	    const { name, value, checked } = event.target;
		if (name === 'sports') {
			const updatedSports = [...FormData.sports];
			if (checked) {
			  updatedSports.push(value);
			} else {
			  const index = updatedSports.indexOf(value);
			  if (index !== -1) {
				updatedSports.splice(index, 1);
			  }
			}
			// Update the FormData state with the new sports array
			setFormData({ ...FormData, sports: updatedSports });
        } else {
            // For other input fields, update the FormData state normally
            setFormData({ ...FormData, [name]: value });
        }
    }
    const HandelSubmit = async(event) => {
        var queryParams =''
        queryParams = new URLSearchParams({ badminton: sportSelection.batminton, tennis: sportSelection.tableTenis, bowling: sportSelection.bowling, twok_run:sportSelection.twokRun }).toString();
         localStorage.setItem('showsport' , JSON.stringify({ badminton: sportSelection.batminton, tennis: sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun:sportSelection.twokRun }))

    navigate(`/game-plan?${queryParams}`);
        // Object.entries(waitListActive).map( async([key, sport]) =>
        //     {
        //       if (sport.waitList) {
        //         flag = true
        //       }
        //     } ) //end loop
        // if (flag) {
        //     queryParams = new URLSearchParams({ badminton: sportSelection.batminton, tennis: sportSelection.tableTenis, bowling: sportSelection.bowling, twok_run:sportSelection.twokRun, waitList: true }).toString();
                
        //     localStorage.setItem('showsport' , JSON.stringify({ badminton: sportSelection.batminton, tennis: sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun:sportSelection.twokRun ,waitList: true }))

       
        // }  else {
        // }

    	// event.preventDefault();
        // if(FormData.sports.length === 0) {
        //     enqueueSnackbar('Please select a sport.sport', { variant: 'error' });
        // } else {
        //     localStorage.setItem('sports',FormData.sports);
        //     const queryParams = new URLSearchParams({ badminton: FormData.sports.includes('badminton') ? 'true' : 'false', tennis: FormData.sports.includes('tennis') ? 'true' :'false' }).toString();
        //     navigate(`/game-plan?${queryParams}`);
        // }
       
    }

    

    function handleSelectSport(Sport,Value){
        console.log(Sport, Value);
        let selectedValue = {
            backgroundColor:'rgba(23, 92, 30, 1)',
            color:'white'
        }

        let avalibleValue = {
            color: 'rgba(23, 92, 30, 1)',
            fontFamily: 'Roboto',
            border:'1px solid rgba(23, 92, 30, 1)'
        }
        
        if(Sport=='badminton'){
            if(Value=='Avalible'){
                SetSelectBatminton({
                    title:'Selected',
                    value:'cancel',
                    style:selectedValue,
                });
                console.log(sportSelection)

                setSportSelection({batminton:true,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: sportSelection.twokRun})
            }
            else if (Value === 'Join Waitlist') {
                Swal.fire({
                    title: "Are you sure to join waitlist?",
                    text: "After joining, we will inform you via email when the slots are available",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Join"
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log("Stat set");
                        
                        SetSelectBatminton(
                            {
                                title:'Joined Waitlist',
                                value:0,
                                style:{
                                    backgroundColor:'#5856d6',
                                    color:'white'
                                },
                            }
                        )

                     let res =  await waitlistRegisterAPI('badminton')
                     if (res) {
                        let propertirs = {
                            title: "Waitlist joined successfully",
                            text: "You have beene enrolled in waitling list, we will notify you once the slot is available",
                            icon: "success", 
                            confirmButtonColor: "#3085d6",
                            allowOutsideClick: false,
                            allowEscapeKey: false,

                         
                            confirmButtonText: "Logout"
                        }
                        if (logoutCount != 0) {
                            console.log("logcount", logoutCount)
                            propertirs.cancelButtonColor = "info";
                            propertirs.cancelButtonText = "Continue"
                            propertirs.showCancelButton = true
                            propertirs.allowOutsideClick = true;
                            propertirs.allowEscapeKey = true
                           
                        }
                        Swal.fire(propertirs).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/logout'
                            }
                          })
                     }
                     return res
                    }
                });

              
                // setSportSelection({batminton:true,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: sportSelection.twokRun})
            }
            else{

               if ( waitListActive['badminton'].waitList ) {
                SetSelectBatminton({
                    title:'Join Waitlist',
                    value:badmintionSlot,
                    style: {  backgroundColor:'#fab92c',
                        color:'white'},
                })
                setSportSelection({batminton:false,tableTenis:sportSelection.tableTenis,bowling: sportSelection.bowling, twokRun: sportSelection.twokRun })
               } else {
                   SetSelectBatminton({
                       title:'Avalible',
                       value:badmintionSlot,
                       style:avalibleValue,
                   })
                   setSportSelection({batminton:false,tableTenis:sportSelection.tableTenis,bowling: sportSelection.bowling, twokRun: sportSelection.twokRun })

               }
            }
        } else if (Sport=='tableTenis'){
            if(Value=='Avalible'){
                SetSelectTableTenis({
                    title:'Selected',
                    value:'cancel',
                    style:selectedValue,
                })
                setSportSelection({batminton:sportSelection.batminton,tableTenis:true, bowling: sportSelection.bowling,twokRun: sportSelection.twokRun})
            } else if (Value === 'Join Waitlist') {
                Swal.fire({
                    title: "Are you sure to join waitlist?",
                    text: "After joining, we will inform you via email when the slots are available",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Join"
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log("Stat set");
                        
                        SetSelectTableTenis(
                            {
                                title:'Joined Waitlist',
                                value:0,
                                style:{
                                    backgroundColor:'#5856d6',
                                    color:'white'
                                },
                            }
                        )

                     let res =  await waitlistRegisterAPI('table_tenis')
                     if (res) {
                        let propertirs = {
                            title: "Waitlist joined successfully",
                            text: "You have beene enrolled in waitling list, we will notify you once the slot is available",
                            icon: "success", 
                            confirmButtonColor: "#3085d6",
                            allowOutsideClick: false,
                            allowEscapeKey: false,

                         
                            confirmButtonText: "Logout"
                        }
                        if (logoutCount != 0) {
                            console.log("logcount", logoutCount)
                            propertirs.cancelButtonColor = "info";
                            propertirs.cancelButtonText = "Continue"
                            propertirs.showCancelButton = true
                            propertirs.allowOutsideClick = true;
                            propertirs.allowEscapeKey = true
                           
                        }
                        Swal.fire(propertirs).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/logout'
                            }
                          })
                     }
                     return res
                    }
                });

                // setSportSelection({batminton:sportSelection.batminton,tableTenis:true, bowling: sportSelection.bowling,twokRun: sportSelection.twokRun})
            }
            else{
                if ( waitListActive['table_tenis'].waitList ) {
                    SetSelectTableTenis({
                        title:'Join Waitlist',
                        value:tableTennisSlot,
                        style: {  backgroundColor:'#fab92c',
                            color:'white'},
                    })
                    setSportSelection({batminton:sportSelection.batminton,tableTenis:false, bowling: sportSelection.bowling,twokRun : sportSelection.twokRun})
                } else {
                    SetSelectTableTenis({
                        title:'Avalible',
                        value:tableTennisSlot,
                        style:avalibleValue,
                    })
                    setSportSelection({batminton:sportSelection.batminton,tableTenis:false, bowling: sportSelection.bowling,twokRun : sportSelection.twokRun})

                }
            }
        } else if (Sport=='bowling'){
            if(Value=='Avalible'){
                SetSelectBowling({
                    title:'Selected',
                    value:'cancel',
                    style:selectedValue,
                })
                setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: true, twokRun: sportSelection.twokRun})
            }else if (Value === 'Join Waitlist') {

                Swal.fire({
                    title: "Are you sure to join waitlist?",
                    text: "After joining, we will inform you via email when the slots are available",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Join"
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log("Stat set");
                        
                        SetSelectBowling(
                            {
                                title:'Joined Waitlist',
                                value:0,
                                style:{
                                    backgroundColor:'#5856d6',
                                    color:'white'
                                },
                            }
                        )

                     let res =  await waitlistRegisterAPI('bowling')
                     if (res) {
                        let propertirs = {
                            title: "Waitlist joined successfully",
                            text: "You have beene enrolled in waitling list, we will notify you once the slot is available",
                            icon: "success", 
                            confirmButtonColor: "#3085d6",
                            allowOutsideClick: false,
                            allowEscapeKey: false,

                         
                            confirmButtonText: "Logout"
                        }
                        if (logoutCount != 0) {
                            console.log("logcount", logoutCount)
                            propertirs.cancelButtonColor = "info";
                            propertirs.cancelButtonText = "Continue"
                            propertirs.showCancelButton = true
                            propertirs.allowOutsideClick = true;
                            propertirs.allowEscapeKey = true
                           
                        }
                        Swal.fire(propertirs).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/logout'
                            }
                          })
                     }
                     return res
                    }
                });
                // setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: true, twokRun: sportSelection.twokRun})
            }
            else{
                if ( waitListActive['bowling'].waitList ) {
                    SetSelectBowling({
                        title:'Join Waitlist',
                        value:bowlingSlot,
                        style: {   backgroundColor:'#fab92c',
                            color:'white'},
                    })
                    
                    setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: false, twokRun: sportSelection.twokRun})
                }  else {
                    SetSelectBowling({
                        title:'Avalible',
                        value:bowlingSlot,
                        style:avalibleValue,
                    })
                    setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: false, twokRun: sportSelection.twokRun})

                }
            }
        } else if (Sport=='twok_run'){
            console.log("in two"); 
            console.log(sportSelection);
            if(Value=='Avalible'){
                console.log("in ")
                SetSelect2k_run({
                    title:'Selected',
                    value:'cancel',
                    style:selectedValue,
                })
                setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: true})
            } else if (Value === 'Join Waitlist') {
                Swal.fire({
                    title: "Are you sure to join waitlist?",
                    text: "After joining, we will inform you via email when the slots are available",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Join"
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                        console.log("Stat set");
                        
                        SetSelect2k_run(
                            {
                                title:'Joined Waitlist',
                                value:0,
                                style:{
                                    backgroundColor:'#5856d6',
                                    color:'white'
                                },
                            }
                        )

                     let res =  await waitlistRegisterAPI('2k_run')
                     if (res) {
                        let propertirs = {
                            title: "Waitlist joined successfully",
                            text: "You have beene enrolled in waitling list, we will notify you once the slot is available",
                            icon: "success", 
                            confirmButtonColor: "#3085d6",
                            allowOutsideClick: false,
                            allowEscapeKey: false,

                         
                            confirmButtonText: "Logout"
                        }
                        if (logoutCount != 0) {
                            console.log("logcount", logoutCount)
                            propertirs.cancelButtonColor = "info";
                            propertirs.cancelButtonText = "Continue"
                            propertirs.showCancelButton = true
                            propertirs.allowOutsideClick = true;
                            propertirs.allowEscapeKey = true
                           
                        }
                        Swal.fire(propertirs).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/logout'
                            }
                          })
                     }
                     return res
                    }
                });
                
                // setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: true})
            }
            else{
                if ( waitListActive['twok_run'].waitList ) {
                    SetSelect2k_run({
                        title:'Join Waitlist',
                        value:twokRunSlot,
                        style: {  backgroundColor:'#fab92c',
                            color:'white'},
                    })
                    setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: false})
                } 
                SetSelect2k_run({
                    title:'Avalible',
                    value:twokRunSlot,
                    style:avalibleValue,
                })
                setSportSelection({batminton:sportSelection.batminton,tableTenis:sportSelection.tableTenis, bowling: sportSelection.bowling, twokRun: false})
            }
        }
    }

    let LogoutPlayer=  () => {
        navigate('/logout')
    }
    return (
        <>
        {loaderStatus ? 
                <Loader />
            : null}
            <FormContainer classname = 'col-md-10' >
                <h4 className="heading"><strong>CORPORATE SPORTS</strong></h4>
                <p className='col-desc' style={{fontWeight:400}}>Corporate sports organized activities that companies sponsor for their employees to participate in, either within the company or in leagues against other firms. </p>
                <div className="image">
                    {/* <div><img src={manimg}/></div> */}
                    <ImageUpload/>
                    {/* <p className="h5 text-black" style={{ marginTop: '10px', fontWeight:700}}>David Joe</p> */}
                </div>
                <div className="select-sport">
                    <p className="h5" style={{fontWeight:700 }}>Select Sports</p>
                </div>
                <div className="container">
                    <div className="row option-row" style={{padding:'10px 0 0 53px'}}>
                        <div className="col-6 upper-col">
                            <span  className='innerspan' style={{fontWeight:400}}> 
                                <img src={batminton} className='icons' style={{color: '#000'}}/>
                            </span>
                            Badminton
                        </div> 
                        {

                            selectBatminton != null ? (
                                <>
                                <div className="col-6 upper-col d-flex">
                                <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('badminton',selectBatminton.title)} style={selectBatminton.style}
                                disabled={selectBatminton.title == 'Unavalible' || selectBatminton.title == 'Joined waitlist' ? true :false}   >{selectBatminton.title}</button>
                                <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={()=>handleSelectSport('badminton',selectBatminton.value)}
                                 disabled={selectBatminton.title == 'Unavalible' ? true :false}       >{selectBatminton.value}</button>
                            </div>
                            </>

                            ) : null
                        }
                       
                        
                        {/* <div className="col-6 upper-col d-flex">
                            <button type="button" class="btn  btn-sm slected-btn btn-1" value="badmintion" onClick={handle1} style={btn1stylebt}>{xB}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle3}>{val1B}</button>
                        </div> */}
                        <div className="col-6 lower-col">
                            <span className='innerspan'  style={{fontWeight:400}}> 
                                <img src={tabletenis} className='icons' style={{color: '#000'}}/>
                            </span>
                            Table Tennis
                        </div>
                        {
                            selectTableTenis != null ?(
                                <>
                                 <div className="col-6 lower-col d-flex mb-3">
                            <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('tableTenis',selectTableTenis.title)} style={selectTableTenis.style}
                               disabled={selectTableTenis.title == 'Unavalible' || selectTableTenis.title === 'Joined Waitlist' ? true :false}   
                                >{selectTableTenis.title}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={()=>handleSelectSport('tableTenis',selectTableTenis.value)}
                             disabled={selectTableTenis.title == 'Unavalible'||  selectTableTenis.value == 0? true :false} 
                              >{selectTableTenis.value}</button>
                            {/* <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('table_tanis')} style={btn1stylett}>{xT}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle33}>{val1T}</button> */}
                            {/* <button type="button" class="btn btn btn-sm slected-btn btn-1" onClick={handle2} style={btn2style}>{y}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle4}>{val2}</button> */}
                        </div>
                                </>
                            ): null
                        }
                       
                       {/* // new bowling code */}
                       <div className="col-6 lower-col  ">
                            <span className='innerspan'  style={{fontWeight:400}}> 
                                <img src={bowling1} className='icons' style={{color:'black'}}/>
                            </span>
                           Bowling
                        </div>
                        {
                            selectBowling != null ?(
                                <>
                                 <div className="col-6 lower-col d-flex mb-3">
                                    <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('bowling',selectBowling.title)} style={selectBowling.style}
                                    disabled={selectBowling.title == 'Unavalible' || selectBowling.title == 'Joined waitlist' ? true :false}   
                                        >{selectBowling.title}</button>
                                    <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={()=>handleSelectSport('bowling',selectBowling.value)}
                                    disabled={selectBowling.title == 'Unavalible' ? true :false} 
                                    >{selectBowling.value}</button>
                            {/* <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('table_tanis')} style={btn1stylett}>{xT}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle33}>{val1T}</button> */}
                            {/* <button type="button" class="btn btn btn-sm slected-btn btn-1" onClick={handle2} style={btn2style}>{y}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle4}>{val2}</button> */}
                            </div>
                                </>
                            ): null
                        }

                      <div className="col-6 lower-col  ">
                            <span className='innerspan'  style={{fontWeight:400}}> 
                                <img src={walk} className='icons' style={{color: '#000'}}/>
                            </span>
                        2K Run
                        </div>
                        {
                            select2k_run != null ?(
                                <>
                                 <div className="col-6 lower-col d-flex">
                                    <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('twok_run',select2k_run.title)} style={select2k_run.style}
                                    disabled={select2k_run.title == 'Unavalible' ||  select2k_run.title == 'Joined waitlist'? true :false}   
                                        >{select2k_run.title}</button>
                                    <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={()=>handleSelectSport('twok_run',select2k_run.value)}
                                    disabled={select2k_run.title == 'Unavalible' ? true :false} 
                                    >{select2k_run
                                    .value}</button>
                            {/* <button type="button" class="btn  btn-sm slected-btn btn-1" onClick={()=>handleSelectSport('table_tanis')} style={btn1stylett}>{xT}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle33}>{val1T}</button> */}
                            {/* <button type="button" class="btn btn btn-sm slected-btn btn-1" onClick={handle2} style={btn2style}>{y}</button>
                            <button type="button" class="btn btn-outline-secondary btn-sm cancel-btn btn-2" onClick={handle4}>{val2}</button> */}
                            </div>
                                </>
                            ): null
                        }
                        <span className=" mt-3" style={{fontSize:"15px" , color:"#f9b115"}}>A waitlist can only be joined if the matches have not started yet.</span>   
                    </div>
                <div className="submitbtn">
                    <button type="button" onClick={HandelSubmit} disabled={ 
                        sportSelection.batminton || sportSelection.tableTenis || sportSelection.bowling || sportSelection.twokRun?false:true} class="btn btn-danger text-white font-weight-bold">Submit</button>
                        {
                            sportSelection.batminton || sportSelection.tableTenis || sportSelection.bowling || sportSelection.twokRun?  (
                                null

                            ) :<button type="button" onClick={LogoutPlayer} class="btn btn-danger text-white font-weight-bold mt-3">Logout</button>
                        }
                </div>
                </div>
            </FormContainer>
        </>
    )
}
export default SportSelection

