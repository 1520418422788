import { createStore } from 'redux';
const CLEAR_ALL_STATE = 'CLEAR_ALL_STATE';

export const clearAllState = () => ({
  type: CLEAR_ALL_STATE
});

const initialState = {
  sidebarShow: true,
  narrow: false,
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL_STATE:
      return initialState;
    case "NARROW": 
      return {
        ...state,
        narrow: !state.narrow,
      };
    default:
      return state;
  }
};

const store = createStore(changeState);

export default store;
