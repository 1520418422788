import React, { useEffect, useState } from "react";
import FormContainer from './FormContainer';
import {getPlayerSports, initiatePayment, avalibleSlotsAPI} from '../../API/player';
import {getGamePricing} from '../../API/admin';
import {Authenticated} from '../../API/auth';
import { useLocation, useNavigate } from 'react-router-dom'; // Import necessary hooks

import Loader from '../../views/admin/Common/Loader';
import Swal from "sweetalert2";


import PaymentModal from "../modal/PaymentModal";
import { useSnackbar } from 'notistack';

const Gameplan = () => {
  	let navigate = useNavigate();
    const [sports ,setSports] = useState([]);
    const [gamePricing ,setGamePricing] = useState([]);
    const [amount , setAmount] =useState(0);    
    const [visible, setVisible] = useState(false);
    const [disable, setDisable] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [paymentIntent, setPaymentIntent] = useState("");
    const [waitList, setWaitList] = useState(false);
    const [loaderStatus, setloaderStatus] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
  //  var waiListPlayer = false;
   var [waiListPlayer, setwaiListPlayer] = useState(false)
    const params = new URLSearchParams(location.search);
    const nameMapping = {
      "tennis": "table_tenis",
      "twok_run": "2k_run"
    };
  
   
    useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
            const selectedSports = [];
            params.forEach((value, key) => {
             if (key === 'waitList') {
                console.log("waiting true");
                
                    setWaitList(true)
             }
             else if(value === 'true') {
                selectedSports.push(key);
              }
            });

            setSports(selectedSports);
            
		} 
		
	}, []);

 let waitLitManage = async () => {
    const urlParams = new URLSearchParams(location.search);
    const isWaitlist = urlParams.has('wl');

    if (isWaitlist) {
        // Perform your actions here
        console.log("waitlist is true")
          var enrolled = false;
          setloaderStatus(true)
          let res = await avalibleSlotsAPI();
          if (res.status) {
            setloaderStatus(false)
            if (sports.includes('tennis')) {
             enrolled = res.data.TotalResult.table_tenis == 0 ? false : true
            } else if (sports.includes('bowling')) {
              enrolled =  res.data.TotalResult.bowling == 0 ? false : true
            } else if(sports.includes('badminton')) {
              enrolled = res.data.TotalResult.badminton == 0? false : true
            } else if (sports.includes('twok_run')) {
              enrolled = res.data.TotalResult.twok_run == 0 ? false : true
            }
            if (enrolled) {
              console.log("user can register")
              return true
            } else {
                Swal.fire({
                  title: "Slots are not available",
                  text: "We are sorry slots are booked again , thank you for followig the email , we will inform you again if any slot gets avialable.",
                  icon: "error",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Logout",
                  allowOutsideClick: false,
                  allowEscapeKey: false
      
                }).then((result) => {
                  if (result.isConfirmed) {
                      // window.location.href('/logout')
                      navigate('/logout')
                  }
              });
            }
          } else {
            console.log("user can  not register")

            setloaderStatus(false);
            return false
          }
        
    } else {
      return false
    }
  }

  let HandelSubmit = async () => {
   let result =  await waitLitManage();
    if (result == true) {
      console.log("player can register");
      
      // waiListPlayer = true;
      setwaiListPlayer(true)
      setVisible(!visible)
    } else {
      console.log("player can not register");

      setVisible(!visible)
      return false;
    }
  }
     useEffect(() => {
        if (sports.length > 0 && gamePricing.length> 0) {
          const fetchData = () => {
            const matchedData = sports.map(item => {
              const mappedName = nameMapping[item] || item;
              return gamePricing.find(data => data.name === mappedName);
            }).filter(Boolean); // Filters out any undefined results
            return matchedData
          };
         let result=  fetchData()
         console.log(result);
         var TotalAmount = 0;
         result.map((data, index) => {
            TotalAmount += data.price
         })
          setAmount(TotalAmount);
        }
      }, [sports, gamePricing]);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (amount == 0) {
            setDisable(true);
           } else {
            setDisable(false);
           }
        if (amount > 0 && token) {
          const callAPI = async () => {
            console.log("Amountpay" , amount);
            
            let res = await initiatePayment({ amount: amount });

            try {
                if (res.status) {
                    setClientSecret(res.data.data.client_secret);
                    setPaymentIntent(res.data.data.id);
                } else {
                    enqueueSnackbar('unable to initiate payment', { variant: 'error' });
    
                }
            } catch (error) {
                enqueueSnackbar('unable to initiate payment', { variant: 'error' });
                
            }
           
          };
          callAPI();
        } 
      }, [amount]);

    useEffect(() => {
      let CallAPI = async () => {
      setloaderStatus(true)
        let res = await getGamePricing();
      setloaderStatus(false)

        console.log(res);
        if (res.status) {
          setGamePricing(res.data);
        } else {

        }
      }
      if (sports.length> 0) {

        CallAPI()
      }
    },[sports])
    const handleClick = () => {
        setVisible(!visible);
    };

    const getPriceForSport = (sport) => {
      const mappedName = nameMapping[sport] || sport;
      const game = gamePricing.find(data => data.name === mappedName);
      return game ? game.price : null;
    };
  
    return (
        <>
       { loaderStatus ? 
                <Loader />
            : null}
            <FormContainer classname = 'col-md-10' >
            <PaymentModal waitListPlayer= {waiListPlayer}  visible={visible} onClose={() => setVisible(false)} clientSecret={clientSecret} paymentIntent={paymentIntent} sports={sports} waitList={waitList}/>
            <div class="card d-flex justify-content-center mt-5 mb-3" style={{ width:250 , borderRadius:10,margin:'auto' }}>
                <div
                    className="card text-center"
                    style={{
                    borderRadius: 15,
                    boxShadow: "0 4px 18px rgba(0,0,0,0.1)",
                    maxWidth: 300
                    }}
                >
                    <div
                    className="card-header  text-white w-100"
                    style={{ borderTopLeftRadius: 15,
                         borderTopRightRadius: 15, 
                         backgroundColor:'#0D3C6D',
                        paddingTop:40, 
                        paddingBottom:35,  
                       borderRadius: '10px 10px 45% 45%',
                       backgroundColor: 'rgb(13, 60, 109)'


                     }}
                    >
                    <h5>Games Plan</h5>
                    </div>
                    <div className="card-body">
                        <h2
                            className="text-danger font-weight-bold"
                            style={{ color: "##0D3C6D" }}
                        >
                            ${amount}
                        </h2>
                        {/* <ul className="list-unstyled">
                        {sports.includes('badminton') && (
                          <li className="d-flex justify-content-between">
                            <span>
                              <i className="text-success">✔</i> Badminton
                            </span>
                            <span>$80</span>
                          </li>
                        )}
                        {sports.includes('tennis') && (
                          <li className="d-flex justify-content-between">
                            <span>
                              <i className="text-success">✔</i> Table Tennis
                            </span>
                            <span>$80</span>
                          </li>
                        )}
                        {sports.includes('bowling') && (
                          <li className="d-flex justify-content-between">
                            <span>
                              <i className="text-success">✔</i> Bowling
                            </span>
                            <span>$80</span>
                          </li>
                        )}
                        {sports.includes('twok_run') && (
                          <li className="d-flex justify-content-between">
                            <span>
                              <i className="text-success">✔</i> 2k Run
                            </span>
                            <span>$80</span>
                          </li>
                        )}
                        </ul> */}

                        <ul className="list-unstyled">
                                {sports.includes('badminton') && (
                                  <li className="d-flex justify-content-between">
                                    <span>
                                      <i className="text-success">✔</i> Badminton
                                    </span>
                                    <span>${getPriceForSport('badminton')}</span>
                                  </li>
                                )}
                                {sports.includes('tennis') && (
                                  <li className="d-flex justify-content-between">
                                    <span>
                                      <i className="text-success">✔</i> Table Tennis
                                    </span>
                                    <span>${getPriceForSport('tennis')}</span>
                                  </li>
                                )}
                                {sports.includes('bowling') && (
                                  <li className="d-flex justify-content-between">
                                    <span>
                                      <i className="text-success">✔</i> Bowling
                                    </span>
                                    <span>${getPriceForSport('bowling')}</span>
                                  </li>
                                )}
                                {sports.includes('twok_run') && (
                                  <li className="d-flex justify-content-between">
                                    <span>
                                      <i className="text-success">✔</i> 2k Run
                                    </span>
                                    <span>${getPriceForSport('twok_run')}</span>
                                  </li>
                                )}
                        </ul>
                    </div>
                </div>
            </div>

                <center>
                    <button
                        className='btn btn-light w-80 border-0 px-3 py-3 fs-5 fw-bold rounded-3 submit-button text-white' disabled={disable} onClick={() => HandelSubmit()}> Submit
                    </button>
                </center>

            </FormContainer>
        </>
    )
}
export default Gameplan