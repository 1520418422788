import React,{useState} from "react";
import FormContainer from './FormContainer';
import {useNavigate} from"react-router-dom"
import { useSnackbar } from 'notistack';
import { ForgotPasswordAPI } from "../../API/auth";
function ForgotPassword() {
	let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setEmail] = useState({
        email:''
    });
  	const [error, setError] = useState('');
    const HandelChange = (event) => {
        setEmail({
            email: event.target.value
        });
        console.log(event.target);
    }
    const HandelSubmit = async(event) => {
		event.preventDefault() ;
        let res = await ForgotPasswordAPI(value);
        if(res.data.status) {
            // swal('Mail sent', 'verification mail has been sent to your email' , 'success')
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } else {
            setError(res.data.message);
        }
    }
    return (
        <>
        <FormContainer>
            <form className="mt-3 login-form" onSubmit={HandelSubmit}>
                <label className="form-title mb-2">  FORGET PASSWORD</label>
                <p className="form-heading"> Please enter your email address we will send you a link to reset your password. </p>
                <div data-mdb-input-init="" className="form-outline mb-4">
                    <label className='mb-3 input-label' htmlFor="email">Email address</label>
                    <input
                    type="email"
                    id="form2Example18"
                    placeholder="Email"
                    name="email"
                    className="form-control bg-transparent form-input"
                    onChange={HandelChange}
                    value={value.email}
                    />
                    {error && <span className='text-danger'>{error}</span>}

                </div>
                <div className="pt-1 mb-4">
                    <button
                    className="btn btn-light w-100 border-0 px-3 py-3 fs-5 text-white submit-button" type="submit" >  Continue </button>
                </div>
            </form>
        </FormContainer>
        </>
    )
}
export default ForgotPassword;