import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  CCloseButton,
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarToggler,
} from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import  logo  from '../assets/logo.png'
// sidebar nav config
import Admin_nav from '../admin_nav'
import Participant_nav from '../participant_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const narrow = useSelector((state) => state.narrow)
  const user_data = useSelector((state) => state.user);
	const user_role = localStorage.getItem('role');
  const [role, setRole] = useState('');
  

	useEffect(() => {
		if (user_data != null && user_data.role != undefined || user_role != null) {
			setRole(user_data != undefined && user_data.role != undefined ? user_data.role : user_role);
    }
    console.log(narrow);
    }, [user_data, narrow]);
  return (
    <CSidebar
      className="border-end"
      colorScheme="dark"
      position="fixed"
      narrow={narrow}
      size='md'
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarHeader className="border-bottom justify-content-center">
        <CSidebarBrand to="/" className=''>
          <img
            src={logo}
            className="img-fluid rounded-top"
            alt="logo"
            height={80}
            width={80}
          />
          </CSidebarBrand>
        <CCloseButton
          className="d-lg-none"
          dark
          onClick={() => dispatch({ type: 'set', sidebarShow: false })}
        />
      </CSidebarHeader>
      <AppSidebarNav items={Admin_nav} />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
