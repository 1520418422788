import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { uploadPdf } from '../../../API/admin';
import Loader from '../Common/Loader';
import {
    CCard,
    CCardBody
} from '@coreui/react';

const CreateTournament = () => {
    const [Document_name, setDocument_name] = useState('');
    const [Document_type, setDocument_type] = useState('');
    const [file, setFile] = useState(null);
    const [loaderStatus, setLoaderStatus] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('Document_name', Document_name);
        formData.append('Document_type', Document_type);
        formData.append('file', file);

        // Debug: log FormData content
        console.log('Document_name:', Document_name);
        console.log('Document_type:', Document_type);
        console.log('file:', file);

        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        setLoaderStatus(true);

        try {
            let res = await uploadPdf(formData);
            if (res.data.status) {
                enqueueSnackbar('Successfully Uploaded Pdf', { variant: 'success' });
                window.history.back(); setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else {
                enqueueSnackbar('PDF is not uploaded', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('An error occurred during upload', { variant: 'error' });
        } finally {
            setLoaderStatus(false);
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log("yes", file.type);
        if (file) {
            if (file.type === 'application/pdf') {
                setFile(file)
            } else {
                enqueueSnackbar('Please select pdf only!', { variant: 'error' });
                event.target.value = ''; // Clear the input
            }
        }
    };

    return (
        <>
            {loaderStatus && <Loader />}
            <h4 className="card-title mb-3 align-items-center">
                Create a Document
            </h4>

            <CCard className="mb-4">
                <CCardBody>
                    <div className="row justify-content-center">
                        <div className="col-md-7">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Document Name</label>
                                    <input
                                        type="text"
                                        name="document_name"
                                        className="form-control"
                                        placeholder="Enter Document Name"
                                        pattern=".*\S.*"
                                        onChange={(e) => setDocument_name(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Document Type</label>
                                    <select className='form-select' onChange={(e) => setDocument_type(e.target.value)}>
                                    <option value="privacy">Privacy & Policy</option>
                                    <option value="t&c">T&C</option>
                                    <option value="waviear">Waviear Document</option>

                                </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Upload</label>
                                    <input
                                        type="file"
                                        name="pdf_file"
                                        className="form-control"
                                        accept="application/pdf"
                                        onChange={handleFileChange}
                                        required
                                    />
                                </div>
                                <button type="button" className="btn btn-secondary ms-3 float-end" onClick={() => {
                                    window.history.back(); setTimeout(() => {
                                        window.location.reload();
                                    }, 100);
                                }}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary float-end" onClick={() => {
                                  
                                }} >
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </>
    );
};

export default CreateTournament;
