import React, { useEffect, useRef, useState } from 'react'
import FormContainer from './FormContainer';
import { useSnackbar } from 'notistack';
import {otpVerification, Authenticated , RegisterUser} from '../../API/auth';
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router-dom';


function OtpVerfication() {
    const location = useLocation();
    // const { enqueueSnackbar } = useSnackbar();
    console.log(location.state  ,"state");
    let [FormData,setFormData] = useState(location.state);
    const { enqueueSnackbar } = useSnackbar();
    console.log(FormData , "formdata");
    // console.log(state , "state");
    const [seconds, setSeconds] = useState(30);
   function sendotp(){
    // console.log("yes");
    let callAPI = async () => {
        let res = await RegisterUser(FormData)
        if(res.data.status) {
            localStorage.setItem('verifyToken', res.data.token);
            enqueueSnackbar("otp generated succesfully", { variant: 'success' });
           
        }
        else {
            enqueueSnackbar("something error happened", { variant: 'error' });
        }
    }
    callAPI();
    setSeconds(30);
   }
  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    // Clean up the interval on unmount or when seconds reach 0
    return () => clearInterval(timer);
  }, [seconds]);
    const navigate = useNavigate();
	
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            let callAPI = async () => {
                const verifyToken = {
                    token:token,
                }
                let res =  await Authenticated(verifyToken)
                if(res.data.status) {
                    window.location.href = '/dashboard';
                }
            }
            callAPI()
        } 
        
    }, [navigate]);
    const [otp, setOtp] = useState(['', '', '', '']); // Array to hold OTP digits
    const refs = [useRef(), useRef(), useRef(), useRef()]; // Array of refs for input fields
  
    const HandleChange = (index, event) => {
      const value = event.target.value;
      if (/^[0-9]*$/.test(value) && value.length <= 1) {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = value;
          return newOtp;
        });
  
        if (index < 3 && value !== '') {
          refs[index + 1].current.focus();
        }
      }
      if (value === '' && index > 0) {
        refs[index - 1].current.focus();
      }
    };
    
    const HandelSubmit = async(event) => {
    	event.preventDefault() ;
        var verifyToken = localStorage.getItem('verifyToken');
        console.log(verifyToken);
        const combinedOtp = {
            otp : otp.join(''),
            verifyToken : verifyToken
        }
        let res = await otpVerification(combinedOtp);
        if (res.data.token) {
            localStorage.setItem('token', res.data.token);
			localStorage.setItem('role', res.data.data.role);
            localStorage.removeItem('verifyToken');               
            window.location.href = '/select-sport';

        } else {
            enqueueSnackbar('Invalid OTP', { variant: 'error' });
        }
    }
    return(
        <>
        <FormContainer>
            <form className="mt-3" onSubmit={HandelSubmit}>
                <label className="form-title mb-2" > VERIFICATION </label>
                <p className='form-heading'> Please enter your verification OTP .</p>
                <p className="text-center verification-code mt-5">Enter Code</p>
                <div className="row pt-2 pb-2 w-75 mb-3 m-auto">
                    <div className="col-3 text-center">
                        <input 
                        className="otp-letter-input" 
                        type="text"
                        value={otp[0]}
                        onChange={(event) => HandleChange(0, event)}
                        ref={refs[0]}
                        />
                    </div>
                    <div className="col-3 text-center">
                        <input 
                        className="otp-letter-input"
                        type="text"
                        value={otp[1]}
                        onChange={(event) => HandleChange(1, event)}
                        ref={refs[1]}
                            />
                    </div>
                    <div className="col-3 text-center">
                        <input 
                        className="otp-letter-input"
                        type="text"
                        value={otp[2]}
                        onChange={(event) => HandleChange(2, event)}
                        ref={refs[2]}
                            />
                    </div>
                    <div className="col-3 text-center">
                        <input 
                        className="otp-letter-input"
                        type="text"
                        value={otp[3]}
                        onChange={(event) => HandleChange(3, event)}
                        ref={refs[3]}
                            />
                    </div>
                </div>

               {
                    seconds == 0 ? ( <p className='form-heading' style={{ textAlign: 'right' , color:'red' }}>  <a href='#' onClick={sendotp} style={{ textAlign: 'right' , color:'red' }}>Resend OTP?</a></p>):( <p className='form-heading' style={{ textAlign: 'right' , color:'red' }}> Resend otp in {seconds}</p>)
                }
                {/* <p className='form-heading' style={{ textAlign: 'right' , color:'red' }}> Resend otp in {seconds}</p> */}

                {/* <button className='btn  text-white submit-button' type='submit' > Continue </button> */}
                <div className="pt-1 mb-4">
                    <button className='btn btn-light w-100 border-0 px-3 py-3 fs-5 text-white submit-button' type='submit' > Continue </button>
                </div>
            </form>
          
        </FormContainer>
       
        </>
    )
}
export default OtpVerfication