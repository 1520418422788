import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {GetUsers, DeleteUser} from  '../../../../API/admin'
import Swal from 'sweetalert2'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'

const Users = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Users', to: '/users', active: true }
    ];

	const { enqueueSnackbar } = useSnackbar();
    const Navigate = useNavigate();
    const [Users, setUsers] = useState([]);
    const [alluser , setalluser] = useState([]);
    useEffect( () => {
        setSelectedOption('admin');
        getUserAPI('admin')
    }, [])
    
    let  getUserAPI = async(type) =>{
        let res =  await GetUsers(null,type)
        if(res.status) {
            setUsers(res.data);
            setalluser(res.data);
        }  else {
            setUsers([]);
            setalluser([]);
        } 
    }

    const HandelChange = (event) => {
        // event.preventDefault;
        // console.log(event.target.value);
        let search = event.target.value;
        // console.log("yes");
        // let usersarr = Users;
        const subArr = alluser.filter(user => (user.fname.includes(search) || (user.corporate_email!=null && user.corporate_email.includes(search)) || user.email.includes(search)) );
    //   console.log("sub",subArr);
      setUsers(subArr);
        // callAPI(search);
        
    }

    const HandelDelete =async(urlid) => {
      let res = await Swal.fire({
            title: "Are you sure?",
            text: "Are you sure , you want to delete this user?",
            icon: "warning" });
            if (res.isConfirmed) {
                let callAPI = async ()=> {
                    let userID = {
                        id : urlid
                    }
                    console.log(userID);
        
                    let res = await DeleteUser(userID);  
                    if (res.status) {
                        enqueueSnackbar("User Deleted successfully", { variant: 'success' });
                        getUserAPI()
                    } else {
                        enqueueSnackbar("Can not delete user", { variant: 'error' });
                        Navigate('/users')
                    }
                }
                callAPI();
            }
    }

    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        getUserAPI(e.target.value);
    };

    return (
        <>
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            <CCard className="mb-4">
            <CCardBody>
            <CRow>
                <CCol sm={4}>
                <h3 className="card-title mb-3">
                        Users
                </h3>
                </CCol>
             </CRow>
             <CRow>   
                <CCol sm={3}> 
                <input type="search" className='form-control' placeholder="Search" name='data' onChange={HandelChange}/>
                </CCol>
                {/* Select box */}
                <CCol sm={3}>
                    <select className='form-select' value={selectedOption} onChange={handleSelectChange}>
                        <option value="admin">Admin</option>
                        <option value="scorer">Scorer</option>
                        <option value="participant">Participant</option>
                    </select>
                </CCol>
                <CCol sm={6} className="d-none d-md-block" >
                    <Link to='/user/create'>     
                        <CButton className='float-end text-white' color="info" >
                        <i className="bi bi-plus-circle me-2"></i>
                            Add user</CButton>
                    </Link>
                </CCol>
            </CRow><br />
            {/* <MainChart /> */}
            <CRow
                xs={{ cols: 1, gutter: 4 }}
                sm={{ cols: 2 }}
                lg={{ cols: 4 }}
                xl={{ cols: 5 }}
                className="mb-2 text-center"
            >
            <CTable striped className=''>
                <CTableHead>
                <CTableRow>
                    <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Personal Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Corporate Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Role</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Action</CTableHeaderCell>

                </CTableRow>
                </CTableHead>
                <CTableBody>
                    {Users.length === 0 ? (
                        <tr>
                            <td colSpan="6">No data found</td>
                        </tr>
                    ) : (
                        Users.map((user, index) => (
                            <CTableRow>
                                <CTableHeaderCell key={index}>{index + 1}</CTableHeaderCell>
                                <CTableDataCell key={index}>{user.fname}{' ' + user.lname}</CTableDataCell>
                                <CTableDataCell key={index}>{user.email ? user.email : 'N/A'} </CTableDataCell>
                                <CTableDataCell key={index}>{user.corporate_email ? user.corporate_email : 'N/A'} </CTableDataCell>
                                <CTableDataCell key={index}>{user.role}</CTableDataCell>
                                <CTableDataCell key={index}>
                                    <div className='d-flex justify-content-around'>
                                        <Link to={`/user/detail?id=${user._id}`}>
                                            <CButton className='bi bi-eye-fill text-white' color="primary"></CButton>
                                        </Link>
                                        <Link to={`/user/edit?id=${user._id}`}>
                                            <CButton className="bi bi-pencil text-white" color="warning"></CButton>
                                        </Link>
                                        <CButton className='bi bi-trash-fill text-white' color="danger" onClick={() => HandelDelete(user._id)}></CButton>
                                    </div>
                                </CTableDataCell>

                            </CTableRow>
                        ))
                    )}
                </CTableBody>
            </CTable>
            </CRow>
            </CCardBody>
            </CCard>
        </>
    )
}
export default Users;