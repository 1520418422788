import React, { useEffect, useRef, useState } from 'react'
import { NavLink ,Link, useLocation} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  useColorModes,
  CInputGroup,
  CInputGroupText,
  CFormInput,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilContrast,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilMoon,
  cilSun,
} from '@coreui/icons'

import { Row, Col} from 'react-bootstrap';

import logo from '../assets/logo.png';
import Batminton from '../components/icons/Batminton';
import Bowling from '../components/icons/Bowling';
import TableTennis from '../components/icons/TableTennisIcon';
import RunIcon from '../components/icons/RunIcon';
import { ParticipantAppHeaderDropdown } from './header/index'
import '../assets/css/Player/ParticipantAppHeader.css';
import { Dropdown } from 'react-bootstrap';
import Notification from '../assets/notification.svg';
import Notification_danger from '../assets/notification-danger.svg';
import new_notication from '../assets/new-notification.svg'
import avl_notification from '../assets/notification_avai.svg'
import './participant.css';
import {EnrolledTournamentAPI} from '../API/player'
import {useNavigate} from"react-router-dom";
// width: 30px;
//     cursor: pointer;
//     padding: 5px;
//     background-color: rgb(255, 0, 0, 0.1);
// }


// width: 450px;
//     padding: 0px;
//     position: absolute;
//     right: 0px;
//     inset: 0px 0px auto auto;
//     transform: translate(5.799em, 4em);
// }
const AppHeader = () => {
  let navigate = useNavigate();
  const headerRef = useRef()
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [activeMenu ,setActiveMenu] = useState("Badminton"); 
  let [enrolled , setenrolled] = useState(true);
  useEffect(()=>{
    let callAPI = async() => {
      console.log("yes");
   
      let res = await EnrolledTournamentAPI({});
      if (res.status) {
          // setGroupStandings(res.data.data);
          console.log("setting localstorageee");
          localStorage.setItem('isenrolled', res.data.enrolled);
          setenrolled(res.data.enrolled);
          console.log(res);
      }
  };
  callAPI();
  } , []) 
  console.log(enrolled);
  function  handleclick() {
    navigate('/select-sport');
  }
  const NotificationsDropdown = () => {

    return (

      <Dropdown className="d-sm-flex d-none notifications">

        <Dropdown.Toggle as="a" className="nav-link">

        <img src={enrolled == false ? avl_notification: Notification} alt="notification" style={{ width: '25px', cursor: 'pointer' }} />

          <span className="nav-unread bg-warning-1 pulse"></span>

        </Dropdown.Toggle>

        <Dropdown.Menu align="end" className="dropdown-menu-arrow dropdown-menu" style={{width:"450px" , padding:"0px"}}>

        <div className="notification-header dropdown-header" >
        <h5 style={{color: '#121212' ,paddingLeft:"20px" , paddingTop:"6px"}}>Notification</h5>
      </div>
      <Dropdown.Divider style={{margin:"0px" , padding:"10px"}}/>
      
              {enrolled == false ?(
                 <Dropdown.Item href="#" className="mt-2 d-flex pb-3" style={{cursor: "default" }}>
                 <div className="outer-container">
                  <div className="dropdowns-item" >
                 <p style={{fontWeight:"500"}}> <span style={{color:"#000000"}}><img src={new_notication} style={{width : "15px" , height:"15px" , marginBottom:"4px"}}></img> </span>  <span style={{color:"#EF2A2A"}}>New Tournament</span> <span>is live</span> </p>
                 </div>
                <div className="applybutton">
                 <button onClick={handleclick}>Apply Now</button>
                </div>
                </div>
       
                 </Dropdown.Item>
              ):(<div>
                <div className="image-container" style={{display: 'flex', alignItems: 'center', justifyContent:"center" ,paddingTop:"20px"}}>
                        <img src={Notification_danger} alt="notification" style={{ width: '25px', cursor: 'pointer', backgroundColor:"black"}} />
          
                        </div>
                        <br></br>
                        <div>
                          <h4 style={{color:"#000000",display: 'flex', alignItems: 'center', justifyContent:"center" , font:"Roboto" ,fontWeight:"500",fontSize:"16px" }}>No notifications to show yet</h4>
                        </div>
                        <div>
                          <p style={{color:"#434343", display: 'flex', alignItems: 'center', justifyContent:"center" , fontSize:"12px"}}>You'll see useful information here soon. Stay tuned!</p>
                        </div>
                        </div>)}
          
          
          
      

          

        </Dropdown.Menu>

      </Dropdown>

    );

  };


  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })
  }, [])
  useEffect(() => {
    
    const searchParams = new URLSearchParams(location.search);
    const game = searchParams.get('game');
    console.log("Game" + game);
    if (game === 'Badminton') {
      setActiveMenu("Badminton");  
    } else if (game === 'TableTennis') { 
      setActiveMenu("TableTennis");
    }
    else if (game === 'bowling') { 
      setActiveMenu("bowling");
    }
    else if (game === '2k_run') { 
      setActiveMenu("2k_run");
    }

  }, [location.search])

  return (
    <div className='p-header pb-3'>
    <CHeader position="sticky" className="mb-2 p-0 bg-transparent text-white border-0" ref={headerRef}>
      <CContainer className="border-0 px-4" fluid>
        <CNavItem className='list-style-none'>
            <CNavLink to="/dashboard" className='fs-5 fw-bold text-white my-3'>
             <img src={logo} alt="logo" className='header-logo'/>
            </CNavLink>
          </CNavItem>

        <CHeaderNav className="d-none d-md-flex">
          <CNavItem>
            <CNavLink to="/dashboard" as={NavLink} className='fs-5 fw-bold text-white'>
              Corporate Premier Games
            </CNavLink>
          </CNavItem>
        </CHeaderNav>

        <CHeaderNav>
        {/* <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li>
          <CDropdown variant="nav-item" placement="bottom-end">
            <CDropdownToggle caret={false}>
              {colorMode === 'dark' ? (
                <CIcon icon={cilMoon} size="lg" />
              ) : colorMode === 'auto' ? (
                <CIcon icon={cilContrast} size="lg" />
              ) : (
                <CIcon className='bg-light' icon={cilSun} size="lg" />
              )}
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem
                active={colorMode === 'light'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('light')}
              >
                <CIcon className="me-2" icon={cilSun} size="lg" /> Light
              </CDropdownItem>
              <CDropdownItem
                active={colorMode === 'dark'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('dark')}
              >
                <CIcon className="me-2" icon={cilMoon} size="lg" /> Dark
              </CDropdownItem>
              <CDropdownItem
                active={colorMode === 'auto'}
                className="d-flex align-items-center"
                as="button"
                type="button"
                onClick={() => setColorMode('auto')}
              >
                <CIcon className="me-2" icon={cilContrast} size="lg" /> Auto
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
          <li className="nav-item py-1">
            <div className="vr h-100 mx-2 text-body text-opacity-75"></div>
          </li> */}
           <NotificationsDropdown />
          <ParticipantAppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>

      <div className="text-center game_bar">
      
      
        <ul className="list-unstyled d-flex justify-content-between mx-auto mb-2">
        {/* <Link
           to="/dashboard?game=Badminton" style={{ textDecoration: 'none' }}>
            <li className={activeMenu ==='Badminton'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><Batminton /> Badminton</li>
        </Link>
        <Link 
            to="/dashboard?game=TableTennis" style={{ textDecoration: 'none' }}>
            <li className={activeMenu ==='TableTennis'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><TableTennis /> Table Tennis</li>
        </Link> */}
        <Link to="/dashboard?game=Badminton" style={{ textDecoration: 'none' }}>
              <li className={activeMenu ==='Badminton'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><Batminton /> Badminton</li>
        </Link>
        <Link to="/dashboard?game=TableTennis" style={{ textDecoration: 'none' }}>
          <li className={activeMenu ==='TableTennis'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><TableTennis /> Table Tennis</li>
        </Link>
        <Link to="/dashboard?game=bowling" style={{ textDecoration: 'none' }}>
          <li className={activeMenu ==='bowling'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><Bowling /> Bowling</li>
        </Link>
        <Link to="/dashboard?game=2k_run" style={{ textDecoration: 'none' }}>
          <li className={activeMenu ==='2k_run'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><RunIcon /> 2k Run</li>
        </Link>
          {/* <li className="text-white text-center px-5 active"><Batminton/> Badminton</li> */}
          {/* <li className="text-white text-center px-5"><TableTennis/>Table Tennis</li> */}
          {/* <li className="text-white text-center px-5"><Bowling/> Bowling</li> */}
          {/* <li className="text-white text-center px-5"><RunIcon/> 5K Run</li> */}
        </ul>
      </div>

    </div>
  )
}

export default AppHeader
