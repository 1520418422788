import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb'
import { FormatDate , newFormatdate } from '../../../../Common/CommonFunction'
import { Link ,useNavigate} from 'react-router-dom'

import {
  GetTournamentList,
  GetGroupList,
  GetParticipantList,
  GetScorers,
  AssignScorer,
  SwapUser,
  GetLogs,
  GetMatch,
  IsMatchDone,
  SetMatchResult,
  KnockoutStarted
} from '../../../../API/admin'
import Loader from '../../Common/Loader'

import logo from '../../../../assets/images/avatars/demo.jpg'
import p1 from '../../../../assets/images/avatars/2.jpg'
import p2 from '../../../../assets/images/avatars/3.jpg'
import p3 from '../../../../assets/images/avatars/6.jpg'
import p4 from '../../../../assets/images/avatars/1.jpg'
import p5 from '../../../../assets/images/avatars/4.jpg'
import p6 from '../../../../assets/images/avatars/5.jpg'
import trophy from '../../../../../src/assets/trophy1.png'
import add from '../../../../assets/images/add.png'
import less from '../../../../assets/images/less.png'




import Swapmodel from './swapmodal'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CCardFooter,
  CCardHeader,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput     
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import { cilSave, cilX, cilGraph, cilPencil } from '@coreui/icons'
import { useSnackbar } from 'notistack'

const TournamentGroupsMatches = () => {
  // Manage AdminBreadcrumb
  const url = window.location.href;
  const queryString = url.split('?')[1];

// Split the query string by '=' and take the second part after '='
        const sportValue = queryString.split('=')[1];
        console.log(sportValue , "sportvalue");

  const adminBreadcrumbLinks = [
    { label: 'Dashboard', to: '/admin/dashboard', active: false },
    { label: 'Matches', to: '/matches', active: true }
  ]
  const [link , setlink] = useState('/matches?tournament=665ec20fb17c4bbb52d0abe2&&group=G1&&sport=badminton&&gender=male');
  const urlParams = new URLSearchParams(window.location.search)
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE

  const [loaderStatus, setloaderStatus] = useState(true)
  const [scorers, setScorers] = useState(null)
  const [visible, setVisible] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null);


  const [tournamentList, setTournamentList] = useState([])

  const [selectedTournament, setSelectedTournament] = useState('')
  const [groupList, setGroupList] = useState([])

  const [players, setPlayers] = useState([])
  const [participant, setParticipant] = useState([])
  const [selectedParticipant, setSelectedParticipant] = useState([])
  const [playersList, setPlayersList] = useState([])
  const [matchList, setMatchList] = useState([])

  const [selectedGender, setSelectedGender] = useState('male')
  const [selectedGroup, setSelectedGroup] = useState('G1')
  const [matches, setMatches] = useState([])
  const [editMode, setEditMode] = useState(null)
  const [dateTime, setDateTime] = useState([])
  const [newDateTime, setNewDateTime] = useState('')
  const players_badminton = [1, 2, 3, 4]
  const players_bowling = [1, 2, 3, 4, 5, 6]
  const [selectedSport, setSelectedSport] = useState(sportValue)
  const [Logs, setLogs] = useState([])
  const [flag , setflag] = useState(false);
  const [MatchVisible , setMatchVisible] = useState({
    status : false,
    player_1_score: '',
    player_2_score: '',
    match_id: ''
  });
  const [ScorEdit , setScoreEdit] = useState(null);
  const [updateScores , setUpdteScores] = useState({
    player_1_score: '',
    player_2_score: ''
  });
  const [state , setState] = useState(false);
  const [canEdit , setCanEdit] = useState(false);






  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    event.preventDefault();
    const selectedOption = event.target.options[event.target.selectedIndex];
    setnewlink(selectedTournament, selectedGroup , selectedSport,  event.target.value);
    setSelectedGender(event.target.value);
};

  const handleSelectChangeParticipant = index => event => {
    const newSelectedParticipants = [...selectedParticipant]
    newSelectedParticipants[index] = event.target.value
    setSelectedParticipant(newSelectedParticipants)
  }

  const handleSelectChangeGroup = event => {
    const selectedOption = event.target.options[event.target.selectedIndex]
    setMatchList(JSON.parse(selectedOption.getAttribute('data-match')))
    setPlayers(JSON.parse(selectedOption.getAttribute('data-players')))
    setnewlink(selectedTournament, event.target.value , selectedSport, selectedGender  );
    setSelectedGroup(event.target.value)
  }

  function setnewlink(tournament , group , sport , gender){
    navigate(`/matches?tournament=${tournament}&&group=${group }&&sport=${sport}&&gender=${gender}`);      
}
  const handleSelectSport = event => {
    setSelectedSport(event.target.value)
  }

  const handleEdit = index => {
    setEditMode(index)
    setNewDateTime(dateTime) // Preserve the original value for editing
  }

  // const getCurrentDate = event => {
  //   console.log(event)
  //   const today = new Date()
  //   const dd = String(today.getDate()).padStart(2, '0')
  //   const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  //   const yyyy = today.getFullYear()
  //   event.target.min = `${yyyy}-${mm}-${dd}`
  // }

  let GetParticipantListApi = async () => {
    let res = await GetParticipantList()
    let userData = []
    if (res.status) {
      setParticipant(res.data)
      res.data.forEach((item, index) => {
        userData[item._id.toString()] = item
      })
      setPlayersList(userData)
    }
  }

  let tournamentListApi = async () => {
    let res = await GetTournamentList()
    let tornament_id = urlParams.get('tournament')
    if (res.status) {
      if (res.data.length > 0) {
        if (tornament_id !== null && tornament_id !== undefined) {
          setSelectedTournament(tornament_id)
        } else {
          setSelectedTournament(res.data[0]._id.toString())
        }
      }
      setTournamentList(res.data)
    }
  }

  let getGroupData = async () => {
    if (selectedTournament !== null && selectedTournament !== undefined) {
      let res = await GetGroupList({
        tournament: selectedTournament,
        game_type: selectedSport,
        gender: selectedGender
      })

      if (res.status) {
        setGroupList(res.data)
        setloaderStatus(false)
      }
        let res2 = await IsMatchDone({
          tournament_id : selectedTournament,
          game_type : selectedSport
      }) 
      if(res2.status){
        setflag(res2.data.data);
      }
    }
  }

  const handleSelectedTournament = event => {
    setnewlink(event.target.value , selectedGroup , selectedSport , selectedGender); 
    setSelectedTournament(event.target.value)
  }

  const handleSave = async event => {
    event.preventDefault()
    setloaderStatus(true)
    let formData = {
      matchTime: event.target.match_time.value,
      matchScorer: event.target.match_scorer.value,
      matchID: event.target.match_time.id
    }
    const dateObj = new Date(event.target.match_time.value)
    const formattedDate = dateObj.toISOString().slice(0, 10)
    const formattedTime = dateObj.toTimeString().slice(0, 5)
    setEditMode(false)
    setDateTime([formattedDate + ' ' + formattedTime])
    console.log(formData)
    let res = await AssignScorer(formData)
    if (res) {
      await getGroupData()
      setloaderStatus(false)
      enqueueSnackbar('Scorer assigned successfully', { variant: 'success' })
    } else {
      enqueueSnackbar('Unable to assign scorer', { variant: 'error' })
    }
  }

  const handleChange = event => {
    setNewDateTime(event.target.value)
  }

  const handleLogs = async (event, matchData) => {

    let res = await GetLogs(matchData._id)
    console.log(res)
    if (res.status) {
      enqueueSnackbar('Logs fetched', { variant: 'success' })
      setLogs(res.data)
      setVisible(true)
      setSelectedMatch(matchData);
    } else {
      enqueueSnackbar('No logs for this match', { variant: 'error' })
    }
  }
  
  const handleMatchScoreEdit = async(event, index) => {
    console.log("match edit callled")
    setScoreEdit(index)
    setUpdteScores({
      player_1_score : matchList[index].player_1_score,
      player_2_score : matchList[index].player_2_score,
    })
    // let res = await GetMatch(matchData._id)
    // console.log(res)
    // if (res.status) {
    //   enqueueSnackbar('Match fetched', { variant: 'success' })
    //   // setLogs(res.data)
    //   setEdit(true)
    //   // setMatchVisible(true)
    //   setSelectedMatch(matchData);
    // } else {
    //   enqueueSnackbar('No Match Found', { variant: 'error' })
    // }
  }
  useEffect(() => {
    const callApi = async () => {
      await tournamentListApi()
    }
    let GetParticipantListApi = async () => {
      let res = await GetParticipantList();
      let userData = [];
      if (res.status) {
          setParticipant(res.data);
          res.data.forEach((item, index) => {
              userData[item._id.toString()] = item;
          });
          setPlayersList(userData);
      }
  }
    callApi()
    GetParticipantListApi();

    setloaderStatus(false)
  }, [link])


  
  //get scorers
  useEffect(() => {
    const callApi = async () => {
      let res = await GetScorers()
      if (res.status) {
        setScorers(res.data)
      }
    }

   
    callApi()
  }, [])
  useEffect(() => {
    if (groupList.data != undefined && groupList.data.length > 0) {
      let selectedOption = groupList.data.find(
        item => item.name === selectedGroup
      )
      if (!selectedOption) {
        selectedOption = groupList.data.find(
          item => item._id.toString() === selectedGroup
        )
      }
      if (selectedOption) {
        setMatchList(selectedOption.match)
        setPlayers(selectedOption.players)
      }
    }

    if (
      selectedTournament !== '' &&
      selectedSport !== '' &&
      selectedGender !== ''
    ) {
      setloaderStatus(true)

      const callGroupApi = async () => {
        await getGroupData()
      }
      callGroupApi()
    }
  }, [selectedGroup])

  useEffect(() => {
    // console.log("yaeee");
    if (groupList.data!=undefined && groupList.data.length > 0) {
        let selectedOption = groupList.data.find(item =>( item.name === "G1"));
        if (!selectedOption) { 
            selectedOption = groupList.data.find(item => item._id.toString() === selectedGroup);
        }
        if (selectedOption) {
            setMatchList(selectedOption.match);
            setPlayers(selectedOption.players);
        }
    }

    if (selectedTournament !== "" && selectedSport !== "" && selectedGender !== "") { 
        setloaderStatus(true);
        const callAPI2 = async () => {
          if (selectedTournament !== null && selectedTournament !== undefined) { 
            let formData = {
              tournament_id: selectedTournament,
              game_type: sportValue
            }
            let res = await KnockoutStarted(formData)
            console.log(res.data.flag);
            setCanEdit(res.data.flag)
          }
        }
        const callGroupApi = async () => {
            await getGroupData();
        };
        callGroupApi();
        callAPI2()
    }
}, [selectedTournament, selectedSport, selectedGender]);

  useEffect(() => {
    const callApi = async () => {
      if (groupList.data != undefined && groupList.data.length > 0) {
        let selectedOption = groupList.data.find(
          item => item.name === selectedGroup
        )
        if (!selectedOption) {
          selectedOption = groupList.data.find(
            item => item._id.toString() === selectedGroup
          )
        }
        if (selectedOption) {
          setMatchList(selectedOption.match)
          setPlayers(selectedOption.players)
        }
        // await Get ParticipantListApi()
      }
    }

    callApi()
  }, [groupList])

  const imagePaths = {
    male: { p1, p2, p3, p4: logo, p5: p2, p6: p3 },
    female: { p1: p4, p2: p5, p3: p6, p4: logo, p5: p5, p6: p4 }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('group') !== null) {
      setSelectedGroup(urlParams.get('group'))
    }
    if (urlParams.get('sport') !== null) {
      setSelectedSport(urlParams.get('sport'))
    }
    if (urlParams.get('gender') !== null) {
      setSelectedGender(urlParams.get('gender'))
    }
  }, [])
  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  
  console.log("udpate" ,updateScores) 
  let handleUpdateRecord = (getData) => {
    console.log(getData) 
    let updatedata = updateScores;

    if (getData.player == 'player_1_score' ) {
      if(getData.type == 'increase')
          updatedata.player_1_score++;
    // else 
    // { if(updatedata.player_1_score!=0) updatedata.player_1_score-- 
    //  else updatedata.player_1_score;}

    }
    else {
      if(getData.type == 'increase')
        updatedata.player_2_score++;
      //  else
      //  {  if(updatedata.player_2_score!=0) updatedata.player_2_score-- 
      //    else updatedata.player_2_score;}
    }
    setUpdteScores(updatedata);
    setState(!state)

  }

  let SubmitupdatedScore = async() => {
    let formData = {
      match_id : MatchVisible.match_id,
      player_1_score: MatchVisible.player_1_score,
      player_2_score: MatchVisible.player_2_score,
      record_type: 'update'
    }
    setloaderStatus(true);
    let res = await SetMatchResult(formData);

    if (res.status) {
      setloaderStatus(false);
      enqueueSnackbar('Scores Edited successfully', { variant: 'success' })
      window.location.reload()
    } else {
      setloaderStatus(false);
      enqueueSnackbar('unable to Edit scores', { variant: 'error' })
      window.location.reload()

    }
  }
  return (
    <>
      {loaderStatus ? <Loader /> : null}
      <AdminBreadcrumb links={adminBreadcrumbLinks} />
      {/* dropdown */}
      <CRow>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedTournament}
            onChange={handleSelectedTournament}
          >
            {tournamentList.length
              ? tournamentList.map((tournament, index) => (
                  <option value={tournament._id.toString()}>
                    {tournament.name}
                  </option>
                ))
              : ''}
          </select>
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedSport}
            onChange={handleSelectSport}
          >
            <option value='badminton' selected>
              Badminton
            </option>
            <option value='table_tenis' selected>
              Table Tenis
            </option>
            {/* <option value="bowling">Bowling</option>
                        <option value="2k_run">2k run</option> */}
          </select>
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedGender}
            onChange={handleSelectChange}
          >
            <option value='male' selected>
              Male
            </option>
            <option value='female'>Female</option>
          </select>
        </CCol>
        {selectedSport !== '2k_run' ? (
          <CCol sm={3}>
            <select
              className='form-select'
              value={selectedGroup}
              onChange={handleSelectChangeGroup}
            >
              {groupList &&
                groupList['data'] &&
                groupList['data'].map(item => (
                  <option
                    key={item._id.toString()}
                    data-match={JSON.stringify(item.match)}
                    data-players={JSON.stringify(item.players)}
                    value={item._id.toString()}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          </CCol>
        ) : null}
      </CRow>
      <br />
      {/* player cards */}
      <CRow>
        {/* rendering all four player card */}
        {players.map((item, index) =>
          item === '' ? (
            <CCol sm='12' md='6' lg='3' key={index}>
              <CCard className='player-card'>
                <CCardBody className='text-center '>
                  <div
                    className='bg-image-team'
                    style={{
                      backgroundImage: `url(${logo})`
                    }}
                  ></div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </CCardBody>
                <CCardFooter className='text-center'>
                  <CRow>
                    <CCol className='py-2'>Yet to join</CCol>
                    {/* <CCol sm="9">
                                            <select
                                                className='form-select'
                                                value={selectedParticipant[index]}
                                                onChange={handleSelectChangeParticipant(index)}>
                                                {participant && participant.map(item => (
                                                    <option key={item._id.toString()}  value={item._id.toString()}>{item.fname} {item.lname}</option>
                                                ))}
                                            </select>
                                        </CCol>
                                        <CCol sm="3">
                                            <CButton title='View'  color="primary"><CIcon icon={cilSave} /></CButton>
                                        </CCol> */}
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          ) : (
            <CCol sm='12' md='6' lg='3' key={index}>
              <CCard className='player-card '>
                <CCardBody className='text-center'>
                  {playersList[item] !== undefined ? (
                    <>
                      <div
                        className='bg-image-team'
                        style={{
                          backgroundImage: `url(${
                            playersList[item].profile_image !== undefined
                              ? IMAGE_URL +
                                'user/' +
                                playersList[item].profile_image
                              : logo
                          })`
                        }}
                      ></div>
                      {/* <CCardImage
                                                    src={(playersList[item].profile_image !== undefined) ? IMAGE_URL+"user/"+playersList[item].profile_image : IMAGE_URL+"blank_user.png"}
                                                    // setPlayersList
                                                    alt={"player.name"}
                                                    className="img-fluid card-img"
                                        
                                                  /> */}
                      <p>
                        {playersList[item].fname} {playersList[item].lname}
                      </p>
                      <p
                        className='text-primary'
                        id={playersList[item].corporate_email}
                      >
                        {playersList[item].corporate_email}
                      </p>
                    </>
                  ) : null}
                </CCardBody>
                <CCardFooter className='text-center'>
                  {/* <CButton title='View' className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                   */}
                  <CRow>
                
                    <CCol className='py-2'>
                      <Link to={`/user/detail?id=${playersList[item]!=null ? (playersList[item]._id == undefined
                            ? null
                            : playersList[item]._id) : null}`}>
                      <CButton
                        title='View'
                        className='bi bi-eye-fill text-white me-2'
                        color='primary'
                      ></CButton>
                      </Link>
                      
                    </CCol>
                    {
                      flag? (<>
                      <CCol className='py-2'>
                      {' '}
                      <Swapmodel
                        groupList={groupList}
                        selectedGroup={selectedGroup}
                        playerList={playersList}
                        selecteduser={
                          playersList[item]._id == undefined
                            ? null
                            : playersList[item]._id
                        }
                      ></Swapmodel>
                    </CCol>
                      </>)
                    : null
                    }
                  </CRow>
                </CCardFooter>
              </CCard>
            </CCol>
          )
        )}

        {/* <CCol sm="12" md="6" lg="3">
                    <CCard className='player-card'>
                        {selectedSport !== '2k_run' ? (
                            <CCardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <span className='score-success'><CIcon icon={cilThumbUp}/> : 1</span>
                                    <span className='score-danger'><CIcon icon={cilThumbDown} /> : 0</span>
                                </div>
                            </CCardHeader>
                        ):null}
                        <CCardImage
                            src={imagePaths[selectedGender].p1}
                            alt={"player.name"}
                            className="img-fluid card-img"
                            />
                        <CCardBody className="text-center">
                            <p>P1</p>
                            <p>Corporate 1</p>
                        </CCardBody>
                        <CCardFooter className="text-center">
                            <CButton title='View' className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                        </CCardFooter>
                    </CCard>
                </CCol>
                <CCol sm="12" md="6" lg="3">
                    <CCard className='player-card'>
                        {selectedSport !== '2k_run' ? (
                            <CCardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <span className='score-success'><CIcon icon={cilThumbUp}/> : 0</span>
                                    <span className='score-danger'><CIcon icon={cilThumbDown} /> : 1</span>
                                </div>
                            </CCardHeader>
                        ):null}
                        <CCardImage
                            src={imagePaths[selectedGender].p2}
                            alt={"player.name"}
                            className="img-fluid card-img"
                            />
                        <CCardBody className="text-center">
                            <p>P2</p>
                            <p>Corporate 2</p>
                        </CCardBody>
                        <CCardFooter className="text-center">
                            <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                            {selectedSport !== '2k_run' ? (
                                <CButton title='Delete' className='bi bi-trash-fill text-white mx-2' disabled color="secondary"></CButton>
                            ) : (
                                <CButton title='Delete' className='bi bi-trash-fill text-white mx-2' color="danger"></CButton>
                            )}
                        </CCardFooter>
                    </CCard>
                </CCol>
                <CCol sm="12" md="6" lg="3">
                    <CCard className='player-card'>
                        {selectedSport !== '2k_run' ? (
                            <CCardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <span className='score-success'><CIcon icon={cilThumbUp}/> : 0</span>
                                    <span className='score-danger'><CIcon icon={cilThumbDown} /> : 0</span>
                                </div>
                            </CCardHeader>
                        ):null}
                        <CCardImage
                            src={imagePaths[selectedGender].p3}
                            alt={"player.name"}
                            className="img-fluid card-img"
                            />
                        <CCardBody className="text-center">
                            <p>P3</p>
                            <p>Corporate 3</p>
                        </CCardBody>
                        <CCardFooter className="text-center">
                            <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                        </CCardFooter>
                    </CCard>
                </CCol> */}

        {/* 4 */}
        {/* {selectedSport === 'bowling' || selectedSport === '2k_run' ? (
                    <>
                        <CCol sm="12" md="6" lg="3">
                            <CCard className='player-card'>
                               {selectedSport !== '2k_run' ? (
                                    <CCardHeader>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span className='score-success'><CIcon icon={cilThumbUp}/> : 0</span>
                                            <span className='score-danger'><CIcon icon={cilThumbDown} /> : 0</span>
                                        </div>
                                    </CCardHeader>
                                ):null}
                                <CCardImage
                                    src={imagePaths[selectedGender].p2}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol sm="12" md="6" lg="3">
                            <CCard className='player-card'>
                                {selectedSport !== '2k_run' ? (
                                    <CCardHeader>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span className='score-success'><CIcon icon={cilThumbUp}/> : 0</span>
                                            <span className='score-danger'><CIcon icon={cilThumbDown} /> : 0</span>
                                        </div>
                                    </CCardHeader>
                                ):null}
                                <CCardImage
                                    src={imagePaths[selectedGender].p3}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                    </>
                ):null} */}
        {/* 6 */}
        {/* {selectedSport === '2k_run' ? (
                    <>
                        <CCol sm="12" md="6" lg="3">
                            <CCard  className='player-card'>
                                <CCardImage
                                    src={imagePaths[selectedGender].p1}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol sm="12" md="6" lg="3">
                            <CCard  className='player-card'>
                                <CCardImage
                                    src={imagePaths[selectedGender].p3}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol sm="12" md="6" lg="3">
                            <CCard  className='player-card'>
                                <CCardImage
                                    src={imagePaths[selectedGender].p2}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol sm="12" md="6" lg="3">
                            <CCard  className='player-card'>
                                <CCardImage
                                    src={imagePaths[selectedGender].p1}
                                    alt={"player.name"}
                                    className="img-fluid card-img"
                                    />
                                <CCardBody className="text-center">
                                    <p>P3</p>
                                    <p>Corporate 3</p>
                                </CCardBody>
                                <CCardFooter className="text-center">
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                    </>    
                ): null}
                <CCol sm="12" md="6" lg="3">
                    <CCard className='player-card' >
                        {selectedSport !== '2k_run' ? (
                            <CCardHeader style={{ textAlign: 'center' }}>
                                <span>Add Player</span>
                            </CCardHeader>
                        ) : null}
                        <CCardBody className="text-center">
                            <p>&nbsp;</p>    
                            <CCardImage
                                src={logo}
                                alt={"player.name"}
                                className="img-fluid card-img"
                            />
                            <p>&nbsp;</p>    
                        </CCardBody>
                        <CCardFooter className="text-center">
                            <CButton title='View'  color="primary"><CIcon icon={cilUserPlus} /></CButton>
                        </CCardFooter>
                    </CCard>
                </CCol> */}
      </CRow>
      {/* matchList */}
      <CRow>
        {matchList.map((matchData, matchIndex) => (
          <CCol
            xs='12'
            sm='12'
            lg='12'
            key={matchIndex + 1}
            style={{ marginBottom: '15px' }}
          >
            <CCard>
              {matchData && matchData.status == 'completed' && (
                <span>
                  {
                    ScorEdit === matchIndex ? (
                      <>
                      
                      <CButton
                        onClick={(event) => setScoreEdit(null)}
                        id={matchData._id}
                        color='dark'
                        className='text-white float-end ms-3'
                      >
                      Cancel
                      </CButton>
                      <CButton
                        onClick={(event) => {setMatchVisible({
                          status:  true,
                          player_1_score: updateScores.player_1_score,
                          player_2_score: updateScores.player_2_score,
                          match_id: matchData._id
                        }
                        )
                        setState(!state)
                      }}
                        id={matchData._id}
                        color='danger'
                        className='text-white float-end ms-3'
                      >
                      submit
                      </CButton>
                      </>
                      
                    ) :( <>
                            <CButton
                              onClick={(event) => handleMatchScoreEdit(event, matchIndex)}
                              id={matchData._id}
                              color='info'
                              className='text-white float-end ms-3'
                            >
                            <CIcon CIcon icon={cilPencil}></CIcon>
                            </CButton>
                            <CButton
                             onClick={(event) => handleLogs(event, matchData)}
                            id={matchData._id}
                            color='warning'
                            className='text-white float-end'
                            >
                            <CIcon icon={cilGraph}></CIcon>
                            </CButton>

                    </>)
                  }
                  
                  
                </span>
              )}


              {/* Logs modal */}
              <CModal
                size='xl'
                alignment='center'
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby='LiveDemoExampleLabel'
                scrollable={true}
              >
                <CModalHeader onClose={() => setVisible(false)}>
                  <CModalTitle id='LiveDemoExampleLabel'>
                    Match Logs
                  </CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CTable>
                    <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell  style= {{ textAlign:'end'}} colSpan={4}>
                          {
                          playersList &&
                          selectedMatch &&
                          playersList[selectedMatch.player_1_id] &&
                          playersList[selectedMatch.player_1_id].fname !== undefined
                            ? playersList[selectedMatch.player_1_id].fname +
                              ' ' +
                              playersList[selectedMatch.player_1_id].lname
                            : null
                          }

                          </CTableHeaderCell>
                          <CTableHeaderCell className='text-align-end'style={{textAlign:'end'}} colSpan={3}>
                          {
                            playersList &&
                            selectedMatch &&
                            playersList[selectedMatch.player_2_id] &&
                            playersList[selectedMatch.player_2_id].fname !== undefined
                              ? playersList[selectedMatch.player_2_id].fname +
                                ' ' +
                                playersList[selectedMatch.player_2_id].lname
                              : null
                          }

                          </CTableHeaderCell>
                        </CTableRow>
                        <CTableRow>
                        <CTableHeaderCell scope='col'>#</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PA</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PF</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PD</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Score1</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Score2</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PA</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PF</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>PD</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Created By</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Time</CTableHeaderCell>
                          <CTableHeaderCell scope='col'>Type</CTableHeaderCell>

                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {Logs.length > 0
                        ? Logs.map((match, index) => (
                            <>
                            <CTableRow className='text-align-center align-items-center'>
                                <CTableDataCell>#</CTableDataCell>
                                <CTableDataCell>{match.player_1_PA}</CTableDataCell> 
                                <CTableDataCell>{match.player_1_PF}</CTableDataCell>
                                <CTableDataCell>{match.player_1_PD}</CTableDataCell>
                                <CTableDataCell style={{borderRight:'4px solid black'}}>{match.player_1_score}</CTableDataCell>
                                <CTableDataCell style={{textAlign:'center'}}>{match.player_2_score}</CTableDataCell>
                                <CTableDataCell>{match.player_2_PA}</CTableDataCell>
                                <CTableDataCell>{match.player_2_PF}</CTableDataCell>
                                <CTableDataCell style= {{borderRight:'4px solid black'}}>{match.player_2_PD}</CTableDataCell>
                                <CTableDataCell>{match.created_by.fname + ' ' + match.created_by.lname}</CTableDataCell>
                                <CTableDataCell>{FormatDate(match.createdAt,0, 1, 0)}</CTableDataCell>
                                <CTableDataCell>
                                  {
                                      match.status == 'live' ?  (
                                          <>
                                              <span
                                                  className='badge bg-info text-white'
                                                >
                                                  Live
                                              </span>
                                          </>
                                      ) : (
                                          match.status == 'update' ? (
                                          <span
                                          className='badge bg-danger text-white'
                                        >
                                          Updated
                                      </span>

                                          ) : ( 
                                              <span
                                              className='badge bg-success text-white'
                                              >
                                              Submited
                                          </span>
                                          )
                                      )
                                    
                                  }
                                </CTableDataCell>
                            </CTableRow>
                            </>
                          ))
                        : null}
                    </CTableBody>
                  </CTable>
                </CModalBody>
              </CModal>

              {/* Match edit modal */}
            

              <CModal
              visible={MatchVisible.status}
              onClose={() => setMatchVisible(!MatchVisible.status)}
              >
                <CModalHeader>
                  <CModalTitle>Edit Score</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <p>Do you really want to edit scores for this match</p>
                </CModalBody>
                <CModalFooter>
                  <CButton onClick={() => setMatchVisible(!MatchVisible.status)} color="secondary">Close</CButton>
                  <CButton onClick={SubmitupdatedScore} color="primary">Save changes</CButton>
                </CModalFooter>
              </CModal>

              <CCardBody>
                <CRow>
                  <CCol
                    xs='4'
                    sm='4'
                    lg='4'
                    className=''
                    style={{ position: 'relative' }}
                  >
                    {playersList[matchData.player_1_id] !== undefined ? (
                      <>
                        {/* //enter image */}
                        <div className='d-flex justify-content-center align-items-center mt-3'>
                          {/* winning condition */}
                          {
                            matchData.status === 'completed' && ScorEdit === matchIndex ? (
                                <>
                                <div>
                                <CButton
                                    className='score-increase'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: matchData._id.toString(),
                                        player: 'player_1_score',
                                        type: 'increase',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={add} />
                                  </CButton> <br />
                                  <CButton
                                    className='score-less'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: matchData._id.toString(),
                                        player: 'player_1_score',
                                        type: 'less',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={less} />
                                  </CButton>
                                </div>

                                </>
                            ) : (
                              <>
                              {/* player win */}
                              {matchData.player_won ==
                                playersList[matchData.player_1_id]._id ? (
                                  <img
                                    src={trophy}
                                    alt=''
                                    style={{
                                      height: '30px',
                                      width: '50px',
                                      position: 'absolute',
                                      left: '1.3em'
                                    }}
                                  />
                                ) : null
                              }
                              </>
                            )
                          }

                          
                          {/* user 1 details */}
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${
                                playersList[matchData.player_1_id]
                                  .profile_image !== undefined
                                  ? IMAGE_URL +
                                    'user/' +
                                    playersList[matchData.player_1_id]
                                      .profile_image
                                  : logo
                              })`
                            }}
                          ></div>
                        </div>
                        {playersList[matchData.player_1_id] !== undefined ? (
                          <p
                            style={{
                              textAlign: 'center',
                              color: 'rgb(191, 140, 11)'
                            }}
                          >
                            {playersList[matchData.player_1_id].fname}{' '}
                            {playersList[matchData.player_1_id].lname}
                          </p>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <div className='d-flex justify-content-center  align-items-center mt-3'>
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${logo})`
                            }}
                          ></div>
                        </div>
                        <p
                          style={{
                            textAlign: 'center',
                            color: 'rgb(191, 140, 11)'
                          }}
                        >
                          Yet to Join
                        </p>
                      </>
                    )}
                  </CCol>
                  {matchData.status === 'completed' && ScorEdit != matchIndex  ? (
                    <>
                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                        <p
                          className='badge bg-success text-white'
                          style={{ marginTop: '30px', color: 'limegreen' }}
                        >
                          Completed
                        </p>
                        <br />
                        <p
                          className='badge bg-dark text-white'
                          style={{ marginTop: '30px', color: 'limegreen' }}
                        >
                          {matchData.player_1_score +
                            ' ' +
                            '- ' +
                            ' ' +
                            matchData.player_2_score}
                        </p>
                      </CCol>
                    </>
                  ) : matchData.status === 'live' ? (
                    <>
                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                        <p
                          className='badge bg-danger px-3 fs-5 text-white'
                          style={{ marginTop: '30px' }}
                        >
                          Live
                        </p>
                        <br />
                        {/* <p
                          className='badge bg-dark text-white px-3'
                          style={{ marginTop: '30px' }}
                        >
                          {matchData.player_1_score +
                            ' ' +
                            '- ' +
                            ' ' +
                            matchData.player_2_score}
                        </p> */}
                      </CCol>
                    </>
                  ) :ScorEdit === matchIndex && canEdit && matchData.status === 'completed'? (<>
                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>

                        <br />
                        <div className='d-flex justify-content-center align-items-center '>
                        <CFormInput className='bg-dark text-white' style={{width: '50px', textAlign: 'center'}}  size='sm' type="text"  defaultValue={updateScores.player_1_score}  value={updateScores.player_1_score}/>
                          
                          <CFormInput className='bg-dark text-white' style={{width: '50px', textAlign: 'center'}}   size='sm' type="text"  defaultValue=  { updateScores.player_2_score} value={updateScores.player_2_score}/>
                        </div>

                        
                      </CCol>
                  </>) :(
                    <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                      {/* <p style={{ marginTop: '30px', color: 'limegreen' }}>
                        VS
                      </p> */}
                      {/* show all scorer here  */}
                      {editMode === matchIndex ? (
                        <div>
                          <form action='' onSubmit={handleSave}>
                            <p style={{ fontSize: '14px' }}>
                            <input
                              className='form-control'
                              name='match_time'
                              key={matchIndex}
                              min={getCurrentDate()} // Ensure getCurrentDate() returns the correct format
                              type='datetime-local'
                              id={matchData._id.toString()}
                              defaultValue={
                                matchData.match_date != null
                                  ? newFormatdate(matchData.match_date)
                                  : newDateTime
                              }
                              onChange={handleChange}
                              required
                            />

                            </p>
                            <select
                              className='form-select'
                              name='match_scorer'
                              required
                            >
                              <option value=''>-- Select a Scorer --</option>
                              {scorers != null ? (
                                <>
                                  {scorers.map((scorer, index) => {
                                    return (
                                      <option
                                        selected={
                                          matchData.scorer_id === scorer.id
                                        }
                                        key={index}
                                        value={scorer.id}
                                      >
                                        {scorer.fname + ' ' + scorer.lname}
                                      </option>
                                    )
                                  })}
                                </>
                              ) : null}
                            </select>
                            <br />
                            <p style={{ fontSize: '14px' }}>
                              <CButton
                                title='Save'
                                className='text-white'
                                color='primary'
                                type='submit'
                              >
                                <CIcon icon={cilSave} />
                              </CButton>
                              &nbsp; &nbsp;
                              <CButton
                                title='Cancel'
                                className='text-white'
                                color='danger'
                                type='button'
                                onClick={() => setEditMode(false)}
                              >
                                <CIcon icon={cilX} />
                              </CButton>
                            </p>
                          </form>
                        </div>
                      ) : (
                        <div>
                          <p style={{ fontSize: '14px' }}>
                            {matchData.match_date !== null
                              ? newFormatdate(matchData.match_date, 1)
                              : dateTime[matchIndex]}
                          </p>
                          {/* <p style={{ fontSize: '14px' }}>{ dateTime }</p> */}
                          {scorers.map((scorer, index) =>
                            matchData.scorer_id === scorer.id.toString() ? (
                              <>
                                <div className='middle-name' key={index}>
                                  {scorer.fname + ' ' + scorer.lname}
                                </div>
                              </>
                            ) : null
                          )}
                          {/* uncomment if neeeded */}
                          {/* <p style={{ fontSize: '14px' }}>
                            <CButton
                              title='Edit'
                              onClick={() => handleEdit(matchIndex)}
                              className='bi bi-pencil text-white'
                              color='warning'
                            ></CButton>
                          </p> */}
                          {

                         (matchData.player_1_id!=null && matchData.player_2_id!=null) ? (
                            <div>
                                <p style={{ marginTop: '30px', color: 'limegreen' }}>VS</p>
                                <p style={{ fontSize: '14px' }}>
                                    <CButton
                                        title='Edit'
                                        onClick={() => handleEdit(matchIndex)}
                                        className='bi bi-pencil text-white'
                                        color="warning"
                                    ></CButton>
                                </p>
                              
                            </div>
                        ) : (
                            <div>
                            <br />
                            <p style={{ marginTop: '30px', color: 'limegreen' }}>VS</p>
                            </div>
                        )
                        }
                        </div>
                        // if scroer has assigned show dynamic detail
                      )}
                    </CCol>
                  )}
                  {/* middle part */}

                  <CCol
                    xs='4'
                    sm='4'
                    lg='4'
                    className='text-center'
                    style={{ position: 'relative' }}
                  >
                    {playersList[matchData.player_2_id] !== undefined ? (
                      <>
                        <div className='d-flex justify-content-center  align-items-center mt-3'>
                          
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${
                                playersList[matchData.player_2_id]
                                  .profile_image !== undefined
                                  ? IMAGE_URL +
                                    'user/' +
                                    playersList[matchData.player_2_id]
                                      .profile_image
                                  : logo
                              })`
                            }}
                          ></div>
                          
                           {
                            matchData.status === 'completed' && ScorEdit === matchIndex ? (
                                <>
                                <div>
                                <CButton
                                    className='score-increase'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: matchData._id.toString(),
                                        player: 'player_2_score',
                                        type: 'increase',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={add} />
                                  </CButton> <br />
                                  <CButton
                                    className='score-less'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: matchData._id.toString(),
                                        player: 'player_2_score',
                                        type: 'less',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={less} />
                                  </CButton>
                                </div>
                                 
                                </>
                            ) : (
                              <>
                              {/* player win */}
                              {matchData.player_won ==
                                playersList[matchData.player_2_id]._id ? (
                                  <div>

                                    <img
                                      src={trophy}
                                      alt=''
                                      style={{
                                        height: '30px',
                                        width: '50px',
                                        position:'absolute'
                                      }}
                                    />
                                  </div>
                                ) : null
                              }
                              </>
                            )
                          }

                        </div>
                        {playersList[matchData.player_2_id] !== undefined ? (
                          <p
                            style={{
                              textAlign: 'center',
                              color: 'rgb(191, 140, 11)'
                            }}
                          >
                            {playersList[matchData.player_2_id].fname}{' '}
                            {playersList[matchData.player_2_id].lname}
                          </p>
                        ) : null}
                        
                      </>
                    ) : (
                      <>
                        <div className='d-flex justify-content-center  align-items-center mt-3'>
                          <div
                            className='bg-image-container mb-3'
                            style={{
                              backgroundImage: `url(${logo})`
                            }}
                          ></div>
                        </div>
                        <p
                          style={{
                            textAlign: 'center',
                            color: 'rgb(191, 140, 11)'
                          }}
                        >
                          Yet to Join
                        </p>
                      </>
                    )}
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        ))}
      </CRow>

      <br />
      <br />
      {selectedSport !== '2k_run' ? (
        <CRow key={'test'}>
          {matches.map((round, roundIndex) => (
            <CCol
              xs='12'
              sm='12'
              lg='12'
              key={roundIndex}
              style={{ marginBottom: '15px' }}
            >
              {roundIndex === 0 ? (
                <CCard>
                  <CCardHeader
                    color='success'
                    style={{ textAlign: 'center', background: 'limegreen' }}
                  >
                    Match {roundIndex + 1}
                  </CCardHeader>

                  <CCardBody>
                    <CRow>
                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        <img
                          className='img-fluid'
                          style={{ boxShadow: '0px 0px 10px limegreen' }}
                          src={imagePaths[selectedGender]['p' + round[0]]}
                          alt={'p' + round[0]}
                        />
                        <p style={{ textAlign: 'center', color: 'limegreen' }}>
                          {'P' + round[0]}
                        </p>
                        <p style={{ textAlign: 'center', marginTop: '0px' }}>
                          Score : 22
                        </p>
                      </CCol>
                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                        <p style={{ marginTop: '30px', color: 'limegreen' }}>
                          WINNER&nbsp;:&nbsp;P1
                        </p>

                        <div>
                          <p style={{ fontSize: '14px' }}>{dateTime}</p>
                          <p style={{ fontSize: '14px' }}>
                            <div>Scorer {roundIndex + 1}</div>
                          </p>
                        </div>
                      </CCol>
                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        <img
                          className='img-fluid'
                          style={{ boxShadow: '0px 0px 10px red' }}
                          src={imagePaths[selectedGender]['p' + round[1]]}
                          alt={'p' + round[1]}
                        />
                        <p style={{ textAlign: 'center', color: 'red' }}>
                          {'P' + round[1]}
                        </p>
                        <p style={{ textAlign: 'center', marginTop: '0px' }}>
                          Score : 20
                        </p>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              ) : (
                <CCard>
                  <CCardHeader style={{ textAlign: 'center' }}>
                    Match {roundIndex + 1}
                  </CCardHeader>

                  <CCardBody>
                    <CRow>
                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        <img
                          className='img-fluid'
                          src={imagePaths[selectedGender]['p' + round[0]]}
                          alt={'p' + round[0]}
                        />
                        <p style={{ textAlign: 'center' }}>{'P' + round[0]}</p>
                      </CCol>
                      <CCol xs='4' sm='4' lg='4' className='vs-tag'>
                        <p style={{ marginTop: '30px', color: 'limegreen' }}>
                          VS
                        </p>
                        {editMode === roundIndex ? (
                          <div>
                            <p style={{ fontSize: '14px' }}>
                              <input
                                className='form-control'
                                type='datetime-local'
                                value={newDateTime}
                                onChange={handleChange}
                              />
                            </p>
                            <select className='form-select'>
                              <option value=''>-- Select a Scorer --</option>
                              <option value='{roundIndex}'>scorer 1</option>
                              <option value='{roundIndex}'>scorer 2</option>
                              <option value='{roundIndex}'>scorer 3</option>
                              <option value='{roundIndex}'>scorer 4</option>
                              <option value='{roundIndex}'>scorer 5</option>
                              <option value='{roundIndex}'>scorer 6</option>
                            </select>
                            <br />
                            <p style={{ fontSize: '14px' }}>
                              <CButton
                                onClick={handleSave}
                                title='Save'
                                className='text-white'
                                color='primary'
                              >
                                <CIcon icon={cilSave} />
                              </CButton>
                            </p>
                          </div>
                        ) : (
                          <div>
                            {roundIndex == 1 || roundIndex == 4 ? (
                              <p style={{ fontSize: '14px' }}>
                                dd/mm/yyyy 00:00
                              </p>
                            ) : (
                              <p style={{ fontSize: '14px' }}>{dateTime}</p>
                            )}
                            <p style={{ fontSize: '14px' }}>
                              <div>Scorer {roundIndex + 1}</div>
                            </p>
                            <p style={{ fontSize: '14px' }}>
                              <CButton
                                title='Edit'
                                onClick={handleEdit}
                                className='bi bi-pencil text-white'
                                color='warning'
                              ></CButton>
                            </p>
                          </div>
                        )}
                      </CCol>
                      <CCol xs='4' sm='4' lg='4' className='vs-Card'>
                        <img
                          className='img-fluid '
                          src={imagePaths[selectedGender]['p' + round[1]]}
                          alt={'p' + round[1]}
                        />
                        <p>{'P' + round[1]}</p>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              )}
            </CCol>
          ))}
        </CRow>
      ) : null}
    </>
  )
}
export default TournamentGroupsMatches
