import React, { useState, useRef  } from "react";
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons';
import { ChangePasswordAPI } from "../../API/player";
import { useSnackbar } from 'notistack';

import {
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormInput,
    CForm,
} from '@coreui/react'
const Profileupdate = ({ user_details }) => {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE;
    console.log(user_details.profile_image , "userdetails")
    const [visible, setVisible] = useState(false)
    const [profileImage, setProfileImage] = useState(
        user_details.profile_image!=null? (IMAGE_URL + "user/" + user_details.profile_image ):(IMAGE_URL + "profile.jpg")
      );
      console.log(profileImage , "profile");
    const { enqueueSnackbar } = useSnackbar();
   
    const [formData, setFormData] = useState(
        {
        }
    );
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        // Trigger the click event on the hidden file input
        fileInputRef.current.click();
      };

    console.log(user_details, "user details");

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData)
        let res = await ChangePasswordAPI(formData);

        if (res.data.status) {
            enqueueSnackbar("Password changed successfully", { variant: 'success' });
            setFormData({
                fname: user_details.fname,
                lname: user_details.lname,
                profile_image: user_details.lname,
                corporate_email: user_details.corporate_email,
                username: user_details.username,

            })
            setVisible(false)
        } else {
            enqueueSnackbar(res.data.message, { variant: 'error' });

        }
    };
    const HandelChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setProfileImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    return (
        <>
            <CButton className="bg-info text-white" onClick={() => setVisible(!visible)}>Edit Profile</CButton>
            {/* <CButton color="primary" onClick={() => setVisible(!visible)}><CIcon icon={cilPencil} /></CButton> */}
            <CForm onSubmit={handleSubmit}>
                <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => {
                        setVisible(false); setFormData({
                            old_password: '',
                            new_password: ''
                        })
                    }}
                    aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle id="VerticallyCenteredExample">Change Password</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div className="card-body text-center">
                            <img
                                 src={profileImage}
                                alt="Updatable"
                                className="img-fluid"
                                style={{ width: 150,height:100, cursor: 'pointer',borderRadius:'50%' }}
                                onClick={handleImageClick}
                            />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />

                        </div>
                        {/* <CFormInput
                            className="mb-3"
                            type="file" accept="image/*"
                            id="oldpassword"
                            label="image"
                            // placeholder="Old password"
                            required
                            name="image"
                            value={user_details.fname}
                            onChange={HandelChange}
                        /> */}
                          <CFormInput
                            className="mb-3"
                            type="text"
                            // pattern="^\S+$"
                            id="username"
                            label="username"
                            placeholder="username"
                            required
                            name="username"
                            defaultValue={user_details.username}
                            onChange={HandelChange}
                        />
                        <CFormInput
                            className="mb-3"
                            type="text"
                            // pattern="^\S+$"
                            id="fname"
                            label="fname"
                            placeholder="fname"
                            required
                            name="fname"
                            defaultValue={user_details.fname}
                            onChange={HandelChange}
                        />
                          <CFormInput
                            className="mb-3"
                            type="text"
                            // pattern="^\S+$"
                            id="lname"
                            label="lname"
                            placeholder="lname"
                            required
                            name="fname"
                            defaultValue={user_details.lname}
                            onChange={HandelChange}
                        />
                         <CFormInput
                            className="mb-3"
                            type="text"
                            // pattern="^\S+$"
                            id="corporate email"
                            label="corporate_email"
                            placeholder="lname"
                            required
                            name="corporate email"
                            defaultValue={user_details.corporate_email}
                            onChange={HandelChange}
                        />
                        
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => {
                            setVisible(false); setFormData({
                                old_password: '',
                                new_password: ''
                            })
                        }
                        }>
                            Close
                        </CButton>
                        <CButton type="submit" onClick={handleSubmit} color="primary">Save changes</CButton>
                    </CModalFooter>
                </CModal>
            </CForm>

        </>
    )

}
export default Profileupdate
