import React, { useEffect, useState } from "react";
import {useNavigate} from"react-router-dom"
import { Row, Col} from 'react-bootstrap';
import '../../assets/css/Player/dashboard.css'
import Badminton from '../../components/icons/Batminton';
import TableTennis from '../../components/icons/TableTennisIcon';
import Bowling from '../../components/icons/Bowling';
import Run from '../../components/icons/RunIcon';




import {CorporateStandings, UpcommingSlider, LatestMatch, Standing_tabs} from '../../components/Player/index';
import { getPlayerSports, getMatches,GetGroupStandings, GetStandings, GetGroups, Profile, Winner , isfinaldone} from '../../API/player';
import {GetTournamentLis }from  '../../API/admin'
import {FetchAllPoints} from '../../API/player'
import Loader from '../admin/Common/Loader';
import { NULL } from "sass";


function Home () {
    let navigate = useNavigate();
    const [SliderImages, setSliderImages] = useState(['badminton', 'tt', 'running', 'bowling']);
    const [profile, setProfile] = useState(null);
    const [loaderStatus, setloaderStatus] = useState(true);
    const [SelectedTournament, setSelectedTournament] = useState('');
    const [TournamentName, setTournamentName] = useState('');
    const [CorporateList, setCorporateList] = useState([]);
    const [TopPerformer, setTopPerformer] = useState(null);

    const [matches, setMatches] = useState('');
    const [latestMatch, setLatestMatch] = useState([
        // {
        //     "_id": "66961f5ac94d5b1dba733dea",
        //     "group_id": "66961f56c94d5b1dba733ca4",
        //     "tournament_id": "66961f54c94d5b1dba733b9e",
        //     "round": "round_1",
        //     "match_date": "2024-07-17T03:30:00.000Z",
        //     "submited_at": "2024-07-19T08:49:03.456Z",
        //     "players": [
        //         {
        //             "_id": "669636cdf2174d328d674d10",
        //             "points": 4,
        //             "strikes": 5,
        //             "spares": 6
        //         },
        //         {
        //             "_id": "669637c4f2174d328d67515c",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "66963826f2174d328d675315",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "66963981f2174d328d675923",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "669639daf2174d328d675aac",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "669639f6f2174d328d675b2d",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         }
        //     ],
        //     "gender": "male",
        //     "status": "completed",
        //     "rank_1": "669636cdf2174d328d674d10",
        //     "rank_2": "669637c4f2174d328d67515c",
        //     "__v": 0,
        //     "createdAt": "2024-07-16T07:20:58.857Z",
        //     "updatedAt": "2024-07-19T08:49:03.462Z",
        //     "player_details": [
        //         {
        //             "_id": "66963826f2174d328d675315",
        //             "username": "user-303",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user303",
        //             "lname": "303user",
        //             "corporate_email": "corporateuser-303@gmail.com",
        //             "email": "user-303@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-7.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "66963981f2174d328d675923",
        //             "username": "user-475",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user475",
        //             "lname": "475user",
        //             "corporate_email": "corporateuser-475@gmail.com",
        //             "email": "user-475@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-17.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669639daf2174d328d675aac",
        //             "username": "user-519",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user519",
        //             "lname": "519user",
        //             "corporate_email": "corporateuser-519@gmail.com",
        //             "email": "user-519@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-7.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669639f6f2174d328d675b2d",
        //             "username": "user-533",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user533",
        //             "lname": "533user",
        //             "corporate_email": "corporateuser-533@mailinator.com",
        //             "email": "user-533@mailinator.com",
        //             "gender": "male",
        //             "profile_image": "img-21.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669637c4f2174d328d67515c",
        //             "username": "user-254",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user254",
        //             "lname": "254user",
        //             "corporate_email": "corporateuser-254@mailinator.com",
        //             "email": "user-254@mailinator.com",
        //             "gender": "male",
        //             "profile_image": "img-12.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669636cdf2174d328d674d10",
        //             "username": "user-132",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user132",
        //             "lname": "132user",
        //             "corporate_email": "corporateuser-132@gmail.com",
        //             "email": "user-132@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-25.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         }
        //     ]
        // }
    ]);
    const [completedMatches, setCompletedMatches] = useState( [
            // {
            //     "_id": "6698d5b7255a03c7c6f9bacb",
            //     "group_id": "6698d5b5255a03c7c6f9b985",
            //     "tournament_id": "6698d5b3255a03c7c6f9b787",
            //     "round": "round_1",
            //     "match_date": null,
            //     "submited_at": "2024-07-20T10:40:00.136Z",
            //     "players": [
            //         {
            //             "_id": "6698ebfd8b1b279a69f0c2f5",
            //             "minutes": 5,
            //             "millisecond": 25,
            //             "totalSeconds": 343.25,
            //             "second": 43
            //         },
            //         {
            //             "_id": "6698ec028b1b279a69f0c2ff",
            //             "minutes": 5,
            //             "millisecond": 50,
            //             "totalSeconds": 332.5,
            //             "second": 32
            //         },
            //         {
            //             "_id": "6698ec078b1b279a69f0c308",
            //             "minutes": 1,
            //             "millisecond": 76,
            //             "totalSeconds": 61.76,
            //             "second": 1
            //         },
            //         {
            //             "_id": "6698ec0c8b1b279a69f0c311",
            //             "minutes": 2,
            //             "millisecond": 1,
            //             "totalSeconds": 165.01,
            //             "second": 45
            //         },
            //         {
            //             "_id": "6698ec118b1b279a69f0c31a",
            //             "minutes": 5,
            //             "millisecond": 43,
            //             "totalSeconds": 313.43,
            //             "second": 13
            //         },
            //         {
            //             "_id": "6698ec168b1b279a69f0c323",
            //             "minutes": 3,
            //             "millisecond": 30,
            //             "totalSeconds": 216.3,
            //             "second": 36
            //         },
            //         {
            //             "_id": "6698ec1b8b1b279a69f0c32c",
            //             "minutes": 3,
            //             "millisecond": 70,
            //             "totalSeconds": 230.7,
            //             "second": 50
            //         },
            //         {
            //             "_id": "6698ec208b1b279a69f0c335",
            //             "minutes": 3,
            //             "millisecond": 27,
            //             "totalSeconds": 208.27,
            //             "second": 28
            //         },
            //         {
            //             "_id": "6698ec258b1b279a69f0c33e",
            //             "minutes": 5,
            //             "millisecond": 3,
            //             "totalSeconds": 323.03,
            //             "second": 23
            //         },
            //         {
            //             "_id": "6698ec2a8b1b279a69f0c347",
            //             "minutes": 5,
            //             "millisecond": 9,
            //             "totalSeconds": 329.09,
            //             "second": 29
            //         },
            //         {
            //             "_id": "6698ec2f8b1b279a69f0c350",
            //             "minutes": 4,
            //             "millisecond": 75,
            //             "totalSeconds": 271.75,
            //             "second": 31
            //         },
            //         {
            //             "_id": "6698ec348b1b279a69f0c359",
            //             "minutes": 5,
            //             "millisecond": 65,
            //             "totalSeconds": 330.65,
            //             "second": 30
            //         },
            //         {
            //             "_id": "6698ec398b1b279a69f0c362",
            //             "minutes": 3,
            //             "millisecond": 95,
            //             "totalSeconds": 181.95,
            //             "second": 1
            //         },
            //         {
            //             "_id": "6698ec3e8b1b279a69f0c36b",
            //             "minutes": 1,
            //             "millisecond": 25,
            //             "totalSeconds": 95.25,
            //             "second": 35
            //         },
            //         {
            //             "_id": "6698ec438b1b279a69f0c374",
            //             "minutes": 4,
            //             "millisecond": 7,
            //             "totalSeconds": 269.07,
            //             "second": 29
            //         },
            //         {
            //             "_id": "6698ec488b1b279a69f0c37d",
            //             "minutes": 2,
            //             "millisecond": 68,
            //             "totalSeconds": 166.68,
            //             "second": 46
            //         },
            //         {
            //             "_id": "6698ec4d8b1b279a69f0c386",
            //             "minutes": 5,
            //             "millisecond": 4,
            //             "totalSeconds": 325.04,
            //             "second": 25
            //         },
            //         {
            //             "_id": "6698ec528b1b279a69f0c38f",
            //             "minutes": 4,
            //             "millisecond": 30,
            //             "totalSeconds": 248.3,
            //             "second": 8
            //         },
            //         {
            //             "_id": "6698ec578b1b279a69f0c398",
            //             "minutes": 4,
            //             "millisecond": 39,
            //             "totalSeconds": 273.39,
            //             "second": 33
            //         },
            //         {
            //             "_id": "6698ec5c8b1b279a69f0c3a1",
            //             "minutes": 2,
            //             "millisecond": 51,
            //             "totalSeconds": 145.51,
            //             "second": 25
            //         },
            //         {
            //             "_id": "6698ec618b1b279a69f0c3aa",
            //             "minutes": 1,
            //             "millisecond": 32,
            //             "totalSeconds": 104.32,
            //             "second": 44
            //         },
            //         {
            //             "_id": "6698ec668b1b279a69f0c3b3",
            //             "minutes": 2,
            //             "millisecond": 16,
            //             "totalSeconds": 142.16,
            //             "second": 22
            //         },
            //         {
            //             "_id": "6698ec6b8b1b279a69f0c3bc",
            //             "minutes": 4,
            //             "millisecond": 35,
            //             "totalSeconds": 292.35,
            //             "second": 52
            //         },
            //         {
            //             "_id": "6698ec708b1b279a69f0c3c5",
            //             "minutes": 2,
            //             "millisecond": 21,
            //             "totalSeconds": 155.21,
            //             "second": 35
            //         },
            //         {
            //             "_id": "6698ec758b1b279a69f0c3ce",
            //             "minutes": 0,
            //             "millisecond": 0,
            //             "totalSeconds": 0,
            //             "second": 0
            //         }
            //     ],
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "6698ec078b1b279a69f0c308",
            //     "rank_2": "6698ec3e8b1b279a69f0c36b",
            //     "rank_3": "6698ec618b1b279a69f0c3aa",
            //     "__v": 0,
            //     "createdAt": "2024-07-18T08:43:35.942Z",
            //     "updatedAt": "2024-07-20T10:40:00.141Z",
            //     "player_details": [
            //         {
            //             "_id": "6698ec208b1b279a69f0c335",
            //             "username": "user-1810",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1810",
            //             "corporate_email": "corporateuser-1810@gmail.com",
            //             "email": "user-1810@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-2.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec2a8b1b279a69f0c347",
            //             "username": "user-1812",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1812",
            //             "corporate_email": "corporateuser-1812@gmail.com",
            //             "email": "user-1812@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-4.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec258b1b279a69f0c33e",
            //             "username": "user-1811",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1811",
            //             "corporate_email": "corporateuser-1811@mailinator.com",
            //             "email": "user-1811@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-3.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec618b1b279a69f0c3aa",
            //             "username": "user-1823",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1823",
            //             "corporate_email": "corporateuser-1823@mailinator.com",
            //             "email": "user-1823@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-15.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec488b1b279a69f0c37d",
            //             "username": "user-1818",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1818",
            //             "corporate_email": "corporateuser-1818@gmail.com",
            //             "email": "user-1818@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-10.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec668b1b279a69f0c3b3",
            //             "username": "user-1824",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1824",
            //             "corporate_email": "corporateuser-1824@gmail.com",
            //             "email": "user-1824@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-16.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec028b1b279a69f0c2ff",
            //             "username": "user-1804",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1804",
            //             "corporate_email": "corporateuser-1804@gmail.com",
            //             "email": "user-1804@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-23.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec078b1b279a69f0c308",
            //             "username": "user-1805",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1805",
            //             "corporate_email": "corporateuser-1805@mailinator.com",
            //             "email": "user-1805@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-24.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec0c8b1b279a69f0c311",
            //             "username": "user-1806",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1806",
            //             "corporate_email": "corporateuser-1806@gmail.com",
            //             "email": "user-1806@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-25.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec758b1b279a69f0c3ce",
            //             "username": "user-1827",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1827",
            //             "corporate_email": "corporateuser-1827@gmail.com",
            //             "email": "user-1827@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-19.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec6b8b1b279a69f0c3bc",
            //             "username": "user-1825",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1825",
            //             "corporate_email": "corporateuser-1825@gmail.com",
            //             "email": "user-1825@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-17.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec118b1b279a69f0c31a",
            //             "username": "user-1807",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1807",
            //             "corporate_email": "corporateuser-1807@gmail.com",
            //             "email": "user-1807@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-26.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec708b1b279a69f0c3c5",
            //             "username": "user-1826",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1826",
            //             "corporate_email": "corporateuser-1826@mailinator.com",
            //             "email": "user-1826@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-18.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec5c8b1b279a69f0c3a1",
            //             "username": "user-1822",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1822",
            //             "corporate_email": "corporateuser-1822@gmail.com",
            //             "email": "user-1822@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-14.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec1b8b1b279a69f0c32c",
            //             "username": "user-1809",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1809",
            //             "corporate_email": "corporateuser-1809@gmail.com",
            //             "email": "user-1809@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-1.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec528b1b279a69f0c38f",
            //             "username": "user-1820",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1820",
            //             "corporate_email": "corporateuser-1820@mailinator.com",
            //             "email": "user-1820@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-12.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec348b1b279a69f0c359",
            //             "username": "user-1814",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1814",
            //             "corporate_email": "corporateuser-1814@mailinator.com",
            //             "email": "user-1814@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-6.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ebfd8b1b279a69f0c2f5",
            //             "username": "user-1803",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1803",
            //             "corporate_email": "corporateuser-1803@gmail.com",
            //             "email": "user-1803@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-22.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec3e8b1b279a69f0c36b",
            //             "username": "user-1816",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1816",
            //             "corporate_email": "corporateuser-1816@gmail.com",
            //             "email": "user-1816@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-8.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec168b1b279a69f0c323",
            //             "username": "user-1808",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1808",
            //             "corporate_email": "corporateuser-1808@mailinator.com",
            //             "email": "user-1808@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-27.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec398b1b279a69f0c362",
            //             "username": "user-1815",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1815",
            //             "corporate_email": "corporateuser-1815@gmail.com",
            //             "email": "user-1815@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-7.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec438b1b279a69f0c374",
            //             "username": "user-1817",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1817",
            //             "corporate_email": "corporateuser-1817@mailinator.com",
            //             "email": "user-1817@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-9.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec2f8b1b279a69f0c350",
            //             "username": "user-1813",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1813",
            //             "corporate_email": "corporateuser-1813@gmail.com",
            //             "email": "user-1813@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-5.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec4d8b1b279a69f0c386",
            //             "username": "user-1819",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1819",
            //             "corporate_email": "corporateuser-1819@gmail.com",
            //             "email": "user-1819@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-11.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         },
            //         {
            //             "_id": "6698ec578b1b279a69f0c398",
            //             "username": "user-1821",
            //             "password": "$2b$10$17GWzCS6/J4CbxWjZ2Wk4u0S7Jh6R3sQ1zziIfjlNqiG3UzO1DdjK",
            //             "fname": "user",
            //             "lname": "1821",
            //             "corporate_email": "corporateuser-1821@gmail.com",
            //             "email": "user-1821@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-13.jfif",
            //             "sports": [
            //                 "2k_run"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // }
        ]);
    const [upcomingMatches, setUpcomingMatches] = useState([
        // {
        //     "_id": "66961f5ac94d5b1dba733dea",
        //     "group_id": "66961f56c94d5b1dba733ca4",
        //     "tournament_id": "66961f54c94d5b1dba733b9e",
        //     "round": "round_1",
        //     "match_date": "2024-07-17T03:30:00.000Z",
        //     "submited_at": "2024-07-19T08:49:03.456Z",
        //     "players": [
        //         {
        //             "_id": "669636cdf2174d328d674d10",
        //             "points": 4,
        //             "strikes": 5,
        //             "spares": 6
        //         },
        //         {
        //             "_id": "669637c4f2174d328d67515c",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "66963826f2174d328d675315",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "66963981f2174d328d675923",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "669639daf2174d328d675aac",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         },
        //         {
        //             "_id": "669639f6f2174d328d675b2d",
        //             "points": 0,
        //             "strikes": 0,
        //             "spares": 0
        //         }
        //     ],
        //     "gender": "male",
        //     "status": "completed",
        //     "rank_1": "669636cdf2174d328d674d10",
        //     "rank_2": "669637c4f2174d328d67515c",
        //     "__v": 0,
        //     "createdAt": "2024-07-16T07:20:58.857Z",
        //     "updatedAt": "2024-07-19T08:49:03.462Z",
        //     "player_details": [
        //         {
        //             "_id": "66963826f2174d328d675315",
        //             "username": "user-303",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user303",
        //             "lname": "303user",
        //             "corporate_email": "corporateuser-303@gmail.com",
        //             "email": "user-303@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-7.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "66963981f2174d328d675923",
        //             "username": "user-475",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user475",
        //             "lname": "475user",
        //             "corporate_email": "corporateuser-475@gmail.com",
        //             "email": "user-475@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-17.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669639daf2174d328d675aac",
        //             "username": "user-519",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user519",
        //             "lname": "519user",
        //             "corporate_email": "corporateuser-519@gmail.com",
        //             "email": "user-519@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-7.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669639f6f2174d328d675b2d",
        //             "username": "user-533",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user533",
        //             "lname": "533user",
        //             "corporate_email": "corporateuser-533@mailinator.com",
        //             "email": "user-533@mailinator.com",
        //             "gender": "male",
        //             "profile_image": "img-21.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669637c4f2174d328d67515c",
        //             "username": "user-254",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user254",
        //             "lname": "254user",
        //             "corporate_email": "corporateuser-254@mailinator.com",
        //             "email": "user-254@mailinator.com",
        //             "gender": "male",
        //             "profile_image": "img-12.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         },
        //         {
        //             "_id": "669636cdf2174d328d674d10",
        //             "username": "user-132",
        //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
        //             "fname": "user132",
        //             "lname": "132user",
        //             "corporate_email": "corporateuser-132@gmail.com",
        //             "email": "user-132@gmail.com",
        //             "gender": "male",
        //             "profile_image": "img-25.jfif",
        //             "sports": [
        //                 "bowling"
        //             ],
        //             "role": "participant",
        //             "isVerified": true,
        //             "__v": 0
        //         }
        //     ]
        // }
    ]);
    const [todayMatches, setTodayMatches] = useState(  [  
    //     {
    //     "_id": "66961f5ac94d5b1dba733dea",
    //     "group_id": "66961f56c94d5b1dba733ca4",
    //     "tournament_id": "66961f54c94d5b1dba733b9e",
    //     "round": "round_1",
    //     "match_date": "2024-07-17T03:30:00.000Z",
    //     "submited_at": "2024-07-19T08:49:03.456Z",
    //     "players": [
    //         {
    //             "_id": "669636cdf2174d328d674d10",
    //             "points": 4,
    //             "strikes": 5,
    //             "spares": 6
    //         },
    //         {
    //             "_id": "669637c4f2174d328d67515c",
    //             "points": 0,
    //             "strikes": 0,
    //             "spares": 0
    //         },
    //         {
    //             "_id": "66963826f2174d328d675315",
    //             "points": 0,
    //             "strikes": 0,
    //             "spares": 0
    //         },
    //         {
    //             "_id": "66963981f2174d328d675923",
    //             "points": 0,
    //             "strikes": 0,
    //             "spares": 0
    //         },
    //         {
    //             "_id": "669639daf2174d328d675aac",
    //             "points": 0,
    //             "strikes": 0,
    //             "spares": 0
    //         },
    //         {
    //             "_id": "669639f6f2174d328d675b2d",
    //             "points": 0,
    //             "strikes": 0,
    //             "spares": 0
    //         }
    //     ],
    //     "gender": "male",
    //     "status": "completed",
    //     "rank_1": "669636cdf2174d328d674d10",
    //     "rank_2": "669637c4f2174d328d67515c",
    //     "__v": 0,
    //     "createdAt": "2024-07-16T07:20:58.857Z",
    //     "updatedAt": "2024-07-19T08:49:03.462Z",
    //     "player_details": [
    //         {
    //             "_id": "66963826f2174d328d675315",
    //             "username": "user-303",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user303",
    //             "lname": "303user",
    //             "corporate_email": "corporateuser-303@gmail.com",
    //             "email": "user-303@gmail.com",
    //             "gender": "male",
    //             "profile_image": "img-7.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         },
    //         {
    //             "_id": "66963981f2174d328d675923",
    //             "username": "user-475",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user475",
    //             "lname": "475user",
    //             "corporate_email": "corporateuser-475@gmail.com",
    //             "email": "user-475@gmail.com",
    //             "gender": "male",
    //             "profile_image": "img-17.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         },
    //         {
    //             "_id": "669639daf2174d328d675aac",
    //             "username": "user-519",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user519",
    //             "lname": "519user",
    //             "corporate_email": "corporateuser-519@gmail.com",
    //             "email": "user-519@gmail.com",
    //             "gender": "male",
    //             "profile_image": "img-7.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         },
    //         {
    //             "_id": "669639f6f2174d328d675b2d",
    //             "username": "user-533",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user533",
    //             "lname": "533user",
    //             "corporate_email": "corporateuser-533@mailinator.com",
    //             "email": "user-533@mailinator.com",
    //             "gender": "male",
    //             "profile_image": "img-21.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         },
    //         {
    //             "_id": "669637c4f2174d328d67515c",
    //             "username": "user-254",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user254",
    //             "lname": "254user",
    //             "corporate_email": "corporateuser-254@mailinator.com",
    //             "email": "user-254@mailinator.com",
    //             "gender": "male",
    //             "profile_image": "img-12.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         },
    //         {
    //             "_id": "669636cdf2174d328d674d10",
    //             "username": "user-132",
    //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
    //             "fname": "user132",
    //             "lname": "132user",
    //             "corporate_email": "corporateuser-132@gmail.com",
    //             "email": "user-132@gmail.com",
    //             "gender": "male",
    //             "profile_image": "img-25.jfif",
    //             "sports": [
    //                 "bowling"
    //             ],
    //             "role": "participant",
    //             "isVerified": true,
    //             "__v": 0
    //         }
    //     ]
    // }
]);
    const [selfstandings, setSelfStandings] = useState([]);
    const [groupStandings, setGroupStandings] = useState(
        [
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "669636cdf2174d328d674d10",
            //         "points": 4,
            //         "strikes": 5,
            //         "spares": 6
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "669636cdf2174d328d674d10",
            //             "username": "user-132",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user132",
            //             "lname": "132user",
            //             "corporate_email": "corporateuser-132@gmail.com",
            //             "email": "user-132@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-25.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // },
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "669637c4f2174d328d67515c",
            //         "points": 0,
            //         "strikes": 0,
            //         "spares": 0
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "669637c4f2174d328d67515c",
            //             "username": "user-254",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user254",
            //             "lname": "254user",
            //             "corporate_email": "corporateuser-254@mailinator.com",
            //             "email": "user-254@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-12.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // },
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "66963826f2174d328d675315",
            //         "points": 0,
            //         "strikes": 0,
            //         "spares": 0
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "66963826f2174d328d675315",
            //             "username": "user-303",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user303",
            //             "lname": "303user",
            //             "corporate_email": "corporateuser-303@gmail.com",
            //             "email": "user-303@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-7.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // },
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "66963981f2174d328d675923",
            //         "points": 0,
            //         "strikes": 0,
            //         "spares": 0
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "66963981f2174d328d675923",
            //             "username": "user-475",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user475",
            //             "lname": "475user",
            //             "corporate_email": "corporateuser-475@gmail.com",
            //             "email": "user-475@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-17.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // },
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "669639daf2174d328d675aac",
            //         "points": 0,
            //         "strikes": 0,
            //         "spares": 0
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "669639daf2174d328d675aac",
            //             "username": "user-519",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user519",
            //             "lname": "519user",
            //             "corporate_email": "corporateuser-519@gmail.com",
            //             "email": "user-519@gmail.com",
            //             "gender": "male",
            //             "profile_image": "img-7.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // },
            // {
            //     "_id": "66961f5ac94d5b1dba733dea",
            //     "group_id": "66961f56c94d5b1dba733ca4",
            //     "tournament_id": "66961f54c94d5b1dba733b9e",
            //     "round": "round_1",
            //     "match_date": "2024-07-17T03:30:00.000Z",
            //     "submited_at": "2024-07-19T08:49:03.456Z",
            //     "players": {
            //         "_id": "669639f6f2174d328d675b2d",
            //         "points": 0,
            //         "strikes": 0,
            //         "spares": 0
            //     },
            //     "gender": "male",
            //     "status": "completed",
            //     "rank_1": "669636cdf2174d328d674d10",
            //     "rank_2": "669637c4f2174d328d67515c",
            //     "__v": 0,
            //     "createdAt": "2024-07-16T07:20:58.857Z",
            //     "updatedAt": "2024-07-19T08:49:03.462Z",
            //     "player_id": [
            //         {
            //             "_id": "669639f6f2174d328d675b2d",
            //             "username": "user-533",
            //             "password": "$2b$10$eCqLP8bdSuWKXbTh6tQRa.O2ZuBCEkxipVRvy71J6otYdSK0ChbWK",
            //             "fname": "user533",
            //             "lname": "533user",
            //             "corporate_email": "corporateuser-533@mailinator.com",
            //             "email": "user-533@mailinator.com",
            //             "gender": "male",
            //             "profile_image": "img-21.jfif",
            //             "sports": [
            //                 "bowling"
            //             ],
            //             "role": "participant",
            //             "isVerified": true,
            //             "__v": 0
            //         }
            //     ]
            // }
        ]

    );
    const [group, setGroups] = useState([]);
    const [ownGroup,setOwnGroup] = useState([]);
    const [selectedGroup , setSelectedGroup] = useState('')
    const [selectedRound , setRound] = useState('')

    const [game, setGame] = useState('');
    const [TournamentWinner, setTournamentWinner] = useState([]);
    const [state , setstate] = useState(true);
    let [isfinal , setisfinal] = useState(false);
    // console.log(selectedGroup , "seletedgroup");

    useEffect(()=>{
    // setloaderStatus(true);
        let token = localStorage.getItem("token");
        if(!token) {
            navigate('/login');
        } else {
            let callAPI = async () => {
				let res =  await getPlayerSports();
                if (res.status && res.data.length === 0) {
                   navigate('/select-sport');
                }
			}
            let getProfile = async () => {
                let res =  await Profile();
                console.log("profile",res.data)
                if (res.status ) {
                    setProfile(res.data);

                }
            }

          
			callAPI()  
            getProfile();
        }
    }, [])

    useEffect(()=>{
        console.log("yes");
        let callAPI = async () => {
            if (SelectedTournament != '' && game != '') {
                let formData = {
                    "game" : game,
                    "tournament_id" : SelectedTournament
                }
                let res =  await getMatches(formData);
                if (res.data.status) {
                    setLatestMatch(res.data.data.latest_match);
                    setCompletedMatches(res.data.data.completed);
                    setUpcomingMatches(res.data.data.upcoming_match);
                    setTodayMatches(res.data.data.today_match);
                }

            }
        }
        let callAPI2 = async ()  => {
            if (game != '' && SelectedTournament!='') {
                
                let res = await GetStandings(game , SelectedTournament);
                console.log(res.status)
                if (res.status) {
                    setSelfStandings(res.data)
                }  else {
                    setSelfStandings([])

                }

            }
        }
        let callAPI3 = async () => {
            if (SelectedTournament != '' && game != '') {
                let FormData = {
                    "tournamentId":SelectedTournament,
                    "game_type":game,
                }
                let res = await GetGroups (FormData);
                if (res.data.status) {
                    setGroups(res.data.data.other_group);
                    setOwnGroup(res.data.data.own_group);
                    if(res.data.data.own_group!=null){
                    setSelectedGroup(res.data.data.own_group.id);}
                    else 
                    {
                        setSelectedGroup(res.data.data.other_group[0].id);
                    }
                }
             }
        }
        
        let callAPI4 = async () => {
            if (SelectedTournament != '' && game != '') {
                let FormData = {
                    "tournamentId":SelectedTournament,
                    "game_type":game,
                    
                }
                let res = await Winner (FormData);
                console.log("winnEr")
                console.log(res)
                if (res.data.status) {
                    setTournamentWinner(res.data.data)
                } 
             }
        }
        let callAPI5 = async ()  => {
            if (game != '' && SelectedTournament!='') {
                
                let res = await isfinaldone(game , SelectedTournament);
                console.log(res.status)
                if (res.status) {
                    console.log("reesssss" , res.isfinal);
                    setisfinal(res.isfinal);
                }  else {
                    setSelfStandings([])

                }

            }
        }
        let getCorporateStandings = async () => {
            if (SelectedTournament != '') {
                let FormData = {
                    "tournament_id":SelectedTournament,
                }

                let res = await FetchAllPoints(FormData)
                if (res.data.status) {
                    setloaderStatus(false)
                    const CorporateListData = Object.keys(res.data.corporatestanding).map(key => ({
                      name: key,
                      badminton: res.data.corporatestanding[key].badminton,
                      table_tennis: res.data.corporatestanding[key].table_tenis,
                      bowling:res.data.corporatestanding[key].bowling,
                      twokrun:res.data.corporatestanding[key].twokrun,
                      total: res.data.corporatestanding[key].total
                  }));
                    setCorporateList(CorporateListData)
                    setTopPerformer(res.data.data)
                  }
            }
            
        }
        callAPI()
        callAPI2()
        callAPI3()
        callAPI4()
        callAPI5()
        setstate(!state);
        getCorporateStandings()

    }, [SelectedTournament, game])

    useEffect(()=>{
        let callAPI2 = async() => {
            
            let FormData = {
                'group_id' : selectedGroup!=''? selectedGroup: ownGroup
            }
         
            let res = await GetGroupStandings(FormData);
            if (res.status) {
                setGroupStandings(res.data.data);
                 console.log("group ressss",res.data.data);
            }
            else {
                // setGroupStandings([]); 
            }
        };
        if (  selectedGroup != '') {
             callAPI2();
        }
    }, [completedMatches, selectedGroup , game ])
    

    useEffect(() => {
        let GetRoundGroups = async () => {
            if (SelectedTournament != '' && game != '') {
                let FormData = {
                    "tournamentId":SelectedTournament,
                    "game_type":game,
                    "round" : selectedRound !== '' ? selectedRound: null
                }
                let res = await GetGroups (FormData);
                if (res.data.status) {
                    setGroups(res.data.data.other_group);
                    setOwnGroup(res.data.data.own_group);
                    if(res.data.data.own_group!=null){
                    setSelectedGroup(res.data.data.own_group.id);}
                    else 
                    {
                        setSelectedGroup(res.data.data.other_group[0].id);
                    }
                }
             }
        }

        GetRoundGroups()
    }, [selectedRound])
    let handelChange = (value) => {
        console.log("asdlasd" + value);
        setSelectedTournament(value)
    }
    let handelGroups = (value) => {
        console.log("Grouop" + value);
        setSelectedGroup(value)
    }
    let handelRound = (value) => {
        console.log("round" + value);
        setRound(value)
    }
    let handelGameChage = (value) => {
        console.log("game changed");

        setGame(value)
    }
    let tournamentName = (value) => {
        setTournamentName(value)
        console.log("Tourament" + value);

    }
    return (    
        <>
         {loaderStatus ? <Loader /> : null}
        <Row className="mt-3 align-items-center ">
            <Col className="fs-4 fw-bold statistics_header darkSvg">
            
                    {
                    game !== '' ? (
                        game === 'TableTennis' ? (
                        <>
                            <p>{TournamentName} <TableTennis /></p>
                        </>
                        ) : game === 'Badminton' ? (
                        <>
                            <p>{TournamentName} <Badminton /></p>
                        </>
                        ) : game === 'bowling' ? (
                        <>
                            <p>{TournamentName} <Bowling/></p>
                        </>
                        ) : (
                        <>
                            <p>{TournamentName} <Run /></p>
                        </>
                        )
                    ) : (
                        <>
                        <p>{TournamentName} <Badminton /></p>
                        </>
                    )
                    }

            </Col>
        </Row>
        <CorporateStandings  onValueChange={handelChange} gameType= {handelGameChage} TournamentID={SelectedTournament}  TournamentName= {tournamentName} corporate={profile} CorporateList={CorporateList}/>
        <UpcommingSlider TodayMatch= {todayMatches}/>
        <LatestMatch latestMatch = {latestMatch} gameType= {handelGameChage} gametypes = {game}/>
        <Standing_tabs onValueChange = {handelGroups}  onRoundChange= {handelRound} completed={completedMatches} selfstandings={selfstandings} groupStandings={groupStandings}  upcoming = {upcomingMatches} TournamentName={TournamentName} groups={group} ownGroup={ownGroup} TournamentWinner={TournamentWinner} showwinner={isfinal} gameType= {handelGameChage}  gametypes = {game} TopPerformer={TopPerformer}/>
        </>
    )
}
export default Home;