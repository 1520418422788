import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="21"
      fill="none"
      viewBox="0 0 17 21"
    >
      <path
        fill="#fff"
        d="M.167 10.5a1.042 1.042 0 001.041 1.041h7.906L6.72 13.927a1.041 1.041 0 000 1.479 1.04 1.04 0 001.479 0l4.166-4.167c.095-.099.17-.216.22-.344a1.042 1.042 0 000-.791 1.041 1.041 0 00-.22-.344L8.198 5.593a1.046 1.046 0 10-1.48 1.48l2.396 2.385H1.208A1.042 1.042 0 00.167 10.5zM13.708.083H3.292A3.125 3.125 0 00.167 3.208v3.125a1.042 1.042 0 002.083 0V3.208a1.042 1.042 0 011.042-1.042h10.416a1.042 1.042 0 011.042 1.042v14.583a1.041 1.041 0 01-1.042 1.042H3.292a1.041 1.041 0 01-1.042-1.042v-3.125a1.042 1.042 0 00-2.083 0v3.125a3.125 3.125 0 003.125 3.125h10.416a3.125 3.125 0 003.125-3.125V3.208A3.125 3.125 0 0013.708.083z"
      ></path>
    </svg>
  );
}

export default Icon;