import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      fill="none"
      viewBox="0 0 24 26"
    >
      <path
        fillRule="evenodd"
        stroke="#DBDE22"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.5 11.376c0-1.496-1.12-2.709-2.5-2.709S9.5 9.88 9.5 11.376c0 1.495 1.12 2.708 2.5 2.708s2.5-1.213 2.5-2.708z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#DBDE22"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 22.75c-1.199 0-7.5-5.527-7.5-11.306C4.5 6.919 7.857 3.25 12 3.25c4.142 0 7.5 3.669 7.5 8.194 0 5.78-6.302 11.306-7.5 11.306z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;