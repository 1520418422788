import React, { useRef, useState } from 'react';
import CameraSVGIcon from '../../components/icons/CameraIcon';
import profile from '../../assets/images/user.png';
import { useSnackbar } from 'notistack';
import {uploadImage} from '../../API/player';

const ImageUpload = () => {
  const [previewSrc, setPreviewSrc] = useState(profile);
  const [file, setFile] = useState();
  const fileInputRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }

    const fileUpload = new FormData();
    fileUpload.append('file',event.target.files[0]);
    let res = await uploadImage(fileUpload);
    if (res.status) {
      enqueueSnackbar('Profile photo has been uploaded', { variant: 'success' });
    } else {
      enqueueSnackbar('Unable to update profle photo', { variant: 'error' });
    }
  };

  return (
    <div className='d-flex justify-content-center '>
      <img 
        className='image--cover'
        src={previewSrc}
        alt="Click to upload"
        // style={{ cursor: 'pointer', maxWidth: '30%', maxHeight: 'auto' }}
        onClick={handleImageClick}
        key={previewSrc}
      />

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        id="photo" name="photo"
        onChange={handleFileChange}
      />

    </div>
  );
};

export default ImageUpload;
