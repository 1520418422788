import Database  from './model'
const {Get,Post, Image} = Database();
export const GetMatches = async (game,currentTab) => {
    let response = await Post({url:'scorer/get_matches', bodyData:{game:game,currentTab:currentTab}});
    return response;
}
export const SetMatchResult = async(FormData) => {
    let response = await Post({url:'scorer/set_match_result', bodyData:FormData});
    return response;
}

export const UpdateStatus   = async(FormData) => {
    let response = await Post({url:'scorer/update_status', bodyData:FormData});
    return response;
}