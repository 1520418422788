import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="38"
      fill="none"
      viewBox="0 0 35 38"
    >
      <path
        fill="#fff"
        d="M30.147 17.839a1.366 1.366 0 01-1.367-1.366v-3.859c0-5.465-5.05-9.838-11.258-9.838-6.209 0-11.259 4.427-11.259 9.838v3.858a1.367 1.367 0 01-2.732 0v-3.858C3.53 5.662 9.816 0 17.52 0c7.707 0 13.992 5.662 13.992 12.614v3.858a1.355 1.355 0 01-1.366 1.367z"
      ></path>
      <path
        fill="#fff"
        d="M26.452 30.693a1.366 1.366 0 01-1.366-1.366V16.352a1.366 1.366 0 011.366-1.323c4.711 0 8.548 3.53 8.548 7.86 0 4.328-3.837 7.804-8.548 7.804zM27.82 17.86v9.958a5.313 5.313 0 004.437-4.973 5.312 5.312 0 00-4.437-4.985zM8.548 30.693C3.837 30.693 0 27.173 0 22.845c0-4.329 3.837-7.816 8.548-7.816a1.366 1.366 0 011.366 1.367v12.93a1.367 1.367 0 01-1.366 1.367zM7.18 17.86a5.312 5.312 0 00-4.437 4.985 5.312 5.312 0 004.437 4.973V17.86z"
      ></path>
      <path
        fill="#fff"
        d="M26.004 35.142H21.48a1.367 1.367 0 010-2.733h4.525a2.667 2.667 0 002.667-2.667v-.601a1.366 1.366 0 112.733 0v.6a5.4 5.4 0 01-5.4 5.4z"
      ></path>
      <path
        fill="#fff"
        d="M19.14 37.71h-2.7a3.739 3.739 0 01-3.739-3.738v-.153a3.738 3.738 0 013.739-3.727h2.7a3.727 3.727 0 013.727 3.727v.153a3.738 3.738 0 01-3.727 3.739zm-2.678-4.886a1.005 1.005 0 00-1.006.995v.153a1.006 1.006 0 001.006 1.006h2.678a1.006 1.006 0 00.994-1.006v-.153a.994.994 0 00-.994-.995h-2.678z"
      ></path>
    </svg>
  );
}

export default Icon;