import React, { Suspense } from 'react'
import {Outlet} from 'react-router-dom';
import { AppSidebar, AppFooter, AppHeader } from '../components/index';
import { CContainer, CSpinner } from '@coreui/react';

const AdminLayout = () => {
 	return (
		<div>
			<AppSidebar />
			<div className="wrapper d-flex flex-column min-vh-100">
				<AppHeader />
				<div className="body flex-grow-1">
					<CContainer className="px-4" lg>
						<Suspense fallback={<CSpinner color="primary" />}>
							<Outlet/>
						</Suspense>
					</CContainer>
				</div>
				<AppFooter />
			</div>
		</div>

  	);
};

export default AdminLayout;
