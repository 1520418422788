import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import { enqueueSnackbar } from 'notistack';
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CImage, CModalFooter, CModalTitle, CModalBody, CModal, CModalHeader
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilGroup
} from '@coreui/icons'
import { getpdfs , deletepdfs} from '../../../API/admin';
import { FormatDate } from '../../../Common/CommonFunction';
import Loader from '../Common/Loader';

const Documents = () => {
    const [pdf, setpdf] = useState([]);
    const [deletepdfid , setdeletepdfid] = useState('');
    // const [loaderStatus, setLoaderStatus] = useState(true);
    const PDF_URL = process.env.REACT_APP_PDF_MY_ENVIRONMENT_VARIABLE;
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
    const [loaderStatus, setLoaderStatus] = useState(true);
    const [visible, setVisible] = useState(false)
    console.log(PDF_URL, "pdff");
    useEffect(() => {
        const callAPI = async () => {
            let res = await getpdfs();
            if (res.status) {
                setLoaderStatus(false);
                enqueueSnackbar('Successfully fetched Pdf', { variant: 'success' });
                console.log(res);
                setpdf(res.data);
                setLoaderStatus(false);
            }
            else {
                setLoaderStatus(false);
                enqueueSnackbar('Something error happened', { variant: 'error' });
                setLoaderStatus(false);
            }
        }
        callAPI();


    }, [])
    function handleview(pdfurl) {
        window.open(PDF_URL + pdfurl, '_blank');
    }
    console.log(deletepdfid , "deleted")
    function handledelete(){
        const callAPI = async () => {
            let res = await deletepdfs(deletepdfid);
            if (res.status) {
                window.location.reload();
                enqueueSnackbar('Successfully deleted pdf', { variant: 'success' });
              
            }
            else {
                window.location.reload();
                enqueueSnackbar('Something error happened', { variant: 'error' });
                // setLoaderStatus(false);
            }
        }
        callAPI();
    }


    return (
        <>
            {loaderStatus && <Loader />}
            <CCard>
                <CCardBody>
                    <CRow>
                        <CCol sm={4}>
                            <h3 className="card-title mb-3">
                                Documents
                            </h3>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm={3} className="d-none d-md-block">
                            <input type="search" className='form-control' placeholder="Search" name='data' />
                        </CCol>
                        <CCol sm={9}>
                            <Link to={'/documents/create'}>
                                <CButton className='float-end text-white' color="info">
                                    <i className="bi bi-plus-circle me-2"></i>
                                    Add Document
                                </CButton>
                            </Link>
                        </CCol>
                    </CRow><br />
                    {/* <MainChart /> */}
                    <CRow
                        xs={{ cols: 1, gutter: 4 }}
                        sm={{ cols: 2 }}
                        lg={{ cols: 4 }}
                        xl={{ cols: 5 }}
                        className="mb-2 text-center"
                    >
                        <CTable striped>
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Document Name</CTableHeaderCell>
                                    {/* <CTableHeaderCell scope="col">Sport</CTableHeaderCell> */}
                                    <CTableHeaderCell scope="col">Document type</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Created by</CTableHeaderCell>
                                    <CTableHeaderCell scope="col">Date Created</CTableHeaderCell>
                                    {/* <CTableHeaderCell scope="col">Location</CTableHeaderCell> */}
                                    <CTableHeaderCell scope="col-2">Action</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {
                                    pdf.length > 0 ? (
                                        <>
                                            {
                                                pdf.map((pdfs, index) => {
                                                    // const isLastElement = index === Tournaments.length - 1;
                                                    return (
                                                        <CTableRow key={pdfs._id}>
                                                            <CTableDataCell>{index + 1}</CTableDataCell>
                                                            <CTableDataCell>{pdfs.document_name}</CTableDataCell>
                                                            <CTableDataCell>
                                                        
                                                            {
                                                                pdfs.document_type === 'waviear'
                                                                ? 'Waviear Document'
                                                                : pdfs.document_type === 'privacy'
                                                                ? 'Privacy & Policy'
                                                                : pdfs.document_type === 't&c'
                                                                ? 'T&C'
                                                                : 'N/A'
                                                            }

                                                            </CTableDataCell>
                                                            {/* <CTableDataCell>
                                                {
                                                    tournament.sport_type.map((sport, index) => (
                                                        sport === 'badminton' ? (
                                                            <CImage className='svg-icon' src={badminton} width={200} height={200} />
                                                        ) : sport === 'bowling' ? (
                                                            <CImage className='svg-icon' src={bowling} width={200} height={200} />
                                                        ) : sport === 'table_tenis' ? (
                                                            <CImage className='svg-icon' src={table_tenis} width={200} height={200} />        
                                                        ) : sport === '2k_run' ? (
                                                            <CImage className='svg-icon' src={run} width={200} height={200} />            
                                                        ) : null
                                                    )
                                                )}
                                            </CTableDataCell> */}
                                                            <CTableDataCell>{pdfs.admin[0].fname + ' ' + pdfs.admin[0].lname}</CTableDataCell>
                                                            <CTableDataCell>{FormatDate(pdfs.createdAt, 0, 0, 1)}</CTableDataCell>
                                                            <CTableDataCell>

                                                                <CButton className='bi bi-eye-fill text-white' color="primary" onClick={() => { handleview(pdfs.filename) }}></CButton>
                                                                &nbsp;&nbsp;


                                                                <CButton title='Delete' className='bi bi-trash-fill text-white mx-2' color="danger" onClick={() => {setdeletepdfid(pdfs._id);setVisible(!visible)}} >
                                                                </CButton>

                                                            </CTableDataCell>
                                                        </CTableRow>
                                                    );
                                                })
                                            }

                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan="6">No Tournament Found</td>
                                            </tr>
                                        </>
                                    )
                                }


                            </CTableBody>
                        </CTable>
                    </CRow>
                </CCardBody>
            </CCard>
            <CModal
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader>
                    <CModalTitle id="LiveDemoExampleLabel">Delete Document</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Are you sure?</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => {setVisible(false); setdeletepdfid('')}}>
                        Close
                    </CButton>
                    <CButton color="primary" onClick={handledelete}>Delete</CButton>
                </CModalFooter>
            </CModal>
        </>
    )



}

export default Documents;