// export const getData=()=>fetch('http://localhost/player/home').then(res=>res.json())
import Database  from './model'
const {Get,Post, Image} = Database();

export const Profile = async() => {
    let response = await Get({url:'auth/profile'});
   return response;
}

export const CurrentTournament = async() => {
    let response = await Post({url:'player/current/tournament', bodyData:{'player_id':''}});
   return response;
}
export const updateUser = async(FormData) => {
    let response = await Post({url:'player/update_user', bodyData:FormData});
   return response;
}

export const uploadImage = async(FormData) => {
    let response = await Image({url:'player/upload_image', bodyData:FormData});
    return response;
}
export const getPlayerSports = async() => {
    let response = await Get({url:'player/get_player_sports'});
   return response;
}

export const updateSport = async(FormData) => {
    let response = await Post({url:'player/sport_selection', bodyData:FormData});
    return response;
}

export const enrolledUser = async() => {
    let response = await Get({url:'player/enrolled_user'});
    return response;
}

export const GetStandings = async(game,SelectedTournament) => {
    let response = await Get({url:`player/standings?game=${game}&tournament_id=${SelectedTournament}`});
    return response;
}
export const isfinaldone = async(game,SelectedTournament) => {
    let response = await Get({url:`player/isfinaldone?game=${game}&tournament_id=${SelectedTournament}`});
    return response;
}
export const GetGroupStandings = async(FormData) => {
    let response = await Post({url:'player/standings/group', bodyData:FormData});
    return response;
}
export const initiatePayment = async(FormData) => {
    let response = await Post({url:'player/initiate_payment', bodyData:FormData});
    return response;
}

export const GenrateMatch = async(FormData) =>{
    let response = await Post({url:'player/generate/match', bodyData:FormData});
    return response;
}

export const getMatches = async(FormData)  => {
    let response = await Post({url:'player/playermatches', bodyData:FormData});
    return response;
}
export const FetchCorporats = async () =>{
    let  response = await Get({ url: `player/fetchcorporate` })

    return response
}


export const FetchAllPoints = async(FormData)  => {
    let response = await Post({url:'player/corporatestanding', bodyData:FormData});
    return response;
}

export const GetGroups = async(FormData)  => {
    let response = await Post({url:'player/group_list', bodyData:FormData});
    return response;
}
export const EnrolledTournamentAPI  = async(FormData)  => {
    let response = await Post({url:'player/enrolled_tournament', bodyData:FormData});
    return response;
}
export const Winner  = async(FormData)  => {
    let response = await Post({url:'player/winner', bodyData:FormData});
    return response;
}

export const avalibleSlotsAPI  = async(FormData)  => {
    let response = await Post({url:'player/available/slots', bodyData:{gender:true}});
    return response;
}


export const ChangePasswordAPI  = async(FormData)  => {
    let response = await Post({url:'auth/change-password', bodyData:{old_password: FormData.old_password, new_password: FormData.new_password}});
    return response;
}

export const enrolledWaiting  = async(FormData)  => {
    let response = await Post({url:'player/enroll_waitlist', bodyData:FormData});
    return response;
}


export const getPlayerSportsLatest  = async(FormData)  => {
    let response = await Post({url:'player/get_player_sports_latest', bodyData:{FormData}});
    return response;
}

export const withdrawal  = async(FormData)  => {
    let response = await Post({url:'player/withdrawal', bodyData:FormData});
    return response;
}



export const RemoveWaitlist  = async(FormData)  => {
    let response = await Post({url:'player/remove_waitlist', bodyData:FormData});
    return response;
}

export const WaitlistPlayersAPI  = async(FormData)  => {
    let response = await Post({url:'player/waitlist_player', bodyData:FormData});
    return response;
}