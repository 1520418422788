import React, { useState } from 'react';
// import { FaPaperPlane } from 'react-icons/fa';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you would typically send the data to a server
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <>
  {/* Bootstrap CSS */}
  <link
    href="https://stackpath.bootstrapcdn.com/bootstrap/5.1.3/css/bootstrap.min.css"
    rel="stylesheet"
  />
  <div className="min-vh-100 d-flex align-items-center justify-content-center bg-light">
    <div className="card shadow-lg w-100" style={{ maxWidth: "28rem" }}>
      <div className="card-body">
        <h2 className="card-title text-center mb-4">Feedback Form</h2>
        <form onsubmit="handleSubmit(event)">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              defaultValue=""
              oninput="handleChange(event)"
              required=""
              className="form-control"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              defaultValue=""
              oninput="handleChange(event)"
              required=""
              className="form-control"
              placeholder="your.email@example.com"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Feedback Message
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              value=""
              oninput="handleChange(event)"
              required=""
              className="form-control"
              placeholder="Your feedback here..."
              defaultValue={""}
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit Feedback
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  {/* Bootstrap JS (optional) */}
</>

  );
};

export default FeedbackForm;