import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import {CreateCorporate as CreateCorporateAPI, EditCorporate} from  '../../../../API/admin'
import { useNavigate} from"react-router-dom"
import { useSnackbar } from 'notistack';
import {
    CCard,
    CCardBody,
  } from '@coreui/react'

const CreateCorporates = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Corporates', to: '/corporate', active: false },
        { label: 'Edit', to: '/tournament/create', active: true }
    ];

    useEffect(()=> {
        const urlParams = new URLSearchParams(window.location.search);
        var urlid = {
            data : urlParams.get('id')
        }
        let callAPI = async() => {
            let res = await EditCorporate(urlid);
            if (res.status) {
                setFormData(res.data);
            }
        }
        if(urlid) {
            callAPI();
        }   
    }, [])
    
    const [formData, setFormData] = useState({
        name: '',
        domain: '',
        id:''
    });

    const [errors, setErrors] = useState({
        name: '',
        domain: '',
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });

        setErrors({
            ...errors,
            [name]: ''
        });
    };
    
    const HandelSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};
        if (formData.name.trim() === '') {
            newErrors.name = 'Name is required';
        }

        if (formData.domain.trim() === '') {
            newErrors.domain = 'Domain is required';
        } else if (!isValidDomain(formData.domain)) {
            newErrors.domain = 'Invalid domain format';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            let res = await CreateCorporateAPI(formData)
            if (res.status) {
                enqueueSnackbar('Data saved successfully', { variant: 'success' });
                navigate('/corporate');
            } else {
                enqueueSnackbar(res.data.message, { variant: 'error' });
            } 
            
        }
    };

    const isValidDomain = (domain) => {
        return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);
    };
    
    
    return (
        <>
        <h4 className="card-title mb-3 align-items-center">
            Create a Corporate
        </h4>
        <AdminBreadcrumb links={adminBreadcrumbLinks} />  
        <CCard className="mb-4">
        <CCardBody>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <form onSubmit={HandelSubmit}>
                        <div className="mb-3">
                            <label className="form-label"> Name </label>
                            <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="form-control" placeholder='Enter Name' />                                 
                            {errors.name && <span className="error text-danger">{errors.name}</span>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label"> Domain </label>
                            <input type="text" name="domain" value={formData.domain} onChange={handleInputChange} className="form-control" placeholder='Enter Domain (ex:- corporate.com)' />                                 
                            {errors.domain && <span className="error text-danger">{errors.domain}</span>}
                        </div>
                        <button type="submit" className="btn btn-secondary ms-3 float-end" onClick={()=> window.history.back()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary float-end">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </CCardBody>
        </CCard>
        </>
    )
}

export default CreateCorporates