import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      fill="none"
      viewBox="0 0 24 26"
    >
      <path
        stroke="#F4F821"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.608 9.972h20.794M17.183 14.527h.01M12.005 14.527h.011M6.818 14.527h.01M17.183 19.062h.01M12.005 19.062h.011M6.818 19.062h.01M16.718 1.333v3.84M7.293 1.333v3.84"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#F4F821"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.945 3.176H7.066C3.64 3.176 1.5 5.084 1.5 8.593V19.15c0 3.563 2.14 5.516 5.566 5.516h9.868c3.437 0 5.566-1.92 5.566-5.428V8.593c.01-3.509-2.118-5.417-5.555-5.417z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;