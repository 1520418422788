import React, { useEffect } from "react";
import {useNavigate} from"react-router-dom"
import store, { clearAllState } from '../../store';
function Logout () {
    let navigate = useNavigate();
    useEffect(()=>{
        localStorage.clear();
        store.dispatch(clearAllState());
        navigate('/login');
    }, [])
    return true;
}
export default Logout