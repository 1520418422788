import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      fill="none"
      viewBox="0 0 22 24"
    >
      <path
        fill="#fff"
        d="M5.755 3.698h8.827a.346.346 0 100-.694H5.754a.347.347 0 100 .694zm0 4.16h8.827a.346.346 0 100-.694H5.754a.347.347 0 100 .693zm0-2.08h8.827a.346.346 0 100-.694H5.754a.347.347 0 100 .693zm15.94-1.313l-.49-.49a1.04 1.04 0 00-1.47 0l-1.617 1.617V1.733A1.735 1.735 0 0016.385 0H3.952a1.735 1.735 0 00-1.733 1.733v18.002H.347a.347.347 0 00-.347.347v1.849a1.735 1.735 0 001.733 1.733h14.698c.012 0 .025 0 .037-.002a1.735 1.735 0 001.65-1.731V9.514l3.578-3.578a1.041 1.041 0 000-1.47zM1.734 22.971a1.04 1.04 0 01-1.04-1.04v-1.502h13.82v1.433c0 .402.135.793.383 1.109H1.733zm15.692-2.893v1.784a1.11 1.11 0 01-2.219 0v-1.78a.347.347 0 00-.347-.347l-.023.002-.023-.002H2.912V1.733a1.041 1.041 0 011.04-1.04h12.433a1.041 1.041 0 011.04 1.04v4.552L11 12.71H5.755a.346.346 0 100 .693h4.552L8.95 14.76a.354.354 0 00-.026.029v.001h-3.17a.347.347 0 100 .693H8.67l-.462 1.387H5.754a.347.347 0 000 .693h2.589c.025 0 .05-.003.074-.008a.337.337 0 00.152-.015l2.206-.735c.003 0 .005-.002.007-.003a.352.352 0 00.06-.027l.01-.006a.356.356 0 00.022-.016l.008-.006a.342.342 0 00.029-.026l1.238-1.238h2.433a.347.347 0 000-.693h-1.74l4.583-4.583v9.87zm-8.078-4.431l.677.678-1.016.338.339-1.016zm1.319.339l-.98-.98 8.33-8.331.001-.002.906-.906.98.98-9.237 9.239zm10.54-10.54l-.812.812-.98-.98.811-.813a.347.347 0 01.49 0l.49.49a.347.347 0 010 .49z"
      ></path>
    </svg>
  );
}

export default Icon;