import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {DeleteUser as DeleteUserAPI} from  '../../../../API/admin'
const DeleteUser = () => {
    const Navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
    useEffect(()=>{
        let callAPI = async ()=> {
            const urlParams = new URLSearchParams(window.location.search);
            const urlid = urlParams.get('id');
            let userID = {
                id : urlid
            }
            console.log(userID);

            let res = await DeleteUserAPI(userID);  
            if (res.status) {
                enqueueSnackbar("User Deleted successfully", { variant: 'success' });
                Navigate('/users')
            } else {
                enqueueSnackbar("Can not delete user", { variant: 'error' });
                Navigate('/users')
            }
        }
            callAPI();
    }, [])
    return (
        <>
        </>
    )
}

export default DeleteUser;