import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'

import avatar1 from '../../assets/images/avatars/1.jpg'
import avatar2 from '../../assets/images/avatars/2.jpg'
import avatar3 from '../../assets/images/avatars/3.jpg'
import avatar4 from '../../assets/images/avatars/4.jpg'
import avatar5 from '../../assets/images/avatars/5.jpg'
import avatar6 from '../../assets/images/avatars/6.jpg'

import WidgetsBrand from './widgets/WidgetsBrand'
import WidgetsDropdown from './widgets/WidgetsDropdown'
// import MainChart from './MainChart'
import {GetTodayMatches} from '../../API/admin'
const Dashboard = () => {
  const [matches, setMatches] = useState([]);
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE;
  useEffect(()=> {
    const callApi = async () => {
      let res = await GetTodayMatches();
      if (res.status) {
        const statusOrder = {
          live: 1,
          pending: 2,
          completed: 3,
        };
        if (res.data.length) {

          const sortedMatches = res.data.sort((a, b) => {
            return statusOrder[a.status] - statusOrder[b.status];
          });
            setMatches(sortedMatches);
        }

      } else {
        // show error
      }
    };

  callApi();
  const intervalId = setInterval(callApi, 30000);

  // Clean up the interval when the component unmounts
  return () => clearInterval(intervalId);
  }, [])

 let replaceUnderscoreWithSpace = (input) => {
    if (input.includes('_')) {
      return input.replace(/_/g, ' ');
    }
    return input;
  }
  return (
    <>
      <WidgetsDropdown className="mb-4" />
      <CCard> 
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h3 className="card-title mb-3">
                            Today matches
                        </h3>
                    </CCol>
                </CRow>
                {/* <MainChart /> */}
                <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CTable striped>
                    <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">Round</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Group</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Sport</CTableHeaderCell>

                        <CTableHeaderCell scope="col" colSpan={2}>Player 1 </CTableHeaderCell>
                        {/* <CTableHeaderCell scope="col">Sport</CTableHeaderCell> */}
                        <CTableHeaderCell scope="col">Points</CTableHeaderCell>
                        <CTableHeaderCell scope="col" colSpan={2}>Player 2</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Scorer</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Status</CTableHeaderCell>


                        
                    </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {
                        matches.length > 0 ? (
                          matches.map((match, index) => (
                            <CTableRow>
                                <CTableDataCell 
                                  key={index}>
                                  <span> {match.round === 'group_match' ? 'Group Match': 'Knockout Match'} </span>
                                </CTableDataCell>
                                <CTableDataCell>
                                <span> {match.round === 'group_match' ?  match.group_id.name : replaceUnderscoreWithSpace(match.round)} </span>

                                </CTableDataCell>
                                <CTableDataCell>
                                  <span>{match.game_type}</span>
                                </CTableDataCell>
                                <CTableDataCell 
                                   key={index}>
                                    <img  src={ 
                                    +match.player_1_id.profile_image != undefined ? 
                                    IMAGE_URL+"user/"+match.player_1_id.profile_image : IMAGE_URL+"blank_user.png"
                                  } className='group-table-img float-end' alt="blank_user"
                                 />
                                </CTableDataCell>

                                <CTableDataCell>
                                  <span className='float-start'>{match.player_1_id.fname + ' ' + match.player_1_id.lname}</span>
                               
                                 </CTableDataCell>

                                 <CTableDataCell>
                                    <span className=' fs-5'>{match.player_1_score}</span>
                                    <span>{' '+':'+' '}</span>
                                    <span className=' fs-5'>{match.player_2_score}</span>
                                 </CTableDataCell>

                                   <CTableDataCell 
                                    key={index}>
                                    <span className='float-end'>{match.player_2_id.fname + ' ' + match.player_2_id.lname}</span>
                                 </CTableDataCell> 

                                 <CTableDataCell>
                                      <img src={ 
                                        match.player_2_id.profile_image != undefined ? 
                                        IMAGE_URL+"user/"+match.player_2_id.profile_image : IMAGE_URL+"blank_user.png"} className='group-table-img float-start' alt="blank_user" />
                                  </CTableDataCell> 

                                  <CTableDataCell  
                                    key={index}> {match.scorer_id != null || undefined ?  match.scorer_id.fname + " " + match.scorer_id.lname : 'N/A'}
                                  </CTableDataCell>  

                                  <CTableDataCell  
                                    key={index}> {
                                      match.status === 'live' ? (
                                        <>
                                          <span className='badge bg-danger px-3 text-white'>Live</span><br />
                                        </>
                                      ) : match.status === 'pending' ?(
                                          <>
                                             <span className='badge bg-info text-white'>Yet to Start</span>
                                          </>
                                      ): (
                                        <>
                                          <p className='badge bg-success text-white' style={{ color: 'limegreen' }}>Completed</p>
                                        </>
                                      )
                                    }
                                  </CTableDataCell>
                          </CTableRow>
                          ))
                         
                        ): (<>
                         <tr>
                            <td colSpan="12">No data found</td>
                        </tr>
                        </>)
                      }
                     
                    </CTableBody>
                </CTable>
                </CRow>
                </CCardBody>
      </CCard>
    </>
  )
}

export default Dashboard
