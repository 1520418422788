import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import { FormatDate } from '../../../../Common/CommonFunction';
import Loader from '../../Common/Loader';

import badminton from '../../../../assets/images/games/badminton.svg'
import bowling from '../../../../assets/images/games/bowling.svg'
import table_tenis from '../../../../assets/images/games/table_tenis.svg'
import run from '../../../../assets/images/games/run.svg'
    
import {GetTournaments, DeleteTournament, AvailableSlots} from  '../../../../API/admin'
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilGroup
} from '@coreui/icons'

const Tournaments = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Tournaments', to: '/tournaments', active: true }
    ];

    const [loaderStatus, setloaderStatus] = useState(true);
    const [deleteTournament, setDeleteTournament] = useState(false);


    const [Tournaments, setTournaments] = useState([]);
	const Navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
    
    let  getTournamentsAPI = async() =>{
        let res = await GetTournaments()
        if (res.status) {
            
            setTournaments(res.data);
        } else {
            setTournaments([]);
        } 
        setTimeout(() => {
            setloaderStatus(false);
        }, 2000);
    }
    let AvialableSlotsAPI = async() => {
        let res = await AvailableSlots();
        if (res.data.status) {
            console.log(res.data.TotalResult);
           if ( res.data.TotalResult.male.badminton == 256 && res.data.TotalResult.male.table_tenis == 256 && res.data.TotalResult.female.badminton == 256 && res.data.TotalResult.female.table_tenis == 256 ) {
            setDeleteTournament(true);
           } else (
            setDeleteTournament(false)
           )
        } 
    }
    const HandelDelete =async(urlid) => {
        let res = await Swal.fire({
              title: "Are you sure?",
              text: "Are you sure , you want to delete this tournament?",
              icon: "warning" });
              if (res.isConfirmed) {
                  let callAPI = async ()=> {
                      let tournamentID = {
                          id : urlid
                      }
                      let res = await DeleteTournament(tournamentID);  
                      if (res.status) {
                          enqueueSnackbar("Tournament Deleted successfully", { variant: 'success' });
                          getTournamentsAPI()   
                      } else {
                          enqueueSnackbar("Can not delete tournament", { variant: 'error' });
                          Navigate('/tournaments')
                      }
                  }
                  callAPI();
              }
    }

    const HandelChange = (event) => {
        let callAPI = async() => {
            let res =  await GetTournaments(search)
            if(res.status) {
                setTournaments(res.data);
            } else {
                enqueueSnackbar("No Record Found ", { variant: 'error' });
                Navigate('/tournaments');
            }
        }
        let search = {
            data: event.target.value
        }
        callAPI(search);
    }

    useEffect( () => {
        getTournamentsAPI();
        AvialableSlotsAPI();
    }, [])

    return (
        <>
            {loaderStatus ? 
                <Loader />
            : null}
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            <CCard>
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h3 className="card-title mb-3">
                            Tournaments
                        </h3>
                    </CCol>
                </CRow>
                <CRow>    
                    <CCol sm={3} className="d-none d-md-block">
                        <input type="search" className='form-control' placeholder="Search" name='data' onChange={HandelChange}/>
                    </CCol>
                    <CCol sm={9}>
                        <Link to={'/tournament/create'}>
                            <CButton className='float-end text-white' color="info">
                            <i className="bi bi-plus-circle me-2"></i>
                                Add Tournament
                                </CButton>
                        </Link>
                    </CCol>
                </CRow><br />
                {/* <MainChart /> */}
                <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CTable striped>
                    <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        {/* <CTableHeaderCell scope="col">Sport</CTableHeaderCell> */}
                        <CTableHeaderCell scope="col">Starts From</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Ends At</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Location</CTableHeaderCell>
                        <CTableHeaderCell scope="col-2">Action</CTableHeaderCell>
                    </CTableRow>
                    </CTableHead>
                    <CTableBody> 
                        {
                            Tournaments.length > 0 ? (
                                <>
                                {
                                Tournaments.map((tournament, index) => {
                                    const isLastElement = index === Tournaments.length - 1;
                                    return (
                                        <CTableRow key={tournament._id}>
                                            <CTableDataCell>{index + 1}</CTableDataCell>
                                            <CTableDataCell>{tournament.name}</CTableDataCell>
                                            {/* <CTableDataCell>
                                                {
                                                    tournament.sport_type.map((sport, index) => (
                                                        sport === 'badminton' ? (
                                                            <CImage className='svg-icon' src={badminton} width={200} height={200} />
                                                        ) : sport === 'bowling' ? (
                                                            <CImage className='svg-icon' src={bowling} width={200} height={200} />
                                                        ) : sport === 'table_tenis' ? (
                                                            <CImage className='svg-icon' src={table_tenis} width={200} height={200} />        
                                                        ) : sport === '2k_run' ? (
                                                            <CImage className='svg-icon' src={run} width={200} height={200} />            
                                                        ) : null
                                                    )
                                                )}
                                            </CTableDataCell> */}
                                            <CTableDataCell>{FormatDate(tournament.start_date ,0 ,0 ,1)}</CTableDataCell>
                                            <CTableDataCell>{FormatDate(tournament.end_date ,0 ,0 ,1)}</CTableDataCell>
                                            <CTableDataCell>{tournament.location}</CTableDataCell>
                                            <CTableDataCell>
                                                <Link to={`/groups?id=${tournament._id}`}>
                                                    <CButton title='Edit' className='text-white' color="primary">
                                                        <CIcon icon={cilGroup} />
                                                    </CButton>
                                                </Link>&nbsp;&nbsp;
                                                <Link to={`/tournament/edit?id=${tournament._id}`}>
                                                    <CButton title='Edit' className='bi bi-pencil text-white' color="warning"></CButton>
                                                </Link>
                                                {isLastElement && deleteTournament && (
                                                    <CButton title='Delete' className='bi bi-trash-fill text-white mx-2' color="danger" onClick={() => HandelDelete(tournament._id)}>
                                                    </CButton>
                                                )}
                                            </CTableDataCell>
                                        </CTableRow>
                                    );
                                    })
                                }
                                </>
                            ) : ( 
                                <>
                                    <tr>
                                        <td colSpan="6">No Tournament Found</td>
                                    </tr>
                                </>
                            )
                        }
                   

                    </CTableBody>
                </CTable>
                </CRow>
                </CCardBody>
            </CCard>
        </>
    )
}

export default Tournaments;