import React from 'react';

const ArrowIcon = () => {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.266071 13.796C0.560617 14.069 1.03698 14.069 1.3308 13.796L7.55843 8.01116C7.69784 7.88364 7.80884 7.73017 7.88469 7.56007C7.96054 7.38998 7.99965 7.20681 7.99965 7.02171C7.99965 6.83661 7.96054 6.65344 7.88469 6.48334C7.80884 6.31324 7.69784 6.15977 7.55843 6.03226L1.28571 0.204756C1.14335 0.0745194 0.954821 0.00131774 0.758418 1.76182e-05C0.562016 -0.0012825 0.372462 0.0694164 0.228253 0.197756C0.156814 0.261134 0.0996541 0.337953 0.0603118 0.423457C0.0209695 0.50896 0.000282825 0.601326 -0.000467132 0.694837C-0.00121709 0.788347 0.0179856 0.88101 0.0559519 0.967087C0.0939181 1.05316 0.14984 1.13082 0.220253 1.19526L5.96134 6.52716C6.0311 6.59092 6.08665 6.66768 6.12461 6.75276C6.16257 6.83784 6.18215 6.92946 6.18215 7.02206C6.18215 7.11465 6.16257 7.20628 6.12461 7.29136C6.08665 7.37644 6.0311 7.4532 5.96134 7.51696L0.266071 12.8069C0.196333 12.8706 0.140797 12.9473 0.102847 13.0323C0.0648958 13.1173 0.0453243 13.2089 0.0453243 13.3014C0.0453243 13.3939 0.0648958 13.4855 0.102847 13.5705C0.140797 13.6556 0.196333 13.7323 0.266071 13.796Z" fill="white"/>
    </svg>
  );
};

export default ArrowIcon;
