import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      fill="none"
      viewBox="0 0 800 800"
    >
      <path
        fill="#fff"
        d="M565.15 439.975C615.325 464.225 650 515.525 650 575v162.5c0 6.9-5.6 12.5-12.5 12.5h-475c-6.9 0-12.5-5.6-12.5-12.5V575c0-59.475 34.675-110.775 84.85-135.025 5.375-2.6 11.9-.675 15.4 4.15L375 615.625v-82.9l-23.325-39.45C346.9 485.2 352.725 475 362.1 475h75.825c9.375 0 15.2 10.2 10.425 18.275L425 532.725v82.9L549.725 444.1c3.525-4.8 10.05-6.725 15.425-4.125zM625 275c0 39.55-10.425 78.475-30.125 112.525-4.45 7.725-12.7 12.475-21.625 12.475h-70.475c-6.875 7.625-16.7 12.5-27.775 12.5-20.7 0-37.5-16.8-37.5-37.5s16.8-37.5 37.5-37.5c11.075 0 20.9 4.875 27.775 12.5h55.375c11.075-23.3 16.85-48.95 16.85-75 0-96.5-78.5-175-175-175-79.9 0-149.575 53.95-169.475 131.225-1.025 3.925-5.525 22.25-5.525 22.25V322.3c0 13.825-11.2 25.125-25 25.125-28.5 0-50-20.4-50-47.45v-50c0-23.15 15.825-42.7 37.225-48.35C218.2 111.525 303.275 50 400 50c124.05 0 225 100.95 225 225z"
      ></path>
      <path
        fill="#fff"
        d="M550 275c0 10.05-1.025 20.125-3 29.95-2.725 13.55-15.95 22.35-29.45 19.55-13.55-2.75-22.3-15.95-19.55-29.475 1.325-6.55 2-13.3 2-20.025 0-9.8-1.85-19.075-4.5-28.025-31.025-6.3-50.05-22.85-60.325-45.25C417.65 230.55 386.225 250 350 250h-46.45c-2.1 8.05-3.55 16.325-3.55 25 0 47.375 33.625 88.6 79.975 98.025 13.525 2.725 22.275 15.95 19.525 29.475-2.725 13.45-15.875 22.275-29.475 19.5C300.475 407.875 250 346.05 250 275c0-82.725 67.275-150 150-150s150 67.275 150 150z"
      ></path>
    </svg>
  );
}

export default Icon;