import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import { FormatDate } from '../../../../Common/CommonFunction';
import {CreateTournament as CreateTournamentAPI, EditTournament} from  '../../../../API/admin'
import { useSnackbar } from 'notistack';
import {useNavigate} from"react-router-dom"
import {
    CCard,
    CCardBody,
    CFormCheck,
} from '@coreui/react'

const CreateTournament = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Tournaments', to: '/tournaments', active: false },
        { label: 'Edit', to: '/tournament/create', active: true }
    ];

    useEffect(()=> {
        const urlParams = new URLSearchParams(window.location.search);
        var urlid = {
            data : urlParams.get('id')
        }
        let callAPI = async() => {
            let res = await EditTournament(urlid);
            if (res.status) {
                res.data.start_date = FormatDate(res.data.start_date,1);
                res.data.end_date = FormatDate(res.data.end_date,1);
                setFormData(res.data);
            }
        }
        if(urlid) {
            callAPI();
        }   
    }, [])

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
    const [FormData ,setFormData] = useState({
		name:'',
		start_date:'',
		end_date :'',
		location :'',
		sport_type:['badminton', 'bowling', 'table_tenis', '2k_run'],
        status:'upcoming',
        id:''
	})
    
    const getCurrentDate = (event) => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        event.target.min = `${yyyy}-${mm}-${dd}`
    };
      
    const HandelChange = (event) => {
    	const { name, value } = event.target;
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                setFormData(prevState => ({
                    ...FormData,
                    sport_type: [...prevState.sport_type, name]
                }));
            } else {
                setFormData(prevState => ({
                    ...FormData,
                    sport_type: prevState.sport_type.filter(item => item !== name)
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
 
    }
    

    const HandelSubmit = async (event) => {
        event.preventDefault()
        let res = await CreateTournamentAPI(FormData)
        if (res.status) {
            enqueueSnackbar('Data saved successfully', { variant: 'success' });
            navigate('/tournaments');
        } else {
            enqueueSnackbar(res.data.message, { variant: 'error' });
        }   
    }
    
    return (
        <>
        <h4 className="card-title mb-3 align-items-center">
            Create a Tournament
        </h4>
        <AdminBreadcrumb links={adminBreadcrumbLinks} />    

        <CCard className="mb-4">
        <CCardBody>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <form onSubmit={HandelSubmit}>
                        <div className="mb-3">
                                <label className="form-label">
                                    Name
                                    </label>
                                    <input
                                    type="text"
                                    name="name"
                                    className="form-control  "
                                    placeholder='Enter Name'
                                    pattern=".*\S.*"
                                    onChange={HandelChange}
                                    value={FormData.name}
                                    required
                                    /> 
                                
                        </div>
                        <div className='row mb-2'>
                            <div className="col-6">
                                <label htmlFor="exampleInputEmail1" className="form-label">
                                Start Date
                                </label>
                                <input
                                className="form-control"
                                name='start_date'
                                type="date"
                                onClick={getCurrentDate}
                                onChange={HandelChange}
                                value={FormData.start_date}
                                placeholder='Enter Start Date'
                                required
                                />

                                <input type="hidden" value={FormData.id} name='id' />
                            </div>
                            <div className="col-6">
                                <label htmlFor="exampleInputEmail1" className="form-label">
                                End Date
                                </label>
                                <input
                                type="date"
                                min={getCurrentDate}
                                className="form-control  "
                                name='end_date'
                                placeholder='Enter End Date'
                                onClick={getCurrentDate}
                                onChange={HandelChange}
                                value={FormData.end_date}
                                required
                                />
                            
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                            Location
                            </label>
                            <input
                            type="text"
                            className="form-control  "
                            name='location'
                            placeholder='Enter Location'
                            onChange={HandelChange}
                            value={FormData.location}
                            required
                            />
                            
                        </div>
                        {/* <div className="mb-3">
                            <label className='mb-2 form-label' htmlFor="gender">Category</label>
                            <select
                                name='gender'
                                className='mb-4 form-control   form-select'
                                onChange={HandelChange}
                                value={FormData.gender}
                                required
                            >
                                <option  value="" className='form-control' disabled>
                                ---select a category ---
                                </option>
                                <option  value='male'>Male</option>
                                <option  value='female'>Female</option>
                            </select>
                        </div> */}
                        
                        <div className='mb-5' style={{display:'none'}}>
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Select a Sport   
                            </label>
                            <CFormCheck  checked={FormData.sport_type.includes('badminton')} onChange={HandelChange} name="badminton" label="Badminton" />
                            <CFormCheck  checked={FormData.sport_type.includes('bowling')} onChange={HandelChange} name="bowling" label="Bowling" />
                            <CFormCheck  checked={FormData.sport_type.includes('table_tenis')} onChange={HandelChange} name="table_tenis" label="Table Tennis" />
                            <CFormCheck  checked={FormData.sport_type.includes('2k_run')} onChange={HandelChange} name="2k_run" label="2K Run" />

                        </div>
                        <button type="submit" className="btn btn-secondary ms-3 float-end" onClick={()=> window.history.back()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary float-end">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </CCardBody>
        </CCard>
        </>
    )
}

export default CreateTournament