import React, { useEffect, useState } from 'react'
import {
  Button,
  DropdownButton,
  Dropdown,
  Table,
  Row,
  Col,
  Card,
  Nav,
  Tab
} from 'react-bootstrap'
import CompleteMatchBg from '../../assets/images/complete-match-bg.png'
import Melbourne_Knight from '../../assets/images/teams/Melbourne_Knight.png'
import Sydney_thunder from '../../assets/images/teams/Sydney_thunder.png'
import cardBg from '../../assets/images/complete-match-bg.png'
import notFound from '../../assets/images/avatars/notfound1.png'
import { GetStandings, GetGroupStandings } from '../../API/player'
import { CButton, CCard, CCardBody, CRow } from '@coreui/react'
import Calander from '../../components/icons/calander'
import Location from '../../components/icons/location'
import WinnerCupIcon from '../../components/icons/WinnerCupIcon'
import Badminton from '../../components/icons/Batminton'
import jc from '../../assets/images/teams/jaipur_capitals.png'
import cv from '../../assets/images/teams/cv.png'
import CupIcon from '../../assets/images/CupIcon.png'
import Clock from '../../components/icons/Clock'
import golden from '../../assets/images/games/bronzer.svg'
import silver from '../../assets/images/games/silver.svg'
import bronze from '../../assets/images/games/golden.svg'

import trophy from '../../assets/trophy1.png'

import '../../assets/css/Player/Standing_tabs.css'
import {
  FormatDate,
  getTime,
  newFormatdate,
  format_2k_run_Time
} from '../../../src/Common/CommonFunction'
import { CFormSelect } from '@coreui/react'

const StandingsTab = ({
  onValueChange,
  onRoundChange,
  completed,
  upcoming,
  TournamentName,
  groupStandings,
  selfstandings,
  groups,
  ownGroup,
  TournamentWinner,
  showwinner,
  gametypes,
  TopPerformer
}) => {
  console.log('self standing', upcoming)
  console.log('group standing', completed)
  console.log('top performer' , TopPerformer);
  let [newstate, setnewstate] = useState(true)
  let rank
  console.log(showwinner, 'showwinner')
  console.log('self', selfstandings)
  if (groupStandings.length >= 4 && selfstandings.length >= 1) {
    console.log(selfstandings[0]._id)
    const index = groupStandings.findIndex(
      element => element.player_id._id === selfstandings[0].player_id
    )
    if (index != -1) rank = index + 1
  }
  const [completedMatch, setCompletedMatch] = useState([])
  const [standings, setStandings] = useState([])
  const [GroupStandings, setGroupStandings] = useState([])
  const [SelectedGroup, setSelectedGroup] = useState('')
  const [SelectedRound, setSelectedRound] = useState('')

  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE
  const CompleteMatchStyle = {
    backgroundImage: 'url(' + CompleteMatchBg + ')',
    backgroundSize: '3rem',
    backgroundRepeat: 'no-repeat'
  }

  const Statistics_Points = {
    backgroundImage: 'url(' + CupIcon + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 3rem)'
  }
  const [ScoreList, setScoreList] = useState([])
  const [StatisticsPointList, setStatisticsPointList] = useState([])
  const [WinnerPointList, setWinnerPointList] = useState([])
  const [StandingsPointList, setStandingsPointList] = useState([])
  const [topData, setTopData] = useState([])
  const [game, setGame] = useState(null)
  var categories = '' ;
  var gameData='';

  // useEffect(()=>{
  //     let callAPI2 = async() => {
  //         console.log("calling pi")
  //         let FormData = {
  //             'group_id' : completed[0].group_id             }

  //         let res = await GetGroupStandings(FormData);
  //         if (res.status) {
  //             console.log("sduaskd")
  //             console.log(res.data);

  //             setStandingsPointList(res.data.data);

  //         }
  //     }
  //     if ( completed.length > 0 && completed[0].group_id !== undefined) {
  //          callAPI2();
  //     }
  // }, [completed])
  useEffect(() => {
    console.log(gametypes)
    console.log(TopPerformer)
    setGame(gametypes)
  }, [gametypes])
  function extractdomain (inputString, startChar, endChar) {
    const startIndex = inputString.indexOf(startChar)
    let j = -1
    for (let i = startIndex + 1; i < inputString.length; i++) {
      if (inputString[i] == '.') {
        j = i
        break
      }
    }
    let domain = inputString.substring(startIndex + 1, j)
    console.log(domain)
    return domain
  }

  let handleChange = event => {
    console.log('Group changes', event.target.value)
    const selectedId = event.target.value
    setSelectedGroup(selectedId)
    onValueChange(selectedId)
  }

  let handleRound = (event) => {
    console.log('round changes', event.target)
    const selected_round = event.target.value
    setSelectedRound(selected_round)
    onRoundChange(selected_round)
  }
  let replaceUnderscoreWithSpace = (input) => {
    console.log(input)
    if (input !==undefined) {
      if (input.includes('_')) {
        return input.replace(/_/g, ' ')
      }
      return input

    }
  }

  const getGame = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let game = searchParams.get('game')
    if(game == null) return 'badminton'
    return game
  }

  const playerScore = (players, player_id) => {
    console.log(players.find(player => player._id === player_id))
    return players.find(player => player._id === player_id)
  }
  console.log('ccccc', getGame())
  function objectToKeyValueArray(obj) {
   console.log(obj , "object");
    return Object.entries(obj).map(([key, value]) => {
      return { key, value };
    });
  }
  
  // Convert nested object to key-value array
  console.log(game , "gammeee");
useEffect (() => {
  if (TopPerformer !== null) {

    let topperformerr = [];
    if(game == 'Badminton')
    {
      topperformerr = objectToKeyValueArray(TopPerformer['badminton']);
    }
    else if(game == 'TableTennis')
    {
      topperformerr = objectToKeyValueArray(TopPerformer['table_tenis']);
    }
    else if(game == 'bowling')
    {
      topperformerr = objectToKeyValueArray(TopPerformer['bowling']);
    } 
    else if(game == '2k_run')
    {
      topperformerr = objectToKeyValueArray(TopPerformer['twokrun']);
    }

    console.log(topperformerr  ,"toppppp")
    setTopData(topperformerr)
  }

}, [game, TopPerformer])


  return (
    <div className='standing_tabs'>
      <Row className='mb-5'>
        <Col>
          <Tab.Container defaultActiveKey='#schedule'>
          <Nav fill variant='tabs' className='border-bottom-0 m-auto d-flex flex-nowrap' style={{width:"unset"}}>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#schedule'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark mb-5'
        >
            Schedule
        </Nav.Link>
    </Nav.Item>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#score'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark'
        >
            Score
        </Nav.Link>
    </Nav.Item>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#statistics'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark'
        >
            Statistics
        </Nav.Link>
    </Nav.Item>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#standings'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark'
        >
            Standings
        </Nav.Link>
    </Nav.Item>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#winner'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark'
        >
            Winner
        </Nav.Link>
    </Nav.Item>
    <Nav.Item className='flex-grow-4'>
        <Nav.Link
            eventKey='#top_performer'
            className='bg-transparent nav-link border-0 fw-bold fs-5 text-dark'
        >
            Top Performer
        </Nav.Link>
    </Nav.Item>
</Nav>


              

            <Tab.Content className='p-3 text-white mymatchcard'>
              <Tab.Pane eventKey='#schedule'>
                {upcoming.length > 0 ? (
                  game === 'Badminton' || game === 'TableTennis' ? (
                    upcoming.map((match, index) => (
                      <>
                        <Card
                          className='rounded-4 overflow-hidden complete-nav-card border-0 mb-4'
                          style={CompleteMatchStyle}
                        >
                          <Card.Body className='p-0'>
                            <Row className='text-white mb-5'>
                              {/* <Col xs={3} className="nav-active-tab d-flex align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 col-3"><span className="text-center w-100 davidcup-span">David Cup</span></Col> */}

                              <Col sm={4} xl={4} md={4}>
                                <p
                                  className='nav-active-tab align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 '
                                  style={{ background: 'red', width: '79%' }}
                                >
                                  <span className='text-center w-100 davidcup-span'>
                                    {replaceUnderscoreWithSpace(match.round)}
                                  </span>
                                </p>
                              </Col>
                              <Col sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-date float-end'
                                  style={{
                                    color: '#f4f821',
                                    textAlign: 'center'
                                  }}
                                >
                                  {newFormatdate(match.match_date, 1)}
                                </p>
                              </Col>
                            </Row>

                            <Row className='align-items-center text-white my-4'>
                              {/* <div className="text-center">
                                                                    <span class="badge badge-success fw-bold fs-5 bg-warning border-rounded"> 8 May 2024 on 08:00PM</span>
                                                                </div> */}

                              <Row
                                className='align-items-center'
                                style={{ marginLeft: '5px' }}
                              >
                                <Col
                                  xs={4}
                                  className='d-flex justify-content-center align-items-center'
                                >
                                  <div className='ps-4 d-flex align-items-center schedule-img'>
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_details[0]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_details[0]
                                                .profile_image
                                            : null
                                        })`,
                                        backgroundPosition: 'center'
                                      }}
                                      className='scorer-img'
                                    ></div>
                                    <div>
                                      <span className='ms-3 team-name mymatchcard'>
                                        {match.player_details[0].fname +
                                          ' ' +
                                          '' +
                                          match.player_details[0].lname} 
                                      </span>
                                      <br></br>

                                      <span
                                        className='ms-3'
                                        style={{ color: '#2ed5d5' }}
                                      >
                                         
                                        {extractdomain(
                                          match.player_details[0]
                                            .corporate_email,
                                          '@',
                                          '.'
                                        )} 
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={4} className='text-center'>
                                  <div>
                                    {/* <span className="vs-sign text-danger fw-bold fs-5"</span> */}
                                    {/* <br></br> */}
                                    <span className='vs-sign text-danger fw-bold fs-5'>
                                      V/S
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  xs={4}
                                  className='d-flex justify-content-center align-items-center'
                                >
                                  <div className='ps-4 d-flex align-items-center teamlogo schedule-img'>
                                    <div>
                                      <span className='me-3 team-name mymatchcard'>
                                        {match.player_details[1].fname +
                                          ' ' +
                                          '' +
                                          match.player_details[1].lname} 
                                      </span>
                                      <br></br>

                                      <span
                                        className='me-3'
                                        style={{ color: '#2ed5d5' }}
                                      >
                                         
                                        {extractdomain(
                                          match.player_details[1]
                                            .corporate_email,
                                          '@',
                                          '.'
                                        )} 
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_details[1]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_details[1]
                                                .profile_image
                                            : null
                                        })`,
                                        backgroundPosition: 'center'
                                      }}
                                      className='scorer-img'
                                      // className="team_logo"
                                    ></div>
                                  </div>
                                </Col>
                              </Row>
                            </Row>
                            <Row className='align-items-center text-white mb-5'>
                              <Col md={3} className='text-center m-auto'>
                                {/* <div>
                                                                        <span className="team-name  fw-bold text-warning">Venue</span><br />
                                                                        <span className="team-name  fw-bold">Sydney Badminton Ground</span>
                                                                    </div> */}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </>
                    ))
                  ) : game === 'bowling' ? (
                    upcoming.map((match, index) => (
                      <>
                        <Card
                          className='rounded-4 overflow-hidden complete-nav-card border-0 mb-4'
                          style={CompleteMatchStyle}
                        >
                          <Card.Body className='p-0'>
                            <Row className='text-white mb-5'>
                              {/* <Col xs={3} className="nav-active-tab d-flex align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 col-3"><span className="text-center w-100 davidcup-span">David Cup</span></Col> */}

                              <Col sm={4} xl={4} md={4}>
                                <p
                                  className='nav-active-tab align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 '
                                  style={{ background: 'red', width: '79%' }}
                                >
                                  <span className='text-center w-100 davidcup-span'>
                                    {replaceUnderscoreWithSpace(match.round)}
                                  </span>
                                </p>
                              </Col>
                              <Col sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-date'
                                  style={{
                                    color: '#f4f821',
                                    textAlign: 'center'
                                  }}
                                >
                                  {newFormatdate(match.match_date, 1)}
                                </p>
                              </Col>
                            </Row>

                            <Row className='align-items-start text-white my-4'>
                              {/* <div className="text-center">
                                                                    <span class="badge badge-success fw-bold fs-5 bg-warning border-rounded"> 8 May 2024 on 08:00PM</span>
                                                                </div> */}

                              <Row
                                className='align-items-center'
                                style={{ marginLeft: '5px' }}
                              >
                                <Col
                                  xs={12}
                                  className='d-flex justify-content-center'
                                >
                                  <div className='ps-4 d-flex  schedule-img  align-items-center'>
                                    {match.player_details.map(
                                      (player, playIndex) => (
                                        <React.Fragment key={playIndex}>
                                          <div className='text-center'>
                                            <div
                                              style={{
                                                backgroundImage: `url(${
                                                  player.profile_image !==
                                                  undefined
                                                    ? IMAGE_URL +
                                                      'user/' +
                                                      player.profile_image
                                                    : null
                                                })`,
                                                backgroundPosition: 'center',
                                                width: '80px',
                                                height: '80px'
                                              }}
                                              className='scorer-img my-1 mx-auto'
                                            ></div>
                                            <div>
                                              <span className=' team-name mymatchcard'>
                                                {player.fname +
                                                  ' ' +
                                                  '' +
                                                  player.lname} 
                                              </span>
                                              <br></br>

                                              <span
                                                className=''
                                                style={{ color: '#2ed5d5' }}
                                              >
                                                 
                                                {extractdomain(
                                                  player.corporate_email,
                                                  '@',
                                                  '.'
                                                )} 
                                              </span>
                                            </div>
                                          </div>
                                          {playIndex !==
                                            match.player_details.length - 1 && (
                                            <div className=''>
                                              <span className='vs-sign text-danger fw-bold fs-5'>
                                                V/S
                                              </span>
                                            </div>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                    {/* <div>

                                                                                <span className="ms-3 team-name mymatchcard">{match.player_details[0].fname + ' ' + '' + match.player_details[0].lname} </span>
                                                                                <br></br>

                                                                                <span className="ms-3" style={{ color: '#2ed5d5' }}> {extractdomain(match.player_details[0].corporate_email, '@', '.')} </span>
                                                                            </div> */}
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row className="align-items-center text-white mb-5">
                                                                    <Col md={12} className="d-flex justify-content-center align-items-center">
                                                                        {
                                                                            match.player_details.map((player, playerIndex) => (
                                                                                <>  
                                                                                    <div>
                                                                                        <span className="ms-3 team-name mymatchcard">{player.fname + ' ' + '' + player.lname} </span>
                                                                                        <br></br>

                                                                                        <span className="ms-3" style={{color:'#2ed5d5'}}> {extractdomain(player.corporate_email , '@' , '.')} </span>
                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        }
                                                                        
                                                                    </Col>
                                                                </Row> */}
                            </Row>
                            <Row className='align-items-center text-white mb-5'>
                              <Col md={3} className='text-center m-auto'>
                                {/* <div>
                                                                        <span className="team-name  fw-bold text-warning">Venue</span><br />
                                                                        <span className="team-name  fw-bold">Sydney Badminton Ground</span>
                                                                    </div> */}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </>
                    ))
                  ) : upcoming.map((match, index) => (
                        <>
                          <Card
                          className='rounded-4 overflow-hidden complete-nav-card border-0 mb-4'
                          style={CompleteMatchStyle}
                        >
                            <Card.Body className='p-0'>   
                              <Row className='text-white mb-5'>
                                {/* <Col xs={3} className="nav-active-tab d-flex align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 col-3"><span className="text-center w-100 davidcup-span">David Cup</span></Col> */}

                                <Col sm={4} xl={4} md={4}>
                                  <p
                                    className='nav-active-tab align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 '
                                    style={{ background: 'red', width: '79%' }}
                                  >
                                    <span className='text-center w-100 davidcup-span'>
                                      {replaceUnderscoreWithSpace(match.round)}
                                    </span>
                                  </p>
                                </Col>
                                <Col sm={4} xl={4} md={4}>
                                  <p
                                    className='scorer-date'
                                    style={{
                                      color: '#f4f821',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {newFormatdate(match.match_date, 1)}
                                  </p>
                                </Col>
                              </Row>
                              <Row className='text-white mb-5'>
                                  <Col xl={11}>
                                  <div className='custom-scrollbar' style={{ maxHeight: '350px', overflowX: 'hidden' }}>

                                    <Row>
                                    {match.player_details.map(
                                      (player, playIndex) => (
                                      <Col  className='mb-3'>
                                        <React.Fragment key={playIndex}>
                                      <div className='ps-4 d-flex  schedule-img  align-items-center'>
                                          <div className='text-center'>
                                            <div
                                              style={{
                                                backgroundImage: `url(${
                                                  player.profile_image !==
                                                  undefined
                                                    ? IMAGE_URL +
                                                      'user/' +
                                                      player.profile_image
                                                    : null
                                                })`,
                                                backgroundPosition: 'center',
                                                width: '80px',
                                                height: '80px'
                                              }}
                                              className='scorer-img '
                                            ></div>
                                            <div>
                                              <span className=' team-name mymatchcard'>
                                                {player.fname +
                                                  ' ' +
                                                  '' +
                                                  player.lname} 
                                              </span>
                                              <br></br>

                                              <span
                                                className=''
                                                style={{ color: '#2ed5d5' }}
                                              >
                                                 
                                                {extractdomain(
                                                  player.corporate_email,
                                                  '@',
                                                  '.'
                                                )} 
                                              </span>
                                            </div>
                                          </div>
                                         
                                   
                                        </div>
                                        </React.Fragment>
                                      </Col>
                                      )
                                    )}
                                    </Row>
                                  </div>
                                  </Col>
                                  <Col xl={1}>
                                  
                                  </Col>
                              </Row>


                            </Card.Body>
                          </Card>
                        </>
                      ))
                ) : (
                  <>
                    <CCard
                      style={{
                        position: 'relative',
                        background: '#2A2729',
                        borderRadius: '15px'
                      }}
                    >
                      <div
                        className='card-background'
                        style={{ backgroundImage: `url(${cardBg})` }}
                      />
                      <CCardBody
                        className='d-flex justify-content-center align-items-center'
                        style={{
                          padding: '0px',
                          color: '#fff',
                          height: '200px'
                        }}
                      >
                        <div>
                          <div
                            style={{
                              backgroundImage: `url(${notFound})`,
                              border: 'none'
                            }}
                            className='scorer-card-logo bg-image-container2  ms-4'
                          ></div>
                          <span
                            style={{
                              color: 'white',
                              fontSize: '20px',
                              fontWeight: '700'
                            }}
                          >
                            No matches found
                          </span>
                        </div>
                      </CCardBody>
                    </CCard>
                  </>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey='#score'>
                {completed.length > 0 ? (
                  game === 'Badminton' || game === 'TableTennis' ? (
                    completed.map((match, index) => (
                      <>
                        <Row className='mb-5'>
                          <Col>
                            <Card className='border-radius25px overflow-hidden complete-nav-card border-0 text-white score-card'>
                              <Card.Body className='p-0'>
                                <div className='text-white px-3 text-white px-4 py-2 fs-5 fw-bold score_heading'>
                                  {replaceUnderscoreWithSpace(match.round)}
                                </div>

                                <Row className='ps-5 mb-3' id='match-row'>
                                  <Col
                                    xs={7}
                                    className='tab-contebt-right-border px-5 col-md-8'
                                    id='matchscore'
                                  >
                                    <div className='mt-3 d-flex align-items-center'>
                                      {match.player_won!=null && match.player_details[0]._id!=null && match.player_won.toString() ==
                                      match.player_details[0]._id.toString() ? (
                                        <>
                                          <img
                                            src={trophy}
                                            alt=''
                                            className='trophy-img-score'
                                          />
                                        </>
                                      ) : null}
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_details[0]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_details[0]
                                                  .profile_image
                                              : null
                                          })`,
                                          width: '75px',
                                          height: '77px',
                                          backgroundPosition: 'center'
                                        }}
                                        className='scorer-img'
                                      ></div>

                                      <div>
                                        <span className='ms-3 team-name mymatchcard'>
                                          {match.player_details[0].fname +
                                            ' ' +
                                            '' +
                                            match.player_details[0].lname} 
                                        </span>
                                        <br></br>

                                        <span
                                          className='ms-3'
                                          style={{ color: '#2ed5d5' }}
                                        >
                                           
                                          {extractdomain(
                                            match.player_details[0]
                                              .corporate_email,
                                            '@',
                                            '.'
                                          )} 
                                        </span>
                                      </div>
                                      {match.player_details[0]._id ==
                                      match.player_1_id ? (
                                        <span className='ms-auto fw-bold'>
                                          {match.player_1_score}
                                        </span>
                                      ) : (
                                        <span className='ms-auto fw-bold'>
                                          {match.player_2_score}
                                        </span>
                                      )}
                                    </div>

                                    <div className='mt-3 d-flex align-items-center'>
                                      {match.player_won!=null && match.player_details[1]._id!=null &&match.player_won.toString() ==
                                      match.player_details[1]._id.toString() ? (
                                        <>
                                          <img
                                            src={trophy}
                                            alt=''
                                            className='trophy-img-score'
                                          />
                                        </>
                                      ) : null}

                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_details[1]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_details[1]
                                                  .profile_image
                                              : null
                                          })`,
                                          width: '75px',
                                          height: '77px',
                                          backgroundPosition: 'center'
                                        }}
                                        className='scorer-img'
                                        // className="team_logo"
                                      ></div>
                                      <div>
                                        <span className='ms-3 team-name mymatchcard'>
                                          {match.player_details[1].fname +
                                            ' ' +
                                            '' +
                                            match.player_details[1].lname} 
                                        </span>
                                        <br></br>

                                        <span
                                          className='ms-3'
                                          style={{ color: '#2ed5d5' }}
                                        >
                                           
                                          {extractdomain(
                                            match.player_details[1]
                                              .corporate_email,
                                            '@',
                                            '.'
                                          )} 
                                        </span>
                                      </div>
                                      {match.player_details[1]._id ==
                                      match.player_1_id ? (
                                        <span className='ms-auto fw-bold'>
                                          {match.player_1_score}
                                        </span>
                                      ) : (
                                        <span className='ms-auto fw-bold'>
                                          {match.player_2_score}
                                        </span>
                                      )}
                                    </div>
                                  </Col>

                                  <Col
                                    xs={5}
                                    className='match_schedule d-flex flex-column justify-content-center ps-3 col-md-4'
                                  >
                                    <div className='d-flex align-items-center'>
                                      <Calander />
                                      <span className='ms-2'>
                                        {match.match_date != null
                                          ? newFormatdate(match.match_date, 1)
                                          : ''}
                                      </span>
                                    </div>
                                    <div className='d-flex align-items-center  my-3'>
                                      <Clock />
                                      <span className='ms-2'>
                                        {match.match_date != null
                                          ? newFormatdate(
                                              match.match_date,
                                              0,
                                              1
                                            )
                                          : ''}
                                      </span>
                                    </div>
                                    <div className='d-flex align-items-center location-logo'>
                                      <Location />
                                      <span className='ms-2 ground-name'>
                                        Sydney Ground Australia
                                      </span>
                                    </div>
                                  </Col>
                                </Row>

                                {/* <Table bordered className="mb-0 text-center match-score-table">
                                                                <thead className="border-0">
                                                                    <tr className="border-0">
                                                                        <th className="border-0 text-start ps-4">Team</th>
                                                                        <th className="border-0">Round 1</th>
                                                                        <th className="border-0">Round 2</th>
                                                                        <th className="border-0">Round 3</th>
                                                                        <th className="border-0">Set</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        ScoreList.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td className="align-middle py-3 ">
                                                                                    <div className="w-100 text-start ps-3">
                                                                                        <img src={item.img} alt={item.team} className="pe-3" />{item.team}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle">{item.round_1}</td>
                                                                                <td className="align-middle">{item.round_2}<    /td>
                                                                                <td className="align-middle">{item.round_3}</td>
                                                                                <td className="align-middle">{item.set}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table> */}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    ))
                  ) : (
                    <>
                      {game === 'bowling'  ? completed.map((match, index) => (
                            <>
                              <div className='container mb-4'>
                                <div className='card  text-white rounded-lg'>
                                  <div
                                    className='card-header d-flex justify-content-between align-items-center'
                                    style={{ backgroundColor: '#EF2A2A' }}
                                  >
                                    <div>
                                      <span className='fw-bold'>
                                        Match Score
                                      </span>
                                      <span className='ms-3'>
                                        {replaceUnderscoreWithSpace(
                                          match.round
                                        )}
                                      </span>
                                    </div>
                                    <div>
                                    <span className='mr-3'>
                                                      <Calander></Calander>
                                                      <span className='ms-2'>
                                                      { match.match_date != null ? ( FormatDate(
                                                          match.match_date,
                                                          1,
                                                          0,
                                                          0
                                                      ) ) :('N/A')
                                                    }
                                                      </span>
                                                  </span>
                                                  <span className='ms-2'>
                                                      <Clock></Clock>

                                                      <span className='ms-2'>
                                                      {
                                                      match.match_date != null ? ( newFormatdate(
                                                        match.match_date,
                                                        0,
                                                        1,
                                                        0
                                                    ) ) :('N/A')
                                                    }
                                                      </span>
                                                  </span>
                                    </div>
                                  </div>
                                  <div className='card-body p-0'>
                                    <table className='table table-dark  mb-0 w-100'>
                                      <thead className=''>
                                        <tr className=''>
                                          <th className='text-center'>
                                            Winner
                                          </th>
                                          <th>Name</th>
                                          <th className='text-center'>
                                            Strikes
                                          </th>
                                          <th className='text-center'>
                                            Spares
                                          </th>
                                          <th className='text-center'>
                                            Scores
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                       
                                      {match.players && match.players.length > 0 && match.players.map((player, playerIndex) => {
                              const playerDetails = match.player_details.find(
                                details => details._id === player._id
                              );

                              if (!playerDetails) return null;

                              return (
                                <tr key={playerIndex} className='text-center'>
                                  <td>
                                    <i className='fas fa-trophy text-warning' />
                                    {match.rank_1  === player._id ? (
                                      <span>
                                        <img src={golden} className='icons' alt='Golden Trophy' />
                                      </span>
                                    ) : match.rank_2 === player._id ? (
                                      <span>
                                        <img src={silver} className='icons' alt='Silver Trophy' />
                                      </span>
                                    ) : match.rank_3 === player._id && match.rank_3 != null ? (
                                      <span>
                                        <img src={bronze} className='icons' alt='Bronze Trophy' />
                                      </span>
                                    ) : null}
                                  </td>
                                  <td className='align-items-center'>
                                    <div className='d-flex'>
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            playerDetails.profile_image
                                              ? IMAGE_URL + 'user/' + playerDetails.profile_image
                                              : null
                                          })`,
                                          backgroundPosition: 'center',
                                          height: '50px',
                                          width: '50px'
                                        }}
                                        className='scorer-img'
                                      ></div>
                                      <div>
                                        <span className='ms-3'>
                                          {playerDetails.fname + ' ' + playerDetails.lname}
                                        </span>
                                        <br />
                                        <span style={{ color: '#2ed5d5' }}>
                                          {extractdomain(playerDetails.corporate_email, '@', '.')}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{player.strikes}</td>
                                  <td>{player.spares}</td>
                                  <td>{player.points}</td>
                                </tr>
                              );
                            })}

                                                </tbody>
                                       </table>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        : completed.map((match, index) => (
                            <>
                              <div className='container mb-4'>
                                    <div className='card  text-white rounded-lg'>
                                        <div
                                            className='card-header d-flex justify-content-between align-items-center'
                                            style={{ backgroundColor: '#EF2A2A' }}
                                        >
                                                 <div>
                                                <span className='fw-bold'>
                                                    Match Score
                                                </span>
                                                <span className='ms-3'>
                                                    {replaceUnderscoreWithSpace(
                                                    match.round
                                                    )}
                                                </span>
                                                </div>
                                                 <div>
                                                  <span className='mr-3'>
                                                      <Calander></Calander>
                                                      <span className='ms-2'>
                                                      { match.match_date != null ? ( FormatDate(
                                                          match.match_date,
                                                          1,
                                                          0,
                                                          0
                                                      ) ) :('N/A')
                                                    }
                                                      </span>
                                                  </span>
                                                  <span className='ms-2'>
                                                      <Clock></Clock>

                                                      <span className='ms-2'>
                                                      {
                                                      match.match_date != null ? ( newFormatdate(
                                                        match.match_date,
                                                        0,
                                                        1,
                                                        0
                                                    ) ) :('N/A')
                                                    }
                                                      </span>
                                                  </span>
                                                </div>  
                                                
                                        </div>
                                          <div className='card-body p-0'>
                                              <table className='table table-dark  mb-0 w-100'>
                                              <thead className=''>
                                                  <tr className=''>
                                                  <th className='text-center'>
                                                      Winner
                                                  </th>
                                                  <th>Name</th>
                                                  <th className='text-center'>
                                                      Minutes
                                                  </th>
                                                  <th className='text-center'>
                                                      Seconds
                                                  </th>
                                                  <th className='text-center'>
                                                      Milliseconds
                                                  </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {match.players.map((player, playerIndex) => {
                                                    const playerDetails = match.player_details.find(
                                                      details => details._id === player._id
                                                    );

                                                    if (!playerDetails) return null;

                                                    return (
                                                      <tr key={playerIndex} className='text-center'>
                                                        <td>
                                                          <i className='fas fa-trophy text-warning' />
                                                          {match.rank_1 === player._id ? (
                                                            <span>
                                                              <img src={golden} className='icons' alt='Golden Trophy' />
                                                            </span>
                                                          ) : match.rank_2 === player._id ? (
                                                            <span>
                                                              <img src={silver} className='icons' alt='Silver Trophy' />
                                                            </span>
                                                          ) : match.rank_3 === player._id && match.rank_3 != null ? (
                                                            <span>
                                                              <img src={bronze} className='icons' alt='Bronze Trophy' />
                                                            </span>
                                                          ) : null}
                                                        </td>
                                                        <td className='align-items-center'>
                                                          <div className='d-flex'>
                                                            <div
                                                              style={{
                                                                backgroundImage: `url(${
                                                                  playerDetails.profile_image
                                                                    ? IMAGE_URL + 'user/' + playerDetails.profile_image
                                                                    : null
                                                                })`,
                                                                backgroundPosition: 'center',
                                                                height: '50px',
                                                                width: '50px'
                                                              }}
                                                              className='scorer-img'
                                                            ></div>
                                                            <div>
                                                              <span className='ms-3'>
                                                                {playerDetails.fname + ' ' + playerDetails.lname}
                                                              </span>
                                                              <br />
                                                              <span
                                                                className=''
                                                                style={{ color: '#2ed5d5' }}
                                                              >
                                                                {extractdomain(playerDetails.corporate_email, '@', '.')}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>{player.minutes}</td>
                                                        <td>{player.second}</td>
                                                        <td>{player.millisecond}</td>
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </table>
                                          </div>
                                    </div>
                              </div>
                            </>
                          ))}
                    </>
                  )
                ) : (
                  <>
                    <CCard
                      style={{
                        position: 'relative',
                        background: '#2A2729',
                        borderRadius: '15px'
                      }}
                    >
                      <div
                        className='card-background'
                        style={{ backgroundImage: `url(${cardBg})` }}
                      />
                      <CCardBody
                        className='d-flex justify-content-center align-items-center'
                        style={{
                          padding: '0px',
                          color: '#fff',
                          height: '200px'
                        }}
                      >
                        <div>
                          <div
                            style={{
                              backgroundImage: `url(${notFound})`,
                              border: 'none'
                            }}
                            className='scorer-card-logo bg-image-container2  ms-4'
                          ></div>
                          <span
                            style={{
                              color: 'white',
                              fontSize: '20px',
                              fontWeight: '700'
                            }}
                          >
                            No matches found
                          </span>
                        </div>
                      </CCardBody>
                    </CCard>
                  </>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey='#statistics'>
                {/* <Row className="text-dark mb-5 align-items-center">
                                <Col md={6} className="fs-5 fw-bold statistics_header">
                                    David Cup 2022 Melbourn Badminton Ground <Badminton/>
                                </Col>
                                <Col md={6} className="text-end">
                                    <div className="d-flex justify-content-end">
                                        <span className="fs-5 fw-bold me-3" >Select Tournament</span>
                                        <DropdownButton title="David Cup" variant="dark">
                                            <Dropdown.Item href="#">David Cup</Dropdown.Item>
                                            <Dropdown.Item href="#">David Cup</Dropdown.Item>
                                            <Dropdown.Item href="#">David Cup</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </Col>
                            </Row> */}
                <Row className='mb-5'>
                  <Col>
                    {selfstandings.length > 0 ? (
                      game === 'Badminton' || game === 'TableTennis' ? (
                        <>
                          <Card
                            className='border-radius25px overflow-hidden complete-nav-card border-0 bg-image'
                            style={Statistics_Points}
                          >
                            <Card.Body className='p-0'>
                              <Row className='my-5'>
                                <Col md='12' className='p-5' id='trophycard'>
                                  <Row className='text-center'>
                                    <Col md='2' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].win +
                                          selfstandings[0].lost} 
                                      </span>
                                      <br />
                                      Total Games
                                    </Col>
                                    <Col md='1' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].win}
                                      </span>
                                      <br />
                                      Won
                                    </Col>
                                    <Col md='1' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].lost}
                                      </span>
                                      <br />
                                      Lost
                                    </Col>
                                    <Col md='2' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].points}
                                      </span>
                                      <br />
                                      Points
                                    </Col>
                                    <Col md='2' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].PF}
                                      </span>
                                      <br />
                                      Points For
                                    </Col>
                                    <Col md='2' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].PA}
                                      </span>
                                      <br />
                                      Points Against
                                    </Col>
                                    <Col md='2' className='text-white fs-5'>
                                      <span className='fw-bold'>
                                        {selfstandings[0].PD}
                                      </span>
                                      <br />
                                      Points Difference
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </>
                      ) : game === 'bowling' ?(
                        <Card
                          className='border-radius25px overflow-hidden complete-nav-card border-0 bg-image'
                          style={Statistics_Points}
                        >
                          <Card.Body className='p-0'>
                            <Row className='my-5'>
                              <Col md='12' className='p-5' id='trophycard'>
                                <Row className='text-center'>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].total_games} 
                                    </span>
                                    <br />
                                    Total Games
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].strikes}
                                    </span>
                                    <br />
                                    Strikes
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].spares}
                                    </span>
                                    <br />
                                    Spares
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].points}
                                    </span>
                                    <br />
                                    Score
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].winning_points}
                                    </span>
                                    <br />
                                    winning Points
                                  </Col>
                                  {/* <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].spares}
                                    </span>
                                    <br />
                                    Points Against
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].points}
                                    </span>
                                    <br />
                                    Score
                                  </Col> */}
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ) : (
                          <>
                          <Card 
                          className='border-radius25px overflow-hidden complete-nav-card border-0 bg-image'
                          style={Statistics_Points}
                        >
                          <Card.Body className='p-0'>
                            <Row className='my-5'>
                              <Col md='12' className='p-5' id='trophycard'>
                                <Row className='text-center'>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].total_games} 
                                    </span>
                                    <br />
                                    Total Games
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      { format_2k_run_Time(selfstandings[0].total_run)}
                                    </span>
                                    <br />
                                    Total Run
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      
                                      { format_2k_run_Time (selfstandings[0].best_run)}
                                    </span>
                                    <br />
                                    Best Run
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].winning_points}
                                    </span>
                                    <br />
                                    winning Points
                                  </Col>
                                  {/* <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].spares}
                                    </span>
                                    <br />
                                    Points Against
                                  </Col>
                                  <Col md='2' className='text-white fs-5'>
                                    <span className='fw-bold'>
                                      {selfstandings[0].points}
                                    </span>
                                    <br />
                                    Score
                                  </Col> */}
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                          </>
                      )
                    ) : (
                      <>
                        <CCard
                          style={{
                            position: 'relative',
                            background: '#2A2729',
                            borderRadius: '15px'
                          }}
                        >
                          <div
                            className='card-background'
                            style={{ backgroundImage: `url(${cardBg})` }}
                          />
                          <CCardBody
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              padding: '0px',
                              color: '#fff',
                              height: '200px'
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  backgroundImage: `url(${notFound})`,
                                  border: 'none'
                                }}
                                className='scorer-card-logo bg-image-container2 ms-4'
                              ></div>
                              <span
                                style={{
                                  color: 'white',
                                  fontSize: '20px',
                                  fontWeight: '700'
                                }}
                              >
                                No Statistics found
                              </span>
                            </div>
                          </CCardBody>
                        </CCard>
                      </>
                    )}
                  </Col>
                </Row>

                {/* <Row className="mb-5 statstics-match">
                                <Col>
                                    <Card className="border-radius25px overflow-hidden complete-nav-card border-0 ">
                                        <Card.Body className="p-0">
                                            <Row className="text-white">
                                                <Col className="nav-active-tab fw-bold text-white fs-5 py-2 px-5"><span className="text-center w-100">David Cup Match</span></Col>
                                            </Row>
                                            <Table className="text-center mb-0">
                                                <thead className="border-0">
                                                    <tr className="border-0">
                                                        <th className="border-0 text-start ps-5">Name</th>
                                                        <th className="border-0">M</th>
                                                            <th className="border-0">TO</th>
                                                        <th className="border-0">TF</th>
                                                        <th className="border-0">TP</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        StatisticsPointList.map((item, index) => (
                                                            <tr>
                                                            <td className="text-start ps-5">{item.name}</td>
                                                            <td>{item.m}</td>
                                                            <td>{item.to}</td>
                                                            <td>{item.tf}</td>
                                                            <td>{item.tp}</td>
                                                        </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}
              </Tab.Pane>

              <Tab.Pane eventKey='#standings'>
                <Row className='text-dark mb-md-5 align-items-center'>
                  <Col
                    md={6}
                    className='d-md-flex mb-md-0 mb-sm-2 mb-3 justify-content-md-start fs-5 fw-bold col-sm-7 col-8 primarytext'
                  >
                    Group Standings List
                    <CButton
                      onClick={handleChange}
                      value={ownGroup !== undefined ? ownGroup.id : null}
                      className='my-group-btn ms-md-3 text-white'
                    >
                      My Group
                    </CButton>
                  </Col>

                  <Col
                    md={6}
                    className='d-md-flex mb-md-0 mb-sm-2 mb-3  justify-content-md-end d-sm-flex justify-content-sm-end d-flex flex-md-row flex-sm-column flex-column justify-content-end align-items-md-center align-item-start col-sm-5 col-4'
                    id='groups'
                  >
                    <span className='text-group fs-5 fw-bold me-md-3 me-sm-2 me-1 primarytext'>
                      Groups
                    </span>

                    {
                      game ==='bowling' ? (
                        <CFormSelect 
                        aria-label='Default select example'
                        onChange={handleRound}
                        className='bg-dark text-white'
                        data-game = {game}
                        value={SelectedRound}
                        style={{ width: '157px' }}
                        >
                          <option value= 'round_1' >Round 1</option>
                          <option value= 'round_2' >Round 2</option>
                          <option value= 'round_3' >Round 3</option>
                          <option value= 'final' > final</option>

                        </CFormSelect>
                      ) : game === '2k_run' ?(
                            <CFormSelect
                            aria-label='Default select example'
                            onChange={handleRound}
                            data-game = {game}
                            className='bg-dark text-white'
                            value={SelectedRound}
                            style={{ width: '157px' }}
                            >
                            <option value= 'round_1' >Round 1</option>
                            <option value= 'round_2' >Round 2</option>
                            <option value= 'round_3' >Round 3</option>
                            <option value= 'final' > final</option>
                          </CFormSelect>
                      ) : null
                    }
                    <CFormSelect
                      aria-label='Default select example'
                      onChange={handleChange}
                      className='ms-3 bg-dark text-white'
                      value={SelectedGroup}
                      style={{ width: '157px' }}
                    >
                      {groups.length > 0
                        ? groups.map((group, index) => (
                            <option
                              key={index}
                              name={group.id}
                              value={group.id}
                            >
                              {group.name  }
                            </option>
                          ))
                        : null}
                    </CFormSelect>
                   
                    
                  </Col>
                </Row>
                {
                groupStandings.length > 0 ? (
                  game === 'Badminton' || game === 'TableTennis' ? (
                    <>
                      <Row className='mb-5 standings-match'>
                        <Col>
                          <Card className='border-radius25px overflow-hidden complete-nav-card border-0 '>
                            <Card.Body className='p-0'>
                              <Table className='text-center mb-0 '>
                                <thead className='border-0 nav-active-tab'>
                                  <tr className='border-0'>
                                    <th className='border-0 text-start ps-5'>
                                      #
                                    </th>
                                    <th className='border-0 text-start'>
                                      Participant
                                    </th>
                                    <th className='border-0'>W</th>
                                    <th className='border-0'>L</th>
                                    <th className='border-0'>P</th>
                                    <th className='border-0'>PF</th>
                                    <th className='border-0'>PA</th>
                                    <th className='border-0'>PD</th>
                                  </tr>
                                </thead>
                                {groupStandings.map((player, index) => (
                                  <>
                                    <tbody>
                                      <tr>
                                        <td className='text-start ps-5'>
                                          <span
                                            className={
                                              'standing_badge_' +
                                              (index + 1) +
                                              ' py-1 px-3'
                                            }
                                          >
                                            {index + 1}
                                          </span>
                                        </td>
                                        <td className='text-start'>
                                          {player.player_id.fname +
                                            ' ' +
                                            player.player_id.lname}
                                        </td>
                                        <td>{player.win}</td>
                                        <td>{player.lost}</td>
                                        <td>{player.points}</td>
                                        <td>{player.PF}</td>
                                        <td>{player.PA}</td>
                                        <td>{player.PD}</td>
                                      </tr>
                                    </tbody>
                                  </>
                                ))}
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  ) : game === 'bowling' ? (
                      <>
                      <CRow>
                        <Col>
                          <Card className='border-radius25px overflow-hidden border-0 '>
                            <Card.Body className='p-0'>
                              <Table className='text-center mb-0 '>
                                <thead
                                  className='border-0 standing-head'
                                  style={{ backgroundColor: '#EF2A2A' }}
                                >
                                  <tr className='border-0'>
                                    <th className='border-0'>#</th>
                                    <th className='border-0 text-start'>
                                      Participant
                                    </th>
                                    <th className='border-0'>Spares</th>
                                    <th className='border-0'>Strikes</th>
                                    <th className='border-0'>Scores</th>
                                    <th className='border-0'>Points</th>
                                  </tr>
                                </thead>
                                {groupStandings.length > 0 ? (
                                  <>
                                    <tbody>
                                      {groupStandings.map((player, index) => (
                                        <>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td className='text-start'>
                                             {player.player_id.length >0 ? player.player_id[0].fname  +
                                              ' ' + player.player_id[0].lname :'N/A'}
                                            </td>
                                            <td>{ (player.players!=null && player.players._id !==null && player.players._id !==  undefined) ? player.players.spares : 'N/A'}</td>
                                            <td>{ (player.players!=null && player.players._id !==null && player.players._id !== undefined ) ? player.players.strikes : 'N/A'}</td>
                                            <td>{ (player.players!=null && player.players._id !==null && player.players._id !== undefined )? player.players.points : 'N/A'}</td>
                                            <td>{ (player.players!=null && player.players._id !==null && player.players._id !== undefined )? index > 2 ? 0 : 3 - index : 'N/A'}</td>
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </>
                                ) : (
                                  <>
                                    <h1>No standing found</h1>
                                  </>
                                )}
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                      </CRow>
                     
                    </>
                  ) :(
                    <>
                    <CRow>
                      <Col>
                        <Card className='border-radius25px overflow-hidden border-0 '>
                          <Card.Body className='p-0'>
                            <Table className='text-center mb-0 '>
                              <thead
                                className='border-0 standing-head'
                                style={{ backgroundColor: '#EF2A2A' }}
                              >
                                <tr className='border-0'>
                                  <th className='border-0'>#</th>
                                  <th className='border-0 text-start'>
                                    Participant
                                  </th>
                                  <th className='border-0'>Time</th>
                                  <th className='border-0'>Points</th>

                                </tr>
                              </thead>
                              {groupStandings.length > 0 ? (
                                <>
                                  <tbody>
                                    {groupStandings.map((player, index) => (
                                      <>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td className='text-start'>
                                        
                                            {player.player_id.length >0 ? player.player_id[0].fname  +
                                              ' ' + player.player_id[0].lname :'N/A'}
                                          </td>
                                          <td>{player.players._id !==null ? 
                                         format_2k_run_Time( player.players.totalSeconds)
                                         : 'N/A'}</td>
                                          <td>{player.players._id !==null ? index > 2 ? 0 : 3 - index :'N/A'}</td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </>
                              ) : (
                                <>
                                  <h1>No standing found</h1>
                                </>
                              )}
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </CRow>
                  </>
                  )
                ) : (
                  <>
                    <CCard
                      style={{
                        position: 'relative',
                        background: '#2A2729',
                        borderRadius: '15px'
                      }}
                    >
                      <div
                        className='card-background'
                        style={{ backgroundImage: `url(${cardBg})` }}
                      />
                      <CCardBody
                        className='d-flex justify-content-center align-items-center'
                        style={{
                          padding: '0px',
                          color: '#fff',
                          height: '200px'
                        }}
                      >
                        <div>
                          <div
                            style={{
                              backgroundImage: `url(${notFound})`,
                              border: 'none'
                            }}
                            className='scorer-card-logo bg-image-container2  ms-4'
                          ></div>
                          <span
                            style={{
                              color: 'white',
                              fontSize: '20px',
                              fontWeight: '700'
                            }}
                          >
                            No standings found
                          </span>
                        </div>
                      </CCardBody>
                    </CCard>
                  </>
                )
              }
              </Tab.Pane>

              <Tab.Pane eventKey='#winner'>
                <Row className='mb-5 winner-match'>
                  <Col>
                    {/* show winner */}
                    {TournamentWinner.length > 0 && showwinner == true ? (
                      <>
                        <Card className='border-radius25px overflow-hidden complete-nav-card border-0 '>
                          <Card.Body className='p-0'>
                            <Row className='text-white'>
                              <Col className='nav-active-tab fw-bold text-white fs-5 py-2 px-5 match-winner'>
                                <span className='text-center w-100'>
                                  Match Winner
                                </span>
                              </Col>
                            </Row>
                            <Table className='text-center mb-0'>
                              <thead className='border-0'>
                                <tr className='border-0'>
                                  <th className='border-0 text-start ps-5'>
                                  Participant	
                                  </th>
                                  <th className='border-0'>Rank</th>
                                  <th className='border-0'>Points</th>
                                  <th className='border-0'>Winner</th>
                                </tr>
                              </thead>
                              <tbody>
                                {TournamentWinner.map((item, index) => (
                                  <tr>
                                    <td className='text-start ps-5'>
                                      {item.name}
                                    </td>
                                      
                                    <td>{index + 1}</td>
                                      {
                                        game === 'Badminton' || game === 'TableTennis' ? (
                                          <td>
                                          {item.points}
                                           </td>
                                        ) :(<td>
                                          {item.winning_points}
                                           </td>)
                                      }
                                    <td>
                                      {index < 3 ? <WinnerCupIcon /> : ''}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </>
                    ) : (
                      <>
                        <CCard
                          style={{
                            position: 'relative',
                            background: '#2A2729',
                            borderRadius: '15px'
                          }}
                        >
                          <div
                            className='card-background'
                            style={{ backgroundImage: `url(${cardBg})` }}
                          />
                          <CCardBody
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              padding: '0px',
                              color: '#fff',
                              height: '200px'
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  backgroundImage: `url(${notFound})`,
                                  border: 'none'
                                }}
                                className='scorer-card-logo bg-image-container2  ms-4'
                              ></div>
                              <span
                                style={{
                                  color: 'white',
                                  fontSize: '20px',
                                  fontWeight: '700'
                                }}
                              >
                                Winners not annouced yet
                              </span>
                            </div>
                          </CCardBody>
                        </CCard>
                      </>
                    )}

                    {/* no winner code  */}

                    {/* <CCard style={{ position: 'relative', background: '#2A2729', borderRadius: '15px' }}>
                                                <div className='card-background' style={{ backgroundImage: `url(${cardBg})`, }} />
                                                <CCardBody className="d-flex justify-content-center align-items-center" style={{ padding: '0px', color: '#fff', height: '200px' }}>
                                                    <div >
                                                        <div
                                                            style={{
                                                                backgroundImage: `url(${notFound})`, border: 'none'
                                                            }}
                                                            className=' '
                                                        ></div>
                                                        <span style={{ color: 'white', fontSize: '20px', fontWeight: '700' }}>
                                                            Winner will be annouced once tournament is completed
                                                        </span>
                                                    </div>
                                                </CCardBody>
                                            </CCard> */}
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="#top_performer">
              {TopPerformer !== null ? (
                (game === 'Badminton' || game === 'TableTennis') ? (
                  <>
                    <Row>
                      <Col>
                        <Card className='border-radius25px overflow-hidden border-0'>
                          <Card.Body className='p-0'>
                            <Table className='text-center mb-0'>
                              <thead
                                className='border-0 standing-head'
                                style={{ backgroundColor: '#EF2A2A' }}
                              >
                                <tr className='border-0'>
                                  <th className='border-0'>#</th>
                                  <th className='border-0 text-start'>Corporate</th>
                                  <th className='border-0'>1st Position</th>
                                  <th className='border-0'>2nd Position</th>
                                  <th className='border-0'>3rd Position</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Placeholder for categories map */}
                                {topData.map((data, index) => {
                                let i = data.value.length; // Declare the integer variable here

                                return (
                                  <tr className='mb-2'style={{height:'95px'}} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.key}</td>
                                    {data.value.length > 0 ? (
                                      <>
                                        {data.value.map((player, playerIndex) => (
                                          <td  className='' key={playerIndex}>
                                            <div className='d-flex justify-content-center' style={{ maxWidth: "300px" }}>
                                              <div
                                                style={{
                                                  backgroundImage: `url(${player.image ? IMAGE_URL + 'user/' + player.image : 'profile.jpg'})`,
                                                  width: '75px',
                                                  height: '77px',
                                                  backgroundPosition: 'center'
                                                }}
                                                className='scorer-img'
                                              ></div>
                                              <div>
                                                <span className='ms-3 team-name'>{player.name !== undefined ? player.name : 'N/A'}</span>
                                                <p className="ms-3" style={{ color: '#2ed5d5' }}>
                                                  {player.corporate_email !== undefined ? extractdomain(player.corporate_email, '@', '.') : 'N/A'}
                                                </p>
                                              </div>
                                              <div className='player-score'>
                                                <span className='ms-2 text-white fw-bold fs-2'>{player.points !== undefined ? player.points : 'N/A'}</span>
                                              </div>
                                            </div>
                                          </td>
                                        ))}
                                        {/* Add additional cells if necessary */}
                                        {
                                        
                                        
                                        Array.from({ length: 3 - i }).map((_, extraIndex) => (
                                          <td>
                                            <div>N/A</div>
                                          {/* <small className=''>N/A</small> */}
                                            
                                          </td>
                                        ))}
                                      </>
                                    ) : (
                                      <td colSpan={3}>
                                        <small className=''>N/A</small>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                              }
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </>
                ) : game === 'bowling' ? (
                  topData.length > 0 ? (
                    <Row>
                    <Col>
                      <Card className='border-radius25px overflow-hidden border-0'>
                        <Card.Body className='p-0'>
                          <Table className='text-center mb-0'>
                            <thead
                              className='border-0 standing-head'
                              style={{ backgroundColor: '#EF2A2A' }}
                            >
                              <tr className='border-0'>
                                <th className='border-0'>#</th>
                                <th className='border-0 text-start'>Corporate</th>
                                <th className='border-0'>1st Position</th>
                                <th className='border-0'>2nd Position</th>
                                <th className='border-0'>3rd Position</th>
                              </tr>
                            </thead>
                            <tbody>
                            {topData.map((data, index) => {
                              let i = data.value.length; // Declare the integer variable here

                              return (
                                <tr className='mb-2' key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.key}</td>
                                  {data.value.length > 0 ? (
                                    <>
                                      {data.value.map((player, playerIndex) => (
                                        <td key={playerIndex}>
                                          <div className='d-flex' style={{ maxWidth: "300px" }}>
                                            <div
                                              style={{
                                                backgroundImage: `url(${player.image ? IMAGE_URL + 'user/' + player.image : 'profile.jpg'})`,
                                                width: '75px',
                                                height: '77px',
                                                backgroundPosition: 'center'
                                              }}
                                              className='scorer-img'
                                            ></div>
                                            <div>
                                              <span className='ms-3 team-name'>{player.name !== undefined ? player.name : 'N/A'}</span>
                                              <p className="ms-3" style={{ color: '#2ed5d5' }}>
                                                {player.corporate_email !== undefined ? extractdomain(player.corporate_email, '@', '.') : 'N/A'}
                                              </p>
                                            </div>
                                            <div className='player-score'>
                                              <span className='ms-4 text-white fw-bold fs-2'>{player.points !== undefined ? player.points : 'N/A'}</span>
                                            </div>
                                          </div>
                                        </td>
                                      ))}
                                      {/* Add additional cells if necessary */}
                                      {
                                      
                                      
                                      Array.from({ length: 3 - i }).map((_, extraIndex) => (
                                        <td>
                                          <div>N/A</div>
                                        {/* <small className=''>N/A</small> */}
                                          
                                        </td>
                                      ))}
                                    </>
                                  ) : (
                                    <td colSpan={3}>
                                      <small className=''>N/A</small>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                            }


          </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  ) : (
                    <h1 className='text-dark'>No data found</h1>
                  )
                ) : (
                  topData.length > 0 ? (
                    <Row>
                      <Col>
                        <Card className='border-radius25px overflow-hidden border-0'>
                          <Card.Body className='p-0'>
                            <Table className='text-center mb-0'>
                              <thead
                                className='border-0 standing-head'
                                style={{ backgroundColor: '#EF2A2A' }}
                              >
                                <tr className='border-0'>
                                  <th className='border-0'>#</th>
                                  <th className='border-0 text-start'>Corporate</th>
                                  <th className='border-0'>1st Position</th>
                                  <th className='border-0'>2nd Position</th>
                                  <th className='border-0'>3rd Position</th>
                                </tr>
                              </thead>
                              <tbody>
                              {topData.map((data, index) => {
                                let i = data.value.length; // Declare the integer variable here

                                return (
                                  <tr className='mb-2'style={{height:'95px'}} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.key}</td>
                                    {data.value.length > 0 ? (
                                      <>
                                        {data.value.map((player, playerIndex) => (
                                          <td  className='' key={playerIndex}>
                                            <div className='d-flex justify-content-center' style={{ maxWidth: "300px" }}>
                                              <div
                                                style={{
                                                  backgroundImage: `url(${player.image ? IMAGE_URL + 'user/' + player.image : 'profile.jpg'})`,
                                                  width: '75px',
                                                  height: '77px',
                                                  backgroundPosition: 'center'
                                                }}
                                                className='scorer-img'
                                              ></div>
                                              <div>
                                                <span className='ms-3 team-name'>{player.name !== undefined ? player.name : 'N/A'}</span>
                                                <p className="ms-3" style={{ color: '#2ed5d5' }}>
                                                  {player.corporate_email !== undefined ? extractdomain(player.corporate_email, '@', '.') : 'N/A'}
                                                </p>
                                              </div>
                                              <div className='player-score'>
                                                <span className='ms-2 text-white fw-bold fs-2'>{player.points !== undefined ? player.points : 'N/A'}</span>
                                              </div>
                                            </div>
                                          </td>
                                        ))}
                                        {/* Add additional cells if necessary */}
                                        {
                                        
                                        
                                        Array.from({ length: 3 - i }).map((_, extraIndex) => (
                                          <td>
                                            <div>N/A</div>
                                          {/* <small className=''>N/A</small> */}
                                            
                                          </td>
                                        ))}
                                      </>
                                    ) : (
                                      <td colSpan={3}>
                                        <small className=''>N/A</small>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                              }
            </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                  </Row>
                  ) : (
                    <h1 className='text-dark'>No data found</h1>
                  )
                )
              ) : (
                <h1>No performer</h1>
              )
      }
              
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  )
}

export default React.memo(StandingsTab)
