import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="#F4F821"
      viewBox="0 0 25 25"
    >
      <path
        fill="#F4F821"
        d="M24.991 3.981c-.002-.158-.005-.295-.005-.41h-4.51C20.562 1.64 20.503 0 20.503 0h-7.956a.543.543 0 00-.055.003.545.545 0 00-.056-.003H4.46s-.058 1.64.029 3.571H0v1.786h.009c.045 1.456.278 3.404 1.246 5.398A11.189 11.189 0 004.77 14.96c1.252.91 2.681 1.566 4.253 1.958.907.969 1.803 1.503 2.584 1.717v1.988c-.112.59-.79 2.59-5.512 2.59h-.738V25h14.285v-1.785h-.735c-5.54 0-5.515-2.753-5.515-2.753v-1.826c.782-.214 1.66-.746 2.562-1.71a12.168 12.168 0 004.276-1.965 11.188 11.188 0 003.515-4.206c1.321-2.72 1.272-5.357 1.246-6.773zM2.861 9.974C2.054 8.313 1.842 6.64 1.796 5.357h2.817c.049.497.11.988.188 1.45.475 2.83 1.128 5.328 2.363 7.533a9.486 9.486 0 01-4.303-4.366zm19.278 0a9.489 9.489 0 01-4.338 4.383c1.24-2.209 1.887-4.713 2.364-7.55.077-.462.138-.953.187-1.45h2.851c-.046 1.283-.258 2.956-1.064 4.617z"
      ></path>
    </svg>
  );
}

export default Icon;