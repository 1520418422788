import React from 'react'
import { CFooter } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilHeart
} from '@coreui/icons'

const AppFooter = () => {
  return (
    <CFooter className="px-4 text-muted">
      <div>
        Cooperative Premiure Leauge
        <span className="ms-1">© { new Date().getFullYear()}</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">All Rights Reserved  by CPL 
         <CIcon icon={cilHeart}></CIcon>
        </span>
        <p></p>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
