import React,{useEffect, useState} from 'react'
import {useNavigate} from"react-router-dom"
import { useSnackbar } from 'notistack';
import FormContainer from './FormContainer'
import profile from '../../assets/download (1).jpg'
import EyeIcon from '../../components/icons/EyeIcon';
import EyeSlashFillIcon from '../../components/icons/EyeSlashFillIcon';
import CameraSVGIcon from '../../components/icons/CameraIcon';
import {RegisterUser, UserExist, Authenticated} from '../../API/auth';
import t from '../../components/translation';

function Register () {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [FormData ,setFormData] = useState({
		firstName:'',
		lastName:'',
		username :'',
		password :'',
		confirmPassword:'',
		email:'',
		corporateEmail:'',
		gender:'male',
		check:'false',
		role:'participant',
	})
  	const [userexist, setUserExist] = useState(false);
  	const [DisableRegister, setDisableRegister] = useState(false);
  	const [errors, setErrors] = useState({});
  	const [showPassword, setShowPassword] = useState({password:true, confirm_password:true});
  	const [PasswordCriteria, setPasswordCriteria] = useState({});
	const [CriteriaShow, setCriteriaShow] = useState(false);
	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			let callAPI = async () => {
				const verifyToken = {
					token:token,
				}
				let res =  await Authenticated(verifyToken)
				if(res.data.status) {
					navigate('/dashboard')
				}
			}
			callAPI()
		}
	}, [navigate]);

	const togglePasswordVisibility = () => {
        setShowPassword(prevState => ({
            ...prevState,	
            password: !prevState.password
        }));
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowPassword(prevState => ({
            ...prevState,
            confirm_password: !prevState.confirm_password
        }));
    };

    // const HandelChange = (event) => {
	//     const { name, value, checked } = event.target;
	// 	if (name === 'sports') {
	// 		const updatedSports = [...FormData.sports];
	// 		if (checked) {
	// 		  updatedSports.push(value);
	// 		} else {
	// 		  const index = updatedSports.indexOf(value);
	// 		  if (index !== -1) {
	// 			updatedSports.splice(index, 1);
	// 		  }
	// 		}
	  
	// 		// Update the FormData state with the new sports array
	// 		setFormData({ ...FormData, sports: updatedSports });
	// 	  } else {
	// 		// For other input fields, update the FormData state normally
	// 		setFormData({ ...FormData, [name]: value });
	// 	  }
    // }
	const HandelChange = (event) => {
    	const { name, value } = event.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
    }
    const HandelSubmit = async (event) => {
    	event.preventDefault();
		const validationErrors = validateFormData();

		if (!Object.keys(validationErrors).length > 0 && !DisableRegister) {
			setDisableRegister(true);
			let res = await RegisterUser(FormData)
			if(res.data.status) {
				localStorage.setItem('verifyToken', res.data.token);
				enqueueSnackbar(t.register_success, { variant: 'success' });
				navigate('/verification' , { state: FormData });
			}
			else {
				enqueueSnackbar(res.data.message, { variant: 'error' });
			}
			setDisableRegister(false);
		}
    }

    const User_exist = async (event) => {
		console.log(event.target.name)
		if (event.target.name === 'email') {
			if (/\S+@\S+\.\S+/.test(FormData.email)) {
				let res = await UserExist(FormData.email,event.target.name)
				if (res.status !== undefined && res.status ) {
					setUserExist(true);
					setErrors({ ...errors, 'email': t.email_already_exist });
					return;
				}
			}
		}

		if (event.target.name === 'corporateEmail') {
			if (/\S+@\S+\.\S+/.test(FormData.corporateEmail)) {
				let res = await UserExist(FormData.corporateEmail,event.target.name)
				if (res.status !== undefined && res.status ) {
					setUserExist(true);
					setErrors({ ...errors, 'corporate_email': t.corporate_email_already_exist });
					return;
				}
			}
		}
		
		setUserExist(false);
    }

   const validateFormData = () => {
		let temp_errors = {};

		if (!FormData.firstName.trim()) {
			temp_errors.firstName = t.f_name_required;
		}

		if (!FormData.lastName.trim()) {
		temp_errors.lastName = t.l_name_required;
		}

		if (!/\S+@\S+\.\S+/.test(FormData.email)) {
			temp_errors.email = t.invalid_email;
		}
		if (temp_errors.email === undefined && userexist) {
			temp_errors.email = t.email_already_exist;
		}

		if (!/\S+@\S+\.\S+/.test(FormData.corporateEmail)) {
			temp_errors.corporate_email = t.invalid_corporate_email;
		}
		
		if (temp_errors.corporate_email === undefined && userexist) {
			temp_errors.corporate_email = t.corporate_email_already_exist;
		}

		if(temp_errors.email === undefined && temp_errors.corporate_email === undefined && FormData.email === FormData.corporateEmail) {
			temp_errors.corporate_email = t.email_and_corporate_email_cant_be_same;
		}

		if (!FormData.password.trim()) {
			temp_errors.password = t.password_required;
		} else if (!/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]).{8,}$/.test(FormData.password)) {
			temp_errors.password = t.password_criteria;
			setCriteriaShow(true);
		}

		if (!FormData.confirmPassword.trim()){
			temp_errors.confirmPassword = t.confirm_password_required;
		} else if(FormData.password !== FormData.confirmPassword) {
			temp_errors.confirmPassword = t.confirm_password_not_match;
		}
		setErrors(temp_errors);
		return temp_errors ;
    };

   const PasswordValidation= (e) => {
		const { value } = e.target;
		const tempCriteria = {};
		tempCriteria.length = value.length >= 8;
		tempCriteria.uppercase = /[A-Z]/.test(value);
		tempCriteria.digit = /\d/.test(value);
		tempCriteria.special_character = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
		setPasswordCriteria(tempCriteria);
	}

	useEffect(() => {
		if(Object.keys(errors).length) {
			 validateFormData();
		}
    }, [FormData, errors, validateFormData]);
   return (
    <>
      	<FormContainer>
        	<form className='login-form mt-3' onSubmit={HandelSubmit}>
				<label className='form-title mb-2'> REGISTRATION </label>
				<p className='form-heading'> Registration fill the form and enjoy games app . </p>
				

				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="firstName">First Name</label>
					<input
						type='text'
						name='firstName'
						pattern="^\S+$"
						placeholder='First Name'
						onChange={HandelChange}
						value={FormData.firstName}
						className={`${errors.firstName ? 'border-danger' : 'border-gray'} mb-2 form-control bg-transparent form-input`}
						required
					/>
					{errors.firstName && <span className='text-danger'>{errors.firstName}</span>}
				</div>

				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="lastName">Last Name</label>
					<input
						type='text'
						name='lastName'
						pattern="^\S+$"
						placeholder='Last Name'
						onChange={HandelChange}
						value={FormData.lastName}
						className={`${errors.lastName ? 'border-danger' : 'border-gray'} mb-2 form-control bg-transparent form-input`}
						required
					/>
					{errors.lastName && <span className='text-danger'>{errors.lastName}</span>}
				</div>

				<div className="mb-3">
				<label className='mb-2 input-label' htmlFor="username">Username</label>
				<input
					type='text'
					name='username'
					pattern="^\S+$"
					placeholder='Username'
					onChange={HandelChange}
					value={FormData.username}
					className='mb-2 form-control bg-transparent border-gray form-input'
					required
				/>
				</div>

				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="password">Password</label>
					<div className="position-relative">
						<input
							type={showPassword.password ? "password" : "text"}
							name='password'
							placeholder='Password'
							className='form-control bg-transparent form-input'
							onChange={(e) => {HandelChange(e); PasswordValidation(e);}}
							onFocus={()=> {setCriteriaShow(true)}}
							onBlur={()=> {setCriteriaShow(false)}}
							value={FormData.password}
							required
						/>
						<i className="btn border-0 position-absolute end-0 top-0" type="button" name="password" onClick={togglePasswordVisibility}>
							{
								showPassword.password ? <EyeIcon /> : <EyeSlashFillIcon />
							}
						</i>
					</div>
				</div>

				<div>
					{ 
						!CriteriaShow && !FormData.password.trim() ? 
							errors.password && <span className='text-danger'>{errors.password}</span>
							: 
							
							<span className={PasswordCriteria.length && PasswordCriteria.uppercase && PasswordCriteria.digit && PasswordCriteria.special_character ? 'text-success' : 'text-danger'}>

							<span className={PasswordCriteria.length !== undefined && PasswordCriteria.length ? 'text-success' : 'text-danger'} data-length={PasswordCriteria.length}>Password must be at least 8 characters long</span> <br/>  
							
							<span className={PasswordCriteria.uppercase!== undefined && PasswordCriteria.uppercase ? 'text-success' : 'text-danger'}  data-length={PasswordCriteria.uppercase}>Contain at least one uppercase letter</span><br/> 
							
							<span className={PasswordCriteria.digit !== undefined && PasswordCriteria.digit ? 'text-success' : 'text-danger'}  data-length={PasswordCriteria.digit}>One digit</span>, and 
							
							<span className={PasswordCriteria.special_character !== undefined && PasswordCriteria.special_character ? 'text-success' : 'text-danger'}  data-length={PasswordCriteria.special_character}> one special character</span>.
							<br/> <br/></span>
					}
				</div>
					 
				<div className="mb-3">
					<label className='mb-3 input-label' htmlFor="password">Confirm Password</label><br />
					<div className="position-relative">

						<input
							type={showPassword.confirm_password ? "password" : "text"}
							name='confirmPassword'
							placeholder='Confirm Password '
							className='form-control bg-transparent form-input'
							onChange={HandelChange}
							value={FormData.confirmPassword}
							required
						/>
						<i className="btn border-0 position-absolute end-0 top-0" onClick={toggleConfirmPasswordVisibility}>
							{
								showPassword.confirm_password ? <EyeIcon /> : <EyeSlashFillIcon />
							}
						</i>
					</div> 
					{errors.confirmPassword && <span className='text-danger'>{errors.confirmPassword}</span>}
				</div>

				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="email">Email</label><br />
					<input
						type='text'
						name='email'
						placeholder='Email'
						className='mb-4 form-control bg-transparent form-input'
						onChange={HandelChange}
						onBlur={User_exist}
						value={FormData.email}
						required
					/>
					{errors.email && <span className='text-danger'>{errors.email}</span>}
				</div>

				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="corporateEmail">Corporate Email</label>
					<input
						type='text'
						name='corporateEmail'
						placeholder='Corporate Email'
						className='mb-3 form-control bg-transparent form-input'
						onChange={HandelChange}
						onBlur={User_exist}
						value={FormData.corporateEmail}
						required
					/>
					{errors.corporate_email && <span className='text-danger'>{errors.corporate_email}</span>}
				</div>
				<div className="mb-3">
					<label className='mb-2 input-label' htmlFor="gender">Gender</label>
					<select
						name='gender'
						className='mb-4 form-control bg-transparent form-input'
						onChange={HandelChange}
						value={FormData.gender}
						required
					>
						<option value="" disabled selected>
						Select a Gender
						</option>
						<option  value='male'>Male</option>
						<option  value='female'>Female</option>
					</select>
				</div>


				{/* <div className='mb-3'>
					<label  className="input-label mb-2">
						Select a Sport   
					</label>
					<select 
					className="form-select form-input"
					onChange={HandelChange}
					value={FormData.sport}
					name='sport'
					required
					>
					<option selected value=''>---select a value ---</option>
					<option value="badmintion">Badmiton</option>
					<option value="bowling">Bowling</option>
					<option value="tenis">Table Tennis</option>
					<option value="running">5K Run</option>
					</select>
				</div> */}
				<div>
					<input type="checkbox" name="check" value='true' onChange={HandelChange} required/>
					<label className='ms-3 mb-5 signup-terms' htmlFor="T&C">I agree to the Terms and Privacy Policy</label>
				</div>
				<button
					className='btn btn-light w-100 border-0 px-3 py-3 fs-5 fw-bold rounded-3 submit-button text-white' type='submit' disabled={DisableRegister || userexist} > Create Account </button>
				<span className='float-end mt-3 below-button'>Already have an account ? <span class="cursor-pointer heading-color" onClick={()=>{navigate('/login')}}>Login</span></span>
			</form>
		</FormContainer>
    </>
  )
}
export default Register
