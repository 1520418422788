import {
    PaymentElement,
  } from '@stripe/react-stripe-js'
import {useEffect, useState} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import Loader from '../../views/admin/Common/Loader';
import { useSnackbar } from 'notistack';
import {useNavigate} from"react-router-dom";
import {GenrateMatch, updateSport, CurrentTournament, RemoveWaitlist} from '../../API/player';
  
  export default function CheckoutForm({sports, waitList, waitListPlayer}) {
    console.log("from checkout", waitListPlayer);
    
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderStatus, setloaderStatus] = useState(false);
    var participantId = '';
    var tournamentId = '';
    useEffect(()=>{
      let callAPI = async () => {
      
    }
      callAPI();
    },[])
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        console.log("Herere")
        setMessage('Please fill all the details')
        return;
      }
  
      
      try {
        setloaderStatus(true);
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: "https://google.com",
          },
          redirect: "if_required",
        });
        
        if (error) {
          setloaderStatus(false);
          enqueueSnackbar(error, { variant: 'error' });

        } else if (paymentIntent && paymentIntent.status === "succeeded") {
          if(sports.length > 0) {
              let res =  await CurrentTournament()
              if(res.status) {
                tournamentId =  res.data.data.id;
                let resSport = await updateSport({tournament_id: res.data.data.id, sports: sports, status: waitList ? 'waiting' :'approved', payment_intent:paymentIntent})
                if (resSport.data ) {
                  console.log("genrating matcjh")
                  participantId = resSport.data.data.player_id;
                  let formData = {
                    'tournament_id' : tournamentId,
                    'participant_id' : participantId,
                    'badminton': sports.includes('badminton') ? true :false,
                    'table_tenis': sports.includes('tennis') ? true : false,
                    'bowling': sports.includes('bowling') ? true : false,
                    'twokrun': sports.includes('twok_run') ? true : false,
                    // 'twokrun': sports.includes('2k_run') ? true : false,
  
                  }
                  let resMatch = await GenrateMatch(formData);
                  if(resMatch.status) {
                    let formDataWait = {
                      'tournament_id' : tournamentId,
                      'participant_id' : participantId,
                      'game_type' : sports.includes('badminton') ? 'badminton' :sports.includes('tennis') ? 'table_tenis' : sports.includes('bowling') ? 'bowling' :  sports.includes('twok_run')? '2k_run': null
                    }
                    if (waitListPlayer) {
                      console.log("Calling removing API")
                      let res = await RemoveWaitlist(formDataWait);
                      console.log(res)
                      if (res.data.status || res.status) {
                        console.log("user remobed in true");
                        
                        setloaderStatus(false);
                        enqueueSnackbar('Welcome to CPL', { variant: 'success' });
                        navigate('/dashboard');
  
                      } else {
                        setloaderStatus(false);
                        enqueueSnackbar('Something went wrong', { variant: 'error' });
                        navigate('/logout');
                      }

                    } else {
                      console.log("not removing API")
                      console.log("user remobed in false");

                      setloaderStatus(false);
                      enqueueSnackbar('Welcome to CPL', { variant: 'success' });
                      navigate('/dashboard');
                    }
    
                  } else {
                    setloaderStatus(false);
                    enqueueSnackbar('unable to genrate match', { variant: 'error' });
                    navigate('/logout');

                  }

                } 
                  //     if (waitList) {
                  //       let FormData = {
                  //         "game_type" : sports.includes('badminton') ? 'badminton' : sports.includes('tennis') ? 'table_tenis' : sports.includes('bowling')  ? 'bowling' :sports.includes('twok_run')? '2k_run' : 'N/A',
                  //         "type":"waiting",
                  //       }
                  //         let res = await enrolledWaiting (FormData);
                  //         if (res.data.status) {
                  //           setloaderStatus(false);
                  //         enqueueSnackbar('Wating List joined Successfully', { variant: 'success' });
                  //         navigate('/dashboard');
                  //         } else {
                  //         console.log("Check again");

                  //         }

                  //       console.log("Wait list done yooo");
                        
                  //     } else {
                  //       // remove code
                  // } 
                 else {
                  enqueueSnackbar('Unable to participate in sports', { variant: 'error' });

                 }
                // const promises = sports.map(async(sport) => {
                // }); 
                // const results = await Promise.all(promises);
                // console.log(participantId); 
                
              } else {
                  enqueueSnackbar('No Tournament is live', { variant: 'error' });
               }
            }
        } else {
          enqueueSnackbar('Payment failed', { variant: 'error' });
        }
        } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  
    return (
      <>
      {loaderStatus ? 
                <Loader />
            : null}
        <form id="payment-form" onSubmit={handleSubmit}>
          
          <PaymentElement id="payment-element" />
          <button className='mt-3 float-end btn btn-light submit-button text-white' disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text ">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </>
    )
  }