import React, { useEffect, useState } from 'react'
import Loader from '../Common/Loader'
import { GetStandings } from '../../../API/admin'
import { useSnackbar } from 'notistack'
import {
  submitScoreCard,
  SportsLogs,
  GetBowlingMatch,
  Get2kMatch,
  SwapUser
} from '../../../API/admin'

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTableRow,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CTooltip,
  CButton,
  CModalFooter,
  CFormInput,
  CFooter,
  CFormSelect,
  CFormLabel
} from '@coreui/react'

import { cilSave, cilSwapHorizontal, cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Form, useFetcher } from 'react-router-dom'

const GroupStandings = ({
  visible,
  onClose,
  group,
  sport,
  groupData,
  allGroups,
  userSwap,
  round,
  showedit
}) => {
  console.log("i am in goufddddd");
  console.log('groupData', groupData)
  const { enqueueSnackbar } = useSnackbar()
  const [paSatup, setPaSatup] = useState([])
  const [pfSatup, setPfSatup] = useState([])
  const [pdSatup, setPdSatup] = useState([])
  const [editMode, setEditMode] = useState(true)
  const [editModeBowling, setEditModeBowling] = useState(false)
  const [editBowlingScore, setEditBowlingScore] = useState(false)
  const [Standigs, setStandings] = useState([])
  const [players, playerData] = useState([])
  const [playerScoreCard, setplayerScoreCard] = useState([])
  const [playerScoreCard_2k, setplayerScoreCard_2k] = useState([])
  const [loaderStatus, setloaderStatus] = useState(true)
  const [SwapVisible, setSwapVisible] = useState({
    status: false,
    player: null
  })
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedPlayer, setSelectedPlayer] = useState('')
  const [playersData, setPlayersData] = useState([])
  const [dateValue, setDateValue] = useState(null)
  let [state, setState] = useState(false)
  const [MatchStatus, setMatchStatus] = useState('pending')
  const handelOnChangePA = (index, event) => {
    console.log(event.target)
    const newValues = [...paSatup]
    newValues[index] = event.target.value
    setPaSatup(newValues)
  }
  console.log(dateValue, 'datevalue')
  const handleStandingsEdit = () => {
    setEditMode(prevState => !prevState)
  }

  const handelOnChangePF = (index, event) => {
    const newValues = [...pfSatup]
    newValues[index] = event.target.value
    setPfSatup(newValues)
  }

  useEffect(() => {
    let callAPI = async () => {
      let res = await GetStandings(group)
      if (res.status) {
        setloaderStatus(false)
        setStandings(res.data)
        const PAValues = res.data.map(player => Number(player.PA))
        const PFValues = res.data.map(player => Number(player.PF))
        setPaSatup(PAValues)
        setPfSatup(PFValues)
      } else {
        setloaderStatus(false)
        enqueueSnackbar('No standings foud for the group', { variant: 'error' })
      }
    }
    if (sport != 'bowling' && sport != '2k_run') {
      callAPI()
    }
  }, [group])
  useEffect(() => {
    if (paSatup.length > 0 && pfSatup.length > 0) {
      const pdValues = paSatup.map((value, index) =>
        parseInt(value - pfSatup[index])
      )
      setPdSatup(pdValues)
    }
  }, [paSatup, pfSatup])

  useEffect(() => {
    if (sport == 'bowling') {
      let scores = []
      for (let i = 0; i < groupData.length; i++) {
        scores[i] = {
          _id: groupData[i].playerId,
          strikes: 0,
          spares: 0,
          points: 0
        }
      }
      let sortedscorecard = [];
      for (let i = 0; i < groupData.length; i++) {
        // sorteddata.push(groupData[i]);
        let data = groupData[i];
        for (let j = 0; j < scores.length; j++) {
          if (data.playerId == scores[j]._id) {
            sortedscorecard.push({
              ...data,
              points: scores[j].points,
              spares: scores[j].spares,
              strikes: scores[j].strikes,
              _id: scores[j]._id
            });
          }

        }


      }
      sortedscorecard.sort((a, b) => {
        // Sort by points descending
        if (b.points !== a.points) {
          return b.points - a.points;
        }

        // If points are tied, sort by strikes descending
        if (b.strikes !== a.strikes) {
          return b.strikes - a.strikes;
        }

        // If strikes are tied, sort by spares descending
        return b.spares - a.spares;
      });
      setplayerScoreCard(sortedscorecard)
      console.log("Player has came")
      // setplayerScoreCard(playerScore)
    } else if (sport == '2k_run') {
      let playerScore_2K = []
      for (let i = 0; i < groupData.length; i++) {
        playerScore_2K[i] = {
          _id: groupData[i].playerId,
          minutes: 0,
          second: 0,
          millisecond: 0,
          totalSeconds:0
        }
      }

      let sortedscorecard = []
      for (let i = 0; i < groupData.length; i++) {
        // sorteddata.push(groupData[i]);
        let data = groupData[i]
        for(let j = 0 ; j<playerScore_2K.length ; j++)
          {
            if (data.playerId == playerScore_2K[j]._id)
              {
                sortedscorecard.push({
                  ...data,
                  minutes: playerScore_2K[j].minutes,
                  second: playerScore_2K[j].second,
                  millisecond: playerScore_2K[j].millisecond,
                  _id: playerScore_2K[j]._id,
                  totalSeconds : playerScore_2K[j].totalSeconds
                })
              }

          }
       
      }
      sortedscorecard.sort((a, b) => {
        if (a.totalSeconds === 0 && b.totalSeconds === 0) {
          return 0; // Leave them in their current order
        } else if (a.totalSeconds === 0) {
          return 1; // a should come after b (b has a non-zero totalSeconds)
        } else if (b.totalSeconds === 0) {
          return -1; // b should come after a (a has a non-zero totalSeconds)
        } else {
          return a.totalSeconds - b.totalSeconds; // Standard comparison for non-zero values
        }
      });
      

      
      console.log('Player has came')
      setplayerScoreCard_2k(sortedscorecard)
    }
  }, [])
  console.log("showedit" , showedit);

  useEffect(() => {
    setloaderStatus(false)
    if (sport == 'bowling') {

      let getBowlingScore = async () => {
        //get match detail by group id if match score is avialable set edit mode true
        let formData = {
          group_id: group
        }
        
        let res = await GetBowlingMatch(formData)
        if (res.data.status) {
          if (res.data.data.submited_at != null) {
            let scores = res.data.data.players
            let sortedscorecard = []
            for (let i = 0; i < groupData.length; i++) {
              // sorteddata.push(groupData[i]);
              let data = groupData[i];
              for (let j = 0; j < scores.length; j++) {
                if (data.playerId == scores[j]._id) {
                  sortedscorecard.push({
                    ...data,
                    points: scores[j].points,
                    spares: scores[j].spares,
                    strikes: scores[j].strikes,
                    _id: scores[j]._id
                  });
                }
      
              }
            }
            sortedscorecard.sort((a, b) => {
              // Sort by points descending
              if (b.points !== a.points) {
                return b.points - a.points
              }

              // If points are tied, sort by strikes descending
              if (b.strikes !== a.strikes) {
                return b.strikes - a.strikes
              }

              // If strikes are tied, sort by spares descending
              return b.spares - a.spares
            })
            console.log(sortedscorecard)
            
            setplayerScoreCard(sortedscorecard)
           
            if (res.data.data.match_date == null) {
              setDateValue(null)
            } else {
              const matchDate = new Date(res.data.data.match_date)
              const formattedDate = matchDate.toISOString().slice(0, 16) // Format to YYYY-MM-DDTHH:MM
              setDateValue(formattedDate)
            }
            setMatchStatus(res.data.data.status)
            setEditModeBowling(true)
            setEditBowlingScore(true)
          } else {
            const matchDate = new Date(res.data.data.match_date)
            if (res.data.data.match_date == null) {
              setDateValue(null)
            } else {
              const formattedDate = matchDate.toISOString().slice(0, 16) // Format to YYYY-MM-DDTHH:MM
              setDateValue(formattedDate)
            }
            setEditModeBowling(false)
          }
        }
      
      }
      getBowlingScore()
      // setEditModeBowling(false)
    } else if (sport == '2k_run') {
      let get2kScore = async () => {
        //get match detail by group id if match score is avialable set edit mode true
        let formData = {
          group_id: group
        }
        let res = await Get2kMatch(formData)
        if (res.data.status) {
          if (res.data.data.submited_at != null) {
            let playerScore_2K = res.data.data.players
            let sortedscorecard = []
            for (let i = 0; i < groupData.length; i++) {
              // sorteddata.push(groupData[i]);
              let data = groupData[i]
              for(let j = 0 ; j<playerScore_2K.length ; j++)
                {
                 
                  if (data.playerId == playerScore_2K[j]._id)
                    {
                      sortedscorecard.push({
                        ...data,
                        minutes: playerScore_2K[j].minutes,
                        second: playerScore_2K[j].second,
                        millisecond: playerScore_2K[j].millisecond,
                        _id: playerScore_2K[j]._id,
                        totalSeconds : playerScore_2K[j].totalSeconds
                      })
                    }
      
                }
            }

            sortedscorecard.sort((a, b) => {
              if (a.totalSeconds === 0 && b.totalSeconds === 0) {
                return 0; // Leave them in their current order
              } else if (a.totalSeconds === 0) {
                return 1; // a should come after b (b has a non-zero totalSeconds)
              } else if (b.totalSeconds === 0) {
                return -1; // b should come after a (a has a non-zero totalSeconds)
              } else {
                return a.totalSeconds - b.totalSeconds; // Standard comparison for non-zero values
              }
            });
            
            setplayerScoreCard_2k(sortedscorecard)
            const matchDate = new Date(res.data.data.match_date)
            if (res.data.data.match_date == null) {
              setDateValue(null)
            } else {
              const formattedDate = matchDate.toISOString().slice(0, 16) // Format to YYYY-MM-DDTHH:MM
              setDateValue(formattedDate)
            }
            
            setMatchStatus(res.data.data.status)
            setEditModeBowling(true)
            setEditBowlingScore(true)
          } else {
            const matchDate = new Date(res.data.data.match_date)
            if (res.data.data.match_date == null) {
              setDateValue(null)
            } else {
              const formattedDate = matchDate.toISOString().slice(0, 16) // Format to YYYY-MM-DDTHH:MM
              setDateValue(formattedDate)
            }
            setEditModeBowling(false)
          }
        }
      }
      get2kScore()
    }
  }, [group, groupData])
  // useEffect(() => {
  //     let getPlayerData = () => {

  //     }
  // } , [])
  const handledateChange = event => {
    console.log(event.target.value)
    setDateValue(event.target.value)
  }

  const handelscore = (event, index) => {
    console.log(event.target)
    const { name, value } = event.target
    if (sport == 'bowling') {
      setplayerScoreCard(prevState => {
        const newState = [...prevState]


        newState[index][name] = parseInt(value, 10) // Ensure the value is a number
        return newState
      })
    }
    if (sport == '2k_run') {
      setplayerScoreCard_2k(prevState => {
        const newState = [...prevState]
        newState[index][name] = value // Ensure the value is a number
        return newState
      })
    }
  }

  const handlesubmit = async event => {
    let formData = {}
    console.log(playerScoreCard)

    if (sport == 'bowling') {
      const allZero = playerScoreCard.every(
        player =>
          player.points === 0 && player.spares === 0 && player.strikes === 0
      )
      console.log(allZero)

      if (playerScoreCard.length < 6 || allZero) {
        console.log('im herer')
        formData = {
          group_id: group,
          score: null,
          sport: sport,
          match_date: dateValue,
          type: 'date'
        }
      } else {
        formData = {
          group_id: group,
          score: playerScoreCard,
          sport: sport,
          match_date: dateValue,
          type: event.target.value
        }
      }
        
      
    } else {
      if ( (playerScoreCard_2k.length < 25 && round == 'round_1') || (playerScoreCard_2k.length < 24 && round == 'round_2') || (playerScoreCard_2k.length < 12 && round == 'final') ) {
        formData = {
          group_id: group,
          score: null,
          sport: sport,
          match_date: dateValue,
          type: 'date'
        }
      } else {
        formData = {
          group_id: group,
          score: playerScoreCard_2k,
          sport: sport,
          match_date: dateValue,
          type: event.target.value
        }
      }        
    }


    console.log(formData)
    setloaderStatus(true)
    let res = await submitScoreCard(formData)
    if (res.data.status) {
      setloaderStatus(false)
      handleClose()

      enqueueSnackbar('Scores submited successfully', { variant: 'success' })
      window.location.reload()

      
    } else {
      setloaderStatus(false)
      enqueueSnackbar('Unable to submit score', { variant: 'error' })
    }
  }

  function getCurrentDate() {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  const handleGroupChange = event => {
    console.log('callled')
    const groupId = event.target.value

    setSelectedGroup(groupId)

    // Find the selected group
    const group = allGroups.find(group => group._id === groupId)
    console.log(group)

    if (group && group.players_data) {
      console.log(group.players_data)
      setPlayersData(group.players_data)
    } else {
      console.log('emoty')
      setPlayersData([])
    }
  }

  const handlePlayerChange = event => {
    setSelectedPlayer(event.target.value)
  }

  const handleUserSwap = async () => {
    setloaderStatus(true)
    const groupSelect = document.querySelector('select[name="group"]')
    const player_1 =
      groupSelect.options[groupSelect.selectedIndex].getAttribute('data-player')

    console.log('Group 2:', selectedGroup)
    console.log('player 1', player_1)
    console.log('Selected Player ID:', selectedPlayer)
    let formData = {
      user_1: {
        id: player_1,
        group_id: group
      },
      user_2: {
        id: selectedPlayer,
        group_id: selectedGroup
      },
      game_type: sport
    }
    console.log(formData)
    let res = await SwapUser(formData)
    console.log(res)
    if (res.data.status) {
      setloaderStatus(false)
      enqueueSnackbar('Player swaped successfully', { variant: 'success' })
      window.location.reload()
    } else {
      enqueueSnackbar('Unable to swapp player ', { variant: 'error' })
    }
  }
  console.log(MatchStatus)
  const handleClose = () => {
    console.log('state resetriing')
    setDateValue(null)
    setMatchStatus('pending')

    setPlayersData([])
    setSelectedPlayer('')
    setSelectedGroup('')
    // setplayerScoreCard([]);
    setEditMode(false)
    setEditBowlingScore(false)
    setEditModeBowling(false)
    // setState((prev) => !prev);


    // Call the onClose function passed from the parent
    if (onClose) onClose()
    // window.location.reload()
  }
  console.log(playerScoreCard, 'playersss')
  console.log(groupData, 'groupdata')
  //  console.log(playerScoreCard_2k , "setplayerScoreCard_2k");
  return (
    <>
      {loaderStatus ? <Loader /> : null}
      <CModal
        size='xl'
        scrollable
        alignment='center'
        visible={visible}
        // onClose={ ()=>{ setMatchStatus('pending'); setDateValue(null);setPlayersData([]);  setSelectedPlayer(''); setSelectedGroup('') ; setplayerScoreCard([]); setEditBowlingScore(true) ; setEditModeBowling(false); onClose  }}
        onClose={handleClose}
        aria-labelledby='LiveDemoExampleLabel'
      >
        {sport == 'bowling' ? (
          <>
            <CModalHeader>
              <CModalTitle id='LiveDemoExampleLabel' onClose={handleClose}>
                Bowling Scoring
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CTable>
                <CTableHead>
                  <CTableRow className='text-center'>
                    <CTableHeaderCell scope="col">#</CTableHeaderCell>
                     {
                      userSwap != null && userSwap == true ? (
                    <CTableHeaderCell scope='col'> Swap</CTableHeaderCell>

                      ) : null
                     }
                     
                    <CTableHeaderCell scope='col'>Image</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Name</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Strikes</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Spares</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Scores</CTableHeaderCell>
                    {
                      MatchStatus ==='completed' && editBowlingScore == true  ? (
                        <CTableHeaderCell scope='col'>Points</CTableHeaderCell>
                      ) : null
                    }

                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {playerScoreCard.length > 0
                    ? playerScoreCard.map((data, index) => (
                        <>
                          <CTableRow className='text-center' key={data._id}>
                            <CTableHeaderCell className='text-align-center' scope="row">{index+1}</CTableHeaderCell>
                            {userSwap != null && userSwap == true ? (
                              <>
                              <CTableDataCell>
                                <CButton
                                  color='warning'
                                  onClick={() =>
                                    setSwapVisible({
                                      status: true,
                                      player: data.playerId
                                    })
                                  }
                                  className='text-white'
                                >
                                  <CIcon icon={cilSwapHorizontal} />
                                </CButton>

                              </CTableDataCell>
                              </>
                              ) : null}
                            <CTableDataCell>
                              <CTooltip content={data.name}>
                                <a customData={data.name} href='!#'>
                                  <img
                                    src={data.image}
                                    className='group-table-img'
                                    alt='blank_user'
                                  />
                                </a>
                              </CTooltip>
                            </CTableDataCell>
                            <CTableDataCell className='text-align-center'>
                              {data.name}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3  text-center'
                                name='strikes'
                                readOnly={
                                  editBowlingScore || playerScoreCard.length < 6
                                }
                                type='number'
                                size='sm'
                                min={0}
                                value={playerScoreCard[index].strikes}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3 text-center'
                                name='spares'
                                readOnly={
                                  editBowlingScore || playerScoreCard.length < 6
                                }
                                type='number'
                                size='sm'
                                min={0}
                                value={playerScoreCard[index].spares}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3 text-center'
                                name='points'
                                type='number'
                                size='sm'
                                min={0}
                                readOnly={
                                  editBowlingScore || playerScoreCard.length < 6
                                }
                                value={playerScoreCard[index].points}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            {
                              MatchStatus ==='completed' && editBowlingScore == true ? (
                                <CTableDataCell>
                                    {index <=2 ?3 - index:0}
                                </CTableDataCell>
                              ) : null
                            }
                          </CTableRow>
                        </>
                      ))
                    : null}
                </CTableBody>
              </CTable>
            </CModalBody>
            <CFooter className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <input
                  className='form-control'
                  name='match_date'
                  min={getCurrentDate()}
                  type='datetime-local'
                  readOnly={editBowlingScore || MatchStatus === 'completed'}
                  onChange={event => handledateChange(event)}
                  value={dateValue}
                  required
                />
                <span>
                  {/* <CButton color='dark' className='text-white ms-2'>
                                <CIcon icon={cilSave} />
                            </CButton> */}
                </span>
              </div>

              <div className='float-end'>
                {editModeBowling ? (
                  editBowlingScore ? (
                    
                      showedit == true ? (
                        <CButton
                        onClick={() => {
                          setEditBowlingScore(false)
                          setEditModeBowling(true)
                        }}
                        className='ms-auto text-white float-end'
                        color='warning'
                        value={'Edit'}
                      >
                        Edit
                      </CButton>
                      ): null
                    
                   
                  ) : (
                    <CButton
                      onClick={e => {
                        setEditBowlingScore(true)
                        setEditModeBowling(true)
                        handlesubmit(e)
                      }}
                      className='ms-auto float-end'
                      color='primary'
                      value={'edit'}
                    >
                      Save Changes
                    </CButton>
                  )
                ) : (
                  <CButton
                    onClick={e => handlesubmit(e)}
                    className='ms-auto float-end'
                    color='primary'
                    value={'submit'}
                  >
                    Save
                  </CButton>
                )}
              </div>
            </CFooter>
          </>
        ) : sport == '2k_run' ? (
        
          <>
            <CModalHeader>
              <CModalTitle id='LiveDemoExampleLabel' onClose={handleClose}>
                2k Run Scoring
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CTable>
                <CTableHead>
                  <CTableRow className='text-center'>
                    <CTableHeaderCell scope='col'>#</CTableHeaderCell>
                    {
                      userSwap != null && userSwap == true ? (
                    <CTableHeaderCell scope='col'> Swap</CTableHeaderCell>

                      ) : null
                     }
                    <CTableHeaderCell scope='col'>Image</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Name</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Minutes</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Seconds</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>
                      MilliSeconds
                    </CTableHeaderCell>
                    {
                      MatchStatus ==='completed' && editBowlingScore == true  ? (
                        <CTableHeaderCell scope='col'>Points</CTableHeaderCell>
                      ) : null
                    }
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  { playerScoreCard_2k.length > 0
                    ? playerScoreCard_2k.map((data, index) => (
                        <>
                          <CTableRow className='text-center' key={data._id}>
                            <CTableHeaderCell
                              className='text-align-center'
                              scope='row'
                            >
                              {index + 1}
                            </CTableHeaderCell>
                              {userSwap != null && userSwap == true ? (
                            <CTableDataCell>
                                <CButton
                                  color='warning'
                                  onClick={() =>
                                    setSwapVisible({
                                      status: true,
                                      player: data.playerId
                                    })
                                  }
                                  className='text-white'
                                >
                                  <CIcon icon={cilSwapHorizontal} />
                                </CButton>
                            </CTableDataCell>
                              ) : null}
                            <CTableDataCell>
                              <CTooltip content={data.name}>
                                <a customData={data.name} href='!#'>
                                  <img
                                    src={data.image}
                                    className='group-table-img '
                                    alt='blank_user'
                                  />
                                </a>
                              </CTooltip>
                            </CTableDataCell>
                            <CTableDataCell className='text-align-center'>
                              {data.name}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3  text-center'
                                name='minutes'
                                readOnly={
                                  editBowlingScore ||
                                  (playerScoreCard_2k.length < 25 &&
                                    round == 'round_1') ||
                                  (playerScoreCard_2k.length < 24 &&
                                    round == 'round_2') ||
                                  (playerScoreCard_2k.length < 12 &&
                                    round == 'final')
                                }
                                type='number'
                                size='sm'
                                min={0}
                                max={60}
                                value={playerScoreCard_2k[index].minutes}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3 text-center'
                                name='second'
                                readOnly={
                                  editBowlingScore ||
                                  (playerScoreCard_2k.length < 25 &&
                                    round == 'round_1') ||
                                  (playerScoreCard_2k.length < 24 &&
                                    round == 'round_2') ||
                                  (playerScoreCard_2k.length < 12 &&
                                    round == 'final')
                                }
                                type='number'
                                size='sm'
                                min={0}
                                max={60}
                                value={playerScoreCard_2k[index].second}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            <CTableDataCell>
                              <CFormInput
                                className='border-3 text-center'
                                name='millisecond'
                                type='number'
                                size='sm'
                                min={0}
                                max={100}
                                readOnly={
                                  editBowlingScore ||
                                  (playerScoreCard_2k.length < 25 &&
                                    round == 'round_1') ||
                                  (playerScoreCard_2k.length < 24 &&
                                    round == 'round_2') ||
                                  (playerScoreCard_2k.length < 12 &&
                                    round == 'final')
                                }
                                value={playerScoreCard_2k[index].millisecond}
                                onChange={e => handelscore(e, index)}
                              ></CFormInput>
                            </CTableDataCell>
                            {
                              MatchStatus ==='completed' && editBowlingScore == true ? (
                                <CTableDataCell>
                                    {index <=2 ?3 - index:0}
                                </CTableDataCell>
                              ) : null
                            }
                            {/* <CTableDataCell className='text-align-center'> {data.win}</CTableDataCell>
                                                <CTableDataCell className=''>{data.lost}</CTableDataCell>
                                                <CTableDataCell className=''>{data.tied}</CTableDataCell>
                                                <CTableDataCell className=''>{data.points}</CTableDataCell>
                                                <CTableDataCell className=''>{data.PF}</CTableDataCell>
                                                <CTableDataCell className=''>{data.PA}</CTableDataCell>
                                                <CTableDataCell className=''>{data.PD}</CTableDataCell> */}
                          {/* 
                                                <CTableDataCell>
                                                    <input type='number' min={0}  onChange={(e) => handelOnChangePA(index, e)} value={paSatup[index]} readOnly={editMode} style={{width:"100px"}} className="" />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <input type='number' min={0}  style={{width:"100px"}} onChange={(e) => handelOnChangePF(index, e)} value={pfSatup[index]} readOnly={editMode}   valueclassName="form-control "  />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                {pdSatup[index]}
                                                </CTableDataCell> */}
                        </CTableRow>
                      </>
                    ))
                    : null}
                </CTableBody>
              </CTable>
            </CModalBody>
            <CFooter className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <input
                  className='form-control'
                  name='match_date'
                  min={getCurrentDate()}
                  type='datetime-local'
                  readOnly={editBowlingScore || MatchStatus == 'completed'}
                  onChange={event => handledateChange(event)}
                  value={dateValue}
                  required
                />
                <span>
                  {/* <CButton color='dark' className='text-white ms-2'>
                                <CIcon icon={cilSave} />
                            </CButton> */}
                </span>
              </div>

              <div className='float-end'>
                {editModeBowling ? (
                  editBowlingScore ? (
                    showedit == true ? (
                      <CButton
                      onClick={() => {
                        setEditBowlingScore(false)
                        setEditModeBowling(true)
                      }}
                      className='ms-auto text-white float-end'
                      color='warning'
                      value={'Edit'}
                    >
                      Edit
                    </CButton>
                    ): null
                  
                  ) : (
                    <CButton
                      onClick={e => {
                        setEditBowlingScore(true)
                        setEditModeBowling(true)
                        handlesubmit(e)
                      }}
                      className='ms-auto float-end'
                      color='primary'
                      value={'edit'}
                    >
                      Save Changes
                    </CButton>
                  )
                ) : (
                  <CButton
                    onClick={e => handlesubmit(e)}
                    className='ms-auto float-end'
                    color='primary'
                    value={'submit'}
                  >
                    Save
                  </CButton>
                )}
              </div>
            </CFooter>
          </>
        )  : (
          <>
            <CModalHeader onClose={onClose}>
              <CModalTitle id='LiveDemoExampleLabel'>
                Group Standings
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CTable>
                <CTableHead>
                  <CTableRow className='text-center'>
                    <CTableHeaderCell scope='col'>#</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Participant</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Won</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Lost</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Tied</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>Points</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>PF</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>PA</CTableHeaderCell>
                    <CTableHeaderCell scope='col'>PD</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {Standigs.length > 0 && paSatup.length > 0
                    ? Standigs.map((data, index) => (
                      <>
                        <CTableRow className='text-center' key={data._id}>
                          <CTableHeaderCell
                            className='text-align-center'
                            scope='row'
                          >
                            {index + 1}
                          </CTableHeaderCell>
                          <CTableDataCell className='text-align-center'>
                            {data.player_id.fname +
                              ' ' +
                              data.player_id.lname}
                          </CTableDataCell>
                          <CTableDataCell className='text-align-center'>
                            {' '}
                            {data.win}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.lost}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.tied}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.points}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.PF}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.PA}
                          </CTableDataCell>
                          <CTableDataCell className=''>
                            {data.PD}
                          </CTableDataCell>
                          {/* 
                                                <CTableDataCell>
                                                    <input type='number' min={0}  onChange={(e) => handelOnChangePA(index, e)} value={paSatup[index]} readOnly={editMode} style={{width:"100px"}} className="" />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                    <input type='number' min={0}  style={{width:"100px"}} onChange={(e) => handelOnChangePF(index, e)} value={pfSatup[index]} readOnly={editMode}   valueclassName="form-control "  />
                                                </CTableDataCell>
                                                <CTableDataCell>
                                                {pdSatup[index]}
                                                </CTableDataCell> */}
                        </CTableRow>
                      </>
                    ))
                    : null}
                </CTableBody>
              </CTable>
            </CModalBody>
          </>
        )}
      </CModal>

      {/* swap modal */}
      <CModal
        alignment='center'
        visible={SwapVisible.status}
        onClose={() =>
          setSwapVisible({
            status: false,
            player: null
          })
        }
        aria-labelledby='VerticallyCenteredExample'
      >
        <CModalHeader>
          <CModalTitle id='VerticallyCenteredExample'>Swap Player</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <label htmlFor='groupselect'> group</label> <br />
          <select
            className='w-100 mb-3 form-select'
            name='group'
            id=''
            onChange={handleGroupChange}
          >
            <option value=''> Group</option>
            {allGroups
              .filter(groupData => groupData._id != group) // Filter out the record with groupData._id == group
              .map((groupData, index) => (
                <option
                  key={index}
                  data-player={SwapVisible.player}
                  name={groupData.name}
                  value={groupData._id}
                >
                  {groupData.name}
                </option>
              ))}
          </select>
          <br />
          <label htmlFor='player'> Select a player</label> <br />
          <select
            className='w-100 mb-3 form-select'
            name='players'
            id='players'
            onChange={handlePlayerChange}
          >
            <option value=''> player</option>
            {playersData.map((player, index) => (
              <option key={index} value={player._id}>
                {player.fname} {player.lname}
              </option>
            ))}
          </select>
          {/* sampel code */}
          {/* <CFormLabel className='mt-3' htmlFor="basic-url">Player</CFormLabel>
            <CFormSelect 
                aria-label="Default select example"
                options={[
                    'Open this select menu',
                    { label: 'One', value: '1' },
                    { label: 'Two', value: '2' },
                    { label: 'Three', value: '3', disabled: true }
                ]}
            /> */}
        </CModalBody>
        <CModalFooter>
          <CButton
            color='secondary'
            onClick={() =>
              setSwapVisible({
                status: false,
                player: null
              })
            }
          >
            Close
          </CButton>
          <CButton color='primary' onClick={e => handleUserSwap()}>
            Swap Player
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
export default GroupStandings
