import React, { useEffect, useState } from 'react'
import Loader from '../Common/Loader'
import {FormatDate , newFormatdate} from '../../../../src/Common/CommonFunction'
import { GetSportLogs } from '../../../API/admin'
import { useSnackbar } from 'notistack'
// import {
//   submitScoreCard,
//   GetBowlingMatch,
//   Get2kMatch,
//   SwapUser
// } from '../../../API/admin'

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CTableRow,
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
  CTooltip,
  CButton,
  CModalFooter,
  CFormInput,
  CFooter,
  CFormSelect,
  CFormLabel,
  CRow,
  CCol
} from '@coreui/react'

import { cilSave, cilSwapHorizontal, cilGraph,cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Form, useFetcher } from 'react-router-dom'
const SportsLogs = ({Logvisible,
    onClose,
    group,
    sport,
    groupData,
    allGroups,
    userSwap,
    round,
    showedit}) => {
console.log(groupData);
const [player ,setPlayer] = useState(groupData.length ? groupData[0].playerId : null ) ;   
const [playerImageData ,setPlayerImageData] = useState(groupData[0]) ;     
const [logs, setLogs] = useState([]);
const { enqueueSnackbar } = useSnackbar();


useEffect(()=> {
  let callAPI = async() => {
    let formData = {
      "player_id" : player,
      "group_id" : group,
      "sport" : sport
    }
    let res = await GetSportLogs(formData);
    console.log(res)
    if (res.data.status) {
      if (res.data.data.length) {
        enqueueSnackbar("Logs Fetched successfully", { variant: 'success' });
      } else {
      enqueueSnackbar("Unable to fetch logs", { variant: 'error' });

      }
      setLogs(res.data.data)
    } else {
      enqueueSnackbar("Something went wrong", { variant: 'error' });

    }
  }

callAPI()
},[player])
let handelPlayerChange = (e) => {
  setPlayer(e.target.value)
  const temp = groupData.find((dataTemp) => dataTemp.playerId == e.target.value);
  setPlayerImageData(temp)
}

return (
    <>
      <CModal
        alignment="center"
        visible={Logvisible}
        onClose={onClose}
        size='xl'
        aria-labelledby="ScrollingLongContentExampleLabel"
      >
        {
          sport =='bowling' ? (
            <>
              <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel">Bowling Match Logs</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <div className='d-flex justify-content-between'>
            <CCol xxl={8}>
              {
                player != null && playerImageData != null ? (
                  <>
                  <CTooltip className='text-dark' content={playerImageData.name}>
                        <a customData={playerImageData.name} href='!#'>
                          <img
                            src={playerImageData.image}
                            className='group-table-img'
                            alt='blank_user'
                          />
                        </a>
                      </CTooltip>
                      <span className='ms-3'>{playerImageData.name}</span>
                  </>

                ) : null
              }
            
            </CCol>
              <CCol xxl={4}>
                <select className='float-end form-select' style={{width:'180px'}}  onChange={(e) => handelPlayerChange(e)} name="player" id="">
                    {
                      groupData.map ((player, index) => (
                    <option value={player.playerId}>{player.name}</option>

                      ))
                    }
                </select>
              </CCol>
            </div>
          
          </CRow>
          <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">#</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Strikes</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Spares</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Points</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Submited By</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Submited At</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Type</CTableHeaderCell>

                </CTableRow>
              </CTableHead>
              <CTableBody>
              {logs.length > 0 ? (
                logs.map((data, index) => (
                  <CTableRow key={index}>
                    {console.log(data)}
                    <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                    <CTableDataCell>{data.strikes}</CTableDataCell>
                    <CTableDataCell>{data.spares}</CTableDataCell>
                    <CTableDataCell>{data.points}</CTableDataCell>
                    <CTableDataCell>{data.submited_by}</CTableDataCell>
                    <CTableDataCell>{ newFormatdate(data.submited_at , 1)}</CTableDataCell>
                    <CTableDataCell>
                          {
                            data.type === 'submit' ? (
                              <span className='badge bg-info text-white'>
                                Submit
                              </span>
                            ) : (
                              data.type === 'edit' ? (
                                <span className='badge bg-danger text-white'>
                                  Updated
                                </span>
                              ) : null
                            )
                          }
                    </CTableDataCell>

                  </CTableRow>
                ))
              ) : (
                null
              )}
</CTableBody>
          </CTable>
        </CModalBody>
            </>
          ) : (
            <>

<CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel">Bowling Match Logs</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <div className='d-flex justify-content-between'>
            <CCol xxl={8}>
              {
                player != null && playerImageData != null ? (
                  <>
                  <CTooltip className='text-dark' content={playerImageData.name}>
                        <a customData={playerImageData.name} href='!#'>
                          <img
                            src={playerImageData.image}
                            className='group-table-img'
                            alt='blank_user'
                          />
                        </a>
                      </CTooltip>
                      <span className='ms-3'>{playerImageData.name}</span>
                  </>

                ) : null
              }
            
            </CCol>
              <CCol xxl={4}>
                <select className='float-end form-select' style={{width:'180px'}}  onChange={(e) => handelPlayerChange(e)} name="player" id="">
                    {
                      groupData.map ((player, index) => (
                    <option value={player.playerId}>{player.name}</option>

                      ))
                    }
                </select>
              </CCol>
            </div>
          
          </CRow>
          <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">#</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Minutes</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Seconds</CTableHeaderCell>
                  <CTableHeaderCell scope="col">MillieSeconds</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Submited By</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Submited At</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Type</CTableHeaderCell>

                </CTableRow>
              </CTableHead>
              <CTableBody>
              {logs.length > 0 ? (
                logs.map((data, index) => (
                  <CTableRow key={index}>
                    {console.log(data)}
                    <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                    <CTableDataCell>{data.minutes}</CTableDataCell>
                    <CTableDataCell>{data.second}</CTableDataCell>
                    <CTableDataCell>{data.millisecond}</CTableDataCell>
                    <CTableDataCell>{data.submited_by}</CTableDataCell>
                    <CTableDataCell>{ newFormatdate(data.submited_at , 1)}</CTableDataCell>
                    <CTableDataCell>
                          {
                            data.type === 'submit' ? (
                              <span className='badge bg-info text-white'>
                                Submit
                              </span>
                            ) : (
                              data.type === 'edit' ? (
                                <span className='badge bg-danger text-white'>
                                  Updated
                                </span>
                              ) : null
                            )
                          }
                    </CTableDataCell>

                  </CTableRow>
                ))
              ) : (
                null
              )}
</CTableBody>
          </CTable>
        </CModalBody>
            </>
          )
        }
        
        <CModalFooter>
          <CButton color="secondary" onClick={() => onClose()}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
)
}
export default SportsLogs
