import React from 'react'
import CIcon from '@coreui/icons-react'
import match from './assets/images/games/match.png';
import match_svg from './assets/images/games/Match.svg'
import group_svg from './assets/images/games/groups.svg'

import tournament from './assets/images/games/tournament.png';
import knockout from './assets/images/games/Group 3769.svg';
import badminton from './assets/images/games/badminton.svg'
import tableTennis from './assets/images/games/table_tenis.svg';
import Bowling from './assets/images/games/Bowling11.svg';
import twokrun from './assets/images/games/run.svg';




import {
  cilSpeedometer,
  cilUser,
  cilBuilding,
  cilGroup,
  cilFile,
  cilLockLocked,
  cilListRich,
  cilClipboard,
  cilSettings,
  cilDollar,
  cilPeople
  // CNavGroup
} from '@coreui/icons'
import {  CNavItem, CNavTitle,CNavGroup } from '@coreui/react'
const navIconStyle = {
  // width:'50px',
  // fontSize: '20px',
  backgrounColor:"red",
  paddingRight:"4px"
};
const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/admin/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Manage',
  },
  {
    component: CNavItem,
    name: 'Users',
    to: '/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Corporate',
    to: '/corporate',
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'SCHEDULE',
  },
  {
    component: CNavItem,
    name: 'Tournaments',
    to: '/tournaments',
    icon: <img src={tournament} className='nav-icon' /> ,
  },
  // {
  //   component: CNavItem,
  //   name: 'Groups',
  //   to: '/groups',
  //   icon: <CIcon icon={cilGroup} customClassName="nav-icon"  />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Matches',
  //   to: '/matches',
  //   icon: <img src={match} className='nav-icon' />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Knockout',
  //   to: '/knockout',
  //   icon: <img src={knockout} className='nav-icon' />,
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Components',
  // },
  {
    component: CNavGroup,
    name: 'Badminton',
    to: '/base',
    icon: <img src={badminton} className='nav-icon' />,
    items: [
      {
        component: CNavItem,
         icon: <img  src={group_svg} className='nav-icon' />,
        name: 'Groups',
        to: '/groups?sport=badminton&tournament=null&gender=male',
      },
      {
        component: CNavItem,
        icon: <img  src={match_svg} className="nav-icon" />,
        name: 'Matches',
        to: '/matches?sport=badminton',
      },
      {
        component: CNavItem,
        icon: <img src={knockout} className="nav-icon" />,
        name: 'Knockout',
        to: '/knockout?sport=badminton',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Table Tenis',
    to: '/base',
    icon: <img src={tableTennis} className='nav-icon' />,
    items: [
      {
        component: CNavItem,
         icon: <img  src={group_svg} className='nav-icon' />,
        name: 'Groups',
        to: '/groups?sport=table_tenis&tournament=null&gender=male',
      },
      {
        component: CNavItem,
        icon: <img  src={match_svg} className="nav-icon" />,
        name: 'Matches',
        to: '/matches?sport=table_tenis',
      },
      {
        component: CNavItem,
        icon: <img src={knockout} className="nav-icon" />,
        name: 'Knockout',
        to: '/knockout?sport=table_tenis',
      },
    ],
  },
  {
    component: CNavItem,
    icon: <img src={Bowling} className='nav-icon' />,
    name: 'Bowling',
    to: '/groups?sport=bowling&tournament=null&round=round_1&gender=male',   
  },
  {
    component: CNavItem,
    icon: <img src={twokrun} className='nav-icon' />,
    name: '2k Run',
    to: '/groups?sport=2k_run&tournament=null&round=round_1&gender=male',   

},
  {
    component: CNavGroup,
    name: 'settings',
    to: '/base',
    icon:<CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        icon:<CIcon icon={cilDollar} customClassName="nav-icon" />,
        name: 'payment details',
        to: '/payment_details',   
        
        },
        {
          component: CNavItem,
          icon:<CIcon icon={cilFile} customClassName="nav-icon" />,
          name: 'Document',
          to: '/documents',   
          
          },
          {
            component: CNavItem,
            icon:<CIcon icon={cilPeople} customClassName="nav-icon" />,
            name: 'Withdrawals & Waitings',
            to: '/manage',   
            
            },
          {
            component: CNavItem,
            icon:<CIcon icon={cilPeople} customClassName="nav-icon" />,
            name: 'Site Config',
            to: '/config',   
            
            },
      
    ],
  },
  
  

]

export default _nav
 