import { Value } from 'sass';
import Database  from './model'
const {Get,Post} = Database();

export const RegisterUser = async(FormData) => {
    let response = await Post({url:'auth/register', bodyData:FormData});
   return response;
}

export const LoginUser = async(FormData) => {
    let response =await Post({url:'auth/login', bodyData:FormData});
    return response;
}

export const UserExist = async(email, value) => {
     let response = await Get({url: `auth/isexist?email=${email}&value=${value}`});

    return response;
}

export const ForgotPasswordAPI = async(email) => {
    let response = await Post({url:'auth/forgot-password', bodyData: email});
    return response;
}

export const otpVerification = async(otp) => {
    let response = await Post({url:'auth/otp-verification', bodyData: otp});
    return response;

}

export const NewPasswordAPI = async(password) => {
    let response = await Post({url:'auth/new-password', bodyData: password});
    return response;
}

export const Authenticated = async(token) => {
    let response = await Post({url:'auth/authenticated', bodyData: token});
    return response;
}
