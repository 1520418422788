// import React from "react";
import { CPagination, CPaginationItem } from "@coreui/react"; 
import "./Pagination.css";
import React, { useEffect, useState } from 'react'
const Pagination = ({
    totalPosts,
    postsPerPage,
    setCurrentPage,
    currentPage,
}) => {
    let pages = [];
    let [currpage , setcurrpage] = useState(1);
    // let [visiblepage ,]
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i);
    }
    let startingpage = currpage;
    let endingpage;
    if(pages.length > currpage+4) 
        endingpage = currpage+4;
    else 
    endingpage = pages.length;
    const visiblePages = pages.slice(startingpage-1, endingpage);

    return (
       <>
           <div className="pagination">
           <div className="pagination">
      <a href="#" className={currpage === 1 ? "disabled" : "" } onClick={ (e)=>{ e.preventDefault();if(currpage>1){ setcurrpage(currpage-1); setCurrentPage(currpage-1) ;}  }} >&laquo;</a>
      {visiblePages.map((page) => (
        <a
          key={page}
          href="#"
          className={page == currentPage ? "active" : ""}
        //   onClick={() => handlePageChange(page)}
        onClick={(e) => { e.preventDefault();setCurrentPage(page)}}
        >
          {page}
        </a>
      ))}
      <a href="#" className={endingpage === pages.length ? "disabled" : ""}  onClick={ (e)=>{ e.preventDefault();setcurrpage(currpage+1); setCurrentPage(currpage+1)}}>&raquo;</a>
    </div>
    </div>
    
    
    

     
        </>

    );
};

export default Pagination;
