import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './scss/style.scss'
import {Route,Routes,BrowserRouter } from "react-router-dom"
import React,{useState, useEffect} from 'react'
import { SnackbarProvider } from 'notistack';
import PlayerDashboard from "./views/player/Dashboard";
import PlayerFedback from "./views/player/Feedback";

import AdminDashboard from "./views/admin/Dashboard";
import Login from "./views/auth/login";
import Logout  from './views/auth/Logout';
import Register from "./views/auth/register";
import SportSelection from './views/auth/SportSelection';
import Gameplan from './views/auth/GamePlan';
import NewPassword from './views/auth/newPassword';
import OtpVerfication from './views/auth/OtpVerification';
import ForgotPassword from './views/auth/ForgotPassword';
import Profile from './views/Profile';
import NotFound from './views/NotFound';
import Redirect from './views/Redirect';
import AdminLayout from './layouts/AdminLayout';
import ScorerLayout from './layouts/ScorerLayout';
import ParticipantLayout from './layouts/ParticipantLayout';
import { useSelector } from 'react-redux';

// admin imports
import Tournaments from './views/admin/Schedule/Tournament/Tournaments';
import CreateTournament from './views/admin/Schedule/Tournament/Create';

import Groups from './views/admin/Schedule/Groups/Index';

import Matches from './views/admin/Schedule/Match/Index';

import Knockout from './views/admin/Schedule/Knockout/Index';

import Corporates from './views/admin/Manage/Corporate/Corporates';
import CreateCorporates from './views/admin/Manage/Corporate/Create';

import Users from './views/admin/Manage/User/List';	
import CreateUser from './views/admin/Manage/User/Create';
import DeleteUser from './views/admin/Manage/User/Delete';
import DetailUser from './views/admin/Manage/User/Detail'
import Settings from './views/admin/settings/index'
import Config from './views/admin/settings/Config'
import Withdrwal_Waiting from './views/admin/Withdrwal&Waiting/index';
import Documents from './views/admin/settings/document'
import  CreateDocument  from './views/admin/settings/Create';

import ScorerDashboard from './views/scorer/Home'    

function App() {

	var currentRoute = window.location.pathname;
	const user_data = useSelector((state) => state.user);
	const user_role = localStorage.getItem('role');
	const [role, setRole] = useState('');
	const page_routes = ['/','/login', '/register', '/forgot-password', '/verification'];
	const user_token = localStorage.getItem('token');
	const [IsRedirect, setIsRedirect] = useState(false);
	const [RedirectUrl, setRedirectUrl] = useState('/dashboard');

	const callback = () => {
		setIsRedirect(false);
	}

	useEffect(() => {
		const substring = '/new-password/';
		if (currentRoute.includes(substring)) {
			page_routes.push(currentRoute);
		}
		
		if (user_data != undefined && user_data != null && user_data.role != undefined || user_role != undefined &&user_role != null) {
			setRole(user_data != undefined && user_data.role != undefined ? user_data.role : user_role)
		}
		if(user_token && page_routes.includes(currentRoute)) {
			setRedirectUrl('/dashboard');
			setIsRedirect(true);
		}

		if(!user_token && !page_routes.includes(currentRoute)) {
			const url = new URL(window.location.href);
			const pathname = url.pathname;
			const search = url.search;
				 currentRoute = `${pathname}${search}`;
			setRedirectUrl(`/login?redirect=${encodeURIComponent(currentRoute)}`);
			setIsRedirect(true);
		}
    }, [user_data, setRole]);

  return (  
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
    	<BrowserRouter>
			{
				IsRedirect ? <Routes><Route path="*" element={<Redirect url={RedirectUrl} callback={callback}/>} /></Routes> : 
				<Routes>
					{
						role == 'admin' ? 
							<Route element={<AdminLayout/>} >
								{/* <Route path ='/path' element={<element/>} ></Route> */}
								<Route exact path ='/dashboard' element={<AdminDashboard/>} ></Route>
								<Route path='/admin/profile' element={<Profile />} ></Route>
							
								<Route path='/admin/dashboard' element={<AdminDashboard />} ></Route>
								<Route path ='/tournaments' element={<Tournaments/>} ></Route>
								<Route path ='/tournament/create' element={<CreateTournament/>} ></Route>
								<Route path ='/tournament/edit' element={<CreateTournament/>} ></Route>
								
								<Route path='/groups' element={<Groups />} ></Route>
								
								<Route path='/knockout' element={<Knockout />} ></Route>
									
								<Route path='/matches' element={<Matches />} ></Route>

								<Route path ='corporate' element={<Corporates/>} ></Route>
								<Route path ='/corporate/create' element={<CreateCorporates/>} ></Route>
								<Route path ='/corporate/edit' element={<CreateCorporates/>} ></Route>

								<Route path ='/users' element={<Users/>} ></Route>
								<Route path ='user/create' element={<CreateUser/>} ></Route>
								<Route path ='user/delete' element={<DeleteUser/>} ></Route>
								<Route path ='user/edit' element={<CreateUser/>} ></Route>
								<Route path ='user/detail' element={<DetailUser/>} ></Route>
								<Route path='/payment_details' element={<Settings />} ></Route>
								<Route path='/documents' element={<Documents />} ></Route>
								<Route path='/documents/create' element={<CreateDocument />} ></Route>
								<Route path='/manage' element={<Withdrwal_Waiting />} ></Route>
								<Route path='/config' element={<Config />} ></Route>


							</Route>
						:  
						role == 'scorer' ?
							<Route element={<ScorerLayout/>} >
								{/* <Route path ='/path' element={<element/>} ></Route> */}
								<Route exact path ='/dashboard' element={<ScorerDashboard/>} ></Route>
								<Route path ='/scorer/profile' element={<Profile/>} ></Route>  
							</Route>
						: role == 'participant' ?<>
							<Route element={<ParticipantLayout/>} >
								{/* <Route path ='/path' element={<element/>} ></Route> */}
								<Route exact path ='/dashboard' element={<PlayerDashboard/>} ></Route>
								<Route path ='/participant/profile' element={<Profile/>} ></Route>  
								<Route path ='/participant/feedback' element={<PlayerFedback/>} ></Route>  

							</Route>
							<Route  path ='/select-sport' element={<SportSelection/>} ></Route>  
							<Route  path ='/game-plan' element={<Gameplan/>} ></Route>  
							

						</>:null


					}
					{/* common routes */}
					<Route path ='/' element={<Login/>} ></Route>
					<Route path ='/login' element={<Login/>} ></Route>
					<Route exact path ='/logout' element={<Logout/>} ></Route>
					<Route path ='/register' element={<Register/>} ></Route>  
					<Route exact path ='/new-password/:token' element={<NewPassword/>} ></Route>
					<Route path ='/verification' element={<OtpVerfication/>} ></Route>  
					<Route path ='/forgot-password' element={<ForgotPassword/>} ></Route>  
					<Route path ='/profile' element={<Profile/>} ></Route>  
					<Route path="*" element={<NotFound />} />

					{/* admin routes */}
					<Route element={<AdminLayout/>} >
						{/* <Route path ='/path' element={<element/>} ></Route> */}
						{/* <Route path ='/admin/dashboard' element={<AdminDashboard/>} ></Route>
						<Route path ='/tournaments' element={<Tournaments/>} ></Route>
						<Route path ='/tournament/create' element={<CreateTournament/>} ></Route>
						<Route path ='/tournament/edit' element={<CreateTournament/>} ></Route>
						
						<Route path='/groups' element={<Groups />} ></Route>
						
						<Route path='/knockout' element={<Knockout />} ></Route>
							  
						<Route path='/matches' element={<Matches />} ></Route>

						<Route path ='corporate' element={<Corporates/>} ></Route>
						<Route path ='/corporate/create' element={<CreateCorporates/>} ></Route>
						<Route path ='/corporate/edit' element={<CreateCorporates/>} ></Route>

						<Route path ='/users' element={<Users/>} ></Route>
						<Route path ='user/create' element={<CreateUser/>} ></Route>
						<Route path ='user/delete' element={<DeleteUser/>} ></Route>
						<Route path ='user/edit' element={<CreateUser/>} ></Route>
						<Route path ='user/detail' element={<DetailUser/>} ></Route> */}

					</Route>	
					{/* participant routes */}
					<Route element={<ParticipantLayout/>} >
						{/* <Route exact path ='/participant/dashboard' element={<PlayerDashboard/>} ></Route> */}
					</Route>
				</Routes>
			}
      	</BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
