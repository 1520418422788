import React, { useState } from 'react';
import Slider from 'react-slick';
import logo from '../../assets/logo.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import badminton from '../../assets/images/sliders/Badminton.jpg';
import tt from '../../assets/images/sliders/tt1.jpg';
import running from '../../assets/images/sliders/running.jpg';
import bowling from '../../assets/images/sliders/bowling.jpg';

import ArrowIcon from '../../components/icons/ArrowIcon'

function Login ({ children }) {

	const [showPassword, setShowPassword] = useState(false);
	const [SliderImages, setSliderImages] = useState([badminton, tt, running, bowling]);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};


  const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
	arrows: false,
  };
  
  return (
    <>
      <div className='h-100 main-box'>
        <section className='login-form'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-sm-6 text-white h-100 overflow-auto scroll-none'>
              		<img src={logo} alt='logo' className='mx-5 mt-5 logo'/>
                  <div className='row justify-content-center form-row'>
                    <div className='col-md-8'>
                        <div className='py-5'>
                          {children}
                        </div>
                    </div>
                  </div>
              </div>
              <div className='col-sm-6 px-0 d-none d-sm-block position-relative'>
                <div className='slider-custom-arrow position-absolute top-0 end-0 z-index-9 rounded-circle text-center mt-4 me-4 cursor-pointer'><ArrowIcon/>
                  </div>
                  <Slider {...SliderSettings}>
                    {
                      SliderImages.length ? SliderImages.map((item, index) => (
                        <div className='vh-100'>
                          <div className='form-side-bg vh-100' style={{backgroundImage: `url(${item})`}}></div>
                        </div>
                      )) : ''
                    }
                  </Slider>
                </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Login
