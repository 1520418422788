import React, { useEffect, useState } from "react";
import { Row, Col, Card } from 'react-bootstrap';
import Slider from 'react-slick';
import '../../assets/css/Player/upcomming_slider.css';
import crown from '../../assets/images/crown.png';
import CardPlayer from '../../assets/images/CardPlayer_.png';
import Bowling from '../../assets/images/bowling.png';
import twokrun from '../../assets/images/twok.png';

import team1 from '../../assets/images/teams/Perth_City_.png';
import team2 from '../../assets/images/teams/Hobart_Home.png';
import { FormatDate, getTime } from '../../../src/Common/CommonFunction'
import { CCol, CRow } from "@coreui/react";

const CorporateStandings = ({ TodayMatch }) => {
    console.log(TodayMatch.length);
    const [SliderImages, setSliderImages] = useState(['badminton', 'tt', 'running', 'bowling']);
    const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE;
    const [game, setGame] = useState(null);


    const SliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const cardBodyStyle = {
        backgroundImage: "linear-gradient(rgb(220 69 69 / 70%), rgb(220 69 69 / 70%)), url(" + crown + ")",
        backgroundSize: '25%',
        backgroundPosition: '20%',
        backgroundRepeat: 'no-repeat',
    };
    console.log("game", "url(" + CardPlayer + ")")
    // let str = game === 'bowling'?Bowling:game==='Badmintion'?CardPlayer:game==='TableTenis'?CardPlayer :twokrun+")"
    // console.log( "url("+str );
    const cardStyle = {
        backgroundImage: `url(${game === 'bowling' ? Bowling :
                game === 'Badmintion' ? CardPlayer :
                    game === 'TableTenis' ? CardPlayer :
                        twokrun
            })`,
        CardPlayer: twokrun + ")",
        backgroundSize: 'contain',
        backgroundPosition: '97%',
        backgroundRepeat: 'no-repeat',
    };

    useEffect(() => {
        setGame(getGame);
    }, [])


    const getGame = () => {
        const searchParams = new URLSearchParams(window.location.search);
        let game = searchParams.get('game');
        if (!game) {
            game = 'Badminton';
        }
        return game;
    };
    return (
        <div className='upcomming_slider'>
            <div>
                {
                    TodayMatch.length > 0 ? (
                        game === 'Badminton' || game === 'TableTennis' ? (
                            TodayMatch.map((match, index) =>
                            (
                                <>
                                    <Card className="upcomming-match px-2 py-2 mb-3" style={cardStyle}>
                                        <Card.Body style={cardBodyStyle}>
                                            <Card.Title className="text-white fw-bold card-text">TODAY MATCH</Card.Title>
                                            <Card.Text className="text-white mt-4 card-text"> {FormatDate(match.match_date, 0, 1, 0)}</Card.Text>
                                            <Row className="text-white mt-4 align-items-center text-group ">
                                                <Col md="auto" >
                                                    <div className="team-info d-flex align-items-center card-text" >
                                                        < div
                                                            style={{
                                                                backgroundImage: `url(${match.player_details[0].profile_image !== undefined ?
                                                                    IMAGE_URL + "user/" + match.player_details[0].profile_image : null})`, backgroundPosition: 'center',
                                                            }}
                                                            className='scorer-img-today'
                                                        ></div>

                                                        {/* <span className="ms-3">Perth City</span> */}
                                                        <span className=" ">{match.player_details[0].fname + ' ' + '' + match.player_details[0].lname} </span>
                                                    </div>
                                                </Col>

                                                <Col md="auto" className="mx-3">
                                                    <div className="team-info bg-dark text-white p-2 text-center mt-1 rounded card-text" id="timepara">
                                                        {getTime(match.match_date, 1)}
                                                    </div>
                                                </Col>

                                                <Col md="auto">
                                                    <div className="team-info d-flex align-items-center card-text">
                                                        < div
                                                            style={{
                                                                backgroundImage: `url(${match.player_details[1].profile_image !== undefined ?
                                                                    IMAGE_URL + "user/" + match.player_details[1].profile_image : null})`, backgroundPosition: 'center',
                                                            }}
                                                            className='scorer-img-today'
                                                        // className="team_logo"

                                                        ></div>
                                                        <span className="ms-3 team-name mymatchcard ">{match.player_details[1].fname + ' ' + '' + match.player_details[1].lname} </span>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </>
                            ))
                        ) : game === 'bowling' ? (
                            TodayMatch.map((match, index) =>
                            (
                                <>
                                    <Card className="upcomming-match px-2 py-2 mb-3" style={cardStyle}>
                                        <Card.Body style={cardBodyStyle}>
                                            <Card.Title className="text-white fw-bold card-text">TODAY MATCH</Card.Title>
                                            <Card.Text className="text-white mt-4 card-text"> {FormatDate(match.match_date, 0, 1, 0)}</Card.Text>
                                            <Row className="text-white mt-2  text-group ">
                                                <CCol xl={6} className="d-flex justify-content-center align-items-center" style={{
                                                    //  display:"grid !mportant",
                                                    //  gridTemplateColumns:'repeat(1, fr  )',
                                                    //  gap:"20px",
                                                    //  textAlign:"center"
                                                    flexWrap:'wrap'
                                                }}>
                                                    {

                                                        match.player_details.map((player, playerIndex) => (
                                                            <>
                                                                <div className="" style={{
                                                                width:"100px", margin:"0 10px 10px 10px", display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                textAlign: "center"
                                                                }}>
                                                                    < div
                                                                        style={{
                                                                            backgroundImage: `url(${player.profile_image !== undefined ?
                                                                                IMAGE_URL + "user/" + player.profile_image : null})`, backgroundPosition: 'center',
                                                                                width: "100px",
                                                                                height: "100px",
                                                                                backgroundSize: "cover"
                                                                        }}
                                                                        className='scorer-img-today'
                                                                    ></div>

                                                                    {/* <span className="ms-3">Perth City</span> */}
                                                                    <span className="">{player.fname + ' ' + '' + player.lname} </span>
                                                                </div>

                                                            </>

                                                        ))
                                                    }

                                                </CCol>

                                            </Row>

                                            {/* <Col md="auto" className="mx-3">
                                            <div className="team-info bg-dark text-white p-2 text-center mt-1 rounded card-text" id="timepara">
                                               {getTime(match.match_date,1)}
                                            </div>
                                        </Col> */}

                                            {/* <Col md="auto">
                                            <div className="team-info d-flex align-items-center card-text">
                                                < div
                                                    style={{
                                                        backgroundImage: `url(${match.player_id[1].profile_image !== undefined ?
                                                            IMAGE_URL + "user/" + match.player_id[1].profile_image : null})`, backgroundPosition: 'center',
                                                    }}
                                                    className='scorer-img-today'
                                                // className="team_logo"
                                                        
                                                ></div>
                                                <span className="ms-3 team-name mymatchcard ">{match.player_id[1].fname + ' ' + '' + match.player_id[1].lname} </span>
    
                                            </div>
                                        </Col> */}

                                        </Card.Body>
                                    </Card>
                                </>
                            ))

                        ) : (TodayMatch.map((match, index) =>
                        (
                            <>
                                <Card className="upcomming-match px-2 py-2 mb-3" style={cardStyle}>
                                    <Card.Body style={cardBodyStyle}>
                                        <Card.Title className="text-white fw-bold card-text">TODAY MATCH</Card.Title>
                                        <Card.Text className="text-white mt-4 card-text"> {FormatDate(match.match_date, 0, 1, 0)}</Card.Text>
                                        <Row className="mt-4">
                                            <Col xl={8}>
                                                <div className='' style={{ maxHeight: '450px' }}>
                                                    <Row className="text-white  custom-scrollbar text-group  d-flex justify-content-center">

                                                        {
                                                            match.player_details.map((player, playerIndex) => (
                                                                <Col className="mb-3 " style={{

                                                                }}>
                                                                    <>
                                                                        <div className="" style={{

                                                                        }}>
                                                                            < div
                                                                                style={{
                                                                                    backgroundImage: `url(${player.profile_image !== undefined ?
                                                                                        IMAGE_URL + "user/" + player.profile_image : null})`, backgroundPosition: 'center',
                                                                                }}
                                                                                className='scorer-img-today'
                                                                            ></div>

                                                                            {/* <span className="ms-3">Perth City</span> */}
                                                                            <span className="ms-3 ">{player.fname + ' ' + '' + player.lname} </span>
                                                                        </div>

                                                                    </>
                                                                </Col>

                                                            ))
                                                        }


                                                    </Row>

                                                </div>
                                            </Col>
                                        </Row>


                                    </Card.Body>
                                </Card>
                            </>
                        )))


                    ) : (null)
                }

            </div>
            {/* <Slider {...SliderSettings}>
            {
                SliderImages.length ? SliderImages.map((item, index) => (
                    <div >
                        <Card className="upcomming-match px-4 py-5"  style={cardStyle}>
                        <Card.Body  style={cardBodyStyle} >
                        <Card.Title className="text-white fw-bold card-title h5 card-text">TODAY MATCH</Card.Title>
                        <Card.Text className="text-white mt-4 card-text">Mon 12 Apr 2024 | David Cup</Card.Text>
                      <Row className="text-white mt-4 align-items-center card-text">
                        <Col md="auto">
                            <div className="team-info d-flex align-items-center">
                                <img src={team1} alt="Team 1 Logo" className="team-logo" />
                                <span className="ms-3">Perth City</span>
                            </div>
                        </Col>

                        <Col md="auto">
                            <div className="team-info bg-dark text-white p-2 text-center mt-1 rounded" >
                                20:00
                            </div>
                        </Col>

                        <Col md="auto col-md-auto col-auto col-12 col-sm-12 d-flex justify-content-center">
                            <div className="team-info d-flex align-items-center">
                                <img src={team2} alt="Team 2 Logo" className="team-logo" />
                                <span className="ms-3">Hobart Home</span>
                            </div>
                        </Col>
                    </Row>

                        </Card.Body>
                    </Card>
                    </div>
                )) : ''
            }
        </Slider> */}
        </div>
    )
}

export default React.memo(CorporateStandings)
