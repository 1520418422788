import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../Common/AdminBreadcrumb';
import Loader from '../../../views/admin/Common/Loader';
import {getGamePricing,getSetting,updatePricing, updatSetting} from  '../../../API/admin'
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Swal from 'sweetalert2';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormInput 
} from '@coreui/react'

const Config = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Site Settings', to: '/config', active: true }
    ];

    const [games, setGames] = useState([]);
	const Navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
    const [visible, setVisible] = useState(false);
    const [selectedGame, setSelectedGame] = useState({ id: '', name: '', price: '' });
    const [loaderStatus, setloaderStatus] = useState(false);
    const [isOn, setIsOn] = useState(false);
    

    
    let  GetPricingAPI = async() =>{
        setloaderStatus(true)
        let res =  await getGamePricing()
        setloaderStatus(false)

        console.log(res)
        if(res.status) {
            setGames(res.data);

            
        } else { 
            setGames([]);
            enqueueSnackbar('unable to get price', { variant: 'error' });

        } 
    }

    let GetSettingAPI = async () => {
        setloaderStatus(true)
        let res =  await getSetting()
        setloaderStatus(false)

        if(res.status) {
            setIsOn(res.data.value);

            
        } else { 
            setIsOn(null);
            enqueueSnackbar('unable to fetch setting', { variant: 'error' });

        } 
    }
  let HandelUpdate = async (id, price) => {
    let formData = {
        "id" : id,
        "price" : price
    }
    setloaderStatus(true)

   let res =  await updatePricing(formData)
   
   if (res.status) {
    setloaderStatus(false)
    enqueueSnackbar('Data updated successfully', { variant: 'success' });

    window.location.reload()
   } else {
    setloaderStatus(false)
    enqueueSnackbar('Unable to update data', { variant: 'error' });

   }
  }

  const handleEditClick = (game) => {
    setSelectedGame(game);
    setVisible(true);
};


const handleInputChange = (e) => {
    // Capture the checked state at the beginning
    const isChecked = e.target.checked;

    // Update state early to reflect the change in UI (optional)
    setIsOn(isChecked);

    // Define your Swal text messages
    const actionText = isChecked
        ? "allow participants withdrawal from the game"
        : "stop participants withdrawal from the game";
    const confirmationText = isChecked
        ? "Participants will be able to withdraw from the game."
        : "Participants will no longer be able to withdraw from the game.";

    Swal.fire({
        title: `Are you sure you want to ${actionText}?`,
        text: confirmationText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                let formData = {
                    name: "withdrawal",
                    value: isChecked  // Use captured value here
                };
                
                setloaderStatus(true);
                let res = await updatSetting(formData);
                
                if (res.data.status) {
                    enqueueSnackbar('Setting is updated', { variant: 'success' });
                     setIsOn(res.data.data.value);

                } else {
                    enqueueSnackbar('Unable to update setting', { variant: 'error' });
                }
            } catch (error) {
                console.error('Error updating setting:', error);
                enqueueSnackbar('An error occurred while updating the setting', { variant: 'error' });
            } finally {
                setloaderStatus(false);
            }
        } else {
            // Revert the change if the user cancels the confirmation
            setIsOn(!isChecked);
        }
    });

    console.log('Switch toggled:', isChecked);
};
const handleSaveChanges = () => {
    // Call your update function with id and updated price
    console.log(selectedGame);
    
    HandelUpdate(selectedGame.id, selectedGame.price);
    setVisible(false);
};

    useEffect( () => {
        GetPricingAPI()
        GetSettingAPI()
    }, [])

    return (
        <>
            { loaderStatus ? 
                <Loader />
            : null}
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            
            <CCard className='mb-5'>
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h4 className="card-title mb-3">
                            Withdrawal Setting
                        </h4>
                        
                    </CCol>
                    <CCol xl={8}>
                        <div className="form-check form-switch ms-3 float-end">
                        <input
                            className="form-check-input text-white"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={isOn === "true" ? true : false}
                            value= {isOn === "true" ? true : false}
                            onChange={handleInputChange}
                            style={{ height: '30px', width: '60px' }} // Custom size
                        />
                       
                        </div>
                    </CCol>
                </CRow>
               
                {/* <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CCol xl={12}>
                    <div className='d-flex justify-content-end align-items-center'>
                        <h6>Withdrwal</h6>
                        <div className="form-check form-switch ms-3">
                        <input
                            className="form-check-input text-white"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={isOn}
                            value= {isOn ? 'On' : 'Off'}
                            onChange={handleInputChange}
                            style={{ height: '30px', width: '60px' }} // Custom size
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                            {isOn ? 'On' : 'Off'}
                        </label>
                        </div>
                    </div>
                </CCol>
                </CRow> */}
                </CCardBody>
            </CCard>

        <CCard>
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h4 className="card-title mb-3">
                            Games Pricing
                        </h4>
                    </CCol>
                </CRow>
                {/* <CRow>    
                    <CCol sm={3} className="d-none d-md-block">
                        <input type="search" className='form-control' placeholder="Search" name='data' onChange={HandelChange}/>
                    </CCol>
                    <CCol sm={9}>
                        <Link to={'/corporate/create'}>
                            <CButton className='float-end text-white' color="info">
                                <i className="bi bi-plus-circle me-2"></i>
                                Add Corporate
                            </CButton>
                        </Link>
                    </CCol>
                </CRow><br /> */}
                {/* <MainChart /> */}
                <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CTable striped>
                    <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Price</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Action</CTableHeaderCell>

                    </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {
                            games.length > 0 ? (

                                games.map((Game, index) => (
                                    <CTableRow>
    
                                        <CTableDataCell>{index +1}</CTableDataCell>
                                        <CTableDataCell>{Game. name ==='badminton' ? 'Badminton' :Game.name === 'table_tenis' ? "Table Tenis" :Game.name === 'bowling' ? 'Bowling': Game.name ==='2k_run' ? '2k Run' : 'N/A' }</CTableDataCell>
                                        <CTableDataCell > ${Game.price}</CTableDataCell>
                                        <CTableDataCell>
                                        <CButton onClick={() => handleEditClick(Game)} title='Edit' className='bi bi-pencil text-white' color="warning"></CButton>

                                        </CTableDataCell>
                                        
                                    </CTableRow>
    
                                ))

                            ) :  (
                                <CTableRow>
                                    <CTableDataCell colSpan={3}> No data found</CTableDataCell>
                                </CTableRow>
                            )
                        }

                    </CTableBody>
                </CTable>
                </CRow>
                </CCardBody>
            </CCard>

            <CModal
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalHeader>
                    <CModalTitle id="VerticallyCenteredExample">Edit Game</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CFormInput
                        label="Game Name"
                        value={selectedGame.name === 'badminton' ? 'Badminton' :
                               selectedGame.name === 'table_tenis' ? "Table Tennis" :
                               selectedGame.name === 'bowling' ? 'Bowling' :
                               selectedGame.name === '2k_run' ? '2k Run' : 'N/A'}
                        readOnly
                        className="mb-3"
                    />
                    <CFormInput
                        label="Price"
                        name="price"
                        type="number"
                        min={1}
                        value={selectedGame.price}
                        onChange={handleInputChange}
                        className="mb-3"
                    />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setVisible(false)}>
                        Close
                    </CButton>
                    <CButton color="primary" onClick={handleSaveChanges}>
                        Save changes
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default Config;