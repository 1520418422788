module.exports = {
    f_name_required : "First Name can`t be empty.",
    l_name_required : "Last Name can`t be empty.",
    invalid_email : "Invalid Email.",
    invalid_corporate_email : "Invalid Corporate Email.",
    password_required : "Password can`t be empty.",
    confirm_password_required : "Confirm password can`t be empty.",
    password_criteria:"Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one special character.",
    confirm_password_not_match:"Password and confirm password do not match.",
    email_and_corporate_email_cant_be_same:"Personal email and corporate email can`t be same.",
    register_success:"User registered successfully!",
    user_already_exist : "User already exist",
    email_already_exist : "Email already exist",
    corporate_email_already_exist : "Corporate Email already exist",

}; 
