import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import logo from '../../../../assets/images/avatars/blank_user_image.png'
import { useNavigate } from 'react-router-dom';
import {UserDetail} from  '../../../../API/admin'
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CRow,
  } from '@coreui/react'


const DetailUser = () => {
     // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Users', to: '/users', active: false },
        { label: 'Details', to: '', active: true },
    ];

    const [data, setData] = useState(undefined);
	const navigate = useNavigate();
    const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE

    useEffect(()=>{
    let callAPI = async ()=> {
        const urlParams = new URLSearchParams(window.location.search);
        const urlid = urlParams.get('id');
        let userID = {
            id : urlid
        }
        let res = await UserDetail(userID)
        console.log("API ", res.status)
        if (res.status) {
            console.log(res.data)
            setData(res.data);
        }
    }
    callAPI();
    }, []);
    
    return(
        <>
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            
            {/* <CCard className="mb-4">
                <CCardBody>
                    <CRow>
                        <CCol sm={2}>
                            <img src={logo} alt="user" style={{width: '100px', height: '100px'}} />
                        </CCol>
                        <CCol sm={8}>
                            <h1>user details will be here</h1>
                        </CCol>
                        <CCol sm={2}>
                            <CButton color="primary" className='float-end'>View</CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard> */}
             <section>
                {
                    data !== undefined ?(
                        <>
                        <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card mb-4">
                                    <div className="card-boddata.y text-center">
                                        <img
                                        src={data.user.profile_image !== undefined ?
                                            IMAGE_URL+"user/" + data.user.profile_image : IMAGE_URL+"profile.jpg" }
                                        alt="avatar"
                                        className="img-fluid"
                                        style={{ width: 150 }}
                                        />
                                        <h5 className="my-3">{data.user.fname +" " +data.user.lname}</h5>
                                        <p className="text-muted mb-1">{data.user.role}</p>
                                        <p className="text-muted mb-4">Corporate Premier Games</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <p className="mb-0">Full Name</p>
                                            </div>
                                            <div className="col-sm-9">
                                                <p className="text-muted mb-0">{data.user.fname +" " +data.user.lname}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <p className="mb-0">Email</p>
                                            </div>
                                            <div className="col-sm-9">
                                                <p className="text-muted mb-0">{data.user.email}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <p className="mb-0">Corporate Email</p>
                                            </div>
                                            <div className="col-sm-9">
                                                <p className="text-muted mb-0">{data.user.corporate_email ? data.user.corporate_email :'N/A'}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <p className="mb-0">Gender</p>
                                            </div>
                                            <div className="col-sm-9">
                                                <p className="text-muted mb-0">{data.user.gender}</p>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-sm-3">
                                                <p className="mb-0">Sports</p>
                                            </div>
                                            <div className="col-sm-9">
                                                {
                                                
                                                <p className="text-muted mb-0">{user.sports}</p> 
                                                }

                                            </div>
                                        </div> */}
                                        
                                        {
                                            data.player ==null ? ( 
                                                <>
                                                
                                                </>
                                            ) :(<>
                                           
                                                <hr />

                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <p className="mb-0">Tournaments</p>
                                                    </div>
                                                        <div className="col-sm-9">
                                                            {
                                                            data.player.length> 0 ? (
                                                                <>
                                                                 {
                                                                    data.player.map ((player, index)=> (
                                                                        <>
                                                                
                                                                        <span className="text-muted mb-0">{
                                                                        player.tournament_id.name + ' ( ' + player.name+ ' )  '}</span>
                                                                    
                                                                    
                                                                        </>
                                                                    ) )
                                                                 }
                                                                </>
                                                            ) : (
                                                                <>
                                                                
                                                                <span className="text-muted mb-0">N/A</span>
                                                            
                                                            
                                                                </>
                                                            )
                                                            
                                                         }
                                                    </div>
                                                </div>
                                                </>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </>
                     ) :( 
                        <>
                        </>
                    )
                }
                    
            </section>
            
        </>
    )

}
export default DetailUser