import React, { useEffect, useState } from 'react';
import FormContainer from './FormContainer';
import EyeIcon from '../../components/icons/EyeIcon';
import EyeSlashFillIcon from '../../components/icons/EyeSlashFillIcon';
import {LoginUser,Authenticated} from '../../API/auth';
import { useLocation, useNavigate } from 'react-router-dom'; // Import necessary hooks
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
function Login () {
	let navigate = useNavigate();
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const location = useLocation(); // Hook to get the current location
	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			let callAPI = async () => {
				const verifyToken = {
					token:token,
				}
				let res =  await Authenticated(verifyToken)
				if(res.data.status) {
					// enqueueSnackbar('.', { variant: 'error' });
					navigate('/dashboard');
				}
			}
			callAPI()
		} 
		
	}, []);
	const [showPassword, setShowPassword] = useState(true);
	const [formData, setFormData] = useState({
		email:'',
		password:''
	})
	
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const HandelChange = (event) => {
		const { name, value } = event.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const HandelSubmit = async (event) => {
		event.preventDefault() ;
		console.log("LOGggggggggggginnnnnnnnnnnnn");
		
		let res = await LoginUser(formData);
		if(res.data.token) {
			console.log('user = ',res.data.data);
			dispatch({ type: 'set',  user: res.data.data });
			dispatch({ type: 'set',  token: res.data.token });
			localStorage.setItem('token', res.data.token);
			localStorage.setItem('role', res.data.data.role);
			enqueueSnackbar(res.data.message, { variant: 'success' });
			const redirectUrl = new URLSearchParams(location.search).get('redirect');
			console.log(redirectUrl);
			const url = new URL(redirectUrl, window.location.origin); // Using current origin to parse

			// Extract the pathname and search parameters
			const trimmedUrl = url.pathname + url.search;
			if(res.data.isnew != null && res.data.isnew == true)
				{
				window.location.href = redirectUrl === null || undefined  ? '/select-sport' :redirectUrl;
				// window.location.href = '/select-sport';

					
					// navigate(trimmedUrl || '/select-sport')
				}
				else  {

					// window.location.href = trimmedUrl === '/null'  ? '/dashboard' :`${trimmedUrl}&wl`;
				window.location.href = redirectUrl === null || undefined  ? '/dashboard' :redirectUrl;

					// window.location.href ='/dashboard';

				}
				// navigate(trimmedUrl || '/dashboard')
			// if(res.data.data.role == 'admin') {
			// 	navigate('/admin/dashboard');
			// 	window.location.href = '/admin/dashboard';
			// } else {
			// 	navigate('/participant/dashboard');
			// }
		  }
		  else {
			enqueueSnackbar(res.data.message, { variant: 'error' });
		  }
	}

  return (
    <>
	<FormContainer>
		<form className='mt-3' onSubmit={HandelSubmit}>
			<label className='form-title mb-2' > LOGIN </label>
			<p className='form-heading'> Join CPL App create fun and earn money and create team enjoyed games.</p>
			<div className='form-outline mb-4'>
				<label className='mb-3 input-label' htmlFor="email">Email address</label>
				<input
					type='text'
					name='email'
					placeholder='Email'
					className='form-control bg-transparent form-input'
					onChange={HandelChange}
					value={formData.email}
					required
				/>
			</div>
			<div className="form-outline mb-4">
				<label className='mb-3 input-label' htmlFor="password">Password</label>
				<div className="position-relative">
					<input
						type={showPassword ? "password" : "text"}
						placeholder='Password'
						name='password'
						className='form-control bg-transparent form-input'
						onChange={HandelChange}
						value={formData.password}
						required
					/>
					<i className="btn btn-outline-secondary border-0 position-absolute end-0 top-0" type="button" id="togglePassword" onClick={togglePasswordVisibility}>
						{
							showPassword ? <EyeIcon /> : <EyeSlashFillIcon />
						}
					</i>
				</div>  
			</div>
			<p className='small mb-5 pb-lg-2 mt-3'>
				<a className='float-end login-navigation' href='#' onClick={(event)=> {event.preventDefault();navigate('/forgot-password')}}>
					Forgot password?
				</a>
			</p>
			<div className='pt-1 mb-4'>
				<button className='btn btn-light w-100 border-0 px-3 py-3 fs-5 text-white submit-button' type='submit' > Login </button>
				<span className='float-end mt-2 below-button'>Don`t Have Account Yet? <span class="cursor-pointer heading-color" onClick={()=>{navigate('/register')}}>Sign up</span></span>
			</div>
		</form>
	</FormContainer>
    </>
  )
}
export default Login
