import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Nav, Tab } from 'react-bootstrap'
import trophy from '../../assets/trophy1.png'
import CompleteMatchBg from '../../assets/images/complete-match-bg.png'
import Melbourne_Knight from '../../assets/images/teams/Melbourne_Knight.png'
import Sydney_thunder from '../../assets/images/teams/Sydney_thunder.png'
import Calander from '../../components/icons/calander'
import Location from '../../components/icons/location'
import Clock from '../../components/icons/Clock'
import '../../assets/css/Player/LatestMatch.css'
import { FormatDate, getTime, newFormatdate, format_2k_run_Time } from '../../Common/CommonFunction'
import cardBg from '../../assets/images/complete-match-bg.png'
import notFound from '../../assets/images/avatars/notfound1.png'
import golden from '../../assets/images/games/bronzer.svg'
import silver from '../../assets/images/games/silver.svg'
import bronze from '../../assets/images/games/golden.svg'

import { CCard, CCardBody, CCol, CRow } from '@coreui/react'

const LatestMatch = ({ latestMatch,gameType , gametypes}) => {
  console.log(latestMatch);
    const [game, setGame] = useState(null); 
  useEffect(() => {
    console.log("gamme"  + game)
    // gameType(getGame)
    setGame(gametypes);

}, [gametypes])
console.log(game);
  const CompleteMatchStyle = {
    backgroundImage: 'url(' + CompleteMatchBg + ')',
    backgroundSize: '3rem',
    backgroundRepeat: 'no-repeat'
  }
  const getGame = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let game = searchParams.get('game');
    if (!game) {
        game = 'Badminton';
    }
    return game;
};
  function extractdomain(inputString, startChar, endChar) {
    const startIndex = inputString.indexOf(startChar);
    const endIndex = inputString.indexOf(endChar);
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        return inputString.substring(startIndex + 1, endIndex);
    } else {
        console.error('Start or end characters not found or in the wrong order.');
        return inputString;
    }
}
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE
  let replaceUnderscoreWithSpace = (input) => {
    if (input.includes('_')) {
      return input.replace(/_/g, ' ');
    }
    return input;
  }

  const playerScore = (players, player_id) => {
    console.log("In functionm")
    console.log(players.find(player => player._id === player_id))
    return players.find(player => player._id === player_id);

}



  return (
    <div className='latest_match'>
      <h2 className='mt-5 mb-4 fw-bold fs-4 text-center'>Latest Match</h2>
      {latestMatch.length > 0 ? (

        game === 'Badminton' || game === 'TableTennis' ? (
          latestMatch.map((match, index) => (
            <>
              <Row className='mb-5'>
                <Col>
                  <Card
                    className='rounded-4 overflow-hidden complete-nav-card border-0'
                    style={CompleteMatchStyle}
                  >
                    <Card.Body className='p-0'>
                      <Tab.Container defaultActiveKey='#upcoming'>
                        <Nav fill variant='tabs' className='border-bottom-0'>
                          <Nav.Item className='flex-none w-25'>
                            <Nav.Link
                              eventKey='#upcoming'
                              className='nav-active-tab nav-link active border-0 text-white fw-bold px-5 rounde-bottom-right-1rem nav-link active myMatch'
                            >
                              {replaceUnderscoreWithSpace(match.round)}
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item className="flex-none">
                                                  <Nav.Link eventKey="#completed" className="bg-transparent nav-link active border-0 text-white fw-bold px-5">Melbourne Won the match  </Nav.Link>
                                              </Nav.Item> */}
                        </Nav>
                        <Tab.Content className='p-3 text-white mymatchcard'>
                          <Tab.Pane eventKey='#upcoming'>
                            <Row className=''>
                              {/* <Col xs={1} className='pt-4' id='trophyimg'>
                                  <span>
                                    {match.player_won ===
                                    match.player_details[0]._id ? (
                                      <img
                                        className='float-end'
                                        src={trophy}
                                        style={{ height: '30px' }}
                                      ></img>
                                    ) : null}
                                  </span>
                                </Col> */}
                              <Col
                                xs={8}
                                className='tab-contebt-right-border'
                                id='mymatchrow'
                              >
                                <div className='mt-3 d-flex align-items-center'>
  
                                  <div style={{ width: '57px' }}>
                                    <span className={match.player_won ===
                                      match.player_details[0]._id ? '' : 'd-none'}>
                                      <img
                                        src={trophy}
                                        style={{ height: '30px' }}
                                      ></img>
  
                                    </span>
                                  </div>
  
  
                                  <div
                                    style={{
                                      backgroundImage: `url(${match.player_details[0].profile_image !==
                                          undefined
                                          ?  IMAGE_URL+
                                          'user/' +
                                          match.player_details[0].profile_image
                                          : null
                                        })`,
                                      width: '75px',
                                      height: '77px',
                                      backgroundPosition: 'center'
                                    }}
                                    className=' scorer-img'
                                  // className="team_logo"
                                  ></div>
                                  {/* <img src={Melbourne_Knight} alt="team_logo" className="" /> */}
                                  <div className='w-25'>
                                    <div>
                                    <span className='ms-3 team-name'>
                                      {match.player_details[0].fname +
                                        ' ' +
                                        match.player_details[0].lname}
                                    </span>
                                    <p className="ms-3" style={{color:'#2ed5d5'}}> {extractdomain(match.player_details[0].corporate_email , '@' , '.')} </p>
                                      </div>
                                  </div>
                                  {match.player_details[0]._id!=null && match.player_1_id!=null && match.player_details[0]._id.toString() == match.player_1_id.toString()?( <span className="ms-md-auto ms-sm-auto ms-auto fw-bold">{match.player_1_score}</span>):( <span className="ms-md-auto ms-sm-auto ms-auto fw-bold">{match.player_2_score}</span>)}  
                                  
                                </div>
  
                                <div className='mt-3 d-flex align-items-center'>
                                  {/* <img src={Sydney_thunder} alt="team_logo" className="" /> */}
                                  <div style={{ width: '57px' }}>
                                    <span className={match.player_won ===
                                      match.player_details[1]._id ? '' : 'd-none'}>
                                      <img
                                        src={trophy}
                                        style={{ height: '30px' }}
                                      ></img>
  
                                    </span>
                                  </div>
  
                                  <div
                                    style={{
                                      backgroundImage: `url(${match.player_details[1].profile_image !==
                                          undefined
                                          ? IMAGE_URL +
                                          'user/' +
                                          match.player_details[1].profile_image
                                          : null
                                        })`,
                                      width: '75px',
                                      height: '77px',
                                      backgroundPosition: 'center'
                                    }}
                                    className='scorer-img'
                                  ></div>
                                  <div>
                                    <span className='ms-3 team-name'>
                                      {match.player_details[1].fname +
                                        ' ' +
                                        match.player_details[1].lname}
                                    </span>
                                    <p className="ms-3" style={{color:'#2ed5d5'}}>{extractdomain(match.player_details[1].corporate_email , '@' , '.')} </p>
                                  </div>
  
                                  {match.player_details[1]._id!=null&&  match.player_1_id!=null &&match.player_details[1]._id.toString() == match.player_1_id.toString()?( <span className="ms-md-auto ms-sm-auto ms-auto fw-bold">{match.player_1_score}</span>):( <span className="ms-md-auto ms-sm-auto ms-auto fw-bold">{match.player_2_score}</span>)}  
                                </div>
                              </Col>
                              <Col
                                xs={4}
                                className='match_schedule d-flex flex-column justify-content-center ps-3 col-4'
                              >
                                <div className='d-flex align-items-center'>
                                  <Calander />
                                  <span className='ms-2 '>
                                    { match.match_date != null ? newFormatdate(match.match_date, 1):'N/A'}
                                  </span>
                                </div>
                                <div className='d-flex align-items-center  my-3'>
                                  <Clock />
                                  <span className='ms-2'>
                                    { match.match_date != null ? newFormatdate(match.match_date, 0,1,0):'N/A'}
  
                                  </span>
                                </div>
                                <div className='d-flex align-items-center location-logo'>
                                  <Location />
                                  <span className='ms-2 ground-name'>
                                    Sydney Ground Australia
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey='#completed'>
                            <Row className='ps-md-5'>
                              <Col xs={1} className='pt-4'>
                                <span>
                                  <img
                                    className='float-end'
                                    src={trophy}
                                    style={{ height: '30px' }}
                                  ></img>
                                </span>
                              </Col>
                              <Col
                                xs={7}
                                className='tab-contebt-right-border px-md-5 px-sm-5 px-2 col-md-8 col-sm-6'
                              >
                                <div className='mt-md-3 d-flex align-items-center'>
                                  <img
                                    src={Melbourne_Knight}
                                    alt='team_logo'
                                    className=''
                                  />
                                  <span className='ms-3'>Melbourne Knight </span>
                                  <span className='ms-auto fw-bold'>3</span>
                                </div>
  
                                <div className='mt-3 d-flex align-items-center'>
                                  <img
                                    src={Sydney_thunder}
                                    alt='team_logo'
                                    className=''
                                  />
                                  <span className='ms-3'>Sydney thunder </span>
                                  <span className='ms-auto fw-bold'>1</span>
                                </div>
                              </Col>
  
                              <Col
                                xs={5}
                                className='match_schedule d-flex flex-column justify-content-center ps-md-3 col-md-4 col-sm-6match_schedule d-flex flex-column justify-content-center ps-md-3 col-md-4 col-sm-6'
                              >
                                <div className='d-flex align-items-center'>
                                  <Calander />
                                  <span className='ms-2'>11/04/2024</span>
                                </div>
                                <div className='d-flex align-items-center  my-3'>
                                  <Clock />
                                  <span className='ms-2'>5:00PM</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <Location />
                                  <span className='ms-2'>
                                    Sydney Ground Australia
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane> */}
                        </Tab.Content>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ))
        ) :game === 'bowling'? (
          latestMatch.map((match, index) => (
              <>
              <Row className='mb-5'>
                <Col>
                <Card
                    className='rounded-4 overflow-hidden complete-nav-card border-0'
                    style={CompleteMatchStyle}
                > 
                    <Card.Body className='p-0'>
                    <Row className="text-white mb-5">
                            <Col sm={4} xl={4} md={4}>
                                <p className="nav-active-tab align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 " style={{ background: 'red', width: '79%' }}><span className="text-center w-100 davidcup-span">{replaceUnderscoreWithSpace(match.round)}</span></p>
                            </Col>
                            
                    </Row>
                    
                    <Row className='text-white'>
                        <CCol xl={9} className='' >
                          <CRow>
                    
                          {
                            match.players && match.players.length > 0 && match.players.map((player, playerIndex) => {
                              const playerDetails = match.player_details.find(
                                details => details._id === player._id
                              );

                              if (!playerDetails) return null;
                              
                              return (
                                <CCol xl={6} className='mb-3' key={playerIndex}>
                                  <div className='d-flex justify-content-center align-items-center'>
                                    <div
                                      style={{
                                        backgroundImage: `url(${playerDetails.profile_image ? IMAGE_URL + "user/" + playerDetails.profile_image : ''})`,
                                        backgroundPosition: 'center',
                                        width: '80px',
                                        height: '80px',
                                        backgroundSize: 'cover', // Added to ensure the image covers the div
                                      }}
                                      className='scorer-img me-3'
                                    ></div>
                                    <div className='player-name me-5 text-start'>
                                      {/* name div */}
                                      {match.rank_1 === playerDetails._id ? (
                                        <span>
                                          <img src={golden} className='icons' alt='Golden Trophy' style={{ height: '20px' }} />
                                        </span>
                                      ) : match.rank_2 === playerDetails._id ? (
                                        <span>
                                          <img src={silver} className='icons' alt='Silver Trophy' style={{ height: '20px' }} />
                                        </span>
                                      ) : match.rank_3 === playerDetails._id && match.rank_3 != null ? (
                                        <span>
                                          <img src={bronze} className='icons' alt='Bronze Trophy' style={{ height: '20px' }} />
                                        </span>
                                      ) : null}
                                      <h6 className='mb-0'>{playerDetails.fname + ' ' + playerDetails.lname}</h6>
                                      <p className="m-0 p-0" style={{ color: '#2ed5d5' }}>{extractdomain(playerDetails.corporate_email, '@', '.')}</p>
                                    </div>
                                    <div className='player-score'>
                                      <span className='text-white fw-bold fs-2'>{playerScore(match.players, player._id).points}</span>
                                    </div>
                                  </div>
                                </CCol>
                              );
                            })
                          }



                          </CRow>
                          {/* <div className='d-flex align-items-center justify-content-around'>
                              <div className='w-100 ' >
                                  {match.player_details.map((player, playerIndex) => (
                                  <div className='d-flex justify-content-center align-items-center'>
                                        <div key={playerIndex} className='player-container'>
                                          <div
                                            style={{
                                              backgroundImage: `url(${player.profile_image ? IMAGE_URL + "user/" + player.profile_image : ''})`,
                                              backgroundPosition: 'center',
                                              width: '80px',
                                              height: '80px',
                                              backgroundSize: 'cover', // Added to ensure the image covers the div
                                            }}
                                            className='scorer-img'
                                          ></div>

                                          <div className='player-name'>
                                            
                                            {player.fname}
                                          </div>
                                          <div className='player-score'>
                                            <span className='text-white'>45</span>
                                          </div>
                                        </div>
                                  </div>
                                  ))}

                              </div>

                              <div  className='w-100 second-part'>
                            <h1 className='text-white '>Hello</h1>
                                
                              </div>
                          </div> */}
                        </CCol>
                        <CCol xl={3} >
                          <div className='d-flex flex-column gap-5 mt-0'>
                            <div className=''>
                              <span><Clock /></span>
                              <span className="ms-2">{match.match_date != null ? newFormatdate(match.match_date, 0, 1) : 'N/A'}</span>
                            </div>
                            <div>
                              <Calander />
                              <span className="ms-2">{match.match_date != null ? newFormatdate(match.match_date, 1) : 'N/A'}</span>
                            </div>
                            <div>
                              <Location />
                              <span>Sydney Ground Australia </span>
                            </div>
                          </div>
                         
                        </CCol>
                  </Row>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
            ))
            
        ) :(
          latestMatch.map((match, index) => (
            <Row className='mb-5'> 
          <Col>
                <Card
                    className='rounded-4 overflow-hidden complete-nav-card border-0'
                    style={CompleteMatchStyle}
                > 
                    <Card.Body className='p-0'>
                    <Row className="text-white mb-5">
                            <Col sm={4} xl={4} md={4}>
                                <p className="nav-active-tab align-items-center text-center border-radiusrb20px fw-bold text-white fs-5 py-2 " style={{ background: 'red', width: '79%' }}><span className="text-center w-100 davidcup-span">{replaceUnderscoreWithSpace(match.round)}</span></p>
                            </Col>
                            
                    </Row>
                     
                    <Row className='text-white'>
                        <CCol xl={9} className='' >
                        <div className='' style={{ maxHeight: '400px' }}>
                          <CRow className='custom-scrollbar'>

                          {match.players && match.players.length > 0 && match.players.map((player, playerIndex) => {
                            const playerDetails = match.player_details.find(
                              details => details._id === player._id
                            );

                            if (!playerDetails) return null;

                            return (
                              <CCol className='mb-4' key={playerIndex}>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <div
                                    style={{
                                      backgroundImage: `url(${playerDetails.profile_image ? IMAGE_URL + "user/" + playerDetails.profile_image : ''})`,
                                      backgroundPosition: 'center',
                                      width: '80px',
                                      height: '80px',
                                      backgroundSize: 'cover', // Added to ensure the image covers the div
                                    }}
                                    className='scorer-img me-3'
                                  ></div>
                                  <div className='player-name me-5 text-start'>
                                    {/* name div */}
                                    {match.rank_1 === playerDetails._id ? (
                                      <span>
                                        <img src={golden} className='icons' alt='Golden Trophy' style={{ height: '20px' }} />
                                      </span>
                                    ) : match.rank_2 === playerDetails._id ? (
                                      <span>
                                        <img src={silver} className='icons' alt='Silver Trophy' style={{ height: '20px' }} />
                                      </span>
                                    ) : match.rank_3 === playerDetails._id && match.rank_3 != null ? (
                                      <span>
                                        <img src={bronze} className='icons' alt='Bronze Trophy' style={{ height: '20px' }} />
                                      </span>
                                    ) : null}
                                    <h6 className='mb-0'>{playerDetails.fname + ' ' + playerDetails.lname}</h6>
                                    <p className="m-o p-0" style={{ color: '#2ed5d5' }}>{extractdomain(playerDetails.corporate_email, '@', '.')}</p>
                                  </div>
                                  <div className='player-score'>
                                    <span className='text-white fw-bold fs-2'>
                                      {format_2k_run_Time(playerScore(match.players, player._id).totalSeconds)}
                                    </span>
                                  </div>
                                </div>
                              </CCol>
                            );
                          })
                          }


                          </CRow>
                          </div>
                          {/* <div className='d-flex align-items-center justify-content-around'>
                              <div className='w-100 ' >
                                  {match.player_details.map((player, playerIndex) => (
                                  <div className='d-flex justify-content-center align-items-center'>
                                        <div key={playerIndex} className='player-container'>
                                          <div
                                            style={{
                                              backgroundImage: `url(${player.profile_image ? IMAGE_URL + "user/" + player.profile_image : ''})`,
                                              backgroundPosition: 'center',
                                              width: '80px',
                                              height: '80px',
                                              backgroundSize: 'cover', // Added to ensure the image covers the div
                                            }}
                                            className='scorer-img'
                                          ></div>

                                          <div className='player-name'>
                                            
                                            {player.fname}
                                          </div>
                                          <div className='player-score'>
                                            <span className='text-white'>45</span>
                                          </div>
                                        </div>
                                  </div>
                                  ))}

                              </div>

                              <div  className='w-100 second-part'>
                            <h1 className='text-white '>Hello</h1>
                                
                              </div>
                          </div> */}
                        </CCol>
                        <CCol xl={3} >
                          <div className='d-flex flex-column gap-5 mt-0'>
                            <div className=''>
                              <span><Clock /></span>
                              <span className="ms-2">{match.match_date != null ? newFormatdate(match.match_date, 0, 1) : 'N/A'}</span>
                            </div>
                            <div>
                              <Calander />
                              <span className="ms-2">{match.match_date != null ? newFormatdate(match.match_date, 1) : 'N/A'}</span>
                            </div>
                            <div>
                              <Location />
                              <span>Sydney Ground Australia </span>
                            </div>
                          </div>
                         
                        </CCol>
                  </Row>

                    </Card.Body>
                  </Card>
                </Col>
          </Row>
          ))
          
        )

       
      ) : (
        <>
          <CCard
            style={{
              position: 'relative',
              background: '#2A2729',
              borderRadius: '15px'
            }}
          >
            <div
              className='card-background'
              style={{ backgroundImage: `url(${cardBg})` }}
            />
            <CCardBody
              className='d-flex justify-content-center align-items-center'
              style={{ padding: '0px', color: '#fff', height: '200px' }}
            >
              <div>
                <div
                  style={{
                    backgroundImage: `url(${notFound})`,
                    border: 'none'
                  }}
                  className='scorer-card-logo bg-image-container2  ms-4'
                ></div>
                <span
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    fontWeight: '700'
                  }}
                >
                  No Latest Match found
                </span>
              </div>
            </CCardBody>
          </CCard>
        </>
      )}
    </div>
  )
}
export default React.memo(LatestMatch)
