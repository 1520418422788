import React, { useEffect, useState } from "react";
import { Profile as ProfileAPI, getSetting } from "../API/admin";
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons';
import { CButton } from "@coreui/react";
import ChangePassword from "./modal/ChangePassword";
import Profileupdate from "./modal/profileupdate";
import Withdrwal from "./modal/Withdrawal";
const Profile = () => {
    const [user, setUser] = useState([]);
    const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE;
    const [visible, setVisible] = useState(false)
    const [isparticipant, setIsparticipant] = useState(false)
    const [withdrawal, setwithdrwal] = useState(false)


    useEffect(() => {
        callAPI();
        callAPI2();
    }, [])

    let callAPI = async () => {
        let res = await ProfileAPI();
        console.log(res);
        if (res.status) {
            setUser(res.data)
            console.log(res)
            setIsparticipant(res.data.role === 'participant' ? true : false)
        }
    }

   
    let callAPI2 = async () => {
        let res =  await getSetting()

        if(res.status) {
            setwithdrwal(res.data.value);
            
        } else { 
            setIsOn(null);
            enqueueSnackbar('unable to fetch setting', { variant: 'error' });

        } 
    }
    return (
        <>
            <section>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body text-center">
                                    <img
                                        src={user.profile_image !== undefined ?
                                            IMAGE_URL + "user/" + user.profile_image : IMAGE_URL + "profile.jpg"}
                                        alt="avatar"
                                        className="img-fluid"
                                        style={{ width: 150 }}
                                    />
                                    <h5 className="my-3">{user.fname + " " + user.lname}</h5>
                                    <p className="text-muted mb-1">{user.role}</p>
                                    <p className="text-muted mb-4">Corporate Premier Games</p>
                                    <ChangePassword onClick={() => setVisible(true)} visble={visible} ></ChangePassword>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="mb-0">Full Name</p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p className="text-muted mb-0">{user.fname + " " + user.lname}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="mb-0">Email</p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p className="text-muted mb-0">{user.email}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="mb-0">Corporate Email</p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p className="text-muted mb-0">{user.corporate_email ? user.corporate_email : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="mb-0">Gender</p>
                                        </div>
                                        <div className="col-sm-9">
                                            <p className="text-muted mb-0">{user.gender}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <p className="mb-0">Sports</p>
                                        </div>
                                        <div className="col-sm-9">
                                            {

                                                <p className="text-muted mb-0">{user.sports}</p>
                                            }

                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                <Profileupdate user_details ={user} ></Profileupdate>
                                   
                                </div>
                                <div>
                                    {
                                        isparticipant && withdrawal  ==="true"? (

                                            <Withdrwal visible={true}> </Withdrwal>
                                        ) : null 
                                    }
                                    {/* <CButton className="bg-danger text-white">Withdrwal</CButton> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default Profile