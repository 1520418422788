import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb';
import {GetCorporate, DeleteCorporate} from  '../../../../API/admin'
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'

const Corporates = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Corporates', to: '/corporate', active: true }
    ];

    const [Corporates, setCorporates] = useState([]);
	const Navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
    
    let  GetCorporateAPI = async() =>{
        let res =  await GetCorporate()
        if(res.status) {
            setCorporates(res.data);
        } else {
            setCorporates([]);
        } 
    }

    const HandelDelete =async(urlid) => {
        let res = await Swal.fire({
              title: "Are you sure?",
              text: "Are you sure , you want to delete this corporate?",
              icon: "warning" });
              if (res.isConfirmed) {
                  let callAPI = async ()=> {
                      let corporateID = {
                          id : urlid
                      }
                      let res = await DeleteCorporate(corporateID);  
                      if (res.status) {
                          enqueueSnackbar("Corporate Deleted successfully", { variant: 'success' });
                          GetCorporateAPI()   
                      } else {
                        //   enqueueSnackbar("Can not delete corporate", { variant: 'error' });
                          Navigate('/corporate')
                      }
                  }
                  callAPI();
              }
    }

    const HandelChange = (event) => {
        let callAPI = async() => {
            let res =  await GetCorporate(search)
            if(res.status) {
                setCorporates(res.data);
            } else {
                enqueueSnackbar("No Record Found ", { variant: 'error' });
                Navigate('/corporate');
            }
        }
        let search = {
            data: event.target.value
        }
        callAPI(search);
    }

    useEffect( () => {
        GetCorporateAPI()
    }, [])

    return (
        <>
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            
            <CCard>
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h3 className="card-title mb-3">
                            Corporates
                        </h3>
                    </CCol>
                </CRow>
                <CRow>    
                    <CCol sm={3} className="d-none d-md-block">
                        <input type="search" className='form-control' placeholder="Search" name='data' onChange={HandelChange}/>
                    </CCol>
                    <CCol sm={9}>
                        <Link to={'/corporate/create'}>
                            <CButton className='float-end text-white' color="info">
                                <i className="bi bi-plus-circle me-2"></i>
                                Add Corporate
                            </CButton>
                        </Link>
                    </CCol>
                </CRow><br />
                {/* <MainChart /> */}
                <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CTable striped>
                    <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Domain</CTableHeaderCell>
                    </CTableRow>
                    </CTableHead>
                    <CTableBody>
                    {Corporates.map((corporate, index) => (
                        <CTableRow>
                            <CTableDataCell key={index}>{index+1}</CTableDataCell>
                            <CTableDataCell key={index}>{corporate.name}</CTableDataCell>
                            <CTableDataCell key={index}>{corporate.domain}</CTableDataCell>
                            <CTableDataCell key={index}>
                                {/* <Link to={`/corporate/detail?id=${corporate._id}`}>
                                    <CButton title='View'  className='bi bi-eye-fill text-white me-2' color="primary"></CButton>
                                </Link> */}
                                <Link to={`/corporate/edit?id=${corporate._id}`}>
                                    <CButton title='Edit' className='bi bi-pencil text-white' color="warning"></CButton>
                                </Link>
                                <CButton title='Delete' className='bi bi-trash-fill text-white mx-2' color="danger" onClick={() => HandelDelete(corporate._id)}></CButton>
                            </CTableDataCell>
                        </CTableRow>
                    )
                    )}
                    </CTableBody>
                </CTable>
                </CRow>
                </CCardBody>
            </CCard>
        </>
    )
}

export default Corporates;