import React,{useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from"react-router-dom";

function Redirect(data) {
    let navigate = useNavigate();
    const redirect_url = useSelector((state) => state.redirect_url);
  
    useEffect(() => {
        navigate(data.url);
        setTimeout(function(){
            data.callback();
        }, 2000);
    }, [redirect_url]);
    return (
        <>
            <div className='text-center pt-5 vh-100 bg-dark text-white'>
                <h1>Redirecting...</h1>
                <p>We're redirecting you to {data.url} <a href={data.url}>Click here.</a></p>
			</div>
        </>
    )
}
export default Redirect;