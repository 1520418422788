import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Loader from '../admin/Common/Loader'
import moment from 'moment-timezone'
import Pagination from "./pagination";
import { useSnackbar } from 'notistack'
import { GetMatches, SetMatchResult, UpdateStatus } from '../../API/scorer'

import cardBg from '../../assets/images/complete-match-bg.png'
import add from '../../assets/images/add.png'
import back from '../../assets/images/back.png'
import less from '../../assets/images/less.png'
import notFound from '../../assets/images/avatars/notfound1.png'
import trophy from '../../assets/trophy1.png'
import { FormatDate , newFormatdate} from '../../Common/CommonFunction'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter
} from '@coreui/react'
import { isVisible } from '@testing-library/user-event/dist/utils'

const ScorerDashboard = ({ selectedMenu }) => {
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE
  const TIME_ZONE = process.env.TIME_ZONE

  const { enqueueSnackbar } = useSnackbar()
  const [currentTab, setCurrentTab] = useState('ongoing')
  const [getMatchData, setGetMatchData] = useState([])
  const [cardRecord, setCardRecord] = useState([])
  const [loaderStatus, setloaderStatus] = useState(true)
  const [refreshData, setRefreshData] = useState(false)
  // const [matchData, setmatchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  let location = useLocation()

  const is24HoursCompleted = date2 => {
    let date1 = moment.tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
    const msIn24Hours = 24 * 60 * 60 * 1000
    const diff = Math.abs(new Date(date1) - new Date(date2))
    return diff >= msIn24Hours
  }

  const MathcCal = async (game, currentTab) => {
    let matchData = await GetMatches(game, currentTab)
    try {
      let totalRecord = {}
      let editUser = false
      for (let record of matchData.data.message) {
        editUser = false
        if (record.submited_at != undefined) {
          if (!is24HoursCompleted(record.submited_at)) {
            editUser = true
          }
        }
        totalRecord[record._id.toString()] = {
          player_1_score:
            record.player_1_score != undefined ? record.player_1_score : 0,
          player_2_score:
            record.player_2_score != undefined ? record.player_2_score : 0,
          editMode: false,
          isEditMode: editUser,
          isVisible: false
        }
      }
      setCardRecord(totalRecord)
      setGetMatchData(matchData.data.message)
    } catch (error) {
      setGetMatchData([])
    }
  }

  const submitForm = async getData => {
    submitData(cardRecord, getData)
  }

  const submitData = async (submitRecord, getData) => {
    if (getData.proccess == 'submit' || getData.proccess == 'update') {
      setloaderStatus(true);
        if (submitRecord[getData.matchId].player_1_score == 0 && submitRecord[getData.matchId].player_2_score == 0) {

        // enqueueSnackbar({'message':"Both players can not have 0 as score"}, {variant:"error"})

        enqueueSnackbar('Both players can not have 0 as score', { variant: 'error' })
        setloaderStatus(false);
        
        return 0;

      } else if (submitRecord[getData.matchId].player_1_score == submitRecord[getData.matchId].player_2_score ) {

        // enqueueSnackbar("", {variant:"error"})

        enqueueSnackbar('Match can not be tied', { variant: 'error' })
        setloaderStatus(false);

        return 0;

      }

       else {

        setloaderStatus(true);



      }
    }
    let formData = {
      player_1_score: submitRecord[getData.matchId].player_1_score,
      player_2_score: submitRecord[getData.matchId].player_2_score,
      match_id: getData.matchId,
      record_type: getData.proccess
    }
    let res = await SetMatchResult(formData)

    if (getData.proccess == 'submit' || getData.proccess == 'update') {
      if (res.status) {
        setloaderStatus(false);
        enqueueSnackbar('Scores submited successfully', { variant: 'success' })
        window.location.reload()
      } else {
        setloaderStatus(false);
        enqueueSnackbar('unable to submit scores', { variant: 'error' })
      }
    }
  }

  const handelEditMode = getData => {
    setCardRecord(prevCardRecord => {
      let updateRecord = { ...prevCardRecord }
      if (getData.matchId != undefined) {
        if (updateRecord[getData.matchId] != undefined) {
          if (getData.type == 'cancel') {
            updateRecord[getData.matchId].editMode = false
          } else {
            updateRecord[getData.matchId].editMode = true
          }
        }
      }

      return updateRecord
    })
  }

  const callModel = (type, match_id) => {
    setCardRecord(prevCardRecord => {
      let updateRecord = { ...prevCardRecord }
      if (updateRecord[match_id] != undefined) {
        console.log(type)
        if (type == 'open') {
          updateRecord[match_id].isVisible = true
        } else {
          updateRecord[match_id].isVisible = false
        }
      }
      return updateRecord
    })
    // if(cardRecord[match_id]!=undefined)
  }

  const handleUpdateRecord = getData => {
    setCardRecord(prevCardRecord => {
      let updateRecord = { ...prevCardRecord }
      if (getData.matchId != undefined) {
        if (updateRecord[getData.matchId] != undefined) {
          if (getData.player == 'player_1_score') {
            if (getData.type == 'increase') {
              updateRecord[getData.matchId].player_1_score++
            } else {
              if (updateRecord[getData.matchId].player_1_score != 0) {
                updateRecord[getData.matchId].player_1_score--
              }
            }
          } else if (getData.player == 'player_2_score') {
            if (getData.type == 'increase') {
              updateRecord[getData.matchId].player_2_score++
            } else {
              if (updateRecord[getData.matchId].player_2_score != 0) {
                updateRecord[getData.matchId].player_2_score--
              }
            }
          }
        }
      }
      console.log(getData.proccess)
      if (getData.proccess != 'score_update') {
        submitData(updateRecord, getData)
      }

      return updateRecord
    })
  }
  let replaceUnderscoreWithSpace = (input) => {
    if (input.includes('_')) {
      return input.replace(/_/g, ' ');
    }
    return input;
  }
  useEffect(() => {
    setloaderStatus(true)
    console.log('testdata')
    const searchParams = new URLSearchParams(location.search)
    let game = searchParams.get('game')
    if (game == '' || game == null) {
      game = 'Badminton'
    }

    let callAPI = async () => {
      await MathcCal(game, currentTab)
    }
    callAPI()
    let callAPI2 = async () => {
      let formData = {
        game: game
      }
      let res = await UpdateStatus(formData)
      if (res.status && res.data.data > 0) {
        // window.location.href('dashboard');
        setRefreshData(true)
        enqueueSnackbar('Match is Live now', { variant: 'success' })
        console.log('Match is live')
      } else {
        console.log('wait')
      }
    }

    let intervalId = setInterval(() => {
      callAPI2()
    }, 5000)
    let timeoutId = setTimeout(() => {
      setloaderStatus(false)
    }, 2000)
    return () => {
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [location.search, currentTab, refreshData])
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = getMatchData.slice(firstPostIndex, lastPostIndex);

  return (
    <>
      {loaderStatus ? <Loader /> : null}
      <CNav className='justify-content-center scorer-tab' variant='underline'>
        <CNavItem className='scorer-tab-item'>
          <CNavLink
            className='scorer-tab-link'
            href='#!'
            active={currentTab === 'ongoing'}
            onClick={() => setCurrentTab('ongoing')}
          >
            Ongoing
          </CNavLink>
        </CNavItem>
        <CNavItem className='scorer-tab-item'>
          <CNavLink
            className='scorer-tab-link'
            href='#!'
            active={currentTab === 'upcoming'}
            onClick={() => setCurrentTab('upcoming')}
          >
            Upcoming
          </CNavLink>
        </CNavItem>
        <CNavItem className='scorer-tab-item'>
          <CNavLink
            className='scorer-tab-link'
            href='#!'
            active={currentTab === 'completed'}
            onClick={() => setCurrentTab('completed')}
          >
            Completed
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane
          role='tabpanel'
          aria-labelledby='Ongoing'
          visible={currentTab === 'ongoing'}
        >
          <CContainer className='px-5 '>
            {currentTab == 'ongoing' ? (
              getMatchData.length > 0 ? (
                getMatchData.map((match, index) => (
                  <CRow>
                    <CCol>
                      <CCard
                        className='scorer-db'
                        style={{
                          backgroundImage: `url(${back})`,
                          marginBottom: '40px'
                        }}
                      >
                        <div className='card-background' />
                        <CCardBody style={{ padding: '0px', color: '#fff' }}>
                          <CRow>
                            <CCol sm={4} xl={4} md={4}>
                              <p
                                className='scorer-card-title'
                                style={{ background: 'red', width: '50%' }}
                              >
                                {match.group_data.length >0  ?  match.group_data[0].name
                                 : replaceUnderscoreWithSpace(match.round)}


                              </p>
                            </CCol>
                            <CCol sm={4} xl={4} md={4}>
                              <p
                                className='scorer-date'
                                style={{
                                  color: '#f4f821',
                                  textAlign: 'center'
                                }}
                              >
                                {/* {FormatDate(match.match_date, 0, 1, 0)} */}
                                {newFormatdate(match.match_date,1)}
                              </p>
                            </CCol>
                            <CCol sm={4} xl={4} md={4}>
                              <CButton
                                className='scorer-card-button'
                                id={match.id}
                                style={{
                                  background: '#275E9A',
                                  width: '50%',
                                  float: 'right'
                                }}
                                onClick={() =>
                                  callModel('open', match._id.toString())
                                }
                              >
                                Submit
                              </CButton>
                              <CModal
                                visible={
                                  cardRecord[match._id.toString()] !=
                                    undefined &&
                                  cardRecord[match._id.toString()].isVisible
                                }
                                onClose={() =>
                                  callModel('close', match._id.toString())
                                }
                                aria-labelledby='LiveDemoExampleLabel'
                              >
                                <CModalHeader>
                                  <CModalTitle id='LiveDemoExampleLabel'>
                                    Submit Scores
                                  </CModalTitle>
                                </CModalHeader>
                                <CModalBody>
                                  <p>
                                    Are you sure, you want to submit score for
                                    this match?
                                  </p>
                                </CModalBody>
                                <CModalFooter>
                                  <CButton
                                    color='secondary'
                                    onClick={() =>
                                      callModel('close', match._id.toString())
                                    }
                                  >
                                    Close
                                  </CButton>
                                  <CButton
                                    id={match.id}
                                    color='danger'
                                    className='text-white'
                                    onClick={() =>
                                      submitForm({
                                        matchId: match._id.toString(),
                                        proccess: 'submit'
                                      })
                                    }
                                  >
                                    Submit Score
                                  </CButton>
                                </CModalFooter>
                              </CModal>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol
                              sm={5}
                              xl={5}
                              md={5}
                              style={{ marginTop: '20px' }}
                            >
                              <div class='flex-container'>
                                <div
                                  style={{
                                    width: '20%',
                                    textAlign: 'center',
                                    paddingTop: '40px'
                                  }}
                                >
                                  <CButton
                                    className='score-increase'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: match._id.toString(),
                                        cardType: 'ongoing',
                                        player: 'player_1_score',
                                        type: 'increase',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={add} />
                                  </CButton>
                                  <CButton
                                    className='score-less'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: match._id.toString(),
                                        cardType: 'ongoing',
                                        player: 'player_1_score',
                                        type: 'less',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={less} />
                                  </CButton>
                                </div>
                                <div>
                                  <div
                                    style={{ width: '18%' }}
                                    className='scorer-card-logo'
                                  >
                                    {/* <img className='scorer-img' src={p1_img} /> */}
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_1_data[0]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_1_data[0]
                                                .profile_image
                                            : null
                                        })`
                                      }}
                                      className=' scorer-img'
                                    ></div>
                                  </div>
                                  <p className='scorer-name'>
                                    {match.player_1_data[0].fname +
                                      ' ' +
                                      match.player_1_data[0].lname}
                                  </p>
                                </div>
                              </div>
                            </CCol>
                            <CCol
                              sm={2}
                              xl={2}
                              md={2}
                              style={{ textAlign: 'center', marginTop: '20px' }}
                            >
                              <p
                                className='scorer-name'
                                style={{ paddingTop: '50px' }}
                              >
                                {cardRecord[match._id.toString()] !=
                                undefined ? (
                                  <span>
                                    {
                                      cardRecord[match._id.toString()]
                                        .player_1_score
                                    }
                                  </span>
                                ) : (
                                  <span>0</span>
                                )}
                                {/* <span>{cardRecord}</span> */}
                                <span>&nbsp;-&nbsp;</span>
                                {/* <span>{count2}</span> */}
                                {cardRecord[match._id.toString()] !=
                                undefined ? (
                                  <span>
                                    {
                                      cardRecord[match._id.toString()]
                                        .player_2_score
                                    }
                                  </span>
                                ) : (
                                  <span>0</span>
                                )}
                              </p>
                            </CCol>
                            <CCol
                              sm={5}
                              xl={5}
                              md={5}
                              style={{ marginTop: '20px' }}
                            >
                              <div
                                class='flex-container'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <div>
                                  <div
                                    style={{ alignItems: 'end' }}
                                    className='scorer-card-logo'
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_2_data[0]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_2_data[0]
                                                .profile_image
                                            : null
                                        })`
                                      }}
                                      className=' scorer-img'
                                    ></div>
                                  </div>
                                  <p className='scorer-name'>
                                    {match.player_2_data[0].fname +
                                      ' ' +
                                      match.player_2_data[0].lname}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: '20%',
                                    textAlign: 'center',
                                    paddingBottom: '25px'
                                  }}
                                >
                                  <CButton
                                    className='score-increase'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: match._id.toString(),
                                        cardType: 'ongoing',
                                        player: 'player_2_score',
                                        type: 'increase',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={add} />
                                  </CButton>
                                  <CButton
                                    className='score-less'
                                    onClick={() =>
                                      handleUpdateRecord({
                                        matchId: match._id.toString(),
                                        cardType: 'ongoing',
                                        player: 'player_2_score',
                                        type: 'less',
                                        proccess: 'score'
                                      })
                                    }
                                  >
                                    <img src={less} />
                                  </CButton>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol
                              sm={4}
                              xl={4}
                              md={4}
                              style={{
                                marginTop: '10px',
                                marginBottom: '10px'
                              }}
                            >
                              <div class='flex-container'>
                                <div
                                  style={{
                                    width: '25%',
                                    textAlign: 'center',
                                    paddingTop: '4px'
                                  }}
                                ></div>
                                {/* <div style={{ width: '75%' }}>
                                                                <p className='scorer-date' style={{color: '#f4f821'}}>27/04/2024 5:00PM</p>        
                                                            </div> */}
                              </div>
                            </CCol>
                            {/* <CCol sm={4} xl={4} md={4} style={{textAlign:'center',marginTop:'10px', marginBottom:'10px'}}>
                                                        <p className='scorer-place'>
                                                            <span>Sydney Ground Australia</span>
                                                        </p>        
                                                    </CCol> */}
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                ))
              ) : (
                <CCard
                  style={{
                    position: 'relative',
                    background: '#2A2729',
                    borderRadius: '15px'
                  }}
                >
                  <div
                    className='card-background'
                    style={{ backgroundImage: `url(${cardBg})` }}
                  />
                  <CCardBody
                    className='d-flex justify-content-center align-items-center'
                    style={{ padding: '0px', color: '#fff', height: '200px' }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${notFound})`,
                          border: 'none'
                        }}
                        className='scorer-card-logo bg-image-container2  ms-4'
                      ></div>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: '700'
                        }}
                      >
                        No Ongoing matches
                      </span>
                    </div>
                  </CCardBody>
                </CCard>
              )
            ) : null}
          </CContainer>
        </CTabPane>
        <CTabPane
          role='tabpanel'
          aria-labelledby='upcoming'
          visible={currentTab === 'upcoming'}
        >
          <CContainer className='px-5 '>
            {currentTab == 'upcoming' ? (
              getMatchData.length > 0 ? (
                getMatchData.map((match, index) => (
                  <CRow className='mb-5'>
                    <CCol>
                      <CCard
                        className='scorer-db'
                        style={{ backgroundImage: `url(${back})` }}
                      >
                        <div className='card-background' />
                        <CCardBody style={{ padding: '0px', color: '#fff' }}>
                          <CRow>
                            <CCol sm={4} xl={4} md={4}>
                              <p
                                className='scorer-card-title'
                                style={{ background: 'red', width: '50%' }}
                              >
                                {match.group_data.length >0  ?  match.group_data[0].name : replaceUnderscoreWithSpace(match.round) }

                              </p>
                            </CCol>
                            <CCol sm={4} xl={4} md={4}>
                              <p
                                className='scorer-date'
                                style={{
                                  color: '#f4f821',
                                  textAlign: 'center'
                                }}
                              >
                                {/* {FormatDate(match.match_date, 0, 1, 0)} */}
                                {newFormatdate(match.match_date,1)}
                              </p>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol
                              sm={5}
                              xl={5}
                              md={5}
                              style={{ marginTop: '20px' }}
                            >
                              <div class='flex-container'>
                                <div
                                  style={{
                                    width: '20%',
                                    textAlign: 'center',
                                    paddingTop: '20px'
                                  }}
                                ></div>
                                <div>
                                  <div
                                    style={{ width: '18%' }}
                                    className='scorer-card-logo'
                                  >
                                    {/* <img className='scorer-img' src={p1_img} /> */}
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_1_data[0]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_1_data[0]
                                                .profile_image
                                            : null
                                        })`
                                      }}
                                      className=' scorer-img'
                                    ></div>
                                  </div>
                                  <p className='scorer-name'>
                                    {match.player_1_data[0].fname +
                                      ' ' +
                                      match.player_1_data[0].lname}
                                  </p>
                                </div>
                              </div>
                            </CCol>
                            <CCol
                              sm={2}
                              xl={2}
                              md={2}
                              style={{ textAlign: 'center', marginTop: '20px' }}
                            >
                              <p
                                className='scorer-name'
                                style={{ paddingTop: '50px' }}
                              >
                                <span style={{ color: '#F4F821' }}>V/S</span>
                              </p>
                            </CCol>
                            <CCol
                              sm={5}
                              xl={5}
                              md={5}
                              style={{ marginTop: '20px' }}
                            >
                              <div
                                class='flex-container'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <div>
                                  <div
                                    style={{ alignItems: 'end' }}
                                    className='scorer-card-logo'
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          match.player_2_data[0]
                                            .profile_image !== undefined
                                            ? IMAGE_URL +
                                              'user/' +
                                              match.player_2_data[0]
                                                .profile_image
                                            : null
                                        })`
                                      }}
                                      className=' scorer-img'
                                    ></div>
                                  </div>
                                  <p className='scorer-name'>
                                    {match.player_2_data[0].fname +
                                      ' ' +
                                      match.player_2_data[0].lname}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: '20%',
                                    textAlign: 'center',
                                    paddingTop: '20px'
                                  }}
                                ></div>
                              </div>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol
                              sm={4}
                              xl={4}
                              md={4}
                              style={{
                                marginTop: '10px',
                                marginBottom: '20px'
                              }}
                            >
                              <div class='flex-container'>
                                <div
                                  style={{
                                    width: '75%',
                                    textAlign: 'right',
                                    paddingRight: '10px'
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: '25%',
                                    textAlign: 'center',
                                    paddingTop: '4px'
                                  }}
                                ></div>
                              </div>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                ))
              ) : (
                <CCard
                  style={{
                    position: 'relative',
                    background: '#2A2729',
                    borderRadius: '15px'
                  }}
                >
                  <div
                    className='card-background'
                    style={{ backgroundImage: `url(${cardBg})` }}
                  />
                  <CCardBody
                    className='d-flex justify-content-center align-items-center'
                    style={{ padding: '0px', color: '#fff', height: '200px' }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${notFound})`,
                          border: 'none'
                        }}
                        className='scorer-card-logo bg-image-container2  ms-4'
                      ></div>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: '700'
                        }}
                      >
                        No Upcoming matches
                      </span>
                    </div>
                  </CCardBody>
                </CCard>
              )
            ) : null}
          </CContainer>
        </CTabPane>
        <CTabPane
          role='tabpanel'
          aria-labelledby='Ongoingcompleted'
          visible={currentTab === 'completed'}
        >
          <CContainer className='px-5 '>
            {currentTab == 'completed' ? (
              
              currentPosts.length > 0 ? (
                <div>
               { currentPosts.map((match, index) => (
                  <CRow>
                    <CCol>
                      {cardRecord[match._id.toString()].isEditMode ? (
                        <CCard
                          className='scorer-db'
                          style={{
                            backgroundImage: `url(${back})`,
                            marginBottom: '40px'
                          }}
                        >
                          <div className='card-background' />
                          <CCardBody style={{ padding: '0px', color: '#fff' }}>
                            <CRow>
                              <CCol sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-card-title'
                                  style={{ background: 'red', width: '50%' }}
                                >
                                {match.group_data.length >0  ?  match.group_data[0].name : replaceUnderscoreWithSpace(match.round) }

                                </p>
                              </CCol>
                              <CCol sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-date'
                                  style={{
                                    color: '#f4f821',
                                    textAlign: 'center'
                                  }}
                                >
                                  {/* {FormatDate(match.match_date, 0, 1, 0)} */}
                                  {newFormatdate(match.match_date,1)}
                                </p>
                              </CCol>
                              <CCol sm={4} xl={4} md={4}>
                                {cardRecord[match._id.toString()] !=
                                undefined ? (
                                  cardRecord[match._id.toString()].editMode ==
                                  true ? (
                                    <>
                                      <CButton
                                        className='scorer-card-button'
                                        id={match.id}
                                        style={{
                                          background: '#FF0000',
                                          width: '50%',
                                          float: 'right',
                                          borderRadius: '0px 15px 0px 0px'
                                        }}
                                        onClick={() =>
                                          handelEditMode({
                                            matchId: match._id.toString(),
                                            type: 'cancel'
                                          })
                                        }
                                      >
                                        Cancel
                                      </CButton>
                                      <CButton
                                        className='scorer-card-button'
                                        id={match.id}
                                        style={{
                                          background: '#275E9A',
                                          width: '50%',
                                          float: 'right',
                                          borderRadius: '0px 0px 0px 14px'
                                        }}
                                        onClick={() =>
                                          callModel(
                                            'open',
                                            match._id.toString()
                                          )
                                        }
                                      >
                                        Save
                                      </CButton>
                                    </>
                                  ) : (
                                    <CButton
                                      className='scorer-card-button'
                                      id={match.id}
                                      style={{
                                        background: '#275E9A',
                                        width: '50%',
                                        float: 'right'
                                      }}
                                      onClick={() =>
                                        handelEditMode({
                                          matchId: match._id.toString(),
                                          type: 'edit'
                                        })
                                      }
                                    >
                                      Edit
                                    </CButton>
                                  )
                                ) : (
                                  <CButton
                                    className='scorer-card-button'
                                    id={match.id}
                                    style={{
                                      background: '#275E9A',
                                      width: '50%',
                                      float: 'right'
                                    }}
                                    onClick={() =>
                                      callModel('open', match._id.toString())
                                    }
                                  >
                                    Save
                                  </CButton>
                                )}

                                <CModal
                                  visible={
                                    cardRecord[match._id.toString()] !=
                                      undefined &&
                                    cardRecord[match._id.toString()].isVisible
                                  }
                                  onClose={() =>
                                    callModel('close', match._id.toString())
                                  }
                                  aria-labelledby='LiveDemoExampleLabel'
                                >
                                  <CModalHeader>
                                    <CModalTitle id='LiveDemoExampleLabel'>
                                      Submit Scores
                                    </CModalTitle>
                                  </CModalHeader>
                                  <CModalBody>
                                    <p>
                                      Are you sure, you want to submit score for
                                      this match?
                                    </p>
                                  </CModalBody>
                                  <CModalFooter>
                                    <CButton
                                      color='secondary'
                                      onClick={() =>
                                        callModel('close', match._id.toString())
                                      }
                                    >
                                      Close
                                    </CButton>
                                    <CButton
                                      id={match.id}
                                      color='danger'
                                      className='text-white'
                                      onClick={() =>
                                        submitForm({
                                          matchId: match._id.toString(),
                                          proccess: 'update'
                                        })
                                      }
                                    >
                                      Submit Score
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol
                                sm={5}
                                xl={5}
                                md={5}
                                style={{ marginTop: '20px' }}
                              >
                                <div class='flex-container'>
                                  <div
                                    style={{
                                      width: '20%',
                                      textAlign: 'center',
                                      paddingTop: '40px'
                                    }}
                                  >
                                    {cardRecord[match._id.toString()] !=
                                    undefined ? (
                                      cardRecord[match._id.toString()]
                                        .editMode == true ? (
                                        <>
                                          <CButton
                                            className='score-increase'
                                            onClick={() =>
                                              handleUpdateRecord({
                                                matchId: match._id.toString(),
                                                cardType: 'ongoing',
                                                player: 'player_1_score',
                                                type: 'increase',
                                                proccess: 'score_update'
                                              })
                                            }
                                          >
                                            <img src={add} />
                                          </CButton>
                                          <CButton
                                            className='score-less'
                                            onClick={() =>
                                              handleUpdateRecord({
                                                matchId: match._id.toString(),
                                                cardType: 'ongoing',
                                                player: 'player_1_score',
                                                type: 'less',
                                                proccess: 'score_update'
                                              })
                                            }
                                          >
                                            <img src={less} />
                                          </CButton>
                                        </>
                                      ) : null
                                    ) : (
                                      <>
                                        <CButton
                                          className='score-increase'
                                          onClick={() =>
                                            handleUpdateRecord({
                                              matchId: match._id.toString(),
                                              cardType: 'ongoing',
                                              player: 'player_1_score',
                                              type: 'increase',
                                              proccess: 'score_update'
                                            })
                                          }
                                        >
                                          <img src={add} />
                                        </CButton>
                                        <CButton
                                          className='score-less'
                                          onClick={() =>
                                            handleUpdateRecord({
                                              matchId: match._id.toString(),
                                              cardType: 'ongoing',
                                              player: 'player_1_score',
                                              type: 'less',
                                              proccess: 'score_update'
                                            })
                                          }
                                        >
                                          <img src={less} />
                                        </CButton>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <div
                                      style={{ width: '18%' }}
                                      className='scorer-card-logo'
                                    >
                                      {/* <img className='scorer-img' src={p1_img} /> */}
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_1_data[0]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_1_data[0]
                                                  .profile_image
                                              : null
                                          })`
                                        }}
                                        className=' scorer-img'
                                      ></div>
                                    </div>
                                    <p className='scorer-name'>
                                      {match.player_1_data[0].fname +
                                        ' ' +
                                        match.player_1_data[0].lname}
                                    </p>
                                  </div>
                                </div>
                              </CCol>
                              <CCol
                                sm={2}
                                xl={2}
                                md={2}
                                style={{
                                  textAlign: 'center',
                                  marginTop: '20px'
                                }}
                              >
                                <p
                                  className='scorer-name'
                                  style={{ paddingTop: '50px' }}
                                >
                                  {cardRecord[match._id.toString()] !=
                                  undefined ? (
                                    <span>
                                      {
                                        cardRecord[match._id.toString()]
                                          .player_1_score
                                      }
                                    </span>
                                  ) : (
                                    <span>0</span>
                                  )}
                                  {/* <span>{cardRecord}</span> */}
                                  <span>&nbsp;-&nbsp;</span>
                                  {/* <span>{count2}</span> */}
                                  {cardRecord[match._id.toString()] !=
                                  undefined ? (
                                    <span>
                                      {
                                        cardRecord[match._id.toString()]
                                          .player_2_score
                                      }
                                    </span>
                                  ) : (
                                    <span>0</span>
                                  )}
                                </p>
                              </CCol>
                              <CCol
                                sm={5}
                                xl={5}
                                md={5}
                                style={{ marginTop: '20px' }}
                              >
                                <div
                                  class='flex-container'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{ alignItems: 'end' }}
                                      className='scorer-card-logo'
                                    >
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_2_data[0]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_2_data[0]
                                                  .profile_image
                                              : null
                                          })`
                                        }}
                                        className=' scorer-img'
                                      ></div>
                                    </div>
                                    <p className='scorer-name'>
                                      {match.player_2_data[0].fname +
                                        ' ' +
                                        match.player_2_data[0].lname}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      width: '20%',
                                      textAlign: 'center',
                                      paddingBottom: '25px'
                                    }}
                                  >
                                    {cardRecord[match._id.toString()] !=
                                    undefined ? (
                                      cardRecord[match._id.toString()]
                                        .editMode == true ? (
                                        <>
                                          <CButton
                                            className='score-increase'
                                            onClick={() =>
                                              handleUpdateRecord({
                                                matchId: match._id.toString(),
                                                cardType: 'ongoing',
                                                player: 'player_2_score',
                                                type: 'increase',
                                                proccess: 'score_update'
                                              })
                                            }
                                          >
                                            <img src={add} />
                                          </CButton>
                                          <CButton
                                            className='score-less'
                                            onClick={() =>
                                              handleUpdateRecord({
                                                matchId: match._id.toString(),
                                                cardType: 'ongoing',
                                                player: 'player_2_score',
                                                type: 'less',
                                                proccess: 'score_update'
                                              })
                                            }
                                          >
                                            <img src={less} />
                                          </CButton>
                                        </>
                                      ) : null
                                    ) : (
                                      <>
                                        <CButton
                                          className='score-increase'
                                          onClick={() =>
                                            handleUpdateRecord({
                                              matchId: match._id.toString(),
                                              cardType: 'ongoing',
                                              player: 'player_2_score',
                                              type: 'increase',
                                              proccess: 'score_update'
                                            })
                                          }
                                        >
                                          <img src={add} />
                                        </CButton>
                                        <CButton
                                          className='score-less'
                                          onClick={() =>
                                            handleUpdateRecord({
                                              matchId: match._id.toString(),
                                              cardType: 'ongoing',
                                              player: 'player_2_score',
                                              type: 'less',
                                              proccess: 'score_update'
                                            })
                                          }
                                        >
                                          <img src={less} />
                                        </CButton>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol
                                sm={4}
                                xl={4}
                                md={4}
                                style={{
                                  marginTop: '10px',
                                  marginBottom: '10px'
                                }}
                              >
                                <div class='flex-container'>
                                  <div
                                    style={{
                                      width: '25%',
                                      textAlign: 'center',
                                      paddingTop: '4px'
                                    }}
                                  ></div>
                                  {/* <div style={{ width: '75%' }}>
                                                                        <p className='scorer-date' style={{color: '#f4f821'}}>27/04/2024 5:00PM</p>        
                                                                    </div> */}
                                </div>
                              </CCol>
                              {/* <CCol sm={4} xl={4} md={4} style={{textAlign:'center',marginTop:'10px', marginBottom:'10px'}}>
                                                                <p className='scorer-place'>
                                                                    <span>Sydney Ground Australia</span>
                                                                </p>        
                                                            </CCol> */}
                            </CRow>
                          </CCardBody>
                        </CCard>
                      ) : (
                        <CCard
                          className='scorer-db mb-5'
                          style={{ backgroundImage: `url(${back})` }}
                        >
                          <div className='card-background' />
                          <CCardBody style={{ padding: '0px', color: '#fff' }}>
                            <CRow>
                              <CCol sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-card-title'
                                  style={{ background: 'red', width: '50%' }}
                                >
                                {match.group_data.length >0  ?  match.group_data[0].name : replaceUnderscoreWithSpace(match.round) }

                                </p>
                              </CCol>
                              <CCol sm={4} xl={4} md={4}>
                                <p
                                  className='scorer-date'
                                  style={{
                                    color: '#f4f821',
                                    textAlign: 'center'
                                  }}
                                >
                                  {match.player_won === 'tied'
                                    ? 'Match has been tied'
                                    : match.player_won ===
                                      match.player_1_data[0]._id
                                    ? match.player_1_data[0].fname +
                                      ' ' +
                                      match.player_1_data[0].lname +
                                      ' ' +
                                      'won the match'
                                    : match.player_2_data[0].fname +
                                      ' ' +
                                      match.player_2_data[0].lname +
                                      ' ' +
                                      'won the match'}
                                </p>
                              </CCol>
                              <CCol sm={4} xl={4} md={4}>
                                {/* {
                                                                    match.submited_at ? (
                                                                        Editable(match.match_date) ? (
                                                                            <CButton className='scorer-card-button' 
                                                                            id={match.id} 
                                                                            onClick={() => setVisible2(!visible2)}
                                                                            style={{background:'#275E9A', width: '50%', float: 'right'}}>Edit Scores</CButton>
                    
                                                                        ) : null
                                                                    ) :null
                                                                
                                                                } */}
                                <CModal
                                  // visible={visible2}
                                  // onClose={() => setVisible2(false)}
                                  aria-labelledby='LiveDemoExampleLabel'
                                >
                                  <CModalHeader>
                                    <CModalTitle id='LiveDemoExampleLabel'>
                                      Edit Scores
                                    </CModalTitle>
                                  </CModalHeader>
                                  <CModalBody>
                                    <p>
                                      Are you sure, you want to edit score for
                                      this match?
                                    </p>
                                  </CModalBody>
                                  <CModalFooter>
                                    <CButton color='secondary'>Close</CButton>
                                    <CButton
                                      id={match.id}
                                      color='danger'
                                      className='text-white'
                                    >
                                      Edit Score
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                                <form name='EditScore'>
                                  <CModal
                                    alignment='center'
                                    aria-labelledby='StaticBackdropExampleLabel'
                                  >
                                    <CModalHeader>
                                      <CModalTitle id='StaticBackdropExampleLabel'>
                                        Edit Score
                                      </CModalTitle>
                                    </CModalHeader>
                                    <CModalBody>
                                      <CRow className='mb-3'>
                                        <CCol
                                          className='d-flex justify-content-center'
                                          sm={6}
                                          md={6}
                                          xl={6}
                                        >
                                          <span className='scorer-name fs-3 fw-bold'>
                                            {match.player_1_score}
                                          </span>
                                        </CCol>
                                        <CCol
                                          className='d-flex justify-content-center'
                                          sm={6}
                                          md={6}
                                          xl={6}
                                        >
                                          <span className='scorer-name fs-3 fw-bold'>
                                            {match.player_2_score}
                                          </span>
                                        </CCol>
                                      </CRow>
                                      <CRow className=''>
                                        <CCol
                                          className='d-flex justify-content-center'
                                          sm={6}
                                          md={6}
                                          xl={6}
                                        >
                                          <span className='scorer-name'>
                                            {match.player_1_data[0].fname +
                                              ' ' +
                                              match.player_1_data[0].lname}
                                          </span>
                                        </CCol>
                                        <CCol
                                          className='d-flex justify-content-center'
                                          sm={6}
                                          md={6}
                                          xl={6}
                                        >
                                          <span className='scorer-name'>
                                            {match.player_2_data[0].fname +
                                              ' ' +
                                              match.player_2_data[0].lname}
                                          </span>
                                        </CCol>
                                      </CRow>
                                      <CRow className='mb-3'>
                                        <CCol sm={4} md={4} xl={4}>
                                          <span className='scorer-name'>
                                            {match.player_1_data[0].fname +
                                              ' ' +
                                              match.player_1_data[0].lname}
                                          </span>
                                        </CCol>
                                        <CCol sm={8} md={8} xl={8}>
                                          <input
                                            className='float-end'
                                            name='player_1_score'
                                            type='number'
                                            min={0}
                                            defaultValue={match.player_1_score}
                                            //  onChange={handelEditScore}
                                          ></input>
                                        </CCol>
                                      </CRow>
                                      <CRow>
                                        <CCol sm={4} md={4} xl={4}>
                                          <span className='scorer-name'>
                                            {match.player_2_data[0].fname +
                                              ' ' +
                                              match.player_2_data[0].lname}
                                          </span>
                                        </CCol>
                                        <CCol sm={8} md={8} xl={8}>
                                          <input
                                            className='float-end'
                                            name='player_2_score'
                                            type='number'
                                            min={0}
                                            defaultValue={match.player_2_score}
                                            // onChange={handelEditScore}
                                          ></input>
                                        </CCol>
                                      </CRow>
                                    </CModalBody>
                                    <CModalFooter>
                                      <CButton color='secondary'>Close</CButton>
                                      <CButton
                                        type='submit'
                                        name='submit-btn'
                                        id={match.id}
                                        style={{ color: 'white' }}
                                        color='danger'
                                        // onClick={EditScores}
                                      >
                                        Submit Score
                                      </CButton>
                                    </CModalFooter>
                                  </CModal>
                                </form>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol
                                sm={5}
                                xl={5}
                                md={5}
                                style={{ marginTop: '20px' }}
                              >
                                <div class='flex-container'>
                                  <div
                                    style={{
                                      width: '20%',
                                      textAlign: 'center',
                                      paddingTop: '20px'
                                    }}
                                  ></div>
                                  <div>
                                    <div
                                      style={{ width: '18%' }}
                                      className='scorer-card-logo'
                                    >
                                      {/* <img className='scorer-img' src={p1_img} /> */}
                                      {match.player_won ===
                                      match.player_1_data[0]._id ? (
                                        <img
                                          src={trophy}
                                          alt=''
                                          style={{
                                            height: '30px',
                                            width: '50px',
                                            position: 'absolute',
                                            left: '1.3em'
                                          }}
                                        />
                                      ) : null}
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_1_data[0]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_1_data[0]
                                                  .profile_image
                                              : null
                                          })`
                                        }}
                                        className=' scorer-img'
                                      ></div>
                                    </div>
                                    <p className='scorer-name'>
                                      {match.player_1_data[0].fname +
                                        ' ' +
                                        match.player_1_data[0].lname}
                                    </p>
                                  </div>
                                </div>
                              </CCol>
                              <CCol
                                sm={2}
                                xl={2}
                                md={2}
                                style={{
                                  textAlign: 'center',
                                  marginTop: '20px'
                                }}
                              >
                                <p
                                  className='scorer-name'
                                  style={{ paddingTop: '50px' }}
                                >
                                  <span>{match.player_1_score}</span>
                                  <span>&nbsp;-&nbsp;</span>
                                  <span>{match.player_2_score}</span>
                                </p>
                              </CCol>
                              <CCol
                                sm={5}
                                xl={5}
                                md={5}
                                style={{ marginTop: '20px' }}
                              >
                                <div
                                  class='flex-container'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{ alignItems: 'end' }}
                                      className='scorer-card-logo'
                                    >
                                      {/* <img className='scorer-img' src={p2_img} /> */}
                                      <div
                                        style={{
                                          backgroundImage: `url(${
                                            match.player_2_data[0]
                                              .profile_image !== undefined
                                              ? IMAGE_URL +
                                                'user/' +
                                                match.player_2_data[0]
                                                  .profile_image
                                              : null
                                          })`
                                        }}
                                        className=' scorer-img bg-image-container-2'
                                      ></div>
                                      {match.player_won ===
                                      match.player_2_data[0]._id ? (
                                        <img
                                          src={trophy}
                                          alt=''
                                          style={{
                                            height: '30px',
                                            width: '50px',
                                            position: 'absolute',
                                            right: '1.3em'
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    <p className='scorer-name'>
                                      {match.player_2_data[0].fname +
                                        ' ' +
                                        match.player_2_data[0].lname}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      width: '20%',
                                      textAlign: 'center',
                                      paddingTop: '20px'
                                    }}
                                  ></div>
                                </div>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol
                                sm={4}
                                xl={4}
                                md={4}
                                style={{
                                  marginTop: '10px',
                                  marginBottom: '20px'
                                }}
                              >
                                <div class='flex-container'>
                                  <div
                                    style={{
                                      width: '75%',
                                      textAlign: 'right',
                                      paddingRight: '10px'
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      width: '25%',
                                      textAlign: 'center',
                                      paddingTop: '4px'
                                    }}
                                  ></div>
                                </div>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                      )}
                    </CCol>
                  </CRow>
                ))}
                
                <Pagination
                totalPosts={getMatchData.length}
                postsPerPage={postsPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
                
                
                </div>
                
              
              ) : (
                <CCard
                  style={{
                    position: 'relative',
                    background: '#2A2729',
                    borderRadius: '15px'
                  }}
                >
                  <div
                    className='card-background'
                    style={{ backgroundImage: `url(${cardBg})` }}
                  />
                  <CCardBody
                    className='d-flex justify-content-center align-items-center'
                    style={{ padding: '0px', color: '#fff', height: '200px' }}
                  >
                    <div>
                      <div
                        style={{
                          backgroundImage: `url(${notFound})`,
                          border: 'none'
                        }}
                        className='scorer-card-logo bg-image-container2  ms-4'
                      ></div>
                      <span
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          fontWeight: '700'
                        }}
                      >
                        No Completed matches
                      </span>
                    </div>
                  </CCardBody>
                </CCard>
              )
            ) : null}
          </CContainer>
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default ScorerDashboard
