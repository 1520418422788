import React, { useState } from 'react'
import {useNavigate, useParams} from"react-router-dom"
import FormContainer from './FormContainer';
import EyeIcon from '../../components/icons/EyeIcon';
import EyeSlashFillIcon from '../../components/icons/EyeSlashFillIcon';
import {NewPasswordAPI} from '../../API/auth';

function NewPassword () {
  	const { token } = useParams();
	const navigate = useNavigate();
  	const [error, setError] = useState('');
  	const [showPassword, setShowPassword] = useState({password:true, confirm_password:true});
	const [value ,setPassword] = useState({
		newPassword:'',
		confirmPassword:'',
		token:token
	})

	const togglePasswordVisibility = () => {
			setShowPassword(prevState => ({
				...prevState,
				password: !prevState.password
			}));
		};

    const toggleConfirmPasswordVisibility = () => {
        setShowPassword(prevState => ({
            ...prevState,
            confirm_password: !prevState.confirm_password
        }));
    };
	const HandelChange = (event) => {
		const { name, value } = event.target;
			setPassword(prevState => ({
				...prevState,
				[name]: value
			}));
	}
	const HandelSubmit = async(event) => {
		event.preventDefault();
		if(value.newPassword === value.confirmPassword) {
		setError('');
		let res = await NewPasswordAPI(value);
		if(res.data.status) {
			navigate('/login')
		} else {
				// swal("Oops!", res.data.message, "error");
		}
		} else {
		setError('Password does not match');
		}
	}
  return (
    <>
		<FormContainer>
			<form className='mt-3 login-form' onSubmit={HandelSubmit}>
				<label className='form-title mb-2'> RESET PASSWORD </label>
				<p  className='form-heading'> Please enter your new password. </p>

				<div className="mb-3">
					<label className='mb-3 input-label' htmlFor="password" value={token}>New Password</label>
					<div className="position-relative">
						<input
							type={showPassword.password ? "password" : "text"}
							placeholder='New Password'
							onChange={HandelChange}
							name='newPassword'
							value={value.password}
							className='form-control bg-transparent form-input'
						/>
						<i className="btn border-0 position-absolute end-0 top-0" type="button" onClick={togglePasswordVisibility}>
							
							{
								showPassword.password ? <EyeIcon /> : <EyeSlashFillIcon />
							}
						</i>
					</div>  
				</div>
				<div className="mb-3">
				<label className='mb-3 input-label' htmlFor="password">Confirm Password</label>
				<div className="position-relative">
					<input
						type={showPassword.confirm_password ? "password" : "text"}
						placeholder='Confirm Password '
						name='confirmPassword'
						value={value.confirmPassword}
						onChange={HandelChange}
						className='form-control bg-transparent form-input'
					/> 
					<i className="btn border-0 position-absolute end-0 top-0" onClick={toggleConfirmPasswordVisibility}>
							{
								showPassword.confirm_password ? <EyeIcon /> : <EyeSlashFillIcon />
							}
						</i>
				</div> 
				</div>
				{error && <span className='text-danger'>{error}</span>}
			<div className='pt-1 mb-4'>
				<button className='btn btn-light w-100 border-0 px-3 py-3 fs-5 fw-bold rounded-3 submit-button text-white' type='submit' > Reset Password </button>
			</div>
			</form>
    	</FormContainer>
    </>
  )
}
export default NewPassword
