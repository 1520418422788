import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from '@coreui/react'
import Select from 'react-select';
import { enqueueSnackbar, useSnackbar } from 'notistack'
import Loader from '../Common/Loader'
import React, { useEffect, useState } from 'react'
import { userpaymentdetails, GetTournamentList, UserPayment } from '../../../API/admin';
import { CardBody } from 'react-bootstrap';
import {formatTimestamp} from '../../../../src/Common/CommonFunction'

const Admin_settings = () => {
    let [userdetails, setuserdetails] = useState([]);
    let [loaderStatus, setloaderStatus] = useState(true)
    let [options, setoptions] = useState([]);
    const [tournamentList, setTournamentList] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState('');
    const [selectedGender, setSelectedGender] = useState('male');
    const [selectedGame, setSelectedGame] = useState('badminton');
    const [paymentEntries, setPaymentEntries] = useState([]);
    const [playerDetails, setPlayerDetails] = useState([]);


    useEffect(() => {

        let tournamentListApi = async () => {
            let res = await GetTournamentList()
            if (res.status) {
                setloaderStatus(false)
                setTournamentList(res.data);
                setSelectedTournament(res.data[0].name)
                
            } else {
                setloaderStatus(false)
                enqueueSnackbar('Tournament not found', { variant: 'error' })

            }
        }
    //     callAPI();
        tournamentListApi();

    }, []);

    const handleTournamentChange = (event) => {
        setSelectedTournament(event.target.value);
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleGameChange = (event) => {
        setSelectedGame(event.target.value);
    };
 
    const GenrateReport = async () => {
        let formData = {
            'tournament_id' : selectedTournament,
            'gender': selectedGender,
            'game_type' : selectedGame
        }
        console.log(formData);
        setloaderStatus(true)
       let res =  await userpaymentdetails(formData);
       if (res.data.status) {

            let newdata = res.data.data;
            console.log(newdata, "newdata");
            let option = [];
            for (let i = 0; i < newdata.length; i++) {
                option.push({
                    value: newdata[i].id,
                    label: newdata[i].fname + ' ' + newdata[i].lname + ' (' + newdata[i].corporate_email + ')'
                })
            }
            console.log(option, "option")
            setoptions(option);
            setloaderStatus(false)
            enqueueSnackbar('Succesfully fetched users', { variant: 'success' })

        } else {
            setloaderStatus(false)

            enqueueSnackbar('Matches not found', { variant: 'error' })
        }
                
        console.log(res)
    };

    const handlePlayeChange = async (e) => {
        console.log(e)
        let formData = {
            'id' : e.value
        }
        setloaderStatus(true)
        let res = await UserPayment(formData)
        setloaderStatus(true)
        if (res.data.status) {
            setloaderStatus(false)
            console.log(res.data.data.paymentDetails)
            setPaymentEntries(res.data.data.paymentDetails)
            setPlayerDetails(res.data.data.player)
        } else {
            setloaderStatus(false)
            enqueueSnackbar('No payments found for the player', { variant: 'error' })
            
        }
    }
    
    const exportToCSV = () => {
         const excelData = paymentEntries.map((entry, index) => ({
            s_no: index + 1,
            payment_id: entry.id,
            tournament: playerDetails[index].tournament_id.name,
            enrolled_sports:  playerDetails[index].sports.length > 0
            ? playerDetails[index].sports
                .map((sport) =>
                  sport === "badminton"
                    ? "Badminton"
                    : sport === "tennis"
                    ? "Table Tennis"
                    : sport === "2k_run"
                    ? "2k Run"
                    : sport
                )
                .join(", ")
            : "N/A"
            ,
            amount_paid: entry.amount/100,
            date: formatTimestamp(entry.created),
            status: entry.status,
          }));
          const headers = ["S.NO", "Payment id", "Tournament","Enrolled Sports", "Amount Paid","Date", "Status"];


        const csvRows = [];
    
        // Add headers
        csvRows.push(headers.join(","));
    
        for (const row of excelData) {
          csvRows.push([row.s_no, row.payment_id, row.tournament,  row.enrolled_sports, row.amount_paid, row.date, row.status].join(","));
        }
    
        const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "payment-data.csv";
        a.click();
        URL.revokeObjectURL(url);
      };
    
    return (<>
        {loaderStatus ? <Loader /> : null}
        <CCard className="mb-4">
            <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h3 className="card-title mb-3">
                            Payment details
                        </h3>
                    </CCol>
                </CRow>

                <CRow className='mb-4'>
                <CCol sm={3}>
                <select
                    className='form-select'
                    value={selectedTournament}
                    onChange={handleTournamentChange}
                >
                             <option >
                             --: Select a Tournament :--
                            </option>
                    {tournamentList.length
                        ? tournamentList.map((tournament, index) => (
                            <option key={index} value={tournament._id.toString()}>
                                {tournament.name}
                            </option>
                        ))
                        : ''}
                </select>
            </CCol>
                            
            <CCol sm={3}>
                <select
                    className='form-select'
                    value={selectedGender}
                    onChange={handleGenderChange}
                >  
                  <option >
                --: Select a Gender :--
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
            </CCol>
            <CCol sm={3}>
                <select
                    className='form-select'
                    value={selectedGame}
                    onChange={handleGameChange}
                >
                             <option >
                             --: Select a Game :--
                            </option>
                    <option value="badminton">Badminton</option>
                    <option value="table_tenis">Table Tenis</option>
                    <option value="bowling">Bowling</option>
                    <option value="2k_run">2k run</option>
                </select>
            </CCol>
            <CCol sm={3}>
                <CButton onClick={GenrateReport} color="info" className='text-white float-end'>
                    Fetch Data
                </CButton>
            </CCol>
                </CRow>
               
                
                <CRow>
                 
                    
                        <Select
                            options={options}
                            isSearchable
                            className='form-control text-dark'
                            onChange={ handlePlayeChange}
                        />
                    
                </CRow>
            </CCardBody>

        </CCard>

        <cCard>

            <CardBody>
            <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                    <CTable striped className=''>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Payment ID</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Tournament</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Enrolled Sports</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Amount Paid</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Action</CTableHeaderCell>



                            </CTableRow>
                        </CTableHead>
                        <CTableBody> 
                            {
                                paymentEntries.length > 0 && playerDetails.length > 0 ?(
                                    paymentEntries.map((Entry, index) =>(
                                    <CTableRow>
                                        <CTableDataCell > {index + 1}</CTableDataCell>
                                        <CTableDataCell >{Entry.id}</CTableDataCell>
                                        <CTableDataCell >{playerDetails[index].tournament_id.name}</CTableDataCell>
                                        <CTableDataCell >
                                            {playerDetails[index].sports.length > 0 ? (
                                                playerDetails[index].sports.map((sport, index) => (
                                                <span key={index}>{sport === 'badminton' ? 'Badminton, ' :sport === 'tennis' ? 'Table Tenis, ' : sport === '2k_run' ? '2k Run': sport + ', '} </span>
                                                ))
                                            ) : (
                                                'N/A'
                                            )}
                                    </CTableDataCell>
                                        <CTableDataCell >{'$' +Entry.amount_received / 100}</CTableDataCell>
                                        <CTableDataCell >{ formatTimestamp(Entry.created)}</CTableDataCell>
                                        <CTableDataCell >
                                            <span
                                              className='badge bg-success text-white'
                                              >
                                              {Entry.status}
                                            </span>
                                         </CTableDataCell>
                                            <CTableDataCell>
                                                <CButton onClick={exportToCSV} color='info' className='text-white'>
                                                    <i class="bi bi-file-earmark-arrow-down"></i>
                                                </CButton>
                                            </CTableDataCell>
                                    </CTableRow>

                                    ))
                                ) :(
                                    <CTableRow>
                                        <CTableDataCell colSpan={8}>
                                            <span>No Data Found</span>
                                        </CTableDataCell>
                                    </CTableRow>
                                )
                            }
                        </CTableBody>
                    </CTable>
                </CRow>
            </CardBody>
        </cCard>

        {/* <h1 style={{ color: 'black' }}>Admin panel</h1> */}
    </>)
}
export default Admin_settings;
