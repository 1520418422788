import React, { useState, useEffect } from 'react'
import AdminBreadcrumb from '../../Common/AdminBreadcrumb'
import Loader from '../../Common/Loader'
import Bowling from './Bowling'
import GroupStandings from '../../Modal/GroupStandings'
import SportsLogs from '../../Modal/SportLogs'
import {
  GetTournamentList,
  GetGroups,
  GetParticipantList,
  IsMatchDone,
  stagestatus,
  RoundConfig,
  showedit
} from '../../../../API/admin'

import score from '../../../../assets/score.svg'
import match from '../../../../assets/images/games/match_active.png'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip
} from '@coreui/react'

import { Link } from 'react-router-dom'
import { cilSave, cilSwapHorizontal, cilGraph,cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useSearchParams } from 'react-router-dom';

const TournamentGroups = () => {
  // Manage AdminBreadcrumb
  const [searchParams, setSearchParams] = useSearchParams();
  const sportValue = searchParams.get('sport');
  const round = searchParams.get('round');
  const gender = searchParams.get('gender');
  const seltournament = searchParams.get('tournament');
  // const url = window.location.href
  // const queryString = url.split('?')[1]
  // window.location.replace(
  //   "https://developer.mozilla.org/en-US/docs/Web/API/Location.reload",
  // );

  // Split the query string by '=' and take the second part after '='
  // const sportValue = queryString.split('=')[1]
  console.log(sportValue, 'sportvalue')
  const adminBreadcrumbLinks = [
    { label: 'Dashboard', to: '/admin/dashboard', active: false },
    { label: 'Groups', to: '/groups', active: true }
  ]
  const [loaderStatus, setloaderStatus] = useState(true)

  const urlParams = new URLSearchParams(window.location.search)
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE

  const [visible, setVisible] = useState(false)
  const [Logvisible, setLogvisible] = useState(false)

  const [selectedGender, setSelectedGender] = useState(gender)
  const [selectedSport, setSelectedSport] = useState(sportValue)
  const [selectedRound, setSelectedRound] = useState(round)
  
  const [selectedTournament, setSelectedTournament] = useState(seltournament)
  const [tournamentList, setTournamentList] = useState([])
  const [groupData, setGroupData] = useState([])
  const [groupModalData, setGroupModalData] = useState([])
  const [groupID, setGroupID] = useState(null)
  const [participant, setParticipant] = useState([])
  const [userSwap, setuserSwap] = useState(null);
  const [showRounds, setShowRounds] = useState([]);
  const [KnockoutStatus, setKnockuoutStatus] = useState([]);
  const [edit , setedit] = useState(false);




  const handleSelectGender = event => {
    setSelectedGender(event.target.value)
    if(selectedSport!='badminton' && selectedSport!='table_tenis')
    setSelectedRound('round_1')
    searchParams.set('gender', event.target.value);
    searchParams.set('round', 'round_1');
    setSearchParams(searchParams);
  }

  const handleSelectedTournament = event => {
    setSelectedTournament(event.target.value);
    if(selectedSport!='badminton' && selectedSport!='table_tenis')
    setSelectedRound('round_1')
    searchParams.set('tournament', event.target.value);
    searchParams.set('round', 'round_1');
    setSearchParams(searchParams);
  }

  const handleSelectSport = event => {
    setSelectedSport(event.target.value)
    searchParams.set('sport', event.target.value);
    setSearchParams(searchParams);
  }

  const handelSelectRound = event => {
    setSelectedRound(event.target.value)
    searchParams.set('round', event.target.value);
    setSearchParams(searchParams);
  }

 
  let tournamentListApi = async () => {
    let res = await GetTournamentList()
    let tornament_id = urlParams.get('id')
    if (res.status) {
      if (res.data.length > 0) {
        if (tornament_id !== null && tornament_id !== undefined) {
          setSelectedTournament(tornament_id)
        } else {
          setSelectedTournament(res.data[0]._id.toString())
        }
      }
      setTournamentList(res.data)
    }
  }
  let GetParticipantListApi = async () => {
    let res = await GetParticipantList()
    let userData = {}
    if (res.status) {
      res.data.forEach((item, index) => {
        userData[item._id.toString()] = {
          name: item.fname + ' ' + item.lname,
          image:
            item.profile_image != undefined
              ? IMAGE_URL + 'user/' + item.profile_image
              : IMAGE_URL + 'blank_user.png'
        }
      })
      console.log('userData', userData)
      setParticipant(userData)
    }
  }

 
  let getGroupData = async () => {
    if (selectedTournament !== null && selectedTournament !== undefined) {
      let res = await GetGroups({
        tournament: selectedTournament,
        game_type: selectedSport,
        gender: selectedGender,
        round: selectedRound
      })
      if (res.status) {
        setloaderStatus(false)
        setGroupData(res.data)
      }
    }
  }

  useEffect(() => {
    const callApi = async () => {
      await tournamentListApi()
      await GetParticipantListApi()
    }

    callApi()
  }, [])

  useEffect(() => {
    setloaderStatus(true)
    const callGroupApi = async () => {
      await getGroupData()
    }
    let callAPI = async () => {
      let formData = {
        "tournament_id" : selectedTournament,
        "game_type" : sportValue,
        "gender" : selectedGender,
        "round" : selectedRound
      }
      let res = await IsMatchDone(formData);
      console.log(res)

      if (res.data.status) {
        setuserSwap(res.data.data)
      }
    }
    let CallApi2 = async () => {
      let formData = {
        "tournamentid" : selectedTournament,
        "game_type" : sportValue,
        "gender" : selectedGender,
        "round" : selectedRound
      }
      let res = await showedit(formData);
      console.log(res)

      if (res.data.status) {
        setedit(res.data.data);
      }
    }

    let StageStatusAPI  =  async () => {
      let formData = {
        "tournament_id" : selectedTournament,
        "game" : sportValue,
        "gender" : selectedGender 
      }
     let res = await stagestatus(formData)
     console.log(res);
     if (res.data.status) {
        let data = [];
        data.push  (
          {
            name :"round 1",
            value:"round_1"

          }
        )
        if (res.data.data.round_2) {
          data.push (
            {
              name :"round 2",
              value:"round_2"

            }
          )
        }
        if (res.data.data.round_3) {
          data.push (
            {
              name :"round 3",
              value:"round_3"

            }
          )
        }
        if (res.data.data.final) {
          data.push  (
            {
              name :"Final",
              value:"final"
            }
          )
        }
        console.log(data);
        setShowRounds(data)
     }
    }

    let RoundConfigAPI = async () => {
      let formData = {
          "tournamentid": selectedTournament,
          "gender":selectedGender,
          "gametype" : sportValue,
           "round" : selectedRound
      }
      let res = await RoundConfig(formData);
      console.log(res)

      if (res.data.status) {
        console.log(res.data.data)
        setKnockuoutStatus(res.data.data)
      }
    }
    callGroupApi();
    if (selectedTournament != '') {
        callAPI();
        StageStatusAPI();
        RoundConfigAPI();
        CallApi2();
    }
  }, [selectedTournament, selectedSport, selectedGender, selectedRound])
  const handleCloseModal = () => {
    setVisible(false);
};
const handleCloseLogModal = () => {
  setLogvisible(false);
}
console.log(groupData , "mein groupdata hu");
console.log("showedit" , edit);
  return (
    <>
      {loaderStatus ? <Loader /> : null}
      <AdminBreadcrumb links={adminBreadcrumbLinks} />
     
      {groupID != null && visible == true? (
        <>
         <GroupStandings
            sport={selectedSport}
            group={groupID}
            allGroups= {groupData.data}
            groupData={groupModalData}
            visible={visible}
            onClose={handleCloseModal}
            userSwap={userSwap}
            round={selectedRound}
            showedit = {edit}
          />
          
         
         

        </>
      ) : null}
      {groupID != null && Logvisible == true? (
        <>
         
          <SportsLogs
            sport={selectedSport}
            group={groupID}
            groupData={groupModalData}
            Logvisible={Logvisible}
            onClose={handleCloseLogModal}
          />
         
         

        </>
      ) : null}
     
      <CRow>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedTournament}
            onChange={handleSelectedTournament}
          >
            {tournamentList.length
              ? tournamentList.map((tournament, index) => (
                  <option value={tournament._id.toString()}>
                    {tournament.name}
                  </option>
                ))
              : ''}
          </select>
        </CCol>
        <CCol sm={3}>
          {selectedSport == 'bowling' ? (
            <select
              className='form-select'
              value={selectedRound}
              onChange={handelSelectRound}
              
            >
             
              { showRounds.length > 0 ? (
                   showRounds.map((round, index) => (
                    <option value={round.value} >
                    {round.name}
                  </option>
                   ))
              ) :  null 
               
              }
              {/* <option value="bowling">Bowling</option> */}
            </select>
          ) 
          
          :selectedSport == '2k_run' ? (
            <>
             <select
              className='form-select'
              value={selectedRound}
              onChange={handelSelectRound}
            >
             { showRounds.length > 0 ? (
                   showRounds.map((round, index) => (
                    <option value={round.value} >
                    {round.name}
                  </option>
                   ))
              ) :  null 
               
              }

              {/* <option value="bowling">Bowling</option> */}
            </select>
            </>
          ) : (
            <select
              className='form-select'
              value={selectedSport}
              onChange={handleSelectSport}
              
            >
              <option value='badminton' selected>
                Badminton
              </option>
              <option value='table_tenis' selected>
                Table Tenis
              </option>
              {/* <option value="bowling">Bowling</option> */}
            </select>
          )}
        </CCol>
        <CCol sm={3}>
          <select
            className='form-select'
            value={selectedGender}
            onChange={handleSelectGender}
          >
            <option value='male' selected>
              Male
            </option>
            <option value='female'>Female</option>
          </select>
        </CCol>
      </CRow>
      <br />
      <CCard>
        <CCardBody>
          <CRow
            xs={{ cols: 1, gutter: 4 }}
            sm={{ cols: 2 }}
            lg={{ cols: 4 }}
            xl={{ cols: 5 }}
            className='mb-2 text-center'
          >
            <CTable striped>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope='col'>S.NO</CTableHeaderCell>
                  <CTableHeaderCell scope='col'>Name</CTableHeaderCell>
                  <CTableHeaderCell scope='col'>Players</CTableHeaderCell>
                  <CTableHeaderCell scope='col'>{selectedRound ==='final'? 'Winner':'Next Round' }</CTableHeaderCell>
                  <CTableHeaderCell scope='col'>Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {groupData &&
                  groupData['data'] &&
                  groupData['data'].map((item, index) => (
                    <CTableRow key={index}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item.name}</CTableDataCell>

                      <CTableDataCell style= {sportValue == '2k_run' ?  {width:'300px'} : null }>
                        {item.players !== undefined && item.players.length > 0
                          ? item.players.map((playerName, playerKey) =>
                              playerName !== '' &&
                              playerName !== null &&
                              participant[playerName] != undefined ? (
                                <CTooltip
                                  content={participant[playerName].name}
                                >
                                  <a customData={playerName} href='!#'>
                                    <img
                                      src={participant[playerName].image}
                                      className='group-table-img mb-2'
                                      alt='blank_user'
                                    />
                                  </a>
                                </CTooltip>
                              ) : null
                            )
                          : null}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item.knockout !== undefined && item.knockout.length > 0
                          ? item.knockout.map((playerName, playerKey) =>
                              playerName !== '' &&
                              playerName !== null &&
                              participant[playerName] != null ? (
                                <>
                                {
                                  playerKey == 1 && selectedSport === 'bowling'? (
                                    <>
                                      {
                                        KnockoutStatus[index] == true ? (
                                          <CTooltip
                                          content={
                                            participant[playerName].name != null
                                              ? participant[playerName].name
                                              : ''
                                          }
                                        >
                                          <a customData={playerName} href='!#'>
                                            <img
                                              src={participant[playerName].image}
                                              className='group-table-img'
                                              alt='blank_user'
                                            />
                                          </a>
                                        </CTooltip>
                                        ) : null
                                      }
                                    </>
                                  ): (
                                    <CTooltip
                                    content={
                                      participant[playerName].name != null
                                        ? participant[playerName].name
                                        : ''
                                    }
                                  >
                                    <a customData={playerName} href='!#'>
                                      <img
                                        src={participant[playerName].image}
                                        className='group-table-img'
                                        alt='blank_user'
                                      />
                                    </a>
                                  </CTooltip>
                                  )
                                }
                                
                                </>
                               
                              ) : null
                            )
                          : null}
                      </CTableDataCell>
                      <CTableDataCell>
                        {selectedSport == 'bowling' ||
                        selectedSport == '2k_run' ? (
                          <>
                            <CButton
                              id={item._id}
                              color='warning'
                              onClick={() => {
                                setGroupID(item._id)
                                const matchedPlayers = item.players
                                  .map(playerId => ({
                                    playerId,
                                    ...participant[playerId]
                                  }))
                                  .filter(player => player.name !== undefined) // Ensure the player exists by checking a property like 'name'

                                console.log(matchedPlayers)
                                setGroupModalData(matchedPlayers)
                                setVisible(!visible)
                              }}
                            >
                              <img src={score} width='20' alt='scorer' />
                            </CButton>

                            {/* log modal */}
                            <CButton
                              color='info'
                              className='text-white ms-3'
                              onClick={() => {
                                setGroupID(item._id)
                                const matchedPlayers = item.players
                                  .map(playerId => ({
                                    playerId,
                                    ...participant[playerId]
                                  }))
                                  .filter(player => player.name !== undefined) // Ensure the player exists by checking a property like 'name'

                                console.log(matchedPlayers)
                                setGroupModalData(matchedPlayers)
                                setLogvisible(!Logvisible)
                              }}
                            >
                           <CIcon icon={cilGraph}/>
                            </CButton>
                          </>
                        ) : (
                          <>
                            <CButton
                              id={item._id}
                              color='warning'
                              onClick={() => {
                                setGroupID(item._id)
                                setVisible(!visible)
                              }}
                            >
                              <img src={score} width='20' alt='scorer' />
                            </CButton>
                            &nbsp;&nbsp;
                            <Link
                              to={`/matches?tournament=${item.tournament_id.toString()}&&group=${item._id.toString()}&&sport=${selectedSport}&&gender=${selectedGender}`}
                            >
                              <CButton
                                title='match'
                                className='text-white'
                                color='primary'
                              >
                                <img
                                  src={match}
                                  alt={match}
                                  className='custom-button-icon'
                                />
                              </CButton>
                            </Link>
                          </>
                        )}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
              </CTableBody>
            </CTable>
          </CRow>
        </CCardBody>
      </CCard>
      <br />
    </>
  )
}
export default TournamentGroups
