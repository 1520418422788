import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink , useSearchParams,  useLocation} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import logo from '../assets/logo.png';
import Batminton from '../components/icons/Batminton';
import Bowling from '../components/icons/Bowling';
import TableTennis from '../components/icons/TableTennisIcon';
import RunIcon from '../components/icons/RunIcon';
import { ScorerAppHeaderDropdown } from './header/index'
import '../assets/css/Player/ParticipantAppHeader.css';
const ScorerHeader = () => {
  const headerRef = useRef()

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const location = useLocation();
  const [activeMenu ,setActiveMenu] = useState("Badminton");  
  const params = new URLSearchParams(location.search);
  
  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0)
    })

  }, [])
  useEffect(() => {
    
    const searchParams = new URLSearchParams(location.search);
    const game = searchParams.get('game');

    if (game === 'Badminton') {
      setActiveMenu("Badminton");  
    } else if (game === 'TableTennis') { 
      setActiveMenu("TableTennis");
    }

  }, [location.search])

  return (
    <div className='p-header'>
        <CHeader position="sticky" className="mb-2 p-0 bg-transparent text-white border-0" ref={headerRef}>
        <CContainer className="border-0 px-4" fluid>
            <CNavItem className='list-style-none'>
                <CNavLink href="/dashboard" className='fs-5 fw-bold text-white my-3'>
                <img src={logo} alt="logo" className='header-logo'/>
                </CNavLink>
            </CNavItem>

            <CHeaderNav className="d-none d-md-flex">
                <div className="text-center game_bar">
                    <ul className="list-unstyled d-flex justify-content-between mx-auto mb-2">
                        <Link to="/dashboard?game=Badminton" style={{ textDecoration: 'none' }}>
                          <li className={activeMenu ==='Badminton'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><Batminton /> Badminton</li>
                        </Link>
                        <Link to="/dashboard?game=TableTennis" style={{ textDecoration: 'none' }}>
                          <li className={activeMenu ==='TableTennis'?'text-white text-center px-5 h5 active':'text-white text-center px-5 h5'}><TableTennis /> Table Tennis</li>
                        </Link>
                        {/* <li className="text-white text-center px-5"><TableTennis/>Table Tennis</li> */}
                        {/* <li className="text-white text-center px-5"><Bowling/> Bowling</li>
                        <li className="text-white text-center px-5"><RunIcon/> 2K Run</li> */}
                    </ul>
                </div>
            </CHeaderNav>

            <CHeaderNav>
            <ScorerAppHeaderDropdown />
            </CHeaderNav>
        </CContainer>
        </CHeader>
    </div>
  )
}

export default ScorerHeader
