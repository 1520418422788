import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Table, Row, Col, Card } from 'react-bootstrap';
import { useLocation} from 'react-router-dom'
import '../../assets/css/Player/CorporateStandings.css';
import Batminton from './../icons/Batminton';
import TableTennis from './../icons/TableTennisIcon';
import Bowling from './..//icons/Bowling';
import RunIcon from './..//icons/RunIcon';


import axios from 'axios';
import {GetTournamentList} from  '../../API/admin'
import Loader from '../../views/admin/Common/Loader';
import { FetchCorporats, FetchAllPoints} from '../../API/player';


import {
    CFormSelect

} from '@coreui/react';

const CorporateStandings = ({ onValueChange, gameType , TournamentName, TournamentID ,corporate ,CorporateList}) => {
  const [Tournaments, setTournaments] = useState([]);
  const [SelectedTournament, setSelectedTournament] = useState('');

  const [data, setData] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   let callAPI2 = async () => {
  //     if ( ! TournamentID == '') {
  //       let FormData = {
  //           "tournament_id": TournamentID
  //       }
  //       let res = await FetchAllPoints(FormData);
      
  //       if (res.status) {
  //         setloaderStatus(false)
  //         const CorporateListData = Object.keys(res.data).map(key => ({
  //           name: key,
  //           badminton: res.data[key].badminton,
  //           table_tennis: res.data[key].table_tenis,
  //           total: res.data[key].total
  //       }));
  //         setCorporateList(CorporateListData)
  //       }

  //     }
  //   }
  //   callAPI2()
  // }, [TournamentID]);

  // useEffect(() => {
  //   // Populate CorporateList once data is fetched
  //   const newCorporateList = data.map(item => ({
  //     name: item.name,
  //     badminton: 0,
  //     table_tennis: 0,
  //     total: 0
  //   }));
  //   setCorporateList(newCorporateList);
  // }, [data]);
  const selectStyle = {
    width: '156px',        // Default width
    maxWidth: '100%',      // Maximum width
    overflow: 'hidden',    // Hide overflow content
    textOverflow: 'ellipsis', // Show ellipsis for overflow
    whiteSpace: 'nowrap'   // Prevent text wrapping
  };

  useEffect(() => {

    const callAPI = async () => {
      let res = await GetTournamentList(); // Make sure this function is defined
      if (res.status) {
        console.log(res.status);
        setTournaments(res.data);
      }
    };
    callAPI(); 


  }, [location.search]);

  useEffect(() => {
    if (Tournaments.length > 0) {
      const firstTournamentId = Tournaments[0]._id;
      setSelectedTournament(firstTournamentId);
      onValueChange(firstTournamentId);
      gameType(getGame());
      TournamentName(Tournaments[0].name)
      
    }
  }, [Tournaments]);

  const handleChange = (event) => {
    console.log(event.target);
    const selectedId = event.target.value;
    setSelectedTournament(selectedId);
    onValueChange(selectedId);
    gameType(getGame());
    TournamentName(event.target.options[event.target.selectedIndex].text )
    // setloaderStatus(true)
  };

  const getGame = () => {
    const searchParams = new URLSearchParams(location.search);
    let game = searchParams.get('game');
    if (!game) {
      game = 'Badminton';
    }
    return game;
  };
  function extractdomain(inputString, startChar, endChar) {
    const startIndex = inputString.indexOf(startChar);
    let j = -1;
    for(let i = startIndex+1 ; i<inputString.length ; i++)
      {
        if(inputString[i] == '.') {
            j=i;
            break;
            
        }
        
      }
      let domain = inputString.substring(startIndex+1, j)
      console.log(domain)
      return domain;
}
if (corporate != null) {


  let playerCorporate = extractdomain(corporate.corporate_email , '@' , '.')
console.log("player " +playerCorporate )
}

  return (
    <>
      <div className='corporate_standings'>
        <Row className="mb-5">
          <Row className="text-dark align-items-xs-center mt-4 mb-4">
            <Col md={6} className="fw-bold fs-4 text-start col-group" id="corporateStandings">
              Corporate Standings
            </Col>
            <Col md={6} className="text-end col-group">
              <div className="d-flex justify-content-end align-items-center" style={{ whiteSpace: 'nowrap'}}>
                <CFormSelect
                  aria-label="Default select example"
                  onChange={handleChange}
                  className="bg-dark text-white"
                  value={SelectedTournament}
                  style={selectStyle}
                >
                  {Tournaments.length > 0 ? 
                    Tournaments.map((Tournament, index) => (
                      <option key={index} name={Tournament.name} value={Tournament._id}>{Tournament.name}</option>
                    )) 
                    : null}
                </CFormSelect>  
              </div>
            </Col>
          </Row>
          <Col> 
            <Card className="border-radius25px overflow-auto complete-nav-card border-0">
              <Card.Body className="p-0">
                <Table bordered className="text-center mb-0">
                  <thead className="border-0">
                    <tr className="border-0">
                      <th className="border-0 text-size table-th">Corporate</th>
                      <th className="border-0 text-size table-th"><Batminton /> Badminton</th>
                      <th className="border-0 text-size table-th"><TableTennis /> Table Tennis</th>
                      <th className="border-0 text-size table-th"><Bowling /> Bowling</th>
                      <th className="border-0 text-size table-th"><RunIcon /> 2k Run</th>

                      <th className="border-0 text-size table-th">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CorporateList.map((item, index) =>  (

                      <tr key={index} style={{backgroundColor:extractdomain(corporate.corporate_email, '@' , '.') == item.name? '#2ed5d5' :'#2A2729'}}>
                        
                        <td>{item.name}</td>
                        <td>{item.badminton}</td>
                        <td>{item.table_tennis}</td>
                        <td>{item.bowling}</td>       {/*  //for bowling */}
                        <td>{item.twokrun}</td>            {/*  //for run */}
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CorporateStandings;


