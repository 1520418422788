import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../Common/AdminBreadcrumb';
import { newFormatdate, formatTimestamp } from '../../../Common/CommonFunction';
import Loader from '../Common/Loader'
import {getEnrolledUserData,playerPaymentDetails} from '../../../API/admin'    
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalHeader ,
  CModalFooter,
  CModalTitle,
  CModalBody ,
  CImage,
  CFormSelect
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilGroup
} from '@coreui/icons'

const Withdrwal_Waiting = () => {
    // Manage AdminBreadcrumb
    const adminBreadcrumbLinks = [
        { label: 'Dashboard', to: '/admin/dashboard', active: false },
        { label: 'Withdrwal & Watings', to: '/manage', active: true }
    ];
    const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE

    const [loaderStatus, setloaderStatus] = useState(false);
    const [visible, setVisible] = useState(false)



    const [selectedOption, setSelectedOption] = useState('waiting');
    const [selectedGame, setSelectedGame] = useState('badminton');
    const [PlayeData, setPlayerData] = useState([]);
    const [paymentEntries, setPaymentEntries] = useState([]);



	const Navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
    
    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        console.log(newValue); // Log the selected value
        setSelectedOption(newValue); // Update the state with the new value
      };
    
    const handleGameChange =  (event) => {
    const newValue = event.target.value;
    console.log(newValue); // Log the selected value
    setSelectedGame(newValue); // Upd
    }

    const handleSubmit = async () => {
        setloaderStatus(true)
        let formData = {
            "game_type" : selectedGame,
            "type": selectedOption
        }
        let res = await  getEnrolledUserData(formData)
        setPlayerData(res.data.data)
        setloaderStatus(false)
        console.log(res.data.data);
        
    }

    const handleView = async (playerID, type)  => {
        setloaderStatus(true)
        console.log(PlayeData);
        let formData= {
            "player_id" : playerID,
            "type":type

        }
        let res = await playerPaymentDetails(formData)
        if (res.data.status) {
            setPaymentEntries(res.data.data)
            setloaderStatus(false)
            setVisible(true)
            enqueueSnackbar('Data is fetched successfully', { variant: 'success' })


        } else {
            enqueueSnackbar('Unable to fetch payment details', { variant: 'error' })
            setloaderStatus(false)
        }
        console.log(res.data.data);
        
    }
    return (
        <>
            {loaderStatus ? 
                <Loader />
            : null}
            <AdminBreadcrumb links={adminBreadcrumbLinks} />
            <CCard>
                <CCardBody>
                <CRow>
                    <CCol sm={4}>
                        <h3 className="card-title mb-3">
                            Withdrawal & Waitings
                        </h3>
                    </CCol>
                </CRow>
                <CRow>    
                    <CCol sm={10} className="d-flex gap-5 align-items-center">
                            <div className='w-100'>
                                <select className='form-select' value={selectedOption} onChange={handleSelectChange}>
                                    <option value="waiting">Waiting List</option>
                                    <option value="withdrwal">Withdrawal List</option>
                                </select>
                                
                                
                            </div>
                            <div className='w-100'>
                                
                                <select className='form-select' value={selectedGame} onChange={handleGameChange}>
                                    <option value="badminton">Badminton</option>
                                    <option value="table_tenis">Tabel Tenis</option>
                                    <option value="bowling">Bowling</option>
                                    <option value="2k_run">2k Run</option>

                                </select>
                            </div>

                    </CCol>
                    <CCol sm={2}>
                            <CButton onClick={() => handleSubmit()} className='float-end text-white' color="info">
                            <i className="bi bi-search   me-2"></i>
                                Filter
                            </CButton>
                    </CCol>
                </CRow><br />
                {/* <MainChart /> */}
                <CRow
                    xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }}
                    className="mb-2 text-center"
                >
                <CTable striped>
                    <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                        <CTableHeaderCell colSpan={2}>Participant</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Sport</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Tournament</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Group</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Time</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Action</CTableHeaderCell>


                    </CTableRow>
                    </CTableHead>
                    <CTableBody> 
                     {
                        PlayeData.length > 0 ?(
                            PlayeData.map((player, index) => (
                            <>
                                <CTableRow>
                                <CTableDataCell >{ index + 1 }</CTableDataCell>
                                <CTableDataCell 
                                   key={index}>
                                    <img  src={ 
                                    +player.player_id.profile_image != undefined ? 
                                    IMAGE_URL+"user/"+player.player_id.profile_image : IMAGE_URL+"blank_user.png"
                                  } className='group-table-img float-end' alt="blank_user"
                                 />
                                </CTableDataCell>
                                <CTableDataCell >{player.player_id.fname + ' '  +player.player_id.lname }</CTableDataCell>
                                <CTableDataCell >{player.type == 'waiting' ? 'Waiting List' : 'Withdrawal'}</CTableDataCell>
                                <CTableDataCell >{player.game_type =='table_tenis' ? 'Table Tenis' : 
                                   player.game_type == '2k_run' ? '2k Run' : player.game_type == 'bowling' ? 'Bowling'  : 'Badminton'
                                    }</CTableDataCell>
                                <CTableDataCell >{player.tournament_id == null ? 'N/A' :player.tournament_id.name }</CTableDataCell>
                                <CTableDataCell >{player.group_id == null ? 'N/A' :player.group_id.name }</CTableDataCell>
                                <CTableDataCell >{ newFormatdate(player.createdAt,1,0,0)}</CTableDataCell>
                                <CTableDataCell >
                                    
                                <CButton className='bi bi-eye-fill text-white' color="primary" onClick={()=> handleView(player.player_id._id, player.type)}></CButton>
                                </CTableDataCell>
                                
                                </CTableRow>
                                
                            </>
                        ) )
                        ):
                        <CTableRow>
                            <CTableDataCell colSpan={9}> No Data Found</CTableDataCell>
                        </CTableRow>
                     }
                   

                    </CTableBody>
                </CTable>
                </CRow>
                </CCardBody>
            </CCard>


            <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="VerticallyCenteredExample"
            size='xl'
            >
            <CModalHeader>
                <CModalTitle id="VerticallyCenteredExample">Payment Detail</CModalTitle>
            </CModalHeader>
            <CModalBody>
            <CTable striped className=''>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">S.NO</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Payment ID</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Amount Paid</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Status</CTableHeaderCell>


                            </CTableRow>
                        </CTableHead>
                        <CTableBody> 
                            {
                                paymentEntries.length > 0 && PlayeData.length > 0 ?(
                                    
                                    paymentEntries.map((Entry, index) =>(
                                    <CTableRow>
                                        <CTableDataCell > {index + 1}</CTableDataCell>
                                        <CTableDataCell >{Entry.id}</CTableDataCell>
                                        <CTableDataCell >{'$' +Entry.amount_received / 100}</CTableDataCell>
                                        <CTableDataCell >{ formatTimestamp(Entry.created)}</CTableDataCell>
                                        <CTableDataCell >
                                            <span
                                              className='badge bg-success text-white'
                                              >
                                              {Entry.status}
                                            </span>
                                         </CTableDataCell>

                                    </CTableRow>

                                    ))
                                ) :(
                                    <CTableRow>
                                        <CTableDataCell colSpan={7}>
                                            <span>No Data Found</span>
                                        </CTableDataCell>
                                    </CTableRow>
                                )
                            }
                        </CTableBody>
                    </CTable>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setVisible(false)}>
                Close
                </CButton>
            </CModalFooter>
            </CModal>
        </>
    )
}

export default Withdrwal_Waiting;