import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    CButton,
} from '@coreui/react'
import {
    cilSwapHorizontal
} from '@coreui/icons';
import CIcon from '@coreui/icons-react'
import { getStyle } from '@coreui/utils';
// import {SwapUser} from '../../../../API/admin
import { GetTournamentList, GetGroupList, GetParticipantList, GetScorers , AssignScorer ,SwapUser} from '../../../../API/admin';
import Loader from '../../Common/Loader';
import { Navigate } from 'react-router-dom';


// import { useState } from 'react';
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { useState } from 'react';
function Example(props) {
    console.log(props);
    const groupList = props.groupList;
    const selectedGroup = props.selectedGroup;
    const seletedplayer1 = props.selecteduser;
    console.log(selectedGroup);
	const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [groupPlayer , setgroupPlayer] = useState([]);
  const [selectedGroup2 , setselectedGroup2] = useState('');
  const [selectedplayer2 , setselectedplayer2] = useState('');
  const [playerfetched , setplayerfetched] = useState('0');
  const [loaderStatus, setloaderStatus] = useState(false);
  const navigate = useNavigate();


//   const [x , setx] = useState(false);
  const handleClose = () => setShow(false);
  const handleswap = async()=> 
 {
  setloaderStatus(true);
    let swapdata ={
        "user_1":{
            "id":seletedplayer1,
            "group_id":selectedGroup
        },
        "user_2":{
            "id":selectedplayer2,
            "group_id":selectedGroup2
        }
    
    
    }
    let res = await SwapUser(swapdata);
    if (res.status) {
      setloaderStatus(false);
      enqueueSnackbar("Player swaped successfully", { variant: 'success' });
      navigate(0);
    } else {
      setloaderStatus(false);
      enqueueSnackbar("Unable to  swape Player", { variant: 'error' });
      navigate(0);

    }
 }
   
  const handleShow = () => {

    for (const item of groupList['data']) {
        if (availablegroup(item.players) && item._id !== selectedGroup) {
            getplayers(item._id);
            break;
        }
    }
    

    setShow(true);
  }
  let playersList = props.playerList;
  console.log(playersList);
 
  function availablegroup(players , group) {
    for(let i = 0 ; i<players.length ; i++)
        {
            if(players[i].length != 0)
                return true;       
        }
        return false;
  }
 function getplayers(group_id){
    console.log(group_id)

    let data = groupList.data;
    for(let i = 0 ; i<data.length ; i++)
    {
        if(data[i]._id === group_id)
        {
            let players = [];
            console.log(data[i]);
            for(let j = 0 ; j<data[i].players.length ; j++)
                {  
                  console.log(data[i].players[j]);
                    if(data[i].players[j] != '')
                    {
                       players.push({name: `${playersList[data[i].players[j]].fname} ${playersList[data[i].players[j]].lname}  ` ,  id :playersList[data[i].players[j]]._id });
                   
                  }
                   
                    
                }
                // if(x == false) setx(true);
                setselectedGroup2(group_id);
                setselectedplayer2(players[0].id);
                setgroupPlayer(players);
                break;
        }
    }
   
 }
   
 console.log(groupPlayer);
  return (
    <>
    {loaderStatus ? 
                <Loader />
            : null}
     <CButton title='View' className='text-white' color="warning" onClick={handleShow}><CIcon icon={cilSwapHorizontal} /></CButton>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Swap Participant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Select Group</h4>
    
        <select className='form-select' onChange={(e) => getplayers(e.target.value)}  >
                            {groupList && groupList['data'] && groupList['data'].map(item => (
                                ( availablegroup(item.players)&& item._id!=selectedGroup)?
                                
                                    // getplayers(item._id);
                                
                                (<option kkey={item._id.toString()} data-match={JSON.stringify(item.match)} value={item._id} >{item.name}</option>):null
                            ))}
                        </select>
                        <br></br>
                        <h4>Select User</h4>
                        <br></br>
                        <select className='form-select' onChange={(e)=>{setselectedplayer2(e.target.value)}}>
                            {
                              groupPlayer.map(item=>(
                                <option value={item.id}>{item.name}</option>
                                  
                              ))  
                              
                            }
                        </select>
      
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleswap}>
            Swap Participants
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;