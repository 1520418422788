import React, { Suspense } from 'react'
import {Outlet} from 'react-router-dom';
import { AppSidebar, AppFooter, AppHeader, ParticipantAppSidebar, ParticipantAppHeader } from '../components/index';
import '../assets/css/Player/SidebarHeader.css';
import { CContainer, CSpinner } from '@coreui/react';

const AdminLayout = () => {
 	return (
		<div>
    <ParticipantAppHeader />
    <div className="wrapper d-flex flex-column min-vh-100">
        <div className="body flex-grow-1">
            <CContainer className="px-5 main-container" lg>
                <Suspense fallback={<CSpinner color="primary" />}>
                    <Outlet/>
                </Suspense>
            </CContainer>
        </div>
    </div>
</div>


  	);
};

export default AdminLayout;
