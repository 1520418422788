import React, { useEffect, useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import PencilIcon from '../icons/PencilIcon';
import TNC from '../icons/TermNConditions';
import LogOutIcon from '../icons/LogoutIcon';
import FNQIcon from '../icons/faqIcon';
import CustomerCareIcon from '../icons/CustomerCareIcon';
import avatar from '../../assets/images/avatars/user.jpg'
import { Link } from 'react-router-dom';
import {Profile} from '../../API/player'

const AppHeaderDropdown = () => {
  const [profile, setProfile] =  useState(null);
  const IMAGE_URL = process.env.REACT_APP_IMAGE_MY_ENVIRONMENT_VARIABLE
  useEffect(() => {
    let callAPI = async() => {
      let res = await Profile();
      if (res.status) {
        console.log(res.data.profile_image);
        setProfile(res.data);
      }
    }
    callAPI()
  },[])
  return (
    <CDropdown variwant="nav-item" className='header-nav-dropdown'>
      <CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}>
      <div style={{
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundImage: profile != null ? `url(${IMAGE_URL}user/${profile.profile_image})` : 'none',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}}>
</div>


        {/* <CAvatar src={profile != null ?IMAGE_URL+'user/'+profile.profile_image: null } shape={'rounded-pill'}  size="xl" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="py-0 " placement="bottom-end">
        <Link to='/participant/profile' className='text-decoration-none'>
          <CDropdownItem href="#" className='text-white py-2'>
            <PencilIcon />
              <span className='ps-2 '> Edit Profile </span>
          </CDropdownItem>
        </Link>

        <CDropdownItem href="#" className='text-white py-2'>
          <TNC />
          <span className='ps-2'> Terms & Conditions</span>
        </CDropdownItem>

        <CDropdownItem href="#" className='text-white py-2'>
          <CustomerCareIcon />
          <span className='ps-2'> Help & Support</span>
        </CDropdownItem>

        <CDropdownItem href="#" className='text-white py-2'>
          <FNQIcon />
          <span className='ps-2'> FAQ</span>
        </CDropdownItem>
        
          <Link to='/participant/feedback' className='text-decoration-none'>
        <CDropdownItem  className='text-white py-2'>
          <FNQIcon />
          <span className='ps-2'> Feedback </span>
        </CDropdownItem>
          </Link>

        <CDropdownItem href="/logout" className='text-white py-2'>
            <LogOutIcon />
            <span className='ps-2'> Logout</span>
        </CDropdownItem>

      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
