import React, { useState } from "react";
import CIcon from '@coreui/icons-react'
import { cilPencil} from '@coreui/icons';
import { ChangePasswordAPI } from "../../API/player";
import { useSnackbar } from 'notistack';

import {
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormInput,
    CForm,
  } from '@coreui/react'
const ChangePassword = ({visble}) => {
    const [visible, setVisible] = useState(visble)
	const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState(
        {
            old_password: '',
            new_password:''
        }
    );  
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      console.log(formData)
      let res = await ChangePasswordAPI(formData);
      
      if (res.data.status) {
        enqueueSnackbar("Password changed successfully", { variant: 'success' });
        setFormData({
            old_password: '',
            new_password:''
          })
        setVisible(false)
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' });

      }
    };
   const HandelChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
   }
    return (
      <>
        <CButton color="primary" onClick={() => setVisible(!visible)}><CIcon icon={cilPencil} /></CButton>
        <CForm onSubmit={handleSubmit}>
      <CModal
        alignment="center"
        visible={visible}
        onClose={() => {setVisible(false) ; setFormData({
            old_password: '',
            new_password:''
          })}}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle id="VerticallyCenteredExample">Change Password</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormInput
            className="mb-3"
            type="text"
            pattern="^\S+$"
            id="oldpassword"
            label="Old Password"
            placeholder="Old password"
            required
            name="old_password"
            value={formData.old_password}
            onChange={HandelChange}
          />
          <CFormInput
            type="text"
            pattern="^\S+$"
            id="newpassword"
            label="New Password"
            placeholder="New password"
            required
            name="new_password"
            value={formData.new_password}
            onChange={HandelChange}
          />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() =>  {setVisible(false); setFormData({
        old_password: '',
        new_password:''
      })}
      }>
            Close
          </CButton>
          <CButton type="submit" onClick={handleSubmit} color="primary">Save changes</CButton>
        </CModalFooter>
      </CModal>
    </CForm>

      </>
    )

}
export default ChangePassword
