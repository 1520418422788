import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements,PaymentElement} from '@stripe/react-stripe-js';
import {useNavigate} from"react-router-dom";
import {GenrateMatch, getPlayerSports} from '../../API/player';
import { useSnackbar } from 'notistack';
import CheckoutForm from './Checkout';
import {
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CButton,
    CModalFooter
} from '@coreui/react'
const stripePromise = loadStripe('pk_test_51OlVN0GUaMXdrLF7S3Ow9FiSruSvJvzWi6yV5OgqhdiS9KDHOmmGBQoYGCVq60HrBodSpqEFO7EYCUscDVkV6B1S00hpiHWp6Q');

const PaymentModal = ({ waitListPlayer,visible, onClose,clientSecret, sports, waitList }) => {
  console.log("frim payment modal" , waitListPlayer);
  
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false);
    var participantId = '';
    var tournamentId = '';

    const initializeStripe = async () => {
        const stripe = await stripePromise;
        const options = {
          clientSecret: clientSecret,
          appearance: {},
        };
      };
      if (visible && clientSecret) {
        initializeStripe();
      }
    const handelSubmit = async() =>{
      setIsDisabled(true);
      const sportsArray = [];
      let res =  await getPlayerSports();
          if (res.status) {
              tournamentId = res.data[0].tournament_id;
              participantId = res.data[0].player_id;
              res.data.forEach(data => { 
                  sportsArray.push(data.sports);
              });
          } else {
            enqueueSnackbar('unable to fetch sports', { variant: 'error' });

          }

          if(sportsArray.length > 0) {
              let formData = {
                'tournament_id' : tournamentId,
                'participant_id' : participantId,
                'badminton': sportsArray.includes('badminton') ? 'true' : 'false',
                'table_tenis': sportsArray.includes('tennis') ? 'true' : 'false',

              }
              let res = await GenrateMatch(formData);
              if(res.status) {
                //remove player from waitlist
                enqueueSnackbar('Welcome to CPL', { variant: 'success' });
                navigate('/dashboard');

              } else {
                enqueueSnackbar('unable to genrate match', { variant: 'error' });
              }
          }

    }
    return (
        <>

          <CModal
            alignment="center"
            visible={visible}
            onClose={onClose}
            aria-labelledby="VerticallyCenteredExample"
          >
            <CModalHeader>
              <CModalTitle id="VerticallyCenteredExample">Make a Payment</CModalTitle>
            </CModalHeader>
            <CModalBody >
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                {/* <PaymentElement id="payment-element" /> */}
                   <CheckoutForm sports={sports} waitList= {waitList} waitListPlayer ={waitListPlayer} />
                </Elements>
            </CModalBody>

          </CModal>
        </>
      )
}


export default PaymentModal;
