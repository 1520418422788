// src/layouts/UserLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ScorerHeader } from '../components/index';

const ScorerLayout = () => {
  return (
    <div>
      <ScorerHeader />
      <Outlet />
      
    </div>
  );
};

export default ScorerLayout;


