import React, { useState, useRef, useEffect  } from "react";
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons';
import { withdrawal } from "../../API/player";
import { useSnackbar } from 'notistack';
import Swal from "sweetalert2";
import Loader from '../../views/admin/Common/Loader'
// import Loader from '../../Common/Loader'

import { getPlayerSportsLatest } from "../../API/player";
import {
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CFormInput,
    CForm,
} from '@coreui/react'
 const Withdrwal = (visibleModal) => {

const [visible, setVisible] = useState(false)
const [Sports, setSports] = useState([])
const { enqueueSnackbar } = useSnackbar();
const [loaderStatus, setloaderStatus] = useState(false);

useEffect(() => {
    let callAPI = async () => {

        let res = await getPlayerSportsLatest();
        console.log(res.data.data.sports)
        
        if (res.data.status) {
          const availableSports = res.data.data.sports.filter(sport => !res.data.data.withdrawan_sports.includes(sport));
        
          setSports(availableSports);
        
          const firstAvailableSport = availableSports.find(sport => sport !== res.data.data.withdrawan_sports[0]);
          setSelectedSport(firstAvailableSport)
        } else {

        }
    }
    callAPI()
}, [])
const [selectedSport, setSelectedSport] = useState('');
const sports = ['badminton', 'table_tenis', 'bowling', '2k_run'];

// handle function for select
const handleSelectChange = (event) => {
  setSelectedSport(event.target.value);
  // You can also add any additional logic here, like triggering API calls or other state updates.
  console.log(`Selected sport: ${event.target.value}`);
};

const handelSubmit = () => {
    console.log(selectedSport);
    Swal.fire({
        title: "Are you sure to withdrawal from game?",
        text: "After withdrawal, you will not be able to continue with games",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then(async (result) => {
        if (result.isConfirmed) {
            let formData = {
                "game_type" : selectedSport
            }
            setloaderStatus(true)
            let res = await withdrawal(formData)
            if (res.data.status) {
              setloaderStatus(false)
              Swal.fire({
                title: "You have withdrwan from game!",
                text: "Your withdarwal request has been proceed, thank you",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Logout",
                showCancelButton: true,
                cancelButtonText:"Continue",
                cancelButtonColor:":#d33"
              })

            } else {
                setloaderStatus(false)
                res.data.liveMatch ? enqueueSnackbar('Matches are live for selected sports, ypu can not withdrawal ', { variant: 'error' }): enqueueSnackbar('Unable to withdrwal', { variant: 'error' })

            }
        }
    });
}
   return (
     <>
     {loaderStatus ? <Loader /> : null}
       <CButton className="text-white" color="danger" onClick={() => setVisible(!visible)}>Withdrawal</CButton>
       <CModal
         alignment="center"
         scrollable
         visible={visible}
         onClose={() => setVisible(false)}
         aria-labelledby="VerticallyCenteredScrollableExample2"
       >
         <CModalHeader>
           <CModalTitle id="VerticallyCenteredScrollableExample2">Withdrawal from sports</CModalTitle>
         </CModalHeader>
         <CModalBody>

           <select className="form-select" value={selectedSport} onChange={handleSelectChange}>

                    {Sports.length > 0 ? (
              Sports.map((data, index) => (
                <option key={index} value={data}>
                  {data === "badminton"
                    ? "Badminton"
                    : data === "table_tenis"
                    ? "Table Tennis"
                    : data === "bowling"
                    ? "Bowling"
                    : data === "2k_run"
                    ? "2k Run"
                    : "N/A"}
                </option>
              ))
            ) : <option  value="" disabled> No Sport available</option>}
            
           </select>
         </CModalBody>
         <CModalFooter>
           <CButton color="secondary" onClick={() => setVisible(false)}>
             Close
           </CButton>
           <CButton onClick={() => {handelSubmit()}} color="primary" disabled={Sports.length === 0}>Save changes</CButton>
         </CModalFooter>
       </CModal>
     </>
   )
}
export default Withdrwal