import React from 'react';
import { Link } from 'react-router-dom';
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import CIcon from '@coreui/icons-react'

import { cilArrowRight } from '@coreui/icons';

const AdminBreadcrumb = ({ links }) => {
    return (
        <CBreadcrumb className='align-items-center'>
            {links.map((link, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <CIcon style={{marginLeft:'10px'}} icon={cilArrowRight} className='me-2' />}
                    <Link to={link.to} className={`text-decoration-none${link.active ? ' active' : ''}`}>
                        <CBreadcrumbItem active={link.active}>{link.label}</CBreadcrumbItem>
                    </Link>
                </React.Fragment>
            ))}
        </CBreadcrumb>
    );
}

export default AdminBreadcrumb;