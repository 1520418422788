import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom'

const Database = () => {
    const user_token_localstorage = localStorage.getItem('token');
    const apiRoute = process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE;
  
    const headers = (data) => {
        const currentUrl = window.location.href;
         return {   
            'Content-Type': data.content_type !== undefined && data.content_type  ? data.content_type : 'application/json',
            'Authorization': data.token !== undefined ? data.token : user_token_localstorage ,
        };
    }

    const redirect_login = () => {
        const currentUrl = window.location.href;
        var url = currentUrl.includes('admin') ? '/admin/login' : '/';
        enqueueSnackbar('Unauthorized request, Please login again', { variant: 'error' });
        setTimeout(function() {
            localStorage.clear();
            // navigate(url);
        }, 1000);
    }

    const Get = async({url}) =>  {
        const header = headers({content_type:'application/json',   'Authorization': `Bearer ${user_token_localstorage}`, token: user_token_localstorage});
        return await axios({
            method: 'GET',
            url:apiRoute+url,
            headers:header
        }).then(res => {
            if (res.data.data !== undefined && res.data.data.length) {
                res.data.data.forEach((element, index) => {
                    element.index = index + 1;
                });
            }
            return res.data;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response.data;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Delete = async({url, id}) => {
        const header = headers({content_type:'application/json'});
        return await axios({
            method: 'DELETE',
            url:apiRoute+url+id,
            headers:header
        }).then(res => {  
            return res.data;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response.data;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Add = async({url, bodyData}) => {
        const header = headers({content_type:'application/json'});
        return await axios({
            method: 'POST',
            url:apiRoute+url,
            headers:header,
            body: JSON.stringify(bodyData)
        }).then(res => {  
            return res.data;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response.data;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Put = async({url, bodyData}) => {
        const header = headers({content_type:'multipart/form-data'});
        return await axios({
            method: 'PUT',
            url:apiRoute+url,
            headers:header,
            body: JSON.stringify(bodyData)
        }).then(res => {  
            return res.data;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response.data;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Update = async({url, bodyData}) => {
        const header = headers({content_type:'application/json'});
        return await axios({
            method: 'PUT',
            url:apiRoute+url,
            headers:header,
            body: JSON.stringify(bodyData)
        }).then(res => {  
            return res.data;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response.data;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Login = async({url, bodyData}) => {
        const header = headers({content_type:'application/json'});
        return await axios.post(
            apiRoute+url,
            JSON.stringify(bodyData),
            {
                headers: header
            }
        ).then(res => {  
            return res.data;
        })
        .catch(error => {
            if (error.response) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response.data;
            }
        });
    };

    const Post = async({url, bodyData}) => {
        const header = headers({content_type:'application/json', token: user_token_localstorage});
        return await axios.post(
            apiRoute+url,
            bodyData,
            {
                headers: header
            }   
        )
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response;
            }
        });
    };
    const uploadPost = async ({ url, bodyData }) => {
        const header = headers({content_type:'multipart/form-data', token: user_token_localstorage});
        return await axios.post(
            apiRoute+url,
            bodyData,
            {
                headers: header
            }   
        )
        .then(response => {
            return response;
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response;
            } else {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response;
            }
        });
    };

    const Image = async({url, bodyData}) => {
        const header = headers({content_type: 'multipart/form-data',token: user_token_localstorage});
        return await axios.post(
            apiRoute+url,
            bodyData,
            {
                headers: header
            }   
        )
        .then(response => {
            return response;
        })
        .catch(error => {
            // console.log(error);
            if (error.response && error.response.status === 401) {
                redirect_login();
                return error.response;
            } else {
                // console.log(error);
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return error.response;
            }
        });
    }
  return {headers, Get, Delete, Add, Put, Update, Login, Post, Image  ,uploadPost}
};

export default Database;