import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 800 800"
    >
      <g fill="#fff" clipPath="url(#clip0_180_2299)">
        <path d="M733.25 273.125H465.125v-80.5c0-36.75-29.875-66.75-66.75-66.75L66.75 125.75C30 125.625 0 155.625 0 192.375V404.75c0 36.75 29.875 66.75 66.75 66.75h12.375V509c0 6.625 3.625 12.625 9.5 15.75a18.022 18.022 0 0018.5-.875L185.5 471.5H335v80.625c0 36.75 29.875 66.625 66.625 66.625h213L693 671.25a18.023 18.023 0 0018.5.875c5.875-3.125 9.5-9.125 9.5-15.75v-37.5h12.375c36.875 0 66.75-29.875 66.875-66.625V340c-.25-36.875-30.25-66.875-67-66.875zm-379.875 162.5h-173.5c-3.5 0-7 1-10 3l-55 36.75V453.5c0-9.875-8-17.875-17.875-17.875H66.75c-17 0-30.875-13.875-30.875-30.875V192.375c0-17 13.875-30.875 30.875-30.875l331.625.125c17 0 30.875 13.875 30.875 30.875v212.25c0 17-13.875 30.875-30.875 30.875h-45zm410.75 116.625c0 17-13.875 30.875-31 30.875h-30.25c-9.875 0-17.875 8-17.875 17.875v21.875L630 586c-3-2-6.375-3-10-3H401.5c-17 0-30.875-13.875-30.875-30.875V471.5h27.625c36.75 0 66.75-29.875 66.75-66.75v-96h268.125a30.996 30.996 0 0121.92 9.08 30.996 30.996 0 019.08 21.92v212.5z"></path>
        <path d="M292 349.5a7.176 7.176 0 00-2.75-1.5c-1.125-.375-2.75-1-4.75-1.875s-4.375-2.125-7.375-3.875c-2.875-1.75-6.25-4.25-10-7.5 2.5-2.5 4.75-5.375 6.625-8.625 2-3.25 3.625-6.875 4.875-10.875 1.375-4 2.375-8.25 3-12.875.75-4.625 1-9.625 1-14.875 0-10.25-1.25-19-3.625-26.5s-5.875-13.625-10.625-18.5c-4.625-4.875-10.375-8.5-17.125-10.875s-14.625-3.5-23.375-3.5c-9.375 0-17.625 1.375-24.625 4.25-7.125 2.875-13 7-17.875 12.25C180.625 250 177 256.375 174.5 264c-2.375 7.625-3.625 16.25-3.625 25.75 0 10.5 1.125 19.625 3.375 27.25s5.75 13.875 10.25 18.875c4.5 4.875 10.25 8.5 17.125 10.875s14.875 3.5 24 3.5c4.875 0 9.25-.5 13.25-1.375 4-1 7.375-2 10.125-3.375 3.375 3.875 7 7.25 10.875 10.125 3.875 3 7.75 5.5 11.5 7.5s7.25 3.625 10.5 4.75 5.875 1.625 7.875 1.625c.5 0 1-.125 1.375-.375s.875-.625 1.125-1.375c.432-.997.726-2.049.875-3.125.25-1.375.375-3.125.375-5.25 0-2.75-.125-4.875-.5-6.375s-.25-2.875-1-3.5zm-35.875-44.625c-1 5-2.75 9.5-5.125 13.25s-5.625 6.75-9.625 8.875c-4 2.25-8.875 3.25-14.75 3.25s-10.75-1-14.625-2.875-7-4.75-9.25-8.375a36.125 36.125 0 01-4.875-13.25 92.26 92.26 0 01-1.5-17.5c0-5.5.5-10.625 1.5-15.625s2.75-9.25 5.125-13 5.625-6.625 9.625-8.875 8.875-3.25 14.75-3.25 10.75 1 14.625 3 7 4.75 9.375 8.375 4 8 5 13 1.5 10.625 1.5 16.75a95.16 95.16 0 01-1.75 16.25zM584.75 387.25a8.823 8.823 0 00-1.375-2.875c-.5-.75-1.5-1.25-2.75-1.625a18.748 18.748 0 00-5.25-.625c-2.25-.125-5.25-.125-9.125-.125-3.25 0-5.875 0-7.875.125s-3.5.375-4.625.625c-1.125.375-2 .875-2.5 1.5s-1 1.5-1.375 2.625l-38.5 110.75c-.75 2.25-1.25 4-1.5 5.375s0 2.375.625 3.125 1.75 1.25 3.5 1.375c1.625.25 4 .25 7 .25 2.75 0 5-.125 6.75-.25s3-.375 4-.75c.875-.375 1.625-.875 2-1.5a9.367 9.367 0 001-2.25l7.875-24.375H589.5l8.375 25.125c.25.875.625 1.5 1 2s1 .875 2 1.25c1 .25 2.375.5 4.25.625s4.375.125 7.625.125c3.125 0 5.625-.125 7.375-.25s3-.625 3.75-1.25 1-1.625.75-3a31.186 31.186 0 00-1.5-5.5l-38.375-110.5zm-36.375 72.125l17.625-53h.125l17.625 53h-35.375z"></path>
      </g>
      <defs>
        <clipPath id="clip0_180_2299">
          <path fill="#fff" d="M0 0H800V800H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;